export const FONT_WEIGHT = {
  NORMAL: "font-normal",
  MEDIUM: "font-medium",
  SEMI_BOLD: "font-semibold",
  BOLD: "font-bold",
  EXTRA_BOLD: "font-extrabold",
};

export const FONT_SIZE = {
  MINIMUN: "text-minimun",
  XXS: "text-xxs",
  XS: "text-xs",
  SM: "text-sm",
  BASE: "text-base",
  LG: "text-lg",
  XL: "text-xl",
  "2XL": "text-2xl",
  "3XL": "text-3xl",
};

export const LINE_CLAMP = {
  LINE_CLAMP_1: "line-clamp-1",
  LINE_CLAMP_2: "line-clamp-2",
  LINE_CLAMP_3: "line-clamp-3",
  LINE_CLAMP_4: "line-clamp-4",
};

export const FONT_COLOR = {
  LIGHT: "text-primary-light",
  EXTRA_LIGHT: "text-primary-extralight",
  DEFAULT: "text-primary",
  DARK: "text-primary-dark",
  DARK_LIGHT: "text-dark-light",
  SECONDARY: "text-secondary",
  TERTIARY: "text-tertiary",
  QUATERNARY: "text-quaternary",
  
  OTHERS: {
    WHITE: "text-white",
    GREEN: "text-green",
    RED: "text-red",
    BLUE_DARK: "text-blue-dark",
    BLUE_LIGHT: "text-blue-light",
    BLUE: "text-blue",
    GREEN_LIGHT: "text-green-light",
    GRAY_LIGHT: "text-gray-light",
    GREEN_DARK: "text-green-dark",
    RED_LIGHT: "text-red-light",
    RED_DARK: "text-red-dark",
  },
};
