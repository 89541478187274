import {
    GET_TASKS_RESOLVED_REQUEST,
    GET_TASKS_RESOLVED_SUCCESS,
    GET_TASKS_RESOLVED_FAILURE
} from '../actions/tasksResolved'

const initialState = {
    statistics: {},
    fetching: false,
    getTaskResolvedSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TASKS_RESOLVED_REQUEST: {
            return {
                ...state,
                fetching: true,
                getTaskResolvedSuccess: false
            };
        }
        case GET_TASKS_RESOLVED_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getTaskResolvedSuccess: true
            };
        }
        case GET_TASKS_RESOLVED_FAILURE: {
            return {
                ...state,
                fetching: false,
                getTaskResolvedSuccess: false
            };
        }
        default:
            return state;
    }
}