import profilesSel from './profilesSel';
import departments from './departments';
import channels from './channels';
import status from './status';

export default function* () {
    yield* [
        ...profilesSel,
        ...departments,
        ...channels,
        ...status
    ];
}