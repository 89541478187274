import {
    POST_LINKEDIN_ACCOUNT_REQUEST,
    POST_LINKEDIN_ACCOUNT_SUCCESS,
    POST_LINKEDIN_ACCOUNT_FAILURE,
    GET_LINKEDIN_ACCOUNT_REQUEST,
    GET_LINKEDIN_ACCOUNT_SUCCESS,
    GET_LINKEDIN_ACCOUNT_FAILURE,
    PUT_LINKEDIN_ACCOUNT_REQUEST,
    PUT_LINKEDIN_ACCOUNT_SUCCESS,
    PUT_LINKEDIN_ACCOUNT_FAILURE,
    DELETE_LINKEDIN_ACCOUNT_REQUEST,
    DELETE_LINKEDIN_ACCOUNT_SUCCESS,
    DELETE_LINKEDIN_ACCOUNT_FAILURE,
} from '../actions/linkedInChannel';

const initialState = {
    fetching: true,
    instagramUser: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_LINKEDIN_ACCOUNT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_LINKEDIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                linkedInAccount: action.payload.linkedInAccount
            };
        }
        case POST_LINKEDIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case PUT_LINKEDIN_ACCOUNT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case PUT_LINKEDIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                linkedInAccount: action.payload.linkedInAccount
            };
        }
        case PUT_LINKEDIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case GET_LINKEDIN_ACCOUNT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case GET_LINKEDIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                linkedInAccount: action.payload.linkedInAccount
            };
        }
        case GET_LINKEDIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_LINKEDIN_ACCOUNT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_LINKEDIN_ACCOUNT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                linkedInAccountDeleted: action.payload.linkedInAccountDeleted,
                linkedInAccount: {}
            };
        }
        case DELETE_LINKEDIN_ACCOUNT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
}