import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    GET_FILTERS_REQUEST, 
    getFiltersFailure, 
    getFiltersSuccess,
} from '../actions/filters';
import { showGlobalError } from '../../app/actions/app';
import { 
    getFiltersServices,
} from '../services/filters';
import { accessTokenSelector } from '../../auth/selectors/token';

export const getFiltersWorker = function* ({ payload: { view, entity } = {} } = {}) {
    try {

        const data = {
            view,
            entity,
        }

        const token = yield select(accessTokenSelector);

        const { data : { filters } } = yield call(getFiltersServices, token, data);

        yield put(getFiltersSuccess(filters, view, entity));
    } catch (err) {
        console.log(err);

        yield put(getFiltersFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(GET_FILTERS_REQUEST, getFiltersWorker),
];