import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postTelegramBotsServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/telegram`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const putTelegramBotsServices = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/telegram/${data.botId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const getTelegramBotsServices = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/telegram`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const deleteTelegramBotsServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/telegram/${data.botId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
