export const POST_WHATSAPP_NUMBER_REQUEST = 'POST_WHATSAPP_NUMBER_REQUEST';
export const POST_WHATSAPP_NUMBER_SUCCESS = 'POST_WHATSAPP_NUMBER_SUCCESS';
export const POST_WHATSAPP_NUMBER_FAILURE = 'POST_WHATSAPP_NUMBER_FAILURE';

export const GET_WHATSAPP_NUMBER_REQUEST = 'GET_WHATSAPP_NUMBER_REQUEST';
export const GET_WHATSAPP_NUMBER_SUCCESS = 'GET_WHATSAPP_NUMBER_SUCCESS';
export const GET_WHATSAPP_NUMBER_FAILURE = 'GET_WHATSAPP_NUMBER_FAILURE';

export const PUT_WHATSAPP_NUMBER_REQUEST = 'PUT_WHATSAPP_NUMBER_REQUEST';
export const PUT_WHATSAPP_NUMBER_SUCCESS = 'PUT_WHATSAPP_NUMBER_SUCCESS';
export const PUT_WHATSAPP_NUMBER_FAILURE = 'PUT_WHATSAPP_NUMBER_FAILURE';

export const DELETE_WHATSAPP_NUMBER_REQUEST = 'DELETE_WHATSAPP_NUMBER_REQUEST';
export const DELETE_WHATSAPP_NUMBER_SUCCESS = 'DELETE_WHATSAPP_NUMBER_SUCCESS';
export const DELETE_WHATSAPP_NUMBER_FAILURE = 'DELETE_WHATSAPP_NUMBER_FAILURE';

export const postWhatsAppNumberRequest = whatsAppNumber => ({
    type: POST_WHATSAPP_NUMBER_REQUEST,
    payload: {
        whatsAppNumber
    }  
});

export const postWhatsAppNumberSuccess = whatsAppNumber => ({
    type: POST_WHATSAPP_NUMBER_SUCCESS,
    payload: {
        whatsAppNumber
    }  
});

export const postWhatsAppNumberFailure = err => ({
    type: POST_WHATSAPP_NUMBER_FAILURE,
    error: err
});

export const getWhatsAppNumberRequest = (filters = {}) => ({
    type: GET_WHATSAPP_NUMBER_REQUEST,
    payload: {
        filters
    }  
});

export const getWhatsAppNumberSuccess = whatsAppNumber => ({
    type: GET_WHATSAPP_NUMBER_SUCCESS,
    payload: {
        whatsAppNumber
    }  
});


export const getWhatsAppNumberFailure = err => ({
    type: GET_WHATSAPP_NUMBER_FAILURE,
    error: err
});

export const putWhatsAppNumberRequest = whatsAppNumber => ({
    type: GET_WHATSAPP_NUMBER_REQUEST,
    payload: {
        whatsAppNumber
    }  
});

export const putWhatsAppNumberSuccess = whatsAppNumber => ({
    type: PUT_WHATSAPP_NUMBER_REQUEST,
    payload: {
        whatsAppNumber
    }  
});

export const putWhatsAppNumberFailure = err => ({
    type: PUT_WHATSAPP_NUMBER_FAILURE,
    error: err
});

export const deleteWhatsAppNumberRequest = hatsAppNumberDeleted => ({
    type: DELETE_WHATSAPP_NUMBER_REQUEST,
    payload: {
        hatsAppNumberDeleted
    }  
});

export const deleteWhatsAppNumberSuccess = whatsAppNumberDeleted => ({
    type: DELETE_WHATSAPP_NUMBER_SUCCESS,
    payload: {
        whatsAppNumberDeleted,
        whatsAppNumber: {}
    }  
});

export const deleteWhatsAppNumberFailure = err => ({
    type: DELETE_WHATSAPP_NUMBER_FAILURE,
    error: err
});