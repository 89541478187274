import {
  SET_CONTENT_VIEW_EMAIL_MODAL,
  SET_OPEN_VIEW_EMAIL_MODAL,
} from "../actions/viewEmailModal";

const INITIAL_STATE = {
  isVisible: false,
  content: null,
  subject: null,
  transmitterName: null,
  transmitterEmail: null,
  receiver: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_VIEW_EMAIL_MODAL: {
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };
    }

    case SET_CONTENT_VIEW_EMAIL_MODAL: {
      return {
        ...state,
        content: action.payload.content,
        receiver: action.payload.receiver,
        subject: action.payload.subject,
        transmitterName: action.payload.transmitterName,
        transmitterEmail: action.payload.transmitterEmail,
      };
    }

    default:
      return state;
  }
};
