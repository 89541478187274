import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getChannelsServices = (token) => {
    return axios({
        method: 'get',
        url: `${api}/v1/channels`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })
}