export const GET_APIKEYS_REQUEST = 'GET_APIKEYS_REQUEST';
export const GET_TYPING_APIKEYS_REQUEST = 'GET_TYPING_APIKEYS_REQUEST';
export const GET_APIKEYS_SUCCESS = 'GET_APIKEYS_SUCCESS';
export const GET_APIKEYS_FAILURE = 'GET_APIKEYS_FAILURE';

export const GET_APIKEY_REQUEST = 'GET_APIKEY_REQUEST';
export const GET_APIKEY_SUCCESS = 'GET_APIKEY_SUCCESS';
export const GET_APIKEY_FAILURE = 'GET_APIKEY_FAILURE';

export const SET_APIKEYS = 'SET_APIKEYS';
export const SET_APIKEY = 'SET_APIKEY';

export const SUBMIT_APIKEY_REQUEST = 'SUBMIT_APIKEY_REQUEST';
export const SUBMIT_APIKEY_SUCCESS = 'SUBMIT_APIKEY_SUCCESS';
export const SUBMIT_APIKEY_FAILURE = 'SUBMIT_APIKEY_FAILURE';

export const DELETE_APIKEY_REQUEST = 'DELETE_APIKEY_REQUEST';
export const DELETE_APIKEY_SUCCESS = 'DELETE_APIKEY_SUCCESS';
export const DELETE_APIKEY_FAILURE = 'DELETE_APIKEY_FAILURE';

export const getApikeysRequest = (filters = {}) => ({
    type: GET_APIKEYS_REQUEST,
    payload: {
        filters
    }
});

export const getTypingApikeysRequest = (filters = {}, onlyProfile) => ({
    type: GET_TYPING_APIKEYS_REQUEST,
    payload: {
        filters,
        onlyProfile
    }
});

export const getApikeysSuccess = (apikeys, total, profiles) => ({
    type: GET_APIKEYS_SUCCESS,
    payload: {
        apikeys,
        total,
        profiles
    }
});

export const getApikeysFailure = err => ({
    type: GET_APIKEYS_FAILURE,
    error: err
});

export const getApikeyRequest = (filters = {}) => ({
    type: GET_APIKEY_REQUEST,
    payload: {
        filters
    }
});

export const getApikeySuccess = (apikey) => ({
    type: GET_APIKEY_SUCCESS,
    payload: {
        apikey
    }
});

export const getApikeyFailure = err => ({
    type: GET_APIKEY_FAILURE,
    error: err
});

export const setApikeys = (apikeys = [], total = 0) => ({
    type: SET_APIKEYS,
    payload: {
        apikeys,
        total
    }
});

export const setApikey = (apikey = {}) => ({
    type: SET_APIKEY,
    payload: {
        apikey
    }
});

export const submitApikeysRequest = (apikey, id) => ({
    type: SUBMIT_APIKEY_REQUEST,
    payload: { apikey, id }
});

export const submitApikeysSuccess = apikey => ({
    type: SUBMIT_APIKEY_SUCCESS,
    payload: {
        apikey
    }
});

export const submitApikeysFailure = err => ({
    type: SUBMIT_APIKEY_FAILURE,
    error: err
});

export const deleteApiKeyRequest = (id) => ({
    type: DELETE_APIKEY_REQUEST,
    payload:{
        id
    }
});

export const deleteApiKeySuccess = () => ({
    type: DELETE_APIKEY_SUCCESS
});

export const deleteApiKeyFailure = (err) => ({
    type: DELETE_APIKEY_FAILURE,
    error: err
});
