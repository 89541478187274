import { SET_FILTERS_PUBLICATIONS } from '../actions/filters';

const initialState = {
    text: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PUBLICATIONS:{
            return{
                ...state,
                text: action.payload.text
            }
        }
        default:
            return state;
    }
}

