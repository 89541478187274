export const GET_PREFERENCES_REQUEST = 'GET_PREFERENCES_REQUEST';
export const getPreferencesRequest = () => ({
    type: GET_PREFERENCES_REQUEST,
})
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const getPreferencesSuccess = (preferences) => ({
    type: GET_PREFERENCES_SUCCESS,
    payload: preferences
})
export const GET_PREFERENCES_FAILURE = 'GET_PREFERENCES_FAILURE';
export const getPreferencesFailure = (err) => ({
    type: GET_PREFERENCES_FAILURE,
    error: err
})
export const SUBMIT_PREFERENCES_REQUEST = 'SUBMIT_PREFERENCES_REQUEST';
export const submitPreferencesRequest = (company) => ({
    type: SUBMIT_PREFERENCES_REQUEST,
    payload: { company }
})
export const SUBMIT_PREFERENCES_SUCCESS = 'SUBMIT_PREFERENCES_SUCCESS';
export const submitPreferencesSuccess = (company) => ({
    type: SUBMIT_PREFERENCES_SUCCESS,
    payload: { company }
})
export const SUBMIT_PREFERENCES_FAILURE = 'SUBMIT_PREFERENCES_FAILURE';
export const submitPreferencesFailure = (err) => ({
    type: SUBMIT_PREFERENCES_FAILURE,
    payload: err
})