import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getActionsServices = (token, botId) =>
    axios({
        method: 'get',
        url: `${api}/v1/chatbot/bots/${botId}/actions/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const postActionServices = (token, botId, action) =>
    axios({
        method: 'post',
        url: `${api}/v1/chatbot/bots/${botId}/actions/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: action
});

export const deleteActionServices = (token, botId, id) =>
    axios({
        method: 'delete',
        url: `${api}/v1/chatbot/bots/${botId}/actions/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });