import { lazy } from "react";
import { inProgressSVG } from '../shared/helpers/icons';
import rulesRoutes from '../rules/routes';
import workflowsRoutes from '../workflows/routes';
import botsRoutes from "../bots/routes";

const EntryPoint = lazy(() => import('./components/EntryPoint'));

export const defaultPath = '/automation';

export const innerRoutes = [
    botsRoutes,
    rulesRoutes,
    workflowsRoutes
];

const Automation = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: inProgressSVG,
    title: {
        key: 'automation',
        plural: false
    },
    subTitle: {
        key: 'manage_automations',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path),
    availableSubMenu: true
};

export default Automation;
