import {
    GET_FILTERS_REQUEST,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_FAILURE,
} from '../actions/filters';

import { formatFilterForView } from '../helpers';

const initialState = {
    filters: {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_FILTERS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }

        case GET_FILTERS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                filters: formatFilterForView(action.payload.filters, action.payload.view,action.payload.entity),
            };
        }

        case GET_FILTERS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }
        
        default:
            return state;
    }
};