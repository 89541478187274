import { call, put, select, takeLatest } from "redux-saga/effects";
import { 
    getFinalStatusesFailure,
    getFinalStatusesSuccess,
    getStatusesFailure, 
    getStatusesSuccess, 
    GET_FINAL_STATUSES_REQUEST, 
    GET_STATUSES_REQUEST 
} from "../actions/status";
import { accessTokenSelector } from '../../auth/selectors/token';
import { getStatusesService } from "../services/status";

export const getStatusesWorker = function* () {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(getStatusesService, token, false)
        yield put(getStatusesSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(getStatusesFailure(err));
    }
};

export const getFinalStatusesWorker = function* () {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(getStatusesService, token, true)
        yield put(getFinalStatusesSuccess(data));
    } catch (err) {
        console.log(err);
        yield put(getFinalStatusesFailure(err));
    }
};

export default [
    takeLatest(GET_STATUSES_REQUEST, getStatusesWorker),
    takeLatest(GET_FINAL_STATUSES_REQUEST, getFinalStatusesWorker)
];
