import { lazy } from "react";
import { noteSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Tickets - EntryPoint" */ './components/EntryPoint'));
const ListLoader = lazy(() => import(/* webpackChunkName: "Tickets - ListLoader" */ './components/ListLoader'));
const FormLoader = lazy(() => import(/* webpackChunkName: "Tickets - FormLoader" */ './components/FormLoader'));
const AggentAssistance = lazy(() => import(/* webpackChunkName: "Tickets - AggentAssistance" */ './components/AgentAssistance/AgentAssistance'));

export const defaultPath = `/tickets`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: ListLoader,
        exact: true,
        icon: noteSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'cases_list_detail',
            plural: false
        }
    },
    {
        path: `${defaultPath}/newTicket`,
        Component: FormLoader,
        exact: true,
        icon: noteSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'add_ticket',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: AggentAssistance,
        exact: true,
        icon: noteSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'case_detail',
            plural: false
        }
    }
];

const Tickets = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: noteSVG,
    title: {
        key: 'cases',
        plural: false
    },
    subTitle: {
        key: 'cases_list_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Tickets;
