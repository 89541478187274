import tickets from './tickets';
import ticketsTypes from './ticketsTypes';
import ticketsReasons from './ticketsReasons';
import ticketsDetails from './ticketsDetails';
import ticketUsers from './ticketUsers';
import comments from './comments';
import contacts from './contacts';
import tags from './tags';
import customAttributes from './customAttributes';

export default function* () {
    yield* [
        ...tickets,
        ...ticketsTypes,
        ...ticketsReasons,
        ...ticketsDetails,
        ...comments,
        ...ticketUsers,
        ...contacts,
        ...tags,
        ...customAttributes
    ];
}
