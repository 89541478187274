import { call, put, select, takeLatest } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    getTasksResolvedSuccess,
    getTasksResolvedFailure,
    GET_TASKS_RESOLVED_REQUEST
} from '../actions/tasksResolved';
import {
    getTasksResolvedService
} from '../services/tasksResolved';

export const getTasksResolvedWorker = function* ({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }

        const { data } = yield call(getTasksResolvedService, token, filters);
        yield put(getTasksResolvedSuccess(data));
    }
    catch (err) {
        console.log('ERROR', err)
        yield put(getTasksResolvedFailure(err))
    };
};

export default [
    takeLatest(GET_TASKS_RESOLVED_REQUEST, getTasksResolvedWorker)
];