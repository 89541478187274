export const SET_FILTERS = 'SET_FILTERS';

export const setFilters = ({name, user, workflow, trigger, condition, actions, enabled}) => ({
    type:SET_FILTERS,
    payload:{
        name,
        user,
        workflow,
        trigger,
        condition,
        actions,
        enabled
    }
});