import {
    GET_WEBCHAT_REQUEST,
    GET_WEBCHAT_FAILURE,
    GET_WEBCHAT_SUCCESS
} from '../actions/webchat';
import { SET_WEBCHAT_FAILURE, SET_WEBCHAT_REQUEST, SET_WEBCHAT_SUCCESS } from '../actions/webchat';

const initialState = {
    fetching: false,
    webchat: {},
    fetchingEdit: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_WEBCHAT_REQUEST: {
            return{
                ...state,
                fetching: true
            }
        }
        case GET_WEBCHAT_SUCCESS: {
            return{
                ...state,
                fetching: false,
                webchat: action.payload.webchat
            }
        }
        case GET_WEBCHAT_FAILURE: {
            return{
                ...state,
                fetching: false
            }
        }
        case SET_WEBCHAT_REQUEST:
            return {
                ...state,
                fetchingEdit: true,
                putSuccess: false,
            }
        case SET_WEBCHAT_SUCCESS:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: true,
                webchat: action.payload.webchat
            }
        case SET_WEBCHAT_FAILURE:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: false,
            }
        default:
            return state;
    }
}