import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { validTokensSelector } from "../../selectors/token"
import { refreshTokenRequest } from '../../actions/refresh';

import TokenRefresh from './TokenRefresh';

const mapStateToProps = createStructuredSelector({
    validTokens: validTokensSelector
});

const mapDispatchToProps = { refreshTokenRequest };
export default connect(mapStateToProps, mapDispatchToProps)(TokenRefresh);