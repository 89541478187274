export const ICONS = {
  LOGO: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H20C20.5304 4 21.0391 4.21071 21.4142 4.58579C21.7893 4.96086 22 5.46957 22 6V18C22 18.5304 21.7893 19.0391 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20ZM4 6V18H20V6H4ZM6 9H18V11H6V9ZM6 13H16V15H6V13Z" fill="white"/>
  </svg>`,
  MORE: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#1967D2"/>
  </svg>
  `,
  LOCK: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.00033 11.3344C7.26033 11.3344 6.66699 10.7344 6.66699 10.0011C6.66699 9.26106 7.26033 8.66773 8.00033 8.66773C8.35395 8.66773 8.69309 8.8082 8.94314 9.05825C9.19318 9.3083 9.33366 9.64744 9.33366 10.0011C9.33366 10.3547 9.19318 10.6938 8.94314 10.9439C8.69309 11.1939 8.35395 11.3344 8.00033 11.3344ZM12.0003 13.3344V6.66773H4.00033V13.3344H12.0003ZM12.0003 5.33439C12.3539 5.33439 12.6931 5.47487 12.9431 5.72492C13.1932 5.97497 13.3337 6.3141 13.3337 6.66773V13.3344C13.3337 13.688 13.1932 14.0272 12.9431 14.2772C12.6931 14.5273 12.3539 14.6677 12.0003 14.6677H4.00033C3.26033 14.6677 2.66699 14.0677 2.66699 13.3344V6.66773C2.66699 5.92773 3.26033 5.33439 4.00033 5.33439H4.66699V4.00106C4.66699 3.117 5.01818 2.26916 5.6433 1.64404C6.26842 1.01891 7.11627 0.667725 8.00033 0.667725C8.43807 0.667725 8.87152 0.753944 9.27594 0.92146C9.68036 1.08898 10.0478 1.33451 10.3573 1.64404C10.6669 1.95356 10.9124 2.32103 11.0799 2.72545C11.2474 3.12987 11.3337 3.56332 11.3337 4.00106V5.33439H12.0003ZM8.00033 2.00106C7.46989 2.00106 6.96119 2.21177 6.58611 2.58684C6.21104 2.96192 6.00033 3.47063 6.00033 4.00106V5.33439H10.0003V4.00106C10.0003 3.47063 9.78961 2.96192 9.41454 2.58684C9.03947 2.21177 8.53076 2.00106 8.00033 2.00106Z" fill="#757575"/>
  </svg>  
  `,
  ARROW: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.66602 6.6665L7.99935 9.99984L11.3327 6.6665H4.66602Z" fill="#757575"/>
  </svg>
  `,
  CHECK: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.0742 7.55478L9.07422 19.5548L3.57422 14.0548L4.98422 12.6448L9.07422 16.7248L19.6642 6.14478L21.0742 7.55478Z" fill="#538DDD"/>
  </svg>`,
};
