import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getStatisticsService = (token, filters) =>
    axios({
        method: 'get',
        url: `${api}/v1/statistics/channelStatistics?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getAllCasesService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/generals?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getScoresAverageService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/tasksScoresAverage?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getTasksTypingService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/taskstyping?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getDerivedCasesService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/forwardedTasks?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getAbandonedCasesService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/abandonedTasks?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getDownloadStatisticsService = (token, connectionId, channel, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/reports/${connectionId}?channel=${channel}&${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getDownloadSystemStatisticsService = (token, channel, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/export?channel=${channel}&${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
})

export const getCasesByDateService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/statusesByDate?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getCasesByAgentService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/statusesByAgent?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getTimeAssociatedAgentTaskService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/timeByAssigned?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSessionsStatisticsService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/userSessions?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getInactivityTimeByAgentService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/inactivityTime?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getCustomAttributesStatisticsService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/statistics/customAttributes?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })