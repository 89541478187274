import moment from "moment";
import _ from "lodash";
import { noImageSVG, userSVG } from "./icons";
import Text from "../components/Text/Text";
import { FONT_SIZE, FONT_WEIGHT, LINE_CLAMP } from "../components/Text/types";

export const buildArrayLength = length => {
    let l = [];
    for (let index = 0; index < length; index++) {
        l.push(index);
    }
    return l;
};

export const scrolly = (action, event) => {
    let element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        action();
    }
};

export const complementColor = color => {
    if (color.length === 7 && color.slice(0, 1) === '#') {
        color = color.slice(1);
    }
    return parseInt(color.slice(0, 2), 16) + parseInt(color.slice(2, 4), 16) + parseInt(color.slice(4), 16) > 500
        ? '#262d3d'
        : 'whitesmoke';
};

export const makeQuery = filters => {
    if (!filters) return '';
    return Object.keys(filters)
        .reduce(function (a, k) {
            a.push(k + '=' + encodeURIComponent(filters[k].toString().trim()));
            return a;
        }, [])
        .join('&');
};

export const makeFilters = (filters) => {
    if(Object.keys(filters).length) {
        let filter = ''
        Object.keys(filters).forEach(f => {
            filter += f + '@' + filters[f] + ','
        })
        filter = filter.slice(0, -1)
        return filter
    } else {
        return '';
    }
}

export const makeContractFilter = (contracts) => {
    if (contracts.length) {
        let filter = '-'
        contracts.forEach( contract => {
            filter += contract + '-'
        })
        filter = filter.slice(0, -2)
        return filter
    } else {
        return ''
    }
}

export const emailIsValid = email => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
export const userNameIsValid = username => /^\w+$/g.test(username);
export const passwordIsValid = pass => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(pass);

export const rutIsValid = completeRut => {
    if (!completeRut) return false;
    let rut = completeRut.replace('-', '');
    if (rut.match(/^[̣0-9]+[0-9K]+[0-K]$/) === null) return false;
    let M = 0,
        S = 1;
    let T = rut.slice(0, -1);
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    let vd = S ? S - 1 : 'K';
    return vd.toString() === rut.slice(-1).toUpperCase();
};

export const dniIsValid = dni => String(dni).length >= 6 && String(dni).length <= 9

export const argentineCarPlateIsValid = plate => {

    const plateUpperCase = String(plate).toUpperCase();

    if (plateUpperCase.length === 6) {
        return /[A-ZÑ]{3}[0-9]{3}$/.test(plateUpperCase)
    } else if (plateUpperCase.length === 7) {
        return /[A-ZÑ]{2}[0-9]{3}[A-ZÑ]{2}$/.test(plateUpperCase)
    } else if (plateUpperCase.length === 8) {
        return true
    } else {
        return false
    }
}

export const argentineMotorcyclePlateIsValid = plate => {

    const plateUpperCase = String(plate).toUpperCase();

    if (plateUpperCase.length === 9) {
        return /[A-ZÑ]{1}[0-9]{3}[A-ZÑ]{3}[/ /][A-ZÑ]{1}$/.test(plateUpperCase);
    } else if (plateUpperCase.length === 8) {
        return true
    } else if (plateUpperCase.length === 7) {
        return /[A-ZÑ]{1}[0-9]{3}[A-ZÑ]{3}$/.test(plateUpperCase);
    } else if (plateUpperCase.length === 6) {
        return /[0-9]{3}[A-ZÑ]{3}$/.test(plateUpperCase)
    } else {
        return false
    }
}

export const parseToDni = (identifier) => String(identifier).replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const scrollYTo = (div, to) => {
    if (div && typeof +to === "number") {
        setTimeout(() => {
            div.scrollTop = +to;
        }, 100);
    }
}

export const addDefaultSrc = (ev) => {
    ev.target.src = userSVG;
}

export const addDefaultImageSrc = (ev) => {
    ev.target.src = noImageSVG;
}

export const dataURLtoFile = (dataurl, filename) => {
    try {

        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    } catch (error) {
        throw error;
    }
}

export const capitalize = (s) => {
    let resp = '';
    if(s){
        resp =s && s[0].toUpperCase() + s.slice(1);
    }
    return resp 
}

export const currentMonth = moment().format("M");
export const currentDay = moment().format("DD");

export const validateByPlateType = (type) => {
    switch (type) {
        case 'bicycle': {
            return {
                required: false
            }
        }
        case 'object': {
            return {
                required: false
            }
        }
        case 'pedestrian': {
            return {
                required: false
            }
        }
        case 'motorcycle': {
            return {
                required: true,
                validate: argentineMotorcyclePlateIsValid
            }
        }
        default: {
            return {
                required: true,
                validate: argentineCarPlateIsValid
            }
        }
    }
}

export const filterAllowingKeys = (obj, allowedKeys = []) => ( _.pick(obj, allowedKeys));

export const filterOmmittingKeys = (obj, ommittedKeys = []) => ( _.omit(obj, ommittedKeys));

export const convertArrayToObject = (array, key='value') => {
    let object = {}
    array.forEach(element => {
        object[element[key]] = element;
    });
    return object
}

export const formatSelectOptions = (arrayToFormat = [], labelField, valueField) => {
    const optionsFormatted = arrayToFormat.map( element => {
        return { 'label': element[labelField], 'value': element[valueField] }
    }) ;

    return optionsFormatted;
};

//this function removes duplicates OBJECTS into an array
export const removeDuplicates =(array)=>{
    if (array){
      return array.filter((value, index) => {
        const _value = JSON.stringify(value);
        return index === array.findIndex(obj => {
          return JSON.stringify(obj) === _value;
        });
      });
    }
  }


export function emptyString(str){
    return (!str || str.length === 0)
}

export const percentage = (partialValue, totalValue) => (100 * +partialValue) / +totalValue;

export const getContrastYIQ= (hexcolor)=>{
    if(!hexcolor){
        return "white"
    }
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
  };

export const removeObjectsFromArray=(objects, array)=>{
    let newArray = [];
    const objectsIds = [];
    
    objects.map((element) => { objectsIds.push(element._id) })
    
    newArray= array.filter(element => !objectsIds.includes(element._id))
    return newArray;
};

export const removeFromArrayBy_id=(id, array)=>{
    let newArray = [];
    newArray= array.filter(element=> element._id !== id);
    return newArray;
};

export const replaceFromArrayBy_id=(newOne, array)=>{
    let newArray = [];
    newArray= array.map(elem=>{
        if(elem._id=== newOne._id){
            return newOne
        }else{
            return elem;
        }
    });
    return newArray;
};

export const parseWorkflows = (options) => {
    let parsedWorkflows;
    parsedWorkflows = options.map(item => ({ id: item._id, value: item.name, label: capitalize(item.name), color:item.color}));
    return parsedWorkflows;
};

export const parseStates = (options) => {
    let parsedStatus;
    parsedStatus = options.map(item => ({ id: item.crmId, value: item.name, label: capitalize(item.name), color:item.color, workflow:item.workflow}));
    return parsedStatus;
};

export const filesRestrictionsForChannelsSelectedDescription = (channels) => {
    if( !(Array.isArray (channels)) ){
        return ''
    }
    let formatsAcceptedRepetead=[];

    channels.filter(channel=> channel.mediaLimits.enabled ).forEach(channel => {
        channel.mediaLimits.image.formats.forEach(format => {
            formatsAcceptedRepetead.push(format)
        });
        channel.mediaLimits.video.formats.forEach(format => {
            formatsAcceptedRepetead.push(format)
        });
    });
    let formatsAccepted=[];
    //Here we generate an array with only the formats thats appear in every channel
    for (let i = 0, len = formatsAcceptedRepetead.length; i < len; i++) {
        //The amount of times that the format appear is the same that the amount of channels
        if(formatsAcceptedRepetead.filter(formatRepeated=>formatRepeated === formatsAcceptedRepetead[i]).length === channels.length
            //the format is not in the final array (to enter here the first time we check a repeated format)
            && !(formatsAccepted.find(format=> format ===formatsAcceptedRepetead[i])) 
        ){
            formatsAccepted.push(formatsAcceptedRepetead[i])
        }
    }
    return formatsAccepted.join(', ');
};

/**
 * Description: Get a darker color from a color parameter, which intensity will be according
 * of the amount parameter.
 * 
 * Usage: Use this function to get a darker color from a base color.
 */
export const darkerColor=(color, amount)=> {
    if(!color || !amount) return ""
    const rgb = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    let r = 0
    let g = 0
    let b = 0

    if (rgb) {
      r = parseInt(rgb[1], 10);
      g = parseInt(rgb[2], 10);
      b = parseInt(rgb[3], 10);
    }

    if (!rgb) {
      color = color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, function(m, r, g, b) {
        return r + r + g + g + b + b;
      });
      const hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      r = parseInt(hex[1], 16);
      g = parseInt(hex[2], 16);
      b = parseInt(hex[3], 16);
    }

    r = Math.floor(r * (1 - amount*0.9));
    g = Math.floor(g * (1 - amount*0.9));
    b = Math.floor(b * (1 - amount*0.9));
    
    if (!rgb) {
        return '#' + ('000000' + (r * 65536 + g * 256 + b).toString(16)).slice(-6);
    }
    return 'rgb(' + r + '', '' + g + ',' + b + ')';
  }

  export const removeAllSpaces =(str)=>{
    if(! (typeof str === 'string') ) return ""
    return str.replace(/\s/g, "")
  }

  /**
    It returns true if version1 is before version2
    */
  export const compareVersions=(version1, version2)=> {
    const v1Components = version1.split('.').map(Number);
    const v2Components = version2.split('.').map(Number);
  
    for (let i = 0; i < Math.max(v1Components.length, v2Components.length); i++) {
      const v1Value = i < v1Components.length ? v1Components[i] : 0;
      const v2Value = i < v2Components.length ? v2Components[i] : 0;
  
      if (v1Value < v2Value) {
        return true;
      } else if (v1Value > v2Value) {
        return false;
      }
    }
  
    return false;
  }
/**
 * Description: Get the first 4 letters of a string in uppercase
 * Usage: Pass an string and if it's empty, returns '-'
 */
export const getFirstLettersState = (name) => {
    const DEFAULT = "-";

    if (!name) return DEFAULT;

    const INITIAL_RANGE = 0;
    const FINAL_RANGE = 4;

    const text = removeAllSpaces(name)
      .slice(INITIAL_RANGE, FINAL_RANGE)
      .toUpperCase();
    return text;
  };

  export const sliceString = (str) => {
    if(!str) return ""
    let final = "";
    for (let index = 0; index <= str.length + 10; index += 10) {
      final += str.slice(index, index + 10) + " ";
    }
    return final;
  };

  export const renderTextOrJSX = (content) => {

    if(!content) return;

    const STRING_TYPE = 'string';

    if( typeof content === STRING_TYPE ){
        return(
            <Text
                fontSize={FONT_SIZE.LG}
                fontWeight={FONT_WEIGHT.BOLD}
                lineClamp={LINE_CLAMP.LINE_CLAMP_1}
                value={content}
            />
        )
    }

    if( typeof content !== STRING_TYPE ){
        return(
            content
        )
    }
}

/**
 * Sorts an array of objects by a specified field name.
 *
 * @param {Array} array - The array of objects to be sorted.
 * @param {string} fieldName - The name of the field to sort the objects by.
 * @return {Array} The sorted array of objects.
 */
export const sortObjectsByField = (array, fieldName) => {
    return array.sort((a, b) => {
      if (a[fieldName].toLowerCase() < b[fieldName].toLowerCase()) {
        return -1;
      }
      if (a[fieldName].toLowerCase() > b[fieldName].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  export function capitalizeFirstLetter(cadena) {
    if(!cadena) return ""
    return cadena.charAt(0).toUpperCase() + cadena.slice(1).toLowerCase();
  }