import { lazy } from "react";
import {  notificationSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Channels - EntryPoint" */ './components/EntryPoint'));
const NotificationSettings = lazy(() => import(/* webpackChunkName: "Channels - ListLoader" */ './components/NotificationSettings'));

export const defaultPath = `/settings/notifications`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: NotificationSettings,
        exact: true,
        icon: notificationSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Notifications',
            plural: false
        }
    }
];

const Notifications = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: notificationSVG,
    title: {
        key: 'notifications',
        plural: false
    },
    subTitle: {
        key: 'notification_settings',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Notifications;
