export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';

export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_TEMPLATES = 'SET_TEMPLATES';

export const SUBMIT_TEMPLATE_REQUEST = 'SUBMIT_TEMPLATE_REQUEST';
export const SUBMIT_TEMPLATE_SUCCESS = 'SUBMIT_TEMPLATE_SUCCESS';
export const SUBMIT_TEMPLATE_FAILURE = 'SUBMIT_TEMPLATE_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const SET_TEMPLATES_LIMIT = 'SET_TEMPLATES_LIMIT';


export const getTemplatesRequest = (filters) => ({
    type: GET_TEMPLATES_REQUEST,
    payload:{
        filters
    }
});

export const getTemplatesSuccess = (templates,total) => ({
    type: GET_TEMPLATES_SUCCESS,
    payload:{
        templates,
        total
    }
});

export const getTemplatesFailure = (err) => ({
    type: GET_TEMPLATES_FAILURE,
    error: err
});

export const setTemplate = (template) => ({
    type: SET_TEMPLATE,
    payload:{
        template
    }
});

export const setTemplates = (templates = []) => ({
    type: SET_TEMPLATES,
    payload:{
        templates
    }
});

export const setTemplatesLimit = (limit) => ({
    type: SET_TEMPLATES_LIMIT,
    payload: { limit }
})

export const submitTemplateRequest = (template, id) => ({
    type: SUBMIT_TEMPLATE_REQUEST,
    payload:{
        template,
        id
    }
});

export const submitTemplateFailure = (err) => ({
    type: SUBMIT_TEMPLATE_FAILURE,
    error: err
});

export const submitTemplateSuccess = () => ({
    type: SUBMIT_TEMPLATE_SUCCESS
});

export const deleteTemplateRequest = (id) => ({
    type: DELETE_TEMPLATE_REQUEST,
    payload: { id }
});

export const deleteTemplateSuccess = () => ({
    type: DELETE_TEMPLATE_SUCCESS,
    payload: { }
});

export const deleteTemplateFailure = (err) => ({
    type: DELETE_TEMPLATE_FAILURE,
    payload: { err }
});