export const SET_FILTERS_DASHBOARD = 'SET_FILTERS_DASHBOARD';
export const CLEAN_FILTERS_REQUEST = 'CLEAN_FILTERS_REQUEST';

export const setFiltersDashboard  = (filters) => ({
    type: SET_FILTERS_DASHBOARD,
    payload: {
        filters
    }
});

export const cleanFiltersRequest = () => ({
    type: CLEAN_FILTERS_REQUEST,
    payload: { }
});