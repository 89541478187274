import { call, put, select, takeLatest } from 'redux-saga/effects';
import { signOutSuccess, SIGN_OUT } from '../actions/signout';
import { changeConfigChildRoute } from '../../home/actions/config';
import { setUserLogin } from '../../home/actions/loggedUser';
import { setTokens } from '../actions/token';
import { setPermissions } from '../actions/permissions';
import { signOutService } from '../services/signOut';
import { accessTokenSelector } from '../selectors/token';
import Cookies from 'js-cookie'; 
import { getDomain } from '../helpers';

const signOutWorker = function* ({ payload: {} }) {
    try {

        const token = yield select(accessTokenSelector);

        yield call(signOutService,token);
        yield put(signOutSuccess())
        Cookies.remove('session', { domain: getDomain(), path: '/' });
        setTokens(null, null);
        changeConfigChildRoute();
        setUserLogin();
        setPermissions({});


    } catch (err) {
        console.log(err)
    }
}

export default [
    takeLatest(SIGN_OUT, signOutWorker)
];