export const logotipoSVG = "./assets/logos/cross-min-logo.svg";
export const isotipoSVG = "./assets/logos/cross-Logo-Login.svg";

export const angleLeftSVG = "./assets/icons/angle-line-left.svg";
export const angleRightSVG = "./assets/icons/angle-line-rigth.svg";
export const angleDownSVG = "./assets/icons/angle-down-line.svg";
export const angleUpSVG = "./assets/icons/angle-up-line.svg";

export const cancelSVG = "./assets/icons/close.svg";
export const logoutSVG = "./assets/icons/logout.svg";
export const plusSVG = "./assets/icons/plus.svg";
export const addTransitionSVG = "./assets/icons/add-transition.svg";
export const circlePlusSVG = "./assets/icons/circle-plus.svg";
export const minusSVG = "./assets/icons/menos.svg";
export const checkCircleSVG = "./assets/icons/check-circle-line.svg";
export const errorCircleSVG = "./assets/icons/times-circle-line.svg";
export const userSVG = "./assets/icons/menu-usuario.svg";
export const apikeySVG = "./assets/icons/menu-key.svg";
export const threeDotsSVG = "./assets/icons/three-dots-svgrepo-com.svg";
export const locationSVG = "./assets/icons/location.svg";
export const closeRoundSVG = "./assets/icons/close-round.svg";
export const subTaskArrowSVG = "./assets/icons/subtask-middle-arrow.svg";
export const subTaskBorderHeaderSVG =
  "./assets/icons/subtask-border-header.svg";
export const subTaskLastItemSVG = "./assets/icons/subtask-last-item.svg";
export const chatsSVG = "./assets/icons/chats.svg";
export const ticketsSVG = "./assets/icons/tickets.svg";

export const configurationSVG = "./assets/images/ilustracion-perfiles.svg";

export const settingSVG = "./assets/icons/menu-ajustes.svg";
export const webConfigurationSVG = "./assets/icons/menu-web.svg";

export const famaleSVG = "./assets/images/female.svg";
export const maleSVG = "./assets/images/male.svg";
export const peopleSVG = "./assets/images/people.svg";
export const usersBackgroundSVG = "./assets/images/users.svg";

export const exclamationSVG = "./assets/icons/exclamation.svg";
export const messageSVG = "./assets/icons/message.svg";
export const noteSVG = "./assets/icons/menu-casos.svg";
export const dashboardSVG = "/assets/icons/menu-dash.svg";
export const reportsSVG = "/assets/icons/menu-reportes.svg";
export const socialSVG = "/assets/icons/menu-social.svg";
export const newMessagesSVG = "/assets/icons/messageUnreadIcon.svg";
export const managmentSVG = "./assets/images/managment.svg";
export const channelsSVG = "./assets/icons/menu-canal.svg";
export const socialMentionSVG = "./assets/icons/public.svg";
export const facebookFeedSVG = "./assets/icons/icon-feed.svg";
export const socialMessageSVG = "./assets/icons/icon-message.svg";
export const emailSVG = "./assets/icons/mail.svg";
export const sendGridSVG = "./assets/icons/sendgrid.svg";
export const epecSVG = "./assets/icons/epec.svg";
export const epecLoginSVG = "./assets/icons/epec-login.svg";
export const epecJPG = "./assets/icons/epec.jpg";
export const userUnknownJPG = "./assets/images/unknown-user.png";
export const youtubeSVG = "./assets/icons/youtube.svg";
export const noImageSVG = "./assets/icons/error-image.svg";
export const clipSVG = "./assets/icons/clip.svg";
export const facebookOfficialLogoPNG =
  "./assets/icons/facebook_official_logo.png";
export const webchatSetSVG = "./assets/icons/chatweb.svg";
export const webchatStep1SVG = "./assets/icons/icon-web.svg";
export const webchatStep2SVG = "./assets/icons/icon-copy.svg";
export const webchatStep3SVG = "./assets/icons/icon-personalizar.svg";
export const webchatStep4SVG = "./assets/icons/icon-datos.svg";
export const webchatStep5SVG = "./assets/icons/icon-chat.svg";
export const webchatOmuniIcon = "./assets/icons/omni webchat.svg";
export const iconCopyBlueSVG = "./assets/icons/icon-copy-blue.svg";
export const appStoreSVG = "./assets/icons/app_store.svg";
export const playStoreSVG = "./assets/icons/play_store.svg";

export const verifiedTwitterSVG = "./assets/icons/verified-twitter.svg";
export const retweetSVG = "./assets/icons/retweet.svg";

export const followerSVG = "./assets/icons/follower-icon.svg";
export const notFollowerSVG = "./assets/icons/not-follower-icon.svg";

export const verifiedIconSVG = "./assets/icons/icon-verified.svg";

//transparent channels icons
export const facebookTransparentSVG = "./assets/icons/facebook-transparent.svg";
export const whatsAppTransparentSVG = "./assets/icons/whatsapp-transparent.svg";
export const linkedInTransparentSVG = "./assets/icons/linkedIn-transparent.svg";
export const emailTransparentSVG = "./assets/icons/mail-transparent.svg";
export const instagramTransparentSVG =
  "./assets/icons/instagram-transparent.svg";
export const slackTransparentSVG = "./assets/icons/slack-transparent.svg";
export const telegramTransparentSVG = "./assets/icons/telegram-transparent.svg";
export const mercadoLibreTransparentSVG =
  "./assets/icons/meli_logo_transparent.svg";
export const webchatTransparentSVG = "./assets/icons/webchat-transparent.svg";

export const webchatArrowSVG = "./assets/icons/arrow-webchat.svg";
export const areasSVG = "./assets/icons/menu-areas.svg";
export const profilesSVG = "./assets/icons/menu-perfil.svg";
export const comercialSVG = "./assets/icons/icon-comercial.svg";
export const networkSVG = "./assets/icons/icon-redes.svg";
export const supportSVG = "./assets/icons/icon-soporte.svg";
export const generalSVG = "./assets/icons/icon-general.svg";
export const searchSVG = "./assets/icons/search-icon.svg";
export const templateSVG = "./assets/icons/menu-template.svg";
export const contractOutlinedSVG = "./assets/icons/contract.svg";
export const numberSVG = "./assets/icons/number.svg";
export const flagSVG = "./assets/icons/flag-fine-line.svg";
export const reasonSVG = "./assets/icons/reason.svg";
export const typeSVG = "./assets/icons/type.svg";

export const agentMenuSVG = "./assets/icons/agent-menu.svg";
export const workflowMenuSVG = "./assets/icons/workflow-menu.svg";

export const contactsSVG = "./assets/icons/menu-contactos.svg";
export const webSVG = "./assets/icons/icon-webform.svg";
export const customReportSVG = "./assets/icons/reporte-personalizado.svg";
export const sampleAvatarSVG = "./assets/icons/sampleAvatar.svg";
export const closedSVG = "/assets/icons/icon-closed.svg";
export const pendingSVG = "/assets/icons/icon-pendings.svg";
export const derivedSVG = "/assets/icons/icon-delivered.svg";
export const inProgressSVG = "/assets/icons/icon-in-progress.svg";
export const openedSVG = "/assets/icons/icon_opened.svg";
export const newSVG = "/assets/icons/icon-new.svg";
export const location2SVG = "/assets/icons/icon-location.svg";
export const answerSVG = "./assets/icons/answer.svg";
export const commentSVG = "./assets/icons/comment.svg";
export const commentSocialOutgoingSVG =
  "./assets/icons/comment-social-outgoing.svg";
export const templateOptionSVG = "./assets/icons/template-option.svg";
export const labelOutlineSVG = "./assets/icons/label-outline.svg";

export const medal1SVG = "/assets/icons/icon-ranking-01.svg";
export const medal2SVG = "/assets/icons/icon-ranking-02.svg";
export const medal3SVG = "/assets/icons/icon-ranking-03.svg";
export const rankingSVG = "/assets/images/ilustracion ranking.svg";
export const filterSVG = "/assets/icons/filter.svg";
export const filterIconSVG = "/assets/images/filter-cases.svg";
export const filterStripesSVG = "/assets/icons/filter-cases.svg";

export const crosshairsSVG = "/assets/icons/crosshairs-gps.svg";
export const likeFacebookSVG = "/assets/icons/icon-like.svg";
export const likeFacebookSelectedSVG = "/assets/icons/icon-like-selected.svg";
export const dislikeSVG = "/assets/icons/icon-dislike.svg";
export const dislikeSelectedSVG = "/assets/icons/icon-dislike-selected.svg";
export const shareSVG = "/assets/icons/share.svg";
export const shareSelectedSVG = "/assets/icons/share-selected.svg";
export const commentFacebookSVG = "/assets/icons/icon-comment.svg";
export const emojiPickerSVG = "/assets/icons/emoji-picker.svg";
export const filePickerSVG = "/assets/icons/picker-file.svg";
export const fileDocumentSVG = "/assets/icons/file-document.svg";
export const postSVG = "/assets/icons/post.svg";

export const notificationSVG = "/assets/icons/icon-notificaciones.svg";
export const telephoneSVG = "/assets/icons/icon-telefono.svg";
export const notNotificationSVG =
  "/assets/images/Illustration-no-notifications.svg";

export const trashSVG = "./assets/icons/trash-icon.svg";
export const trashGraySVG = "./assets/icons/trash-icon-gray.svg";
export const deleteIconSVG = "./assets/icons/delete.svg";
export const copySVG = "./assets/icons/copy-icon.svg";
export const copyButtonSVG = "./assets/icons/copy.svg";
export const notPublicationSVG =
  "./assets/images/ilustracion-publicaciones.svg";
export const notStreamSVG = "./assets/images/ilustracion-live-stream.svg";

export const alcanceSVG = "./assets/icons/panel-alcance.svg";
export const audienciaSVG = "./assets/icons/panel-audiencia.svg";
export const interaccionesSVG = "./assets/icons/panel-interacciones.svg";
export const recibidosSVG = "./assets/icons/panel-mensajes-recibidos.svg";
export const resumenSVG = "./assets/icons/panel-resumen.svg";

export const positiveSVG = "/assets/icons/icon-positivo.svg";
export const negativeSVG = "/assets/icons/icon-negativo.svg";
export const neutralSVG = "/assets/icons/icon-neutral.svg";
export const omuniSVG = "./assets/icons/omuni_logo.svg";
export const omuniGreenSVG = "./assets/icons/omuni.svg";
export const omuniWithBackgroundSVG = "./assets/icons/logo-omuni-iso.png";
export const omuniWithNameSVG = "./assets/icons/omuni_logo_with_name.svg";

export const selectAssignedSVG = "/assets/icons/agent-assigned.svg";
export const areaSVG = "/assets/icons/area.svg";
export const prioritySVG = "./assets/icons/priority.svg";
export const historySVG = "/assets/icons/history.svg";
export const selectStatusSVG = "/assets/icons/status.svg";
export const tipificationSVG = "./assets/icons/icon-tipificacion.svg";

export const subtasksSVG = "./assets/icons/subtasks.svg";
export const contactSVG = "./assets/icons/contact.svg";

export const ilustrationProfiles = "./assets/images/ilustrationProfileSvg.svg";
export const camSVG = "./assets/icons/icon-line-cam.svg";
export const expandSVG = "./assets/icons/iso-expandir.svg";
export const contractSVG = "./assets/icons/iso-contraer.svg";
export const archiveSVG = "./assets/icons/icon-archivo.svg";
export const audioSVG = "./assets/icons/icon-audio.svg";
export const videoSVG = "./assets/icons/icon-video.svg";
export const stateSVG = "./assets/icons/state.svg";
export const statisticsSVG = "./assets/icons/icon-estadisticas.svg";

export const casemapSVG = "./assets/icons/casemap.svg";
export const contactImageSVG = "./assets/images/ilustracion-perfiles.svg";
export const apiKeyImageSVG = "./assets/images/ilustracion-key.svg";
export const areasImageSVG = "./assets/images/ilustracion-areas.svg";
export const profilesNotImageSVG =
  "./assets/images/ilustracionNoImageProfiles.svg";

export const preferencesSVG = "./assets/icons/menu-preferencias.svg";
export const mundoCreditoSVG = "./assets/icons/mundo-credito.svg";
export const infoSVG = "./assets/icons/info.svg";
export const collapsableArrowSVG = "./assets/icons/collapsable.svg";

export const spinnerSVG = "./assets/icons/spinner.svg";
export const saveSVG = "./assets/icons/save.svg";
//TODO:MAKE save2SVG the only one saveSVG (some buttons use save.svg instead of save2.svg)
export const save2SVG = "./assets/icons/save2.svg";
export const closeCaseSVG = "./assets/icons/close-case.svg";
export const calendarSVG = "./assets/icons/calendar.svg";
export const calendarStatisticSVG = "./assets/icons/calendar_statistic.svg";

export const sendSVG = "./assets/icons/send.svg";
export const sendMessageSVG = "./assets/icons/send-message.svg";

export const alertSVG = "./assets/icons/alert-triangle.svg";
export const cleanSVG = "./assets/icons/clean.svg";
export const addButtonSVG = "./assets/icons/add.svg";
export const cancelButtonSVG = "./assets/icons/cerrar_.svg";
export const noContactImage = "./assets/icons/contactSearchImage.svg";
export const searchButton = "assets/icons/icons-search.svg";
export const arrowGrowRightSVG = "assets/icons/arrow-grow-right.svg";

export const eyeSVG = "./assets/icons/ver.svg";
export const segmentSVG = "./assets/icons/segmentar.svg";
export const notificationSuccessSVG = "./assets/icons/icon-ok-notification.svg";
export const notificationErrorSVG =
  "./assets/icons/icon-error-notification.svg";
export const notificationInfoSVG = "./assets/icons/icon-info-notification.svg";
export const notificationWarningSVG =
  "./assets/icons/icon-warning-notification.svg";
export const answerMessageSVG = "./assets/icons/answer-message.svg";
export const socialCommentSVG = "./assets/icons/socialComment.svg";
export const privateSVG = "./assets/icons/private.svg";
export const publicSVG = "./assets/icons/icon-public.svg";
export const associateSVG = "./assets/icons/link.svg";
export const editSVG = "./assets/icons/edit.svg";
export const unlinkSVG = "./assets/icons/unlink.svg";

export const replyToSolidSVG = "./assets/icons/responder-solid-01.svg";

export const testingSVG = "./assets/icons/icon-test.svg";
export const stagingSVG = "./assets/icons/icon-stag.svg";
export const certifiedSVG = "./assets/icons/certificate.svg";

export const interactionShareSVG = "./assets/icons/share-interactions.svg";
export const interactionLikeSVG = "./assets/icons/like.svg";
export const interactionLoveSVG = "./assets/icons/love.svg";
export const interactionCommentSVG = "./assets/icons/comment-interactions.svg";

export const goBackArrow = "./assets/icons/back-01.svg";
export const exportReportSVG = "./assets/icons/export_report.svg";

export const workflowSVG = "./assets/icons/workflow.svg";
export const downloadSVG = "./assets/icons/descargar.svg";

export const botSVG = "./assets/icons/icon-bot.svg";
export const ilustrationBots = "./assets/images/Illustration-bot.svg";
export const denwaSVG = "./assets/icons/logo_denwa.svg";

export const agentSVG = "./assets/icons/agente.svg";

export const rulesSVG = "./assets/icons/rules.svg";
export const ruleIllustrationSVG = "/assets/images/Illustration-rules.svg";

export const checkSVG = "./assets/icons/check.svg";
export const cruzSVG = "./assets/icons/cruz.svg";

export const casesNoLikeSVG = "./assets/icons/like twitter line.svg";
export const casesLikeSVG = "./assets/icons/like twitter solid.svg";

export const companySettingsSVG = "./assets/icons/icon_company.svg";
export const domainSettingsSVG = "./assets/icons/icon_dominio.svg";
export const passwordSettingsSVG = "./assets/icons/icon_key.svg";
export const themeSettingsSVG = "./assets/icons/icon_tema.svg";

export const triggerSVG = "./assets/icons/evento.svg";
export const commentRuleSVG = "./assets/icons/comentario.svg";
export const ruleSVG = "./assets/icons/regla.svg";
export const taskCreatedSVG = "./assets/icons/tarea creada.svg";
export const triggerStatusSVG = "./assets/icons/estado inicial.svg";
export const expirationSVG = "./assets/icons/expiracion.svg";
export const actionsSVG = "./assets/icons/acciones.svg";
export const attachSVG = "./assets/icons/attach.svg";

export const notificationMailSVG = "./assets/icons/notification-mail.svg";
export const notificationWebSVG = "./assets/icons/notification-web.svg";
export const notificationMobileSVG = "./assets/icons/notification-mobile.svg";
export const refreshSVG = "./assets/icons/refresh-button.svg";
export const reloadSVG = "./assets/icons/reload.svg";
export const ruleConditionsSVG = "./assets/icons/conditions.svg";

export const desAsociarSVG = "./assets/icons/desasociar.svg";
export const caseTypeSVG = "./assets/icons/tipodecaso.svg";
export const caseReasonSVG = "./assets/icons/motivo.svg";
export const tasksUpdatedSVG = "./assets/icons/tasks-updated.svg";

export const rightArrowSVG = "./assets/icons/right-arrow.svg";
export const leftArrowSVG = "./assets/icons/left-arrow.svg";

export const outlinedChevronSVG = "./assets/icons/chevron-outlined.svg";
export const filledChevronSVG = "./assets/icons/chevron-filled.svg";
export const actionSVG = "./assets/icons/acciones.svg";

export const showSideBarSVG = "./assets/icons/show-sidebar.svg";
export const finalStatusSVG = "./assets/icons/final-status.svg";
export const initialStatusSVG = "./assets/icons/initial-status.svg";
export const toolsSVG = "./assets/icons/tools.svg";

export const expandImageSVG = "./assets/icons/expand.svg";
export const trash2SVG = "./assets/icons/trash.svg";
export const trainingPhrasesSVG = "./assets/icons/trainning-listen.svg";
export const intentSVG = "./assets/icons/intent.svg";
export const responseItemSVG = "./assets/icons/response-item.svg";
export const intentActionsSVG = "./assets/icons/intent-actions.svg";
export const intentActionsItemSVG = "./assets/icons/intent-actions-item.svg";
export const closePopupSVG = "./assets/icons/close-popup.svg";
export const hideRightAtrowSVG = "./assets/icons/hide-right-arrow.svg";
export const inspectionSVG = "./assets/icons/check-icon.svg";
export const newTabSVG = "./assets/icons/open-tab.svg";
export const gotToArrowSVG = "./assets/icons/go-to-arrow.svg";
export const contactDataSVG = "./assets/icons/contact-data.svg";
export const downArrowSVG = "./assets/icons/down_arrow.svg";

//arrows for fold and unfold content in cases table
export const unFoldArrowSVG = "./assets/icons/un-fold-arrow.svg";

export const grayArrowSVG = "/assets/icons/gray-arrow.svg";
export const redArrowSVG = "/assets/icons/red-arrow.svg";
export const greenArrowSVG = "/assets/icons/green-arrow.svg";

export const googleSVG = "./assets/icons/google.svg";

export const priorityLowSVG = "./assets/icons/priority-low.svg";
export const priorityMediumSVG = "./assets/icons/priority-medium.svg";
export const priorityHighSVG = "./assets/icons/priority-high.svg";

//softphone
export const dialPadSVG = "./assets/icons/dialpad.svg";
export const phoneSVG = "./assets/icons/phone.svg";
export const phoneCallingSVG = "./assets/icons/phone-calling.svg";
export const connectSVG = "./assets/icons/connect.svg";
export const disconnectSVG = "./assets/icons/disconnect.svg";
export const deleteSVG = "./assets/icons/dial-pad-delete.svg";
export const transferCallIcon = "./assets/icons/transfer_call.svg";
export const pauseCallIcon = "./assets/icons/pause_call.svg";

export const deleteTagSVG = "./assets/icons/delete-tag.svg";
export const transactionSVG = "./assets/icons/transaction.svg";
export const curlyBracesSVG = "./assets/icons/curly-braces.svg";
export const chevronSVG = "./assets/icons/chevron.svg";
export const botV2SVG = "./assets/icons/bot.svg";
export const htmlSVG = "./assets/icons/html.svg";
export const checkBlue = "./assets/icons/check-blue.svg";
export const qrSVG = "./assets/icons/qr.svg";

export const addSVG = "./assets/icons/burguer-menu.svg";
export const formsSVG = "./assets/icons/forms.svg"; 
export const templateNewSVG = "./assets/icons/templates-new.svg";

//Empy states
export const emptyStateStatisticsSVG = "./assets/images/empty-state-estadisticas.svg";