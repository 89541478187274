export const prepareFormData = (file) => {
    const formDatafile = new FormData();

    file.forEach(f => {

        formDatafile.append("file", f);

    });

    return formDatafile;
};
