// back error
const COMPANY_CREATION_ERRORS =  {
    COMPANY_EXISTS: 'company_exists',
    USER_EMAIL_EXISTS : 'user_email_exists'
};

// custom error
const ERRORS = {
    [COMPANY_CREATION_ERRORS.COMPANY_EXISTS]: 'company_exists',
    [COMPANY_CREATION_ERRORS.USER_EMAIL_EXISTS]: 'user_email_exists'
};

export const getCompanyErrorCode = (error) => {
    return ERRORS[error];
};
