export const SET_CUSTOM_ATTRIBUTES_LIST = "SET_CUSTOM_ATTRIBUTES_LIST";
export const SET_CUSTOM_ATTRIBUTE_SELECTED = "SET_CUSTOM_ATTRIBUTE_SELECTED";

export const setCustomAttributesList = (data) => ({
  type: SET_CUSTOM_ATTRIBUTES_LIST,
  payload: {
    data,
  },
});
export const setCustomAttributeSelected = (data) => ({
  type: SET_CUSTOM_ATTRIBUTE_SELECTED,
  payload: {
    data,
  },
});
