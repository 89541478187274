import { takeLatest, put, select } from "redux-saga/effects";

import { accessTokenSelector } from "../../auth/selectors/token"; 
import {
  DELETE_FILE_REQUEST,
  POST_FILE_REQUEST,
  deleteFileFailure,
  deleteFileSuccess,
  postFileFailure,
  postFileSuccess,
} from "../actions/files";
import { addFileToFolder, removeFileToFolder } from "../actions/folder";
import { setDeleteFilePopupStatus } from "../actions/popup";
import FileService from "../../domain/services/Files/Files";

const Service = new FileService();

const postFileWorker = function* (postFileWorkerParams) {
  const { payload } = postFileWorkerParams;

  const token = yield select(accessTokenSelector);

  if (!token) {
    throw new Error("Missign token");
  }

  try {
    const information = {
      name: payload.information.name,
      file: payload.information.file,
      folderId: payload.information.folderId,
      extension: payload.information.extension,
    };

    const response = yield Service.postFile({
      token,
      information,
      signal: payload.signal,
    });

    yield put(postFileSuccess(response, payload.information.uuid));
    yield put(addFileToFolder(response.file));
  } catch (error) {
    yield put(postFileFailure(payload.information.uuid));
  }
};

const deleteFileWorker = function* (deleteFileWorkerParams) {
  const { payload } = deleteFileWorkerParams;

  const token = yield select(accessTokenSelector);

  if (!token) {
    throw new Error("Missign token");
  }

  try {
    const response = yield Service.deleteFile({
      token,
      fileId: payload.fileId,
      signal: payload.signal,
    });

    yield put(deleteFileSuccess(response, payload.fileId));
    yield put(removeFileToFolder(payload.fileId));
    yield put(setDeleteFilePopupStatus(false));
  } catch (error) {
    yield put(deleteFileFailure(payload.fileId));
  }
};

export default [
  takeLatest(POST_FILE_REQUEST, postFileWorker),
  takeLatest(DELETE_FILE_REQUEST, deleteFileWorker),
];
