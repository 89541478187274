import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import app from "./app";
import config from "./config";
import home from "../../home/reducers";
import auth, { signin } from "../../auth/reducers";
import channels from "../../channels/reducers";
import selectors from "../../selectors/reducers";
import apikeys from "../../apikeys/reducers";
import users from "../../users/reducers";
import bots from "../../bots/reducers";
import folder from "../../drive/reducers/folder";
import files from "../../drive/reducers/files";
import tickets from "../../tickets/reducers";
import dashboard from "../../dashboard/reducers";
import contacts from "../../contacts/reducers";
import areas from "../../areas/reducers";
import profiles from "../../profiles/reducers";
import templates from "../../templates/reducers";
import social from "../../social/reducers";
import statistics from "../../statistics/reducers";
import company from "../reducers/company";
import { SIGN_OUT, SIGN_OUT_SUCCESS } from "../../auth/actions/signout";
import preferences from "../../settings/reducers/preferences";
import filters from "../../filters/reducers/filters";
import shared from "../../shared/reducers";
import rules from "../../rules/reducers";
import workflows from "../../workflows/reducers";
import companies from "../../companies/reducers";
import webchat from "../../Webchat/reducers/index";
import notificationChannels from "../../notificationChannels/reducers/notificationChannels";
import popup from "../../drive/reducers/popup";
import customAttributesPopup from "../../customAttributes/reducers/popup";
import customAttributes from "../../customAttributes/reducers/customAttributes";
import integrations from "../../integrations/reducers"; 

/*
    TODO: Persist is use for save information on localestorage simple and fast.
    when app initialize it rehidrate information declare in to whitelist
    for more information : https://www.npmjs.com/package/redux-persist
*/

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "apikeys",
    "app",
    "areas",
    "bots",
    "channels",
    "contextMenu",
    "companies",
    "integrations",
    "customAttributes",
    "customAttributesPopup",
    "dashboard",
    "filters",
    "files",
    "folder",
    "notificationChannels",
    "preferences",
    "profiles",
    "signin",
    "shared",
    "social",
    "statistics",
    "templates",
    "tickets",
    "users",
    "popup",
    "preferences",
    "filters",
    "shared",
    "rules",
    "workflows",
    "webchat",
  ],
  whitelist: [
    "config",
    "home",
    "auth",
    "profiles",
    "contacts",
    "company",
    "shared",
    "selectors",
  ],
};

const rootReducer = combineReducers({
  apikeys,
  app,
  areas,
  auth,
  bots,
  channels,
  companies,
  company,
  integrations,
  customAttributes,
  customAttributesPopup,
  config,
  contacts,
  dashboard,
  filters,
  files,
  folder,
  home,
  notificationChannels,
  preferences,
  profiles,
  rules,
  selectors,
  signin,
  shared,
  statistics,
  social,
  templates,
  popup,
  tickets,
  users,
  workflows,
  webchat,
});
 

 
const mainReducer = (state, action) => {
  switch (action.type) {
    case SIGN_OUT_SUCCESS: {
      return rootReducer({ company: { ...state.company } }, action);
    } 
    default: {
      return rootReducer(state, action);
    }
  }
};

export default persistReducer(persistConfig, mainReducer);
