export const SET_FILTERS= 'SET_FILTERS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFilters = ({ text }) => ({
    type: SET_FILTERS,
    payload: {
        text
    }
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});
