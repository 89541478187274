import { SET_FILTERS, CLEAN_FILTERS } from '../actions/filters';

export const initialState = {
    name: '',
    lastName: '',
    identifier: '',
    user: '',
    email: '',
    profile: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS: {
            return {
                ...state,
                name: action.payload.name,
                lastName: action.payload.lastName,
                identifier: action.payload.identifier,
                user: action.payload.user,
                email: action.payload.email,
                profile: action.payload.profile
            };
        }
        case CLEAN_FILTERS:{
            return{
                ...initialState,
            }
        }
        default:
            return state;
    }
};