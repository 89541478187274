export const buildDepartments = departments =>
    departments.map((department) => buildDepartment(department));

const buildDepartment = (department) => ({
    ...department,
})

export const channelOptions = [
    { label: 'Formulario Web', value: 'web' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Telegram', value: 'telegram' },
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'Canal de voz', value: 'voiceChannel' },
    { label: 'Webchat', value: 'webchat' },
    { label: 'Email', value: 'email' },
    { label: 'LinkedIn', value: 'linkedin' },
    { label: 'Linze', value: 'linze' },
    { label: 'Chile autos', value: 'chileAutos' }
];

export const typeOptions = [
    { label: 'Reclamo', value: 'Reclamo' },
    { label: 'Consulta', value: 'Consulta' },
    { label: 'Queja', value: 'Queja' },
    { label: 'Notificación', value: 'Notificación' },
    { label: 'Otros', value: 'Otros' },
    { label: 'Spam', value: 'Spam' },
    { label: 'Sistema', value: 'Sistema' },
    { label: 'Comercial', value: 'Comercial' },
    { label: 'Cooperativas', value: 'Cooperativas' },
    { label: 'Usina molet', value: 'Usina molet' },
    { label: 'Publicación cortes', value: 'Publicación cortes' },
];

export const typeOptionsMundoCredito = [
    { label: 'Consulta', value: 'Consulta'},
    { label: 'Solicitud', value: 'Solicitud'},
    { label:'Reclamo', value: 'Reclamo'},
    { label: 'Prepago', value: 'Prepago'}
];

export const typeOptionsMaviGPS = [
    { label: 'Incidente', value: 'Incidente'},
    { label: 'Consulta', value: 'Consulta'},
    { label: 'Requerimiento', value: 'Requerimiento'},
    { label: 'Masivo', value: 'Masivo'}
];

export const reasonOptions = [
    { label: 'Corte', value: 'Corte' },
    { label: 'Facturación', value: 'Facturación' },
    { label: 'Atención', value: 'Atención' },
    { label: 'Positivos', value: 'Positivos' },
    { label: 'Negativos', value: 'Negativos' },
    { label: 'Otros', value: 'Otros' },
    { label: 'Mail', value: 'Mail' },
    { label: 'Tecnica', value: 'Tecnica' },
    { label: 'Problema General', value: 'Problema General' },
    { label: 'Acceso Denegado', value: 'Acceso Denegado' },
    { label: 'RRPP', value: 'RRPP' },
    { label: 'Datos', value: 'Datos' },
    { label: 'ABM', value: 'ABM' },
    { label: 'RETUIT', value: 'RETUIT' },
    { label: 'Pagos', value: 'Pagos' },
    { label: 'Gestiones comerciales', value: 'Gestiones comerciales' },
    { label: 'Facturación Evoltis', value: 'Facturación Evoltis' },
];

export const reasonOptionsMundoCredito = [
    {label: 'Alzamientos', value: 'Alzamientos'},
    {label: 'Certificados', value: 'Certificados' },
    {label: 'Seguros', value: 'Seguros' },
    {label: 'Información de su cuenta', value:  'Información de su cuenta'},
    {label: 'Cuotas vencidas', value: 'Cuotas vencidas'},
    {label: 'Otras', value: 'Otras'}
]

export const subreasonsOptionsMundoCredito = [
    {label: 'Quiero renovar', value: 'Quiero renovar'},
    {label: 'Quiero prepagar', value: 'Quiero prepagar'},
    {label: 'Quiero conocer mi deuda', value: 'Quiero conocer mi deuda'},
]

export const reasonOptionsMaviGPS = [
    {label: 'GPS no transmite', value: 'GPS no transmite'},
    {label: 'Servicio de Notificaciones', value: 'Servicio de Notificaciones' },
    {label: 'Transmisión Erronea', value: 'Transmisión Erronea' },
    {label: 'Bloqueo', value:  'Bloqueo'},
    {label: 'SMS', value: 'SMS'},
    {label: 'Ubicación', value: 'Ubicación'},
    {label: 'Uso', value: 'Uso'},
    {label: 'Estado', value: 'Estado' },
    {label: 'Correo Bienvenida', value: 'Correo Bienvenida' },
    {label: 'Cambio de clave', value:  'Cambio de clave'},
    {label: 'Integración', value: 'Integración'},
    {label: 'Capacitación', value: 'Capacitación'},
    {label: 'Migración', value: 'Migración'},
    {label: 'Cambio de simcard', value: 'Cambio de simcard' },
    {label: 'Cambio de equipo', value: 'Cambio de equipo' },
    {label: 'Renovación', value:  'Renovación'},
    {label: 'Sistema de Ticket', value: 'Sistema de Ticket'},
    {label: 'Sistema Operador', value: 'Sistema Operador'},
    {label: 'APPs Clientes', value: 'APPs Clientes'},
    {label: 'Instalador', value: 'Instalador' },
    {label: 'Plataforma Q', value: 'Plataforma Q' },
    {label: 'Movistar', value:  'Movistar'},
    {label: 'Entel', value: 'Entel'},
    {label: 'Telefonía', value: 'Telefonía'},
];

export const detailOptions = [
    { label: 'Alumbrado publico', value: 'Alumbrado publico' },
    { label: 'Reclamo conexion', value: 'Reclamo conexion' },
    { label: 'Datos comerciales', value: 'Datos comerciales' },
    { label: 'Error de acceso en alguna sección', value: 'Error de acceso en alguna sección' },
    { label: 'Exceso de facturación', value: 'Exceso de facturación' },
    { label: 'Factura', value: 'Factura' },
    { label: 'Factura papel', value: 'Factura papel' },
    { label: 'Falta energia (CORTE)', value: 'Falta energia (CORTE)' },
    { label: 'Impresión', value: 'Impresión' },
    { label: 'No llega la factura', value: 'No llega la factura' },
    { label: 'OVP + Factura digital', value: 'OVP + Factura digital' },
    { label: 'Verificar medidor', value: 'Verificar medidor' },
    { label: 'Adhesión a OVP', value: 'Adhesión a OVP' },
    { label: 'Usuario + Contraseña OVP', value: 'Usuario + Contraseña OVP' },
    { label: 'Baja de facturación digital', value: 'Baja de facturación digital' },
    { label: 'Modificación mail OVP', value: 'Modificación mail OVP' },
    { label: 'Adhesión nvo. contrato', value: 'Adhesión nvo. contrato' },
];

export const priorityOptions = [
    { label: 'Baja', value: 'low' },
    { label: 'Media', value: 'normal' },
    { label: 'Alta', value: 'high' },
];
