import { lazy } from "react";
import settings from "../settings/routes";
import tickets from "../tickets/routes";
import dashboard from "../dashboard/routes";
import social from "../social/routes";
import statistics from "../statistics/routes";
import contacts from "../contacts/routes";
import automation from "../automation/routes";
import drive from "../drive/routes";
import integrations from "../integrations/routes";
import businessIntelligence from "../businessInteligence/routes";

const EntryPoint = lazy(() =>
  import(/* webpackChunkName: "home" */ "./components/EntryPoint")
);

export const innerRoutes = [
  dashboard,
  tickets,
  contacts,
  social,
  integrations,
  drive,
  statistics,
  automation,
  settings,
  businessIntelligence,
];

const home = {
  path: "/",
  Component: EntryPoint,
  exact: false,
  innerRoutes: [...innerRoutes],
};

export default home;
