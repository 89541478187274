import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  GET_BOTS_REQUEST,
  getBotsSuccess,
  getBotsFailure,
  SUBMIT_BOTS_REQUEST,
  submitBotsSuccess,
  submitBotsFailure,
  DELETE_BOT_REQUEST,
  deleteBotSuccess,
  deleteBotFailure,
  updateBotSuccess,
} from "../actions/bots";
import { showGlobalError } from "../../app/actions/app";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
  getUsersServices,
  postUsersServices,
  putUsersServices,
  deleteUserService,
} from "../../users/services/users";
import USER_TYPES from "../../users/constants/user_types";
import { getActionsFailure, getActionsSuccess } from "../actions/actions";
import { getResponsesFailure, getResponsesSuccess } from "../actions/responses";
import { getResponsesServices } from "../services/responses";
import { getActionsServices } from "../services/actions";

export const getBotsWorker = function* ({ payload: { filters } }) {
  try {
    const token = yield select(accessTokenSelector);

    const {
      data: { users, total },
    } = yield call(getUsersServices, token, filters, USER_TYPES.BOT);

    yield put(getBotsSuccess(users, total));
  } catch (err) {
    console.log(err);

    yield put(getBotsFailure(err));
    yield put(showGlobalError(true, err));
  }
};

const submitBotsWorker = function* ({ payload: { bot } }) {
  try {
    const token = yield select(accessTokenSelector);

    if (!bot._id) {
      const { data: newBot } = yield call(postUsersServices, token, bot);

      yield put(submitBotsSuccess(newBot));
      yield put(showGlobalError(true, 200, "created"));
    } else {
      const { data: newBot } = yield call(putUsersServices, token, bot);

      yield put(updateBotSuccess(newBot));
      yield put(showGlobalError(true, 200, "updated"));
    }
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.status &&
      (err.response.status === 409 || err.response.status === 412)
    ) {
      yield put(showGlobalError(true, 409, "bot_already_exist"));
    } else {
      yield put(showGlobalError(true, err));
    }
    yield put(submitBotsFailure(err));
  }
};

export const getTypingBotsWorker = function* ({
  payload: { filters, onlyProfile },
}) {
  try {
    let data = {
      payload: {
        filters: {
          ...filters,
        },
      },
    };

    if (onlyProfile) {
      data.payload.filters.role = onlyProfile;
    }

    yield call(getBotsWorker, data);
  } catch (err) {
    console.log(err);

    yield put(getBotsFailure(err));
    yield put(showGlobalError(true, err));
  }
};

const deleteBotWorker = function* ({ payload: { id } }) {
  try {
    const token = yield select(accessTokenSelector);
    yield call(deleteUserService, token, id);
    yield put(deleteBotSuccess(id));
    yield put(showGlobalError(true, 200, "bot_deleted"));
  } catch (err) {
    const errorData = err.response?.data;
    if(errorData && errorData.statusCode && errorData.errorKey){
      yield put(showGlobalError(true, errorData.statusCode, errorData.errorKey));
    }else{
      yield put(showGlobalError(true, 409, "error_to_delete_bot"));
    }
    yield put(deleteBotFailure(err));
  }
};

export const getActionsWorker = function* ({ payload: { botId } }) {
  try {
    const token = yield select(accessTokenSelector);

    const {
      data: { actions },
    } = yield call(getActionsServices, token, botId);

    yield put(getActionsSuccess(actions));
  } catch (err) {
    console.log(err);

    yield put(getActionsFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export const getResponsesWorker = function* ({ payload: { botId } }) {
  try {
    const token = yield select(accessTokenSelector);

    const {
      data: { responses },
    } = yield call(getResponsesServices, token, botId);

    yield put(getResponsesSuccess(responses));
  } catch (err) {
    console.log(err);

    yield put(getResponsesFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export default [
  takeLatest(GET_BOTS_REQUEST, getBotsWorker),
  takeLatest(SUBMIT_BOTS_REQUEST, submitBotsWorker),
  takeLatest(DELETE_BOT_REQUEST, deleteBotWorker),
];
