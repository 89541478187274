import { lazy } from "react";
import { templateSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Templates - EntryPoint" */ './components/EntryPoint'));
const TemplateLoader = lazy(() => import(/* webpackChunkName: "Templates - Loader" */ './components/ListLoader'));
const TemplateForm = lazy(() => import(/* webpackChunkName: "Templates - Loader" */ './components/FormUserLoader'));

export const defaultPath = `/settings/templates`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: TemplateLoader,
        exact: true,
        icon: templateSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'template_list',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: TemplateForm,
        exact: true,
        icon: templateSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'template_form',
            plural: false
        }
    },
    {
        path: `${defaultPath}/newTemplate`,
        Component: TemplateForm,
        exact: true,
        icon: templateSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'template_form',
            plural: false
        }
    }
];

const Template = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: templateSVG,
    title: {
        key: 'Templates',
        plural: false
    },
    subTitle: {
        key: 'Lista de templates',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Template;
