import { takeLatest, put, call, select, debounce } from "redux-saga/effects";
import {
    getContactsSuccess,
    getContactsFailure,
    GET_CONTACTS,
    EDIT_CONTACT_REQUEST,
    editContactSuccess,
    editContactFailure,
    POST_CONTACT_REQUEST,
    postContactSuccess,
    postContactFailure,
    setContact,
    GET_HISTORY_REQUEST,
    getHistorySuccess,
    getHistoryFailure,
    DELETE_CONTACT_REQUEST,
    deleteContactSuccess,
    deleteContactFailure,
    getContactByIdSuccess,
    getContactByIdFailure,
    GET_CONTACT_BY_ID_REQUEST,
} from "../actions/contacts";
import { getContactsService, editContactService, postContactService, putTicketContactService, getHistoryService, deleteContactService, getContactService, getContactsV2Service } from "../services/contacts";
import { showGlobalError } from "../../app/actions/app";
import { accessTokenSelector } from "../../auth/selectors/token";

export const getContactsWorker = function* ({ payload: { filters } }) {
    try {
        const token = yield select(accessTokenSelector);
        if(filters?.totals){
            const {data: { total }} = yield call(getContactsV2Service, token, filters);
            const contacts = [];
            yield put(getContactsSuccess(contacts, total));
        }
        else{
            const {data: {contacts, total}} = yield call(getContactsV2Service, token, filters);
            yield put(getContactsSuccess(contacts, total));
        }
        
    } catch (error) {
        console.log(error);
        yield put(getContactsFailure(error));
        yield put(showGlobalError(true, 409, "wrong_filters"));
    }
};

export const getContactByIdWorker = function* ({ payload: { idContact } }) {
    try {
        const token = yield select(accessTokenSelector);
       
        const { data : {contact} }  = yield call(getContactService, token, idContact);
        
        yield put(getContactByIdSuccess(contact));
        
    } catch (error) {
        console.log(error);
        yield put(getContactByIdFailure(error));
        yield put(showGlobalError(true, error));

    }
};

export const editUserWorker = function* ({ payload: { contact, id } }) {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(editContactService, token , contact, id);

        yield put(editContactSuccess(data));
        yield put(showGlobalError(true, 200, "updated"));
    } catch (error) {
        console.log(error);
        yield put(editContactFailure(error));
        yield put(showGlobalError(true, error));
    }
};

export const postContactWorker = function* ({ payload: { contact } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(postContactService, token, contact);
        
        yield put(postContactSuccess(data));
        yield put(showGlobalError(true, 200, "created"));
    } catch (error) {

        console.log("error",error);
        const { response, request } = error;

        if(response && response.status === 409){
            yield put(showGlobalError(true, response.status, 'contact_duplicated'));
        }
        else{
            yield put(showGlobalError(true, response));
        }
        yield put(postContactFailure(response));
    }
};


export const getHistoryWorker = function* ({ payload: { contactId } }) {
    try {
        const token = yield select(accessTokenSelector);
        
        const { data: { tasks } } = yield call(getHistoryService, token, contactId);

        yield put(getHistorySuccess(tasks));
    } catch (error) {
        console.log(error);
        yield put(getHistoryFailure(error));
    }
};

const deleteContactWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteContactService, token, id);
        yield put(deleteContactSuccess());
        yield put(showGlobalError(true, 200, 'contact_deleted'));

    } catch(err) {
        console.log(err)
        const { response, request } = err;
        const statusNumber = Number.isInteger(err) ? err : response ? response.status : request.status;
        const messageError = statusNumber === 412 ? "El contacto no puede eliminarse, tiene datos asociados. Pruebe deshabilitandoló. " : "Error al eliminar el contacto"

        yield put(deleteContactFailure(err));
        yield put(showGlobalError(true, messageError));

    }
}


export default [
    debounce(500,GET_CONTACTS, getContactsWorker),
    takeLatest(EDIT_CONTACT_REQUEST, editUserWorker),
    takeLatest(POST_CONTACT_REQUEST, postContactWorker),
    takeLatest(GET_HISTORY_REQUEST, getHistoryWorker),
    takeLatest(DELETE_CONTACT_REQUEST, deleteContactWorker),
    takeLatest(GET_CONTACT_BY_ID_REQUEST, getContactByIdWorker)
];
