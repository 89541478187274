export const GET_CHANNELS_REQUEST = 'GET_CHANNELS_REQUEST';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_FAILURE = 'GET_CHANNELS_FAILURE';

export const GET_CHANNEL_REQUEST = 'GET_CHANNEL_REQUEST';
export const GET_CHANNEL_SUCCESS = 'GET_CHANNEL_SUCCESS';
export const GET_CHANNEL_FAILURE = 'GET_CHANNEL_FAILURE';

export const POST_CHANNEL_REQUEST = 'POST_CHANNEL_REQUEST';
export const POST_CHANNEL_SUCCESS = 'POST_CHANNEL_SUCCESS';
export const POST_CHANNEL_FAILURE = 'POST_CHANNEL_FAILURE';

export const PUT_CHANNEL_REQUEST = 'PUT_CHANNEL_REQUEST';
export const PUT_CHANNEL_SUCCESS = 'PUT_CHANNEL_SUCCESS';
export const PUT_CHANNEL_FAILURE = 'PUT_CHANNEL_FAILURE';

export const DELETE_CHANNEL_REQUEST = 'DELETE_CHANNEL_REQUEST';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
export const DELETE_CHANNEL_FAILURE = 'DELETE_CHANNEL_FAILURE';

export const SET_TWITTER_AUTH_DATA = 'SET_TWITTER_AUTH_DATA';
export const CLEAN_TWITTER_AUTH_DATA = 'CLEAN_TWITTER_AUTH_DATA';

export const SET_CHANNEL_FETCHING = 'SET_CHANNEL_FETCHING';

export const SET_CHANNELS_DATA= 'SET_CHANNELS_DATA';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL';

export const getChannelsRequest = (filters = {}) => ({
    type: GET_CHANNELS_REQUEST,
    payload: {
        filters
    }
});

export const getChannelsSuccess = (channels) => ({
    type: GET_CHANNELS_SUCCESS,
    payload: {
        channels,
    }
});

export const getChannelsFailure = err => ({
    type: GET_CHANNELS_FAILURE,
    error: err
});

export const getChannelRequest = (filters = {}) => ({
    type: GET_CHANNEL_REQUEST,
    payload: {
        filters
    }
});

export const getChannelSuccess = (channel) => ({
    type: GET_CHANNEL_SUCCESS,
    payload: {
        channel,
    }
});

export const removeChannel = (channelId) => ({
    type: REMOVE_CHANNEL,
    payload: {
        channelId,
    }
});

export const addChannel = (channel) => ({
    type: ADD_CHANNEL,
    payload: {
        channel,
    }
});

export const getChannelFailure = err => ({
    type: GET_CHANNEL_FAILURE,
    error: err
});

export const postChannelRequest = (channel) => ({
    type: POST_CHANNEL_REQUEST,
    payload: {
        channel
    }
});

export const postChannelSuccess = (channel) => ({
    type: POST_CHANNEL_SUCCESS,
    payload: {
        channel,
    }
});

export const postChannelFailure = err => ({
    type: POST_CHANNEL_FAILURE,
    error: err
});

export const putChannelRequest = (channel) => ({
    type: PUT_CHANNEL_REQUEST,
    payload: {
        channel
    }
});

export const putChannelSuccess = (channel) => ({
    type: PUT_CHANNEL_SUCCESS,
    payload: {
        channel,
    }
});

export const putChannelFailure = err => ({
    type: PUT_CHANNEL_FAILURE,
    error: err
});

export const deleteChannelRequest = (channel) => ({
    type: DELETE_CHANNEL_REQUEST,
    payload: {
        channel,
    }
});

export const deleteChannelSuccess = (channel) => ({
    type: DELETE_CHANNEL_SUCCESS,
    payload: {
        channel,
    }
});

export const deleteChannelFailure = err => ({
    type: DELETE_CHANNEL_FAILURE,
    error: err
});

export const setChannelFetching = (channelFetching) => ({
    type: SET_CHANNEL_FETCHING,
    payload: {
        channelFetching,
    }
});

export const cleanTwitterAuthData = () => ({
    type: CLEAN_TWITTER_AUTH_DATA,
});

export const setChannelsData = (channelsData) => ({
    type: SET_CHANNELS_DATA,
    payload: {
        channelsData,
    }
});