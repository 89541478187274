import { 
    ADD_NOTIFICATION, 
    SET_NOTIFICATION 
} from '../actions/notifications';
import { addNotification } from '../helpers/notifications';

const initialState = {
    notifications: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION: {
            return {
                ...state,
                notifications: addNotification(state.notifications, action.payload.notification),
            };
        }

        case SET_NOTIFICATION: {
            return {
                ...state,
                notifications: action.payload.notifications,
            };
        }

        default:
            return state;
    }
};
