import { 
    GET_CONTACTS_TICKET_FAILURE,
    GET_CONTACTS_TICKET_REQUEST, 
    GET_CONTACTS_TICKET_SUCCESS, 
    GET_CONTACT_TICKET_FAILURE,
    GET_CONTACT_TICKET_REQUEST, 
    GET_CONTACT_TICKET_SUCCESS, 
    SET_CONTACTS_TICKET, 
    SET_CONTACT_TICKET,
    PUT_TICKET_CONTACT_REQUEST,
    PUT_TICKET_CONTACT_SUCCESS,
    PUT_TICKET_CONTACT_FAILURE,
    POST_CONTACT_TICKET_REQUEST,
    POST_CONTACT_TICKET_SUCCESS,
    POST_CONTACT_TICKET_FAILURE,
    EDIT_CONTACT_TICKET_REQUEST,
    EDIT_CONTACT_TICKET_SUCCESS,
    EDIT_CONTACT_TICKET_FAILURE,
    GET_CONTACT_SELECTED_REQUEST,
    GET_CONTACT_SELECTED_SUCCESS,
    GET_CONTACT_SELECTED_FAILURE,
    SET_CONTACT_SELECTED,
    POST_CONTACT_CONNECTIONS_REQUEST,
    POST_CONTACT_CONNECTIONS_SUCCESS,
    POST_CONTACT_CONNECTIONS_FAILURE,
    CLEAN_CONTACT_TICKET
} from '../actions/contacts';
import {
    SET_FILTERS_FOR_CONTACTS
} from '../actions/filters';
import { GET_TICKET, POST_TICKET } from '../actions/tickets';


export const initialState = {
    contacts: [],
    areContactsSuggested: false,
    contact : {},
    contactSelected: {},
    fetching: false,
    fetchingEdit: false,
    postSuccess: false,
    putSuccess: false,
    filters: {
        searchText: "",
    },
    postConnections: false,
    fetchingConnections: false,
    total: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_FOR_CONTACTS: {
            return {
                ...state,
                filters:{
                    searchText: action.payload.searchText
                }
            }
        }
        case SET_CONTACTS_TICKET:
            return{
                ...state,
                contacts: action.payload.contacts,
                areContactsSuggested: action.payload.areContactsSuggested
            }
        case SET_CONTACT_TICKET:
            return{
                ...state,
                contact: action.payload.contact
            }

        case CLEAN_CONTACT_TICKET:
            return{
                ...state,
                contact: {}
            }
        case SET_CONTACT_SELECTED:
            return{
                ...state,
                contactSelected: action.payload.contact
            }
        case GET_CONTACT_TICKET_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_CONTACT_TICKET_SUCCESS:
            return {
                ...state,
                fetching: false,
                contact: action.payload.contact
            }
        case GET_CONTACT_TICKET_FAILURE:
            return {
                ...state,
                fetching: false
            }
        case GET_CONTACT_SELECTED_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_CONTACT_SELECTED_SUCCESS:
            return {
                ...state,
                fetching: false,
                contactSelected: action.payload.contact
            }
        case GET_CONTACT_SELECTED_FAILURE:
            return {
                ...state,
                fetching: false
            }
        case GET_CONTACTS_TICKET_REQUEST:
            return {
                ...state,
                fetching: true,
                areContactsSuggested: false,
                postSuccess: false,
                fetchingEdit: false,
                putSuccess: false
            }
        case GET_CONTACTS_TICKET_SUCCESS:
            return {
                ...state,
                fetching: false,
                contacts: [...action.payload.contacts],
                areContactsSuggested: action.payload.areContactsSuggested,
                postSuccess: false,
                fetchingEdit: false
            }
        case GET_CONTACTS_TICKET_FAILURE:
            return {
                ...state,
                fetching: false,
                postSuccess: false,
                fetchingEdit: false
            }

        case PUT_TICKET_CONTACT_REQUEST:
            return {
                ...state,
                fetchingEdit: true,
                postSuccess: false,
                postConnections: false,
            }
        case PUT_TICKET_CONTACT_SUCCESS:
            return {
                ...state,
                fetchingEdit: false,
                ...(action.payload.contact && { contact: action.payload.contact })
            }
        case PUT_TICKET_CONTACT_FAILURE:
            return {
                ...state,
                fetchingEdit: false,
            }
        case POST_CONTACT_TICKET_REQUEST:
            return {
                ...state,
                fetching: true,
                postSuccess: false
            }
        case POST_CONTACT_TICKET_SUCCESS:
            return {
                ...state,
                fetching: false,
                postSuccess: true,
                contact: action.payload.contact
            }
        case POST_CONTACT_TICKET_FAILURE:
            return {
                ...state,
                fetching: false,
                postSuccess: false
            }
        case EDIT_CONTACT_TICKET_REQUEST:
            return {
                ...state,
                fetchingEdit: true,
                putSuccess: false,
            }
        case EDIT_CONTACT_TICKET_SUCCESS:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: true,
                contact: action.payload.contact
            }
        case EDIT_CONTACT_TICKET_FAILURE:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: false,
            }
        case GET_TICKET:
            return {
                ...state,
                postSuccess: false
            }
        case POST_TICKET:
            return {
                ...state,
                postSuccess: false
            }
        case POST_CONTACT_CONNECTIONS_REQUEST:
            return {
                ...state,
                fetchingConnections: true,
                postConnections: false
            }
        case POST_CONTACT_CONNECTIONS_SUCCESS:
            return {
                ...state,
                fetchingConnections: false,
                postConnections: true,
                contact: action.payload.contact
            }
        case POST_CONTACT_CONNECTIONS_FAILURE:
            return {
                ...state,
                fetchingConnections: false,
                postConnections: false
            }
        default:
            return state;
    }
};