/**
 * Enumeration of available communication channels.
 * @enum {string}
 */
const CHANNELS_AVAILABLE = {
  /**
   * Facebook channel.
   * @type {string}
   */
  FACEBOOK: "facebook",

  /**
   * Twitter channel.
   * @type {string}
   */
  TWITTER: "twitter",

  /**
   * Telegram channel.
   * @type {string}
   */
  TELEGRAM: "telegram",

  /**
   * Instagram channel.
   * @type {string}
   */
  INSTAGRAM: "instagram",

  /**
   * Email channel.
   * @type {string}
   */
  MAIL: "email",

  /**
   * WhatsApp channel.
   * @type {string}
   */
  WHATSAPP: "whatsapp",

  /**
   * WhatsApp channel using Infobip.
   * @type {string}
   */
  WHATSAPP_INFOBIP: "whatsappInfobip",

  /**
   * Chattigo  channel.
   * @type {string}
   */
  WHATSAPP_CHATTIGO: "whatsappChattigo",

  /**
   * WhatsApp Twilio Conversation  channel.
   * @type {string}
   */
  WHATSAPP_TWILIO_CONVERSATION: "whatsappTwilioConversation",

    /**
   * WhatsApp Cloud  channel.
   * @type {string}
   */
    WHATSAPP_CLOUD: "whatsappCloud",

  /**
   * Web channel.
   * @type {string}
   */
  WEB: "web",

  /**
   * Voice channel.
   * @type {string}
   */
  VOICE: "voiceChannel",

  /**
   * Webchat channel.
   * @type {string}
   */
  WEBCHAT: "webchat",

  /**
   * LinkedIn channel.
   * @type {string}
   */
  LINKEDIN: "linkedin",

  /**
   * Twilio SMS channel.
   * @type {string}
   */
  SMSTWILIO: "smstwilio",

  /**
   * SMS Gateway channel.
   * @type {string}
   */
  SMSGATEWAY: "smsgateway",

  /**
   * Linze channel.
   * @type {string}
   */
  LINZE: "linze",

  /**
   * Slack channel.
   * @type {string}
   */
  SLACK: "slack",

  /**
   * Mercado Libre channel.
   * @type {string}
   */
  MERCADO_LIBRE: "meli",

  /**
   * Play Store channel.
   * @type {string}
   */
  PLAY_STORE: "playStore",

  /**
   * App Store channel.
   * @type {string}
   */
  APP_STORE: "appStore",

  /**
   * Chile Autos channel.
   * @type {string}
   */
  CHILE_AUTOS: "chileAutos",
  /**
   * Hashtagschannel.
   * @type {string}
   */
  HASHTAGS: "hashtags",
};

export default CHANNELS_AVAILABLE;
