export const GET_ACTIONS_REQUEST = 'GET_ACTIONS_REQUEST';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_FAILURE = 'GET_ACTIONS_FAILURE';

export const POST_ACTION_REQUEST = 'POST_ACTION_REQUEST';
export const POST_ACTION_SUCCESS = 'POST_ACTION_SUCCESS';
export const POST_ACTION_FAILURE = 'POST_ACTION_FAILURE';

export const PUT_ACTION_REQUEST = 'PUT_ACTION_REQUEST';
export const PUT_ACTION_SUCCESS = 'PUT_ACTION_SUCCESS';
export const PUT_ACTION_FAILURE = 'PUT_ACTION_FAILURE';

export const DELETE_ACTION_REQUEST = 'DELETE_ACTION_REQUEST';
export const DELETE_ACTION_SUCCESS = 'DELETE_ACTION_SUCCESS';
export const DELETE_ACTION_FAILURE = 'DELETE_ACTION_FAILURE';


export const getActionsRequest = (botId = '') => ({
    type: GET_ACTIONS_REQUEST,
    payload: {
        botId
    }
});

export const getActionsSuccess = (actions) => ({
    type: GET_ACTIONS_SUCCESS,
    payload: {
        actions
    }
});

export const getActionsFailure = err => ({
    type: GET_ACTIONS_FAILURE,
    error: err
});

export const postActionRequest = (botId = '', action) => ({
    type: POST_ACTION_REQUEST,
    payload: {
        botId, action
    }
});

export const postActionSuccess = (action) => ({
    type: POST_ACTION_SUCCESS,
    payload: {
        action
    }
});

export const postActionFailure = err => ({
    type: POST_ACTION_FAILURE,
    error: err
});

export const deleteActionRequest = (botId, id) => ({
    type: DELETE_ACTION_REQUEST,
    payload: {
        botId, id
    }
});

export const deleteActionSuccess = (id) => ({
    type: DELETE_ACTION_SUCCESS,
    payload: {
        id
    }
});

export const deleteActionFailure = (err) => ({
    type: DELETE_ACTION_FAILURE,
    error: err
});