import {
    SET_FILTERS_TICKETS, SET_FILTERS_FOR_CONTACTS, SET_FILTERS_FROM_REPORTS, CLEAN_FILTERS, SET_TICKET_FILTERS_FROM_DASHBOARD
} from "../actions/filters";
import moment from "moment";

export const initialStateFilterTickets = {
    text: null,
    userIdentification: null,
    serviceIdentification: null,
    dateFrom: new Date(moment().set({ hours: 0, minutes: 0, seconds: 0 }).subtract(1, "month")),
    dateTo: new Date(moment().set({ hours: 23, minutes: 59, seconds: 59 })),
    priority: null, 
    area: null,
    status: null,
    source: null,
    assigned: null,
    projects: null,
    filtersFromDashboard: null,
    filtersFromReports: null,
    identifier: null,
    phone: null,
    lastName: null,
    taskId: null,
    workflows: null,
    customAttributes: null,
    selectCustomAttributes: null,
    userDisplayName: null,
};

export default (state = initialStateFilterTickets, action) => {
    switch (action.type) {
        case SET_FILTERS_TICKETS: {
            return {
                ...state,
                userIdentification: action.payload.userIdentification,
                serviceIdentification: action.payload.serviceIdentification,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
                priority: action.payload.priority,
                area: action.payload.area,
                source : action.payload.source,
                status: action.payload.status,
                assigned: action.payload.assigned,
                taskId: action.payload.taskId,
                userDisplayName: action.payload.userDisplayName,
                sourceName : action.payload.sourceName,
                filtersFromDashboard: null,
                filtersFromReports: null,
                caseReason: action.payload.caseReason,
                caseType: action.payload.caseType,
                caseDetail: action.payload.caseDetail,
                workflows: action.payload.workflows,
                customAttributes: action.payload.customAttributes,
                selectCustomAttributes: action.payload.selectCustomAttributes
            };
        }
        case SET_TICKET_FILTERS_FROM_DASHBOARD: {
            return {
                ...state,
                ...action.payload.filters
            }
        }
        case SET_FILTERS_FOR_CONTACTS: {
            return {
                ...state,
                text: action.payload.text,
                identifier: action.payload.identifier,
                phone: action.payload.phone,
                lastName: action.payload.lastName
            }
        }
        case SET_FILTERS_FROM_REPORTS: {
            return {
                ...state,
                filtersFromReports: action.payload.filters
            }
        }
        case CLEAN_FILTERS: {
            return {
                ...initialStateFilterTickets
            }
        }
        default:
            return state;
    }
};
