import { SET_FILTERS } from '../actions/filters';

export const initialState = {
    name: '',
    lastName:'',
    profile: '',
    user:'',
    email:'',
    profile:'',
    legalId:''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS: {
            return {
                ...state,
                name: action.payload.name,
                lastName: action.payload.lastName,
                user: action.payload.user,
                email: action.payload.email,
                profile: action.payload.profile,
                legalId: action.payload.legalId
            };
        }
        default:
            return state;
    }
};