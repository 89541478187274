import { put, takeLatest, call } from "redux-saga/effects";
import {
  changePasswordFailure,
  changePasswordSuccess,
  CHANGE_PASSWORD_REQUEST,
  recoverPasswordFailure,
  recoverPasswordSuccess,
  RECOVER_PASSWORD_REQUEST,
  SIGN_IN_PROVIDER_REQUEST,
  SIGN_IN_REQUEST,
} from "../actions/signin";
import { setTokens } from "../actions/token";
import { signInSuccess, signInFailure } from "../actions/signin";
import {
  changePasswordService,
  recoverPasswordService,
  signInProviderService,
  signInService,
} from "../services/signIn";
import { setUserLogin } from "../../home/actions/loggedUser";
import { showGlobalError } from "../../app/actions/app";
import {
  getAuthProfile,
  usersAuthorizedToEnterPlatform,
} from "../../app/helpers/userAuth";
import { getProfileService } from "../../profiles/services/profiles";
import { setPermissions } from "../actions/permissions";
import {
  getFinalStatusesRequest,
  getStatusesRequest,
} from "../../selectors/actions/status";
import Cookies from "js-cookie";
import { api, environment } from "../../app/conf"; 
import { generateDuration, getDomain } from "../helpers";

const signInWorker = function* (action) {
  const { username, password, tokenId, history, recaptcha } = action.payload;
  try {
    let accessToken;
    let refreshToken;
    let user;

    if (tokenId) {
      const response = yield call(signInProviderService, tokenId);
      accessToken = response.data.access;
      refreshToken = response.data.refresh;
      user = response.data.user;
    }

    if (!tokenId) {
      const response = yield call(
        signInService,
        username,
        password,
        recaptcha?.token
      );

      accessToken = response.data.access;
      refreshToken = response.data.refresh;
      user = response.data.user;
    }

    const profileCode = "administrator";

    if (!usersAuthorizedToEnterPlatform.includes(profileCode)) {
      throw new Error();
    }
 
    const { authRole, authEntities, defaultInitialRoute } =
      getAuthProfile(profileCode);

    yield put(
      setUserLogin({
        user: { ...user },
        permisionEntities: authEntities,
        permission: authRole,
      })
    );

    
    
    yield put(setTokens(accessToken, refreshToken, tokenId ? true : false));
    
    const {
      data: { permissions },
    } = yield call(getProfileService, accessToken, user.profiles);
    const session = JSON.stringify({
      accessToken,
      refreshToken,
      authorities:permissions
    });
 
    const options = {
      expires: generateDuration(), 
      domain: getDomain(),
    }; 

    Cookies.set("session", session, options);

    yield put(setPermissions(permissions));
    yield put(signInSuccess());
    yield put(getStatusesRequest());
    yield put(getFinalStatusesRequest());

    if (!tokenId && recaptcha.token) {
      recaptcha.cleanupScript();
    }

    history.push(defaultInitialRoute || "/");
  } catch (err) {
    yield put(signInFailure());
    if (err && err.response && err.response.status === 500) {
      yield put(showGlobalError(true, 500));
    } else {
      if (err.response.status === 404) {
        if (tokenId) {
          //this happens when you try to login and there's not a user in omuni which email is
          //the email of the account you're trying to login with
          yield put(showGlobalError(true, 409, "account_not_exists"));
        } else {
          //omuni login with a user that doesnt exists
          yield put(showGlobalError(true, 409, "email_not_exists"));
        }
      }
      if (err.response.status === 401) {
        yield put(showGlobalError(true, 409, "wrong_password"));
      }
      if (tokenId) {
        //we are in redirect page, and need to go back to login
        history.push("/");
      }
    }
  }
};

const recoverPasswordWorker = function* ({ payload: { email, url } }) {
  try {
    yield call(recoverPasswordService, email, url);
    yield put(recoverPasswordSuccess());
  } catch (err) {
    console.log(err);
    yield put(recoverPasswordFailure());
    yield put(showGlobalError(true, err));
  }
};

const changePasswordWorker = function* ({
  payload: { email, code, password },
}) {
  try {
    yield call(changePasswordService, email, code, password);
    yield put(changePasswordSuccess());
  } catch (err) {
    console.log(err);
    yield put(changePasswordFailure());
    yield put(showGlobalError(true, err));
  }
};

export default [
  takeLatest(SIGN_IN_REQUEST, signInWorker),
  takeLatest(SIGN_IN_PROVIDER_REQUEST, signInWorker),
  takeLatest(RECOVER_PASSWORD_REQUEST, recoverPasswordWorker),
  takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordWorker),
];
