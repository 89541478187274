import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  GET_CUSTOM_ATTRIBUTES,
  getCustomAttributesSuccess,
  getCustomAttributesFailure,
} from "../actions/customAttributes";
import { showGlobalError } from "../../app/actions/app";
import { accessTokenSelector } from "../../auth/selectors/token";
import { getCustomAttributesService } from "../services/customAttributes";

export const getCustomAttributesWorker = function* ({ payload: { ticketId } }) {
  try {
    const token = yield select(accessTokenSelector);

    const response = yield call(getCustomAttributesService, token, ticketId);
    const customAttributes = response.data.data;

    yield put(getCustomAttributesSuccess(customAttributes));
  } catch (err) {
    yield put(getCustomAttributesFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export default [takeLatest(GET_CUSTOM_ATTRIBUTES, getCustomAttributesWorker)];
