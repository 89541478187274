export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_TYPING_USERS_REQUEST = 'GET_TYPING_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_AND_BOTS_SUCCESS = 'GET_USERS_AND_BOTS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';

export const SUBMIT_USER_REQUEST = 'SUBMIT_USER_REQUEST';
export const SUBMIT_USER_SUCCESS = 'SUBMIT_USER_SUCCESS';
export const SUBMIT_USER_FAILURE = 'SUBMIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const SET_USERS_LIMIT = 'SET_USERS_LIMIT';

export const deleteUserRequest = (id) => ({
    type: DELETE_USER_REQUEST,
    payload: {
        id
    }
});

export const deleteUserSuccess = () => ({
    type: DELETE_USER_SUCCESS
});

export const deleteUserFailure = (err) => ({
    type: DELETE_USER_FAILURE,
    error: err
});

export const getUsersRequest = (filters = {}) => ({
    type: GET_USERS_REQUEST,
    payload: {
        filters
    }
});

export const getTypingUsersRequest = (filters = {}, onlyProfile) => ({
    type: GET_TYPING_USERS_REQUEST,
    payload: {
        filters,
        onlyProfile
    }
});

export const getUsersSuccess = (users, total, profiles) => ({
    type: GET_USERS_SUCCESS,
    payload: {
        users,
        total,
        profiles
    }
});

export const getUsersAndBotsSuccess = (usersAndBots) => ({
    type: GET_USERS_AND_BOTS_SUCCESS,
    payload: {
        usersAndBots,
    }
});

export const getUsersFailure = err => ({
    type: GET_USERS_FAILURE,
    error: err
});

export const setUsers = (users = [], total = 0) => ({
    type: SET_USERS,
    payload: {
        users,
        total
    }
});

export const setUser = (user = {}) => ({
    type: SET_USER,
    payload: {
        user
    }
});

export const submitUsersRequest = (user, history) => ({
    type: SUBMIT_USER_REQUEST,
    payload: { user },
    history
});

export const submitUsersSuccess = user => ({
    type: SUBMIT_USER_SUCCESS,
    payload: {
        user
    }
});

export const submitUsersFailure = err => ({
    type: SUBMIT_USER_FAILURE,
    error: err
});

export const setUsersLimit = (limit) => ({
    type: SET_USERS_LIMIT,
    payload: { limit }
})

export const getAllUsersRequest = (filters = {}) => ({
    type: GET_ALL_USERS_REQUEST,
    payload: {
        filters
    }
});


export const getAllUsersSuccess = (users, total, profiles) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload: {
        users,
        total,
        profiles
    }
});

export const getAllUsersFailure = err => ({
    type: GET_ALL_USERS_FAILURE,
    error: err
});

