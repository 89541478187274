import {
    GET_CUSTOM_ATTRIBUTES,
    GET_CUSTOM_ATTRIBUTES_SUCCESS,
    GET_CUSTOM_ATTRIBUTES_FAILURE,
    SET_CUSTOM_ATTRIBUTES,
    CLEAN_CUSTOM_ATTRIBUTES
} from '../actions/customAttributes';

const initialState = {
    customAttributes: null,
    fetching: false,
    submitSuccess: false,
    fetchingSubmit: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOM_ATTRIBUTES: {
            return {
                ...state,
                fetching: true,
                submitSuccess: false
            };
        }
        case GET_CUSTOM_ATTRIBUTES_SUCCESS: {
            return {
                ...state,
                fetching: false,
                customAttributes: action.payload.customAttributes || [],
            };
        }
        case GET_CUSTOM_ATTRIBUTES_FAILURE: {
            return {
                ...state,
                fetching: false,
                customAttributes: []
            };
        }
        case SET_CUSTOM_ATTRIBUTES: {
            return {
                ...state,
                customAttributes: action.payload.customAttributes
            };
        }
        case CLEAN_CUSTOM_ATTRIBUTES: {
            return {
                ...state,
                customAttributes: initialState.customAttributes
            }
        }
        default:
            return state;
    };
};
