import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    GET_CHANNEL_STATISTICS_REQUEST,
    getChannelsStatisticsSuccess,
    getChannelsStatisticsFailure,
    getAllCasesSuccess,
    getAllCasesFailure,
    GET_ALL_CASES_REQUEST,
    GET_SCORES_AVERAGE_REQUEST,
    getAverageScoresSuccess,
    getAverageScoresFailure,
    GET_TASK_TYPING_REQUEST,
    getTaskTypingSuccess,
    getTaskTypingFailure,
    GET_ABANDONED_CASES_REQUEST,
    getAbandonedCasesSuccess,
    getAbandonedCasesFailure,
    GET_DERIVED_CASES_REQUEST,
    getDerivedCasesFailure,
    getDerivedCasesSuccess,
    getDownloadStatisticsSuccess,
    getDownloadStatisticsFailure,
    getDownloadSystemStatisticsSuccess,
    getDownloadSystemStatisticsFailure,
    GET_DOWNLOAD_STATISTICS_REQUEST,
    GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST,
    getCasesByDateSuccess,
    getCasesByDateFailure,
    GET_CASES_BY_DATE_REQUEST,
    getCasesByAgentSuccess,
    getCasesByAgentFailure,
    GET_CASES_BY_AGENT_REQUEST,
    GET_SESSIONS_STATISTICS_REQUEST,
    GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST,
    getSessionsStatisticsSuccess,
    getSessionsStatisticsFailure,
    getTimeAssociatedAgentTaskSuccess,
    getTimeAssociatedAgentTaskFailure,
    GET_INACTIVITY_TIME_BY_AGENT_REQUEST,
    getInactivityTimeByAgentFailure,
    getInactivityTimeByAgentSuccess,
    GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST,
    getCustomAttributesStatisticsFailure,
    getCustomAttributesStatisticsSuccess
} from '../actions/statistics';
import {
    getAllCasesService,
    getStatisticsService,
    getScoresAverageService,
    getTasksTypingService,
    getDerivedCasesService,
    getAbandonedCasesService,
    getDownloadStatisticsService,
    getDownloadSystemStatisticsService,
    getCasesByDateService,
    getCasesByAgentService,
    getSessionsStatisticsService,
    getTimeAssociatedAgentTaskService,
    getInactivityTimeByAgentService,
    getCustomAttributesStatisticsService
} from '../services/statistics';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';

export const getStatisticsWorker = function* ({payload: {filters}}){
    try {

        const token = yield select(accessTokenSelector);
        let parsedFilters = {...filters}
        delete parsedFilters.quantity
        delete parsedFilters.period
        const { data } = yield call(getStatisticsService, token, parsedFilters);

        yield put(getChannelsStatisticsSuccess(data));

        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getChannelsStatisticsFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getChannelsStatisticsFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}

export const getCasesByDateWorker = function* ({payload: {filters}}){
    try {

        const token = yield select(accessTokenSelector);

        const { data: {statusesByDate: casesByDate} } = yield call(getCasesByDateService, token, filters);

        yield put(getCasesByDateSuccess(casesByDate));

        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getCasesByDateFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getCasesByDateFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}
export const getCasesByAgentWorker = function* ({payload: {filters}}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: {statusesByAgent: casesByAgent} } = yield call(getCasesByAgentService, token, filters);

        yield put(getCasesByAgentSuccess(casesByAgent));
        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getCasesByAgentFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getCasesByAgentFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}
export const getAllCasesWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = {...filters}
        delete parsedFilters.quantity
        delete parsedFilters.period
        if(parsedFilters.channel === 'general') delete parsedFilters.channel

        const { data } = yield call(getAllCasesService,token, parsedFilters);

        yield put(getAllCasesSuccess(data));
        
    } catch (err) {
        console.log(err);
        yield put(getAllCasesFailure(err));
        yield put(showGlobalError(true,err));
    }
}
export const getScoresAverageWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = {...filters}
        delete parsedFilters.quantity
        delete parsedFilters.period
        const { data } = yield call(getScoresAverageService,token, parsedFilters);

        yield put(getAverageScoresSuccess(data));
        
    } catch (err) {
        console.log(err);
        yield put(getAverageScoresFailure(err));
        yield put(showGlobalError(true,err));
    }
}
export const getTasksTypingWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = { ...filters };
        delete parsedFilters.quantity;
        delete parsedFilters.period;
        const { data } = yield call(getTasksTypingService,token, parsedFilters);

        yield put(getTaskTypingSuccess(data));
        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getTaskTypingFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getTaskTypingFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}

export const getAbandonedCasesWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = {...filters};
        delete parsedFilters.quantity;
        delete parsedFilters.period;

        if(parsedFilters.channel === 'general') delete parsedFilters.channel;
        const { data } = yield call(getAbandonedCasesService,token, parsedFilters);

        yield put(getAbandonedCasesSuccess(data));

    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getAbandonedCasesFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getAbandonedCasesFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}
export const getDerivedCasesWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = {...filters}
        delete parsedFilters.quantity
        delete parsedFilters.period
        if(parsedFilters.channel === 'general') delete parsedFilters.channel

        const { data } = yield call(getDerivedCasesService,token, parsedFilters);

        yield put(getDerivedCasesSuccess(data));
        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getDerivedCasesFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getDerivedCasesFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}
export const getDownloadStatisticsWorker = function* ({payload: { connectionId, channel, filters }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(getDownloadStatisticsService,token, connectionId, channel, filters);
        yield put(getDownloadStatisticsSuccess(data));
        
    } catch (err) {
        console.log(err);
        yield put(getDownloadStatisticsFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getDownloadSystemStatisticsWorker = function* ({payload: { channel, filters }}){
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(getDownloadSystemStatisticsService,token, channel, filters);
        yield put(getDownloadSystemStatisticsSuccess(data));

    } catch (err) {
        console.log(err);

        if(err?.response?.status === 429){
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        }
        else{
            yield put(showGlobalError(true,err));
        }
        yield put(getDownloadSystemStatisticsFailure(err));
        
    }
}

export const getSessionsStatisticsWorker = function* ({payload: { filters }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data: { usersSessions } } = yield call(getSessionsStatisticsService,token, filters);
        yield put(getSessionsStatisticsSuccess(usersSessions));
        
    } catch (err) {
        console.log(err);
        if (err?.response?.status === 429) {
            yield put(getSessionsStatisticsFailure(err, true));
            yield put(showGlobalError(true, 429, 'too_many_requests'));
        } else {
            yield put(getSessionsStatisticsFailure(err, false));
            yield put(showGlobalError(true, err));
        }
    }
}

export const getTimeAssociatedAgentsTaskWorker = function* ({payload: { filters }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data: { timeByAssigned } } = yield call(getTimeAssociatedAgentTaskService,token, filters);
        yield put(getTimeAssociatedAgentTaskSuccess(timeByAssigned));
        
    } catch (err) {
        console.log(err);
        yield put(getTimeAssociatedAgentTaskFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getInactivityTimeByAgentWorker = function* ({payload: { filters }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data: { inactivityTime } } = yield call(getInactivityTimeByAgentService,token, filters);
        yield put(getInactivityTimeByAgentSuccess(inactivityTime));

    } catch (err) {
        console.log(err);
        yield put(getInactivityTimeByAgentFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getCustomAttributesWorker = function* ({payload: {filters}}){
    try {
        const token = yield select(accessTokenSelector);

        let parsedFilters = { ...filters };
        delete parsedFilters.quantity;
        delete parsedFilters.period;
        const {data :{customAttributesStatistics}} = yield call(getCustomAttributesStatisticsService,token, parsedFilters);
        yield put(getCustomAttributesStatisticsSuccess(customAttributesStatistics));

    } catch (err) {
        console.log(err);
        yield put(getCustomAttributesStatisticsFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export default [
    takeLatest(GET_CHANNEL_STATISTICS_REQUEST, getStatisticsWorker),
    takeLatest(GET_ALL_CASES_REQUEST, getAllCasesWorker),
    takeLatest(GET_SCORES_AVERAGE_REQUEST, getScoresAverageWorker),
    takeLatest(GET_TASK_TYPING_REQUEST, getTasksTypingWorker),
    takeLatest(GET_ABANDONED_CASES_REQUEST, getAbandonedCasesWorker),
    takeLatest(GET_DERIVED_CASES_REQUEST, getDerivedCasesWorker),
    takeLatest(GET_DOWNLOAD_STATISTICS_REQUEST, getDownloadStatisticsWorker),
    takeLatest(GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST, getDownloadSystemStatisticsWorker),
    takeLatest(GET_CASES_BY_DATE_REQUEST, getCasesByDateWorker),
    takeLatest(GET_CASES_BY_AGENT_REQUEST, getCasesByAgentWorker),
    takeLatest(GET_SESSIONS_STATISTICS_REQUEST, getSessionsStatisticsWorker),
    takeLatest(GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST, getTimeAssociatedAgentsTaskWorker),
    takeLatest(GET_INACTIVITY_TIME_BY_AGENT_REQUEST, getInactivityTimeByAgentWorker),
    takeLatest(GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST, getCustomAttributesWorker)
]