export const SET_FILTERS = 'SET_FILTERS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFilters = ({ name, lastName, identifier, user, email, profile }) => ({
    type: SET_FILTERS,
    payload: {
        name,
        lastName,
        identifier,
        user,
        email,
        profile
    }
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});
