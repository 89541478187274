export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';


export const getLocationsRequest = (filters = {}) => ({
    type: GET_LOCATIONS_REQUEST,
    payload: {
        filters
    }
});

export const getLocationsSuccess = (statistics) => ({
    type: GET_LOCATIONS_SUCCESS,
    payload: {
        statistics
    }
});

export const getLocationsFailure = (err = {}) => ({
    type: GET_LOCATIONS_FAILURE,
    payload: {
        err
    }
});