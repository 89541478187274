import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getSuggestedTagsService = (token, value, type) =>
    axios({
        method: 'get',
        url: `${api}/v1/tags`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        params: { 
            value,
            type: null,
        }
    })

export const postSuggestedTagsService = (token, value) =>
    axios({
        method: 'post',
        url: `${api}/v1/tags`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: {
            value
        }
    })