import _ from 'lodash';
import { closedSVG, commentRuleSVG, expirationSVG, facebookFeedSVG, noteSVG, notificationSVG, selectAssignedSVG, socialMentionSVG, tasksUpdatedSVG } from '../../../shared/helpers/icons';

export const cutMessage = (message) => {
    let messageCutted = message;

    if(message?.length > 40) { 
        messageCutted = `${message.substring(0,40)}...`;
    }

    return messageCutted;
};

export const removeStyle = (message) => {
    if (message && message.match(/<style (.*?)>(.*?)<\/style>/g)) message = message.replace(/<style (.*?)>(.*?)<\/style>/g, '');
    return message;
}

export const removeHtml = (message) => {
    if (message && message.match(/(<([^>]+)>)/ig)) message = message.replace(/(<([^>]+)>)/ig, '');
    return message;
};

export const formatWord = (word) => {
    if(!word) return;

    const lowecaseWord = word.toLowerCase();
    const formattedWord = lowecaseWord.replace(/\s+/g, '');
    return formattedWord;
};

export const addNotification = (arrayNotifications, notification) => {
    if (arrayNotifications && notification && Object.keys(notification).length > 0) {
        const notificationFormatted = notification;
        if(notificationFormatted) arrayNotifications.unshift(notificationFormatted);
        if(arrayNotifications.length > 10) arrayNotifications.pop();
    }

    let notificationsSorted = _.sortBy(arrayNotifications, (notification) =>(new Date()).getTime() - Date.parse(notification.date) );

    return notificationsSorted;
};

export const eventSVG = (event) => {
    switch (event) {
        case "tasks.created":
            return noteSVG;
        case "tasks.comment":
          return commentRuleSVG;
        case "tasks.status.updated":
          return tasksUpdatedSVG;
        case "tasks.status.resolved":
            return closedSVG;
        case "tasks.assigned":
            return selectAssignedSVG;
        case "tasks.status.expired":
            return expirationSVG;
        default:
            return notificationSVG;
    }
}; 

export const socialTypeSVG = (socialType) => {
    switch (socialType) {
        case "comment":{
            return commentRuleSVG;
        }
        case "mention":{
            return socialMentionSVG;
        }
        case "feed":{
            return facebookFeedSVG;
        }
        default:
            return notificationSVG;
    }
}; 

export const getNotificationColor = (event) => {
    switch (event) {
        case "tasks.created":
            return '#D3F4E3';
        case "tasks.comment":
          return '#F6DEFF';
        case "tasks.status.updated":
          return '#D6E9FB';
        case "tasks.status.resolved":
            return "#D3F4E3";
        case "tasks.assigned":
            return "#FFF1B6";
        case "tasks.status.expired":
            return "#E9B6B4";
        default:
            return '#EBECF0';
    }
}; 

export const getNotificationSvgColor = (event) => {
    switch (event) {
        case "tasks.created":
            return '#1AC77D';
        case "tasks.comment":
          return '#BB9CC5';
        case "tasks.status.updated":
          return '#3191E9';
        case "tasks.status.resolved":
            return '#1AC77D';
        case "tasks.assigned":
            return "#FAD744";
        case "tasks.status.expired":
            return "#E97676"
        default:
            return '#EBECF0';
    }
}; 