import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getAreasService = (token,filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/projects?${makeQuery(filters)}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

export const editAreaService = (token,area,id) => 
    axios({
        method: 'put',
        url: `${api}/v1/projects/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: area
    })

export const submitAreaService = (token,area) => 
    axios({
        method: 'post',
        url: `${api}/v1/projects`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: area
    })

export const deleteAreaService = (token, id) => 
    axios({
        method: 'delete',
        url: `${api}/v1/projects/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
    })