import { SET_PAGINATION } from "../actions/pagination";

const INITIAL_STATE = {
  limit: 25,
  page: 1,
};

const PaginationState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGINATION: {
      return {
        ...state,
        ...action.payload.pagination,
      };
    }

    default:
      return state;
  }
};

export default PaginationState;
