import { POST_COMPANY_FAILURE, POST_COMPANY_REQUEST, POST_COMPANY_SUCCESS } from "../actions/register";


const initialState = {
    fetching: false,
    postCompanySuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_COMPANY_REQUEST: {
            return {
                ...state,
                fetching: true,
                postCompanySuccess: false
            };
        }
        case POST_COMPANY_SUCCESS: {
            return {
                ...state,
                fetching: false,
                postCompanySuccess: true
            };
        }
        case POST_COMPANY_FAILURE: {
            return {
                ...state,
                fetching: false,
                postCompanySuccess: false
            };
        }
        default: return state;
    }
};
