import { takeLatest, put, call, select, debounce } from 'redux-saga/effects';
import {
    GET_USERS_REQUEST,
    GET_TYPING_USERS_REQUEST,
    getUsersSuccess,
    getUsersFailure,
    SUBMIT_USER_REQUEST,
    submitUsersSuccess,
    submitUsersFailure,
    DELETE_USER_REQUEST,
    deleteUserSuccess,
    deleteUserFailure,
    getAllUsersSuccess,
    getAllUsersFailure,
    GET_ALL_USERS_REQUEST
} from '../actions/users';
import { deleteUserService, getAllUsersService, getUsersServices, postUsersServices, putUsersServices } from '../services/users';
import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';
import { loggedUserSelector } from '../../home/selectors/loggedUser';
import { updateLoggedUser } from '../../home/actions/loggedUser';
import USER_TYPES from '../constants/user_types';
import { getUserErrorCode } from '../errors';

export const getUsersWorker = function* ({ payload: { filters } }) {
    try {
        const token = yield select(accessTokenSelector);

        const { data: { users, total }} = yield call(getUsersServices, token, filters, USER_TYPES.HUMAN);
        
        yield put(getUsersSuccess(users, total));
    } catch (err) {
        console.log(err);

        yield put(getUsersFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getAllUsersWorker = function* ({ payload: { filters } }) {
    try {
        const token = yield select(accessTokenSelector);

        const { data: { users, total }} = yield call(getAllUsersService, token, filters);
        
        yield put(getAllUsersSuccess(users, total));
    } catch (err) {
        console.log(err);

        yield put(getAllUsersFailure(err));
        yield put(showGlobalError(true, err));
    }
};

const submitUsersWorker = function* ({ payload: { user } }) {
    try {
        const token = yield select(accessTokenSelector);

        if (!user._id) {
            yield call(postUsersServices, token, user);

            yield put(showGlobalError(true, 200, 'created'));

        } else {
            yield call(putUsersServices, token, user);
            yield put(showGlobalError(true, 200, 'updated'));

            const loggedUser = yield select(loggedUserSelector)
            if (loggedUser._id === user._id) {
                yield put(updateLoggedUser(user));
            }
        }

        yield put(submitUsersSuccess(user));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            const errorCode = getUserErrorCode(err.response?.data?.message);
            const errorKey = err.response?.data?.errorKey;
            if (errorCode) {
                yield put(showGlobalError(true, 409, errorCode));
            }
            if(errorKey) {
                yield put(showGlobalError(true, 409, errorKey));
            }
            if(!errorCode && !errorKey) {
                yield put(showGlobalError(true, 409, 'user_already_exist'));
            }
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(submitUsersFailure(err));
    }
};

export const getTypingUsersWorker = function* ({ payload: { filters, onlyProfile } }) {
    try {

        let data = {
            payload: {
                filters: {
                    ...filters
                }
            }
        }

        if (onlyProfile) {
            data.payload.filters.role = onlyProfile;
        }

        yield call(getUsersWorker, data);

    } catch (err) {
        console.log(err);

        yield put(getUsersFailure(err));
        yield put(showGlobalError(true, err));
    }
};

const deleteAreaWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteUserService, token, id);
        yield put(deleteUserSuccess());
        yield put(showGlobalError(true, 200, 'user_deleted'));

    } catch(err) {
        console.log(err)
        const { response, request } = err;
        const statusNumber = Number.isInteger(err) ? err : response ? response.status : request.status;
        const messageError = statusNumber === 412 ? "El usuario no puede eliminarse, tiene datos asociados. Pruebe deshabilitandoló." : "Error al eliminar el usuario"

        yield put(deleteUserFailure(err));
        yield put(showGlobalError(true, messageError));

    }
}

export default [
    debounce(1000, GET_TYPING_USERS_REQUEST, getTypingUsersWorker),
    takeLatest(GET_USERS_REQUEST, getUsersWorker),
    takeLatest(SUBMIT_USER_REQUEST, submitUsersWorker),
    takeLatest(DELETE_USER_REQUEST, deleteAreaWorker),
    takeLatest(GET_ALL_USERS_REQUEST, getAllUsersWorker)
];
