import { combineReducers } from 'redux';
import filters from './filters';
import filtersV2 from './filtersV2';
import mentions from './mentions';
import publications from './publications';
import socials from './socials';
import pagination from './pagination';

export default combineReducers({ 
    filters, 
    filtersV2, 
    pagination, 
    publications, 
    mentions,
    socials
});