import { SET_FILTERS, CLEAN_FILTERS } from '../actions/filters';

export const initialState = {
    name: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return{
                ...state,
                name: action.payload.name
            }
        case CLEAN_FILTERS:{
            return{
                ...initialState,
            }
        }
        default:
            return state;
    }
}