import { takeLatest, put, call, select } from 'redux-saga/effects';

import { 
    GET_SOCIALS_REQUEST, 
    getSocialsSuccess, 
    getSocialsFailure,
    GET_SOCIAL_REQUEST, 
    getSocialSuccess, 
    getSocialFailure,
    GET_SOCIALS_INCOMING_REQUEST, 
    getSocialsIncomingSuccess, 
    getSocialsIncomingFailure,
    GET_SOCIAL_INCOMING_REQUEST, 
    getSocialIncomingSuccess, 
    getSocialIncomingFailure,
    GET_SOCIALS_OUTGOING_REQUEST, 
    getSocialsOutgoingSuccess, 
    getSocialsOutgoingFailure,
    GET_SOCIAL_OUTGOING_REQUEST, 
    getSocialOutgoingSuccess, 
    getSocialOutgoingFailure,
    GET_GEOLOCATIONS_REQUEST, 
    getGeoLocationsSuccess, 
    getGeoLocationsFailure,
    POST_SOCIAL_REQUEST, 
    postSocialSuccess, 
    postSocialFailure,
    DELETE_SOCIAL_OUTGOING_REQUEST,
    deleteSocialOutgoingSuccess,
    PUT_SOCIAL_REQUEST,
    putSocialSuccess,
    putSocialFailure,
    PUT_SOCIAL_LIKE,
    putSocialLikeSuccess,
    putSocialShareSuccess,
    PUT_SOCIAL_SHARE,

} from '../actions/socials';
import {
    getSocialsService,
    getSocialService,
    getSocialsIncomingService,
    getSocialIncomingService,
    getSocialsOutgoingService,
    getSocialOutgoingService,
    getGeoLocationsService,
    postSocialService,
    putSocialService,
    deletePublicationService,
    putSocialLikeService,
    putSocialShareService,
} from '../services/socials';
import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

export const getSocialsWorker = function* ( { payload: { filters } } ){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { socials } } = yield call(getSocialsService, token, filters);

        yield put(getSocialsSuccess(socials));
        
    } catch (err) {
        console.log(err);

        yield put(getSocialsFailure(err));
        yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
    }
}

export const getSocialWorker = function* ({ payload: { filters, socialId } }) {
    try {

        const data = {
            socialId, 
            filters
        };

        const token = yield select(accessTokenSelector);
        const { data: { social } } = yield call(getSocialService, token, data);

        yield put(getSocialSuccess(social));
    } catch (err) {
        console.log(err);

        yield put(getSocialFailure(err));
        yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
    }
};

export const getSocialsIncomingWorker = function* ( { payload: { filters } } ){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { socialsIncoming } } = yield call(getSocialsIncomingService, token, filters);

        yield put(getSocialsIncomingSuccess(socialsIncoming));
        
    } catch (err) {
        console.log(err);

        yield put(getSocialsIncomingFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export const getSocialIncomingWorker = function* ({ payload: { filters, id } }) {
    try {

        const data = {
            id, 
            filters
        };

        const token = yield select(accessTokenSelector);
        const { data: { socialIncoming } } = yield call(getSocialIncomingService, token, data);

        yield put(getSocialIncomingSuccess(socialIncoming));

    } catch (err) {
        console.log(err);

        yield put(getSocialIncomingFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getSocialsOutgoingWorker = function* ( { payload: { filters } } ){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { socialsOutgoing, total: totalItems } } = yield call(getSocialsOutgoingService, token, filters);
        yield put(getSocialsOutgoingSuccess([...socialsOutgoing], totalItems));
        
    } catch (err) {
        console.log(err);

        yield put(getSocialsOutgoingFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export const getSocialOutgoingWorker = function* ({ payload: { filters, id } }) {
    try {
        const data = {
            id, 
            filters
        };

        const token = yield select(accessTokenSelector);
        const { data: { socialOutgoing } } = yield call(getSocialOutgoingService, token, data);


        yield put(getSocialOutgoingSuccess(socialOutgoing));
    } catch (err) {
        console.log(err);

        yield put(getSocialOutgoingFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getGeoLocationsWorker = function* ( { payload: { connectionId, geoLocation } } ){
    try {
        const data = {
            connectionId,
            geoLocation,
        };

        const token = yield select(accessTokenSelector);
        const { data: { geoLocations } } = yield call(getGeoLocationsService, token, data);

        yield put(getGeoLocationsSuccess(geoLocations));
        
    } catch (err) {
        console.log(err);

        yield put(getGeoLocationsFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export const postSocialWorker = function* ({ payload: { social } }){
    try {
        const token = yield select(accessTokenSelector);

        if(social._id){
            const { data: { social: s } } = yield call(putSocialService, token, social);
            
            yield put(showGlobalError( true, 200, 'social_updated' ));

            yield put(postSocialSuccess());
        }
        else{
            const { data: { socialsPublished } } = yield call( postSocialService, token, social);

            if (socialsPublished.length > 0) {
                for(let i in socialsPublished){
                    if(!socialsPublished[i].programmed){
                        yield put(showGlobalError( true, 200, `${socialsPublished[i].channel}_publication_created`));
                    } else {
                        yield put(showGlobalError( true, 200, `${socialsPublished[i].channel}_publication_programmed_created`));
                    }
                }            
            }

            yield put(postSocialSuccess());
        }
        
    } catch (err) {
        if (err.response?.data?.error?.code) {
            yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
        } else {
            yield put(showGlobalError(true, 409, 'social_error'));
        }
        yield put(postSocialFailure(err));
    }
}

export const deletePublicationWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deletePublicationService, token, id);
        yield put(deleteSocialOutgoingSuccess());
        yield put(showGlobalError(true, 200, "Publicación eliminada, espere un momento"));

    } catch(err) {
        console.log(err)
        yield put(putSocialFailure(err));
        yield put(showGlobalError(true, 409, "post_channel_disconected"));
    }
}

export const putSocialWorker = function* ({ payload: { social } }) {
    try {
        const token = yield select(accessTokenSelector);
        const data = yield call(putSocialService, token, social);
        yield put(putSocialSuccess(data));
        yield put(showGlobalError(true, 200, "publication_updated"));

    } catch(err) {
        console.log(err)
        yield put(putSocialFailure(err));
        yield put(showGlobalError(true, "error_publication_updated"));
    }
}

export const putSocialLikeWorker = function* ({ payload: {social} }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: {social: socialUpdated} } = yield call(putSocialLikeService, token, social);

        yield put(putSocialLikeSuccess(socialUpdated));
        yield put(showGlobalError(true, 200, 'publication_reacted'));

    } 
    catch(err) {
        console.log(err)
        yield put(putSocialFailure(err));
        const errorKey = err?.response?.data?.errorKey;
        const errorCode = err?.response?.data?.code;
        if(errorKey === 'facebook_posting_temporary_error'){
            yield put(showGlobalError(true, errorCode, errorKey ));
        }
        else{
            yield put(showGlobalError(true, 409, "error_to_like"));
        }
    }
}

export const putSocialShareWorker = function* ({ payload: {social} }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: {social: socialUpdated} } = yield call(putSocialShareService, token, social);

        yield put(putSocialShareSuccess(socialUpdated));
        yield put(showGlobalError(true, 200, 'publication_shared'));

    } 
    catch(err) {
        console.log('err',err)
        yield put(putSocialFailure(err));
        const errorKey = err?.response?.data?.errorKey;
        const errorCode = err?.response?.data?.code;
        if(errorKey === 'facebook_posting_temporary_error'){
            yield put(showGlobalError(true, errorCode, errorKey ));
        }
        else{
            yield put(showGlobalError(true, 409, 'error_to_share_post'));
        }
    }
}


export default [
    takeLatest(GET_SOCIALS_REQUEST, getSocialsWorker),
    takeLatest(GET_SOCIAL_REQUEST, getSocialWorker),
    takeLatest(GET_SOCIALS_INCOMING_REQUEST, getSocialsIncomingWorker),
    takeLatest(GET_SOCIAL_INCOMING_REQUEST, getSocialIncomingWorker),
    takeLatest(GET_SOCIALS_OUTGOING_REQUEST, getSocialsOutgoingWorker),
    takeLatest(GET_SOCIAL_OUTGOING_REQUEST, getSocialOutgoingWorker),
    takeLatest(GET_GEOLOCATIONS_REQUEST, getGeoLocationsWorker),
    takeLatest(POST_SOCIAL_REQUEST, postSocialWorker),
    takeLatest(PUT_SOCIAL_LIKE, putSocialLikeWorker),
    takeLatest(DELETE_SOCIAL_OUTGOING_REQUEST, deletePublicationWorker),
    takeLatest(PUT_SOCIAL_REQUEST, putSocialWorker),
    takeLatest(PUT_SOCIAL_SHARE, putSocialShareWorker),
]