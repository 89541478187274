export const POST_LINKEDIN_ACCOUNT_REQUEST = 'POST_LINKEDIN_ACCOUNT_REQUEST';
export const POST_LINKEDIN_ACCOUNT_SUCCESS = 'POST_LINKEDIN_ACCOUNT_SUCCESS';
export const POST_LINKEDIN_ACCOUNT_FAILURE = 'POST_LINKEDIN_ACCOUNT_FAILURE';

export const GET_LINKEDIN_ACCOUNT_REQUEST = 'GET_LINKEDIN_ACCOUNT_REQUEST';
export const GET_LINKEDIN_ACCOUNT_SUCCESS = 'GET_LINKEDIN_ACCOUNT_SUCCESS';
export const GET_LINKEDIN_ACCOUNT_FAILURE = 'GET_LINKEDIN_ACCOUNT_FAILURE';

export const PUT_LINKEDIN_ACCOUNT_REQUEST = 'PUT_LINKEDIN_ACCOUNT_REQUEST';
export const PUT_LINKEDIN_ACCOUNT_SUCCESS = 'PUT_LINKEDIN_ACCOUNT_SUCCESS';
export const PUT_LINKEDIN_ACCOUNT_FAILURE = 'PUT_LINKEDIN_ACCOUNT_FAILURE';

export const DELETE_LINKEDIN_ACCOUNT_REQUEST = 'DELETE_LINKEDIN_ACCOUNT_REQUEST';
export const DELETE_LINKEDIN_ACCOUNT_SUCCESS = 'DELETE_LINKEDIN_ACCOUNT_SUCCESS';
export const DELETE_LINKEDIN_ACCOUNT_FAILURE = 'DELETE_LINKEDIN_ACCOUNT_FAILURE';

export const postLinkedInAccountRequest = linkedInAccount => ({
    type: POST_LINKEDIN_ACCOUNT_REQUEST,
    payload: {
        linkedInAccount
    }  
});

export const postLinkedInAccountSuccess = linkedInAccount => ({
    type: POST_LINKEDIN_ACCOUNT_SUCCESS,
    payload: {
        linkedInAccount
    }  
});

export const postLinkedInAccountFailure = err => ({
    type: POST_LINKEDIN_ACCOUNT_FAILURE,
    error: err
});

export const getLinkedInAccountRequest = (filters = {}) => ({
    type: GET_LINKEDIN_ACCOUNT_REQUEST,
    payload: {
        filters
    }  
});

export const getLinkedInAccountSuccess = linkedInAccount => ({
    type: GET_LINKEDIN_ACCOUNT_SUCCESS,
    payload: {
        linkedInAccount
    }  
});


export const getLinkedInAccountFailure = err => ({
    type: GET_LINKEDIN_ACCOUNT_FAILURE,
    error: err
});

export const putLinkedInAccountRequest = linkedInAccount => ({
    type: PUT_LINKEDIN_ACCOUNT_REQUEST,
    payload: {
        linkedInAccount
    }  
});

export const putLinkedInAccountSuccess = linkedInAccount => ({
    type: PUT_LINKEDIN_ACCOUNT_SUCCESS,
    payload: {
        linkedInAccount
    }  
});

export const putLinkedInAccountFailure = err => ({
    type: PUT_LINKEDIN_ACCOUNT_FAILURE,
    error: err
});

export const deleteLinkedInAccountRequest = linkedInAccountDeleted => ({
    type: DELETE_LINKEDIN_ACCOUNT_REQUEST,
    payload: {
        linkedInAccountDeleted
    }  
});

export const deleteLinkedInAccountSuccess = linkedInAccountDeleted => ({
    type: DELETE_LINKEDIN_ACCOUNT_SUCCESS,
    payload: {
        linkedInAccountDeleted,
        linkedInAccount: {}
    }  
});

export const deleteLinkedInAccountFailure = err => ({
    type: DELETE_LINKEDIN_ACCOUNT_FAILURE,
    error: err
});