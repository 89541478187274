import { lazy } from "react";
import { profilesSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Channels - EntryPoint" */ './components/EntryPoint'));
const ChannelsLoader = lazy(() => import(/* webpackChunkName: "Channels - ListLoader" */ './components/ListLoader'));
const FormUserLoader = lazy(() => import(/* webpackChunkName: "FormUserLoader" */ './components/FormUserLoader'));

export const defaultPath = `/settings/profiles`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: ChannelsLoader,
        exact: true,
        icon: profilesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Perfil',
            plural: false
        }
    },
    {
        path: `${defaultPath}/newProfile`,
        Component: FormUserLoader,
        exact: true,
        icon: profilesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Nuevo Perfil',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: FormUserLoader,
        exact: true,
        icon: profilesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Perfil',
            plural: false
        }
    }
];

const Profiles = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: profilesSVG,
    title: {
        key: 'profiles',
        plural: false
    },
    subTitle: {
        key: 'profile_list',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Profiles;
