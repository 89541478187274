import { takeLatest, put } from 'redux-saga/effects';
import { GET_DEPARTMENTS_REQUEST, getDepartmentsFailure, getDepartmentsSuccess } from '../actions/departments';
import { showGlobalError } from '../../app/actions/app';

export const getDepartmentsWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        yield put(getDepartmentsSuccess());
    } catch (err) {
        console.log(err);

        yield put(getDepartmentsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [takeLatest(GET_DEPARTMENTS_REQUEST, getDepartmentsWorker)];
