import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postTestConversationMessageServices = (token, botId, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/chatbot/bots/${botId}/previews/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
});