import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_LINKEDIN_ACCOUNT_REQUEST,
    postLinkedInAccountSuccess,
    postLinkedInAccountFailure,
    PUT_LINKEDIN_ACCOUNT_REQUEST,
    putLinkedInAccountSuccess,
    putLinkedInAccountFailure,
    GET_LINKEDIN_ACCOUNT_REQUEST,
    getLinkedInAccountSuccess,
    getLinkedInAccountFailure,
    DELETE_LINKEDIN_ACCOUNT_REQUEST,
    deleteLinkedInAccountSuccess,
    deleteLinkedInAccountFailure,
} from '../actions/linkedInChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postLinkedInAccountsServices,
    getLinkedInAccountsServices,
    putLinkedInAccountsServices,
    deleteLinkedInAccountsServices,
} from '../services/linkedInChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postLinkedInAccountWorker = function* ({ payload: { linkedInAccount } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { linkedInAccount: lA } } = yield call(postLinkedInAccountsServices, token, linkedInAccount);

        yield put(showGlobalError(true, 200, 'linkedin_created'));

        yield put(postLinkedInAccountSuccess(lA));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'linkedin_account_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postLinkedInAccountFailure(err));
    }
};

const putLinkedInAccountWorker = function* ({ payload: { linkedInAccount } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { linkedInAccount: lA } } = yield call(putLinkedInAccountsServices, token, linkedInAccount);

        yield put(showGlobalError(true, 200, 'linkedin_updated'));

        yield put(putLinkedInAccountSuccess(lA));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'linkedin_account_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(putLinkedInAccountFailure(err));
    }
};

export const getLinkedInAccountWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { linkedInAccount } } = yield call(getLinkedInAccountsServices, token, filters);
        yield put(getLinkedInAccountSuccess(linkedInAccount));
    } catch (err) {
        console.log(err);

        yield put(getLinkedInAccountFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteLinkedInAccountWorker = function* ({ payload: { linkedInAccountDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const { data: { lAD } } = yield call(deleteLinkedInAccountsServices, token, linkedInAccountDeleted);

        yield put(showGlobalError(true, 200, 'linkedin_deleted'));

        yield put(deleteLinkedInAccountSuccess(lAD));
    } catch (err) {
        console.log(err);

        yield put(deleteLinkedInAccountFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_LINKEDIN_ACCOUNT_REQUEST, postLinkedInAccountWorker),
    takeLatest(GET_LINKEDIN_ACCOUNT_REQUEST, getLinkedInAccountWorker),
    takeLatest(PUT_LINKEDIN_ACCOUNT_REQUEST, putLinkedInAccountWorker),
    takeLatest(DELETE_LINKEDIN_ACCOUNT_REQUEST, deleteLinkedInAccountWorker),
];