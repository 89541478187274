import {
    GET_TASK_BY_CHANNEL_REQUEST,
    GET_TASK_BY_CHANNEL_SUCCESS,
    GET_TASK_BY_CHANNEL_FAILURE
} from '../actions/taskByChannel'

const initialState = {
    statistics: {},
    fetching: false,
    getTaskByChannelSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TASK_BY_CHANNEL_REQUEST: {
            return {
                ...state,
                fetching: true,
                getTaskByChannelSuccess: false
            };
        }
        case GET_TASK_BY_CHANNEL_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getTaskByChannelSuccess: true
            };
        }
        case GET_TASK_BY_CHANNEL_FAILURE: {
            return {
                ...state,
                fetching: false,
                getTaskByChannelSuccess: false
            };
        }
        default:
            return state;
    }
}