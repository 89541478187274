import { takeLatest, put, call,select } from 'redux-saga/effects';
import { GET_CHANNELS_REQUEST, getChannelsFailure, getChannelsSuccess } from '../actions/channels';
import { accessTokenSelector } from '../../auth/selectors/token';
import { showGlobalError } from '../../app/actions/app';
import { getChannelsServices } from '../services/channels';

export const getChannelsWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {
        const token = yield select(accessTokenSelector);

        const {data:{channels}} = yield call(getChannelsServices,token);

        yield put(getChannelsSuccess(channels));
    } catch (err) {
        console.log(err);

        yield put(getChannelsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [takeLatest(GET_CHANNELS_REQUEST, getChannelsWorker)];
