import { call, takeLatest, put, select } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    GET_TOP_TAGS_REQUEST,
    getTopTagsByChannelSuccess,
    getTopTagsByChannelFailure
} from '../actions/topTagsByChannel';
import {
    getTopTagsByChannelService
} from '../services/topTagsByChannel';

export const getTopTagsByChannelWorker = function*({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
        
        const { data } = yield call(getTopTagsByChannelService, token, filters);        
        yield put (getTopTagsByChannelSuccess(data));
    }
    catch (err) {
        console.log(err);
        yield put (getTopTagsByChannelFailure(err));
    }
};


export default [
    takeLatest(GET_TOP_TAGS_REQUEST, getTopTagsByChannelWorker)
];