import axios from "axios";
import { api } from "../../app/conf";
import { VERSION } from "../repository/types";

const axiosInstance = axios.create({
  baseURL: `${api}${VERSION.VERSION_1}`,
});

axiosInstance.interceptors.request.use(
  (configuration) => {
    return configuration;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
