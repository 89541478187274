import axios from "axios";
import { api, headers, headerFormData } from "../../app/conf";

export const getCommentsService = (token, ticketId) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks/${ticketId}/comments?limit=1000`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

export const postCommentService = (token, ticketId, data) =>
    axios({
        method: "post",
        url: `${api}/v1/tasks/${ticketId}/comments`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data,
    });

export const postCommentFileService = (token, ticketId, data) =>
    axios({
        method: "post",
        url: `${api}/v1/tasks/${ticketId}/comments/uploads`,
        headers: {
            ...headerFormData,
            "access-token": token,
        },
        data,
    });
