import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postWebchatServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/webchat`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const deleteWebchatServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/webchat/${data.Id}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
