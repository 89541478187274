import { removeFromArrayBy_id } from '../../shared/helpers/commons';
import {
    GET_CHANNELS_REQUEST,
    GET_CHANNELS_SUCCESS,
    GET_CHANNELS_FAILURE,
    GET_CHANNEL_REQUEST,
    GET_CHANNEL_SUCCESS,
    GET_CHANNEL_FAILURE,
    POST_CHANNEL_REQUEST,
    POST_CHANNEL_SUCCESS,
    POST_CHANNEL_FAILURE,
    PUT_CHANNEL_REQUEST,
    PUT_CHANNEL_SUCCESS,
    PUT_CHANNEL_FAILURE,
    DELETE_CHANNEL_REQUEST,
    DELETE_CHANNEL_SUCCESS,
    DELETE_CHANNEL_FAILURE,
    SET_TWITTER_AUTH_DATA,
    CLEAN_TWITTER_AUTH_DATA,
    SET_CHANNEL_FETCHING,
    SET_CHANNELS_DATA,
    REMOVE_CHANNEL,
    ADD_CHANNEL,
} from '../actions/channels';

const initialState = {
    fetching: true,
    channels: [],
    channel:{},
    channelFetching:'',
    twitterAuthData:{},
    postSuccess: false,
    channelsData: []
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_CHANNELS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                postSuccess: false,
                channelFetching: 'all'
            };
        }

        case GET_CHANNELS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channels: action.payload.channels,
                channelFetching: ''
            };
        }

        case GET_CHANNELS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
                channelFetching: ''
            };
        }

        case GET_CHANNEL_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }

        case GET_CHANNEL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channel: action.payload.channel,
            };
        }

        case GET_CHANNEL_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case POST_CHANNEL_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case POST_CHANNEL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channel: action.payload.channel,
                channels: [...state.channels ,action.payload.channel],
                postSuccess: true
            };
        }

        case POST_CHANNEL_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case PUT_CHANNEL_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case PUT_CHANNEL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channel: action.payload.channel,
            };
        }

        case PUT_CHANNEL_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case DELETE_CHANNEL_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case DELETE_CHANNEL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channel: action.payload.channel,
                postSuccess:false
            };
        }

        case DELETE_CHANNEL_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }
        
        case SET_TWITTER_AUTH_DATA: {
            return {
                ...state,
                twitterAuthData: action.payload.twitterAuthData,
            };
        }

        case CLEAN_TWITTER_AUTH_DATA: {
            return {
                ...state,
                twitterAuthData: {},
            };
        }

        case SET_CHANNEL_FETCHING: {
            return {
                ...state,
                channelFetching: action.payload.channelFetching,
            };
        }

        case SET_CHANNELS_DATA: {
            return {
                ...state,
                channelsData: action.payload.channelsData,
            };
        }
        
        case ADD_CHANNEL: {
            return {
                ...state,
                channels: [... state.channels, action.payload.channel],
            };
        }

        case REMOVE_CHANNEL: {
            return {
                ...state,
                channels: removeFromArrayBy_id(action.payload.channelId, state.channels),
            };
        }
        
        default:
            return state;
    }
};