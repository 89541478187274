import { put, call, select, takeLatest } from 'redux-saga/effects';
import { getCallLogSuccess,
        getCallLogFailure,
        GET_CALL_LOG_REQUEST,
        CHANGE_EXPIRED_PASSWORD_REQUEST,
        changeExpiredPasswordSuccess,
        changeExpiredPasswordFailure,
        GET_AGENT_STATUS_REQUEST,

        getAgentStatusSuccess,

        setAgentStatusSuccess,

        getAgentStatusFailure,
    } from '../actions/loggedUser'; 
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';
import { putPasswordUsersServices } from '../../users/services/users';
import { getAgentStatusService, getLogAgentStatusService } from '../services/agent';

export const getCallLogWorker = function * ({payload: {id}}){
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(getLogAgentStatusService,token,id);
        yield put(getCallLogSuccess(data));
        
    } catch (err) {
        console.log(err);
        yield put(getCallLogFailure);
    }
}

export const changeExpiredPasswordWorker = function* ({ payload: { _id, oldPassword, password }}) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(putPasswordUsersServices, token, { _id, oldPassword, password })
        yield put(changeExpiredPasswordSuccess(data))
        yield put(showGlobalError(true, 200, 'psw_changed'));
    
    } catch (err) {
        console.log(err);
        yield put(changeExpiredPasswordFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export const getAgentStatusWorker = function* ({payload: { user, polling }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(getAgentStatusService, token, user, polling);

        if(polling){
            yield put(setAgentStatusSuccess(data));
        }
        else{
            yield put(getAgentStatusSuccess(data));
        }
    } catch (err) {
        console.log(err);
        yield put(getAgentStatusFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export default [
    takeLatest(GET_AGENT_STATUS_REQUEST, getAgentStatusWorker),
    takeLatest(GET_CALL_LOG_REQUEST, getCallLogWorker),
    takeLatest(CHANGE_EXPIRED_PASSWORD_REQUEST, changeExpiredPasswordWorker)
]