import { 
    GET_TOP_TAGS_FAILURE, 
    GET_TOP_TAGS_REQUEST,
    GET_TOP_TAGS_SUCCESS 
} from '../actions/topTagsByChannel'


const initialState = {
    statistics: {},
    fetching: false,
    getTopTagsByChannelSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TOP_TAGS_REQUEST: {
            return {
                ...state,
                fetching: true,
                getTopTagsByChannelSuccess: false
            };
        }
        case GET_TOP_TAGS_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getTopTagsByChannelSuccess: true
            };
        }
        case GET_TOP_TAGS_FAILURE: {
            return {
                ...state,
                fetching: false,
                getTopTagsByChannelSuccess: false
            };
        }
        default:
            return state;
    }
}