import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getTicketUsersService = (token, ticketId, filters) =>
    axios({
        method: 'get',
        url: `${api}/v1/tasks/${ticketId}/users?${ makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })