import { lazy } from 'react';
import { webchatSetSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "EntryPoint" */ './components/EntryPoint'));
const WebchatLoader = lazy(() => import(/* webpackChunkName: "WebchatLoader" */ './components/WebchatLoader'));

export const defaultPath = `/settings/channels/webchat`;

export const innerRoutes = [{
    path: defaultPath,
    Component: WebchatLoader,
    exact: true,
    icon: webchatSetSVG,
    title: {
        key: '',
        plural: false
    },
    subTitle: {
        key: 'webchat_settings',
        plural: false
    }
}];

const Webchat = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: webchatSetSVG,
    title: {
        key: '',
        plural: false
    },
    subTitle: {
        key: 'webchat_settings',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Webchat;
