import { lazy } from "react";
import { apikeySVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "apikeysEntryPoint" */ './components/EntryPoint'));
const ApikeysListLoader = lazy(() => import(/* webpackChunkName: "ApikeysListLoader" */ './components/ApikeysListLoader'));
const ApikeysFormLoader = lazy(() => import(/* webpackChunkName: "FormApiKeyLoader" */ './components/FormApiKeyLoader'));

export const defaultPath = `/settings/apikeys`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: ApikeysListLoader,
        exact: true,
        icon: apikeySVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'apikeys_list_detail',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: ApikeysFormLoader,
        exact: true,
        icon: apikeySVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Detalle de ApiKey',
            plural: false
        }
    },
    {
        path: `${defaultPath}/newApiKey`,
        Component: ApikeysFormLoader,
        exact: true,
        icon: apikeySVG,
        title: {
            key: "Nueva ApiKey",
            plural: false
        },
        subTitle: {
            key: 'Nueva ApiKey',
            plural: false
        }
    }
];

const Apikeys = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: apikeySVG,
    title: {
        key: 'apikeys',
        plural: false
    },
    subTitle: {
        key: 'apikeys_list_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Apikeys;
