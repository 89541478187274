import { 
    GET_STATUSES_FAILURE, 
    GET_STATUSES_REQUEST, 
    GET_STATUSES_SUCCESS,
    GET_FINAL_STATUSES_FAILURE,
    GET_FINAL_STATUSES_SUCCESS,
    GET_FINAL_STATUSES_REQUEST,
} from "../actions/status";

const initialState = {
    fetching: false,
    fetchingFinals:false,
    statuses: [],
    finalStatuses: [],
    error: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUSES_REQUEST:
            return {
                ...state,
                fetching: true,
                statuses: []
            }
        case GET_STATUSES_SUCCESS:
            return {
                ...state,
                fetching: false,
                statuses: action.payload.statuses
            }
        case GET_STATUSES_FAILURE:       
            return {
                ...state,
                fetching: false,
                error: action.payload.err
            }
        case GET_FINAL_STATUSES_REQUEST:
            return {
                ...state,
                fetchingFinals: true,
                finalStatuses: []
            }
        case GET_FINAL_STATUSES_SUCCESS:
            return {
                ...state,
                finalStatuses: action.payload.statuses,
                fetchingFinals:false,
            }
        case GET_FINAL_STATUSES_FAILURE:       
            return {
                ...state,
                fetchingFinals:false,
            }
        case GET_STATUSES_SUCCESS:
            return {
                ...state,
                fetching: false
            }
        default: 
            return state;
    }
};