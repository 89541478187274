import React, { memo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Version from './subComponents/Version';
import LoggedUser from "./subComponents/LoggedUser";
import TokenRefresh from '../../../auth/components/TokenRefresh';
import ChangeTheme from './subComponents/ChangeTheme';
import Company from './subComponents/Company';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from '../../../shared/helpers/commons';
import { getPreferencesRequest } from '../../../settings/actions/preferences';
import { preferencesSelector } from '../../../settings/selectors/preferences';

const Body = withRouter(({ children, history, token }) => {

    const dispatch = useDispatch();
    const { company, fetching } = useSelector(preferencesSelector);

    useEffect(() => {
        document.title = company && company.name ? (capitalize(company.name)) : 'Omuni'
    }, [company])

    useEffect(() => {
        if(token) {
            dispatch(getPreferencesRequest()); 
        }
    }, [token])

    return (
        <>
            <Version history={history} />
            <LoggedUser history={history} />
            <TokenRefresh />
            <ChangeTheme />
            <Company/>
            {children}
        </>
    );
});

export default memo(Body);
