import { SET_FILTERS } from '../actions/filters';

export const initialState = {
    name: '',
    user: '',
    workflow: '',
    trigger: '',
    condition: '',
    actions: '',
    enabled: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return{
                ...state,
                name: action.payload.name,
                user: action.payload.user,
                workflow: action.payload.workflow,
                trigger: action.payload.trigger,
                condition: action.payload.condition,
                actions: action.payload.actions,
                enabled: action.payload.enabled,
            }
        default:
            return state;
    }
}