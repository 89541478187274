import axios from 'axios';
import { api, headers } from '../../app/conf';

export const refreshTokenService = (refreshToken, token) => axios({
    method: 'post',
    url: `${api}/access/refresh`,
    data: { token: refreshToken },
    headers: {
        ...headers,
        'X-Access-Token': token
    }
});
