import { createSelector } from 'reselect';

const getError = state => state.app;

const getLoggedUserAccesor = state => state.home.loggedUser.user;
export const getFetchingPassword = state => state.home.loggedUser.fetchingChangingPsw;
export const getCompanyIDAccesor = state => state.home.loggedUser.user.company;
export const getAgentStatusAccesor = state => state.home.loggedUser.user.agentStatus;
export const getTelephonyStatusAccesor = state => state.home.loggedUser.user.telephonyStatus;
export const getCallLogAccesor = state => state.home.loggedUser.callLog;
export const getCallLogFetching = state => state.home.loggedUser.callFetching;
export const getCallLogSuccess = state => state.home.loggedUser.getSuccess;
export const getFullLoggedUserSelector = state => state.home.loggedUser;

export const loggedUserSelector = createSelector(getLoggedUserAccesor, state => state);
export const fetchingPassword = createSelector(getFetchingPassword, state => state);
export const callLogSelector = createSelector(getCallLogAccesor, state => state);
export const companyIDSelector = createSelector(getCompanyIDAccesor, state => state);
export const agentStatusSelector = createSelector(getAgentStatusAccesor, state => state);
export const telephonyStatusSelector = createSelector(getTelephonyStatusAccesor, state => state);
export const callLogFetchingSelector = createSelector(getCallLogFetching, state => state);
export const callLogSuccessSelector = createSelector(getCallLogSuccess, state => state);
export const fullLoggedUserSelector = createSelector(getFullLoggedUserSelector, state => state);


export const errorSelector = createSelector(getError, state => state);