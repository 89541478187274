import { createSelector } from 'reselect';

export const channelsAccessor = state => state.channels.channels; 

export const channelsDataAccessor = state => state.channels.channels.channelsData; 

export const channelsSelector = createSelector(channelsAccessor, channels => channels);

export const channelsLoginsAccessor = state => state.channels.channelsLogins; 

export const channelsLoginsSelector = createSelector(channelsLoginsAccessor, channelsLogins => channelsLogins);

export const channelsDataSelector = createSelector(channelsDataAccessor, channelsData => channelsData);