export const GET_COMMENTS = 'GET_COMMENTS';
export const getComments = (ticketId) => ({
    type: GET_COMMENTS,
    payload: { ticketId }
});

export const REFRESH_COMMENTS = 'REFRESH_COMMENTS';
export const refreshComments = (ticketId) => ({
    type: REFRESH_COMMENTS,
    payload: { ticketId }
});

export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const getCommentsSuccess = (comments=[]) => ({
    type: GET_COMMENTS_SUCCESS,
    payload: { comments }
});

export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';
export const getCommentsFailure = (error={}) => ({
    type: GET_COMMENTS_FAILURE,
    payload: { error }
});

export const POST_COMMENT = 'POST_COMMENT';
export const postComment = (comment, ticketId, attachment, type) => ({
    type: POST_COMMENT,
    payload: { comment, ticketId, attachment, type }
});

export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const postCommentSuccess = (comment={}) => ({
    type: POST_COMMENT_SUCCESS,
    payload: { comment }
});

export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';
export const postCommentFailure = (error={}) => ({
    type: POST_COMMENT_FAILURE,
    payload: { error }
});

export const SET_COMMENTS = 'SET_COMMENTS';
export const setComments = (comments = []) => ({
    type: SET_COMMENTS,
    payload: {
        comments
    }
});

export const CLEAN_COMMENTS = 'CLEAN_COMMENTS';
export const cleanComments = () => ({
    type: CLEAN_COMMENTS,
});