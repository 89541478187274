import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getRulesService = (token) => 
    axios({
        method: 'get',
        url: `${api}/v1/rules`,// esto para despues ${makeQuery(filters)}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

export const postRulesService = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/rules`,
        headers: {
            ...headers,
            'access-token': token
        },
        data
    })

export const putRulesService = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/rules/${data._id}`,
        headers: {
            ...headers,
            'access-token': token
        },
        data
    })

export const deleteRulesService = (token, id) => 
    axios({
        method: 'delete',
        url: `${api}/v1/rules/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
    })

export const postAffectedTasksService = (token, data) => 
axios({
    method: 'post',
    url: `${api}/v1/rules/affectedTasks`,
    headers: {
        ...headers,
        'access-token': token
    },
    data
})