import { call, takeLatest, put, select } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    GET_RESPONSE_TIME_REQUEST,
    getResponseTimeSuccess,
    getResponseTimeFailure
} from '../actions/responseTime';
import {
    getResponseTimeService
} from '../services/responseTime';


export const getResponseTimeWorker = function*({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
        
        const { data } = yield call(getResponseTimeService, token, filters);        
        yield put (getResponseTimeSuccess(data));
    }
    catch (err) {
        console.log(err);
        yield put (getResponseTimeFailure(err));
    }
};


export default [
    takeLatest(GET_RESPONSE_TIME_REQUEST, getResponseTimeWorker)
];