import {
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_CONTACTS_FAILURE,
    SET_CONTACT,
    SET_CONTACTS,
    EDIT_CONTACT_REQUEST,
    EDIT_CONTACT_SUCCESS,
    EDIT_CONTACT_FAILURE,
    POST_CONTACT_REQUEST,
    POST_CONTACT_SUCCESS,
    POST_CONTACT_FAILURE,
    GET_HISTORY_REQUEST,
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAILURE,
    CLEAN_HISTORY_REQUEST,
    SET_HISTORY,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAILURE,
    SET_CONTACTS_LIMIT,
    SET_CONTACTS_PAGE,
    GET_CONTACT_BY_ID_REQUEST,
    GET_CONTACT_BY_ID_SUCCESS,
    GET_CONTACT_BY_ID_FAILURE,
    CLEAN_CONTACT,
} from '../actions/contacts';

const initialState = {
    contacts: [],
    contact: null,
    total: 0,
    fetching: false,
    fetchingEdit: false,
    postSuccess: false,
    fetchingHistory: false,
    history: [],
    deleteFetching: false,
    deleteSuccess: false,
    putSuccess: false,
    page: 0,
    limit: 10,
    idContact: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                fetching: true,
                postSuccess: false,
                fetchingEdit: false,
                putSuccess: false
            }
        case GET_CONTACTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                contacts: [...action.payload.contacts],
                postSuccess: false,
                fetchingEdit: false,
                total: action.payload.total
            }
        case GET_CONTACTS_FAILURE:
            return {
                ...state,
                fetching: false,
                postSuccess: false,
                fetchingEdit: false
            }
        case GET_CONTACT_BY_ID_REQUEST:
            return {
                ...state,
                fetching: true,
                postSuccess: false,
                fetchingEdit: false,
                putSuccess: false,
                idContact: action.payload.idContact
            }
        case GET_CONTACT_BY_ID_SUCCESS:
            return {
                ...state,
                fetching: false,
                postSuccess: false,
                fetchingEdit: false,
                contact: action.payload.contact
            }
        case GET_CONTACT_BY_ID_FAILURE:
            return {
                ...state,
                fetching: false,
                postSuccess: false,
                fetchingEdit: false
            }
        case EDIT_CONTACT_REQUEST:
            return {
                ...state,
                fetchingEdit: true,
                putSuccess: false,
            }
        case EDIT_CONTACT_SUCCESS:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: true,
                contact: action.payload.contact
            }
        case SET_CONTACTS_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }
        case SET_CONTACTS_PAGE:
        return {
            ...state,
            page: action.payload.page,
        }
        case EDIT_CONTACT_FAILURE:
            return {
                ...state,
                fetchingEdit: false,
                putSuccess: false,
            }
        case POST_CONTACT_REQUEST:
            return {
                ...state,
                fetching: true,
                postSuccess: false
            }
        case POST_CONTACT_SUCCESS:
            return {
                ...state,
                fetching: false,
                postSuccess: true,
                contact: action.payload.contact
            }
        case POST_CONTACT_FAILURE:
            return {
                ...state,
                fetching: false,
                postSuccess: false
            }
        case SET_CONTACTS:
            return{
                ...state,
                contacts: action.payload.contacts,
                total: action.payload.total
            }
        case SET_CONTACT:
            return{
                ...state,
                contact: action.payload.contact
            }
        case GET_HISTORY_REQUEST:
            return {
                ...state,
                fetchingHistory: true
            }
        case GET_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingHistory: false,
                history: action.payload.history
            }
        case GET_HISTORY_FAILURE:
            return {
                ...state,
                fetchingHistory: false,
            }
        case CLEAN_HISTORY_REQUEST:
            return{
                ...state,
                history: []
            }
        case SET_HISTORY:
            return{
                ...state,
                history: action.payload.history
            }
        case DELETE_CONTACT_REQUEST:
            return {
                ...state,
                deleteFetching: true,
                deleteSuccess: false
            }
        case DELETE_CONTACT_SUCCESS: 
            return {
                ...state,
                deleteFetching: false,
                deleteSuccess: true
            }
        case DELETE_CONTACT_FAILURE:
            return {
                ...state,
                deleteFetching: false
            }
        case CLEAN_CONTACT:
            console.log('cleancontact')
            return {
                ...state,
                contact: null
            }
        default:
            return state;
    }
}