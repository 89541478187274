import { memo, useEffect } from 'react';
import { withRouter } from 'react-router';

const TokenRefresh = withRouter(({ history, refreshTokenRequest, validTokens }) => {

    useEffect(() => {
        if (validTokens) {
            refreshTokenRequest(history);
        }
    }, [history, validTokens, refreshTokenRequest]);

    return null;
});

export default memo(TokenRefresh);