import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_WHATSAPP_NUMBER_REQUEST,
    postWhatsAppNumberSuccess,
    postWhatsAppNumberFailure,
    PUT_WHATSAPP_NUMBER_REQUEST,
    putWhatsAppNumberSuccess,
    putWhatsAppNumberFailure,
    GET_WHATSAPP_NUMBER_REQUEST,
    getWhatsAppNumberSuccess,
    getWhatsAppNumberFailure,
    DELETE_WHATSAPP_NUMBER_REQUEST,
    deleteWhatsAppNumberSuccess,
    deleteWhatsAppNumberFailure,
} from '../actions/whatsAppChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postWhatsAppNumbersServices,
    getWhatsAppNumbersServices,
    putWhatsAppNumbersServices,
    deleteWhatsAppNumbersServices,
} from '../services/whatsAppChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postWhatsAppNumberWorker = function* ({ payload: { whatsAppNumber } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { whatsAppNumber: wN } } = yield call(postWhatsAppNumbersServices, token, whatsAppNumber);

        yield put(showGlobalError(true, 200, 'whatsapp_created'));

        yield put(postWhatsAppNumberSuccess(wN));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'whatsapp_number_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postWhatsAppNumberFailure(err));
    }
};

const putWhatsAppNumberWorker = function* ({ payload: { whatsAppNumber } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { whatsAppNumber: wN } } = yield call(putWhatsAppNumbersServices, token, whatsAppNumber);

        yield put(showGlobalError(true, 200, 'whatsapp_updated'));

        yield put(putWhatsAppNumberSuccess(wN));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'whatsapp_user_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(putWhatsAppNumberFailure(err));
    }
};

export const getWhatsAppNumberWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { whatsAppNumber } } = yield call(getWhatsAppNumbersServices, token, filters);
        yield put(getWhatsAppNumberSuccess(whatsAppNumber));
    } catch (err) {
        console.log(err);

        yield put(getWhatsAppNumberFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteWhatsAppNumberWorker = function* ({ payload: { whatsAppNumberDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const { data: { wND } } = yield call(deleteWhatsAppNumbersServices, token, whatsAppNumberDeleted);

        yield put(showGlobalError(true, 200, 'whatsapp_deleted'));

        yield put(deleteWhatsAppNumberSuccess(wND));
    } catch (err) {
        console.log(err);

        yield put(deleteWhatsAppNumberFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_WHATSAPP_NUMBER_REQUEST, postWhatsAppNumberWorker),
    takeLatest(GET_WHATSAPP_NUMBER_REQUEST, getWhatsAppNumberWorker),
    takeLatest(PUT_WHATSAPP_NUMBER_REQUEST, putWhatsAppNumberWorker),
    takeLatest(DELETE_WHATSAPP_NUMBER_REQUEST, deleteWhatsAppNumberWorker),
];