import {
    CLEAN_FILTERS_REQUEST,
    SET_FILTERS_DASHBOARD
} from '../actions/filters';

const initialState = {
    filters: {},
};

export default (state = initialState , action) => {
    switch(action.type) {
        case SET_FILTERS_DASHBOARD:{
            return {
                ...state,
                filters: action.payload.filters
            };
        }
        case CLEAN_FILTERS_REQUEST:{
            return {
                ...state,
                filters: {}
            };
        }
        default:
            return state;

    }
};