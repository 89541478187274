export const GET_TOP_TAGS_REQUEST = 'GET_TOP_TAGS_REQUEST';
export const GET_TOP_TAGS_SUCCESS = 'GET_TOP_TAGS_SUCCESS';
export const GET_TOP_TAGS_FAILURE = 'GET_TOP_TAGS_FAILURE';


export const getTopTagsByChannelRequest = (filters = {}) => ({
    type: GET_TOP_TAGS_REQUEST,
    payload: {
        filters
    }
});

export const getTopTagsByChannelSuccess = (statistics) => ({
    type: GET_TOP_TAGS_SUCCESS,
    payload: {
        statistics
    }
});

export const getTopTagsByChannelFailure = (err = {}) => ({
    type: GET_TOP_TAGS_FAILURE,
    payload: {
        err
    }
});