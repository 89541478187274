export const SET_FILTERS = 'SET_FILTERS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFilters = ({name}) => ({
    type:SET_FILTERS,
    payload:{
        name
    }
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});