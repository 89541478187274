import {
    GET_TICKET_USERS_REQUEST,
    GET_TICKET_USERS_SUCCESS,
    GET_TICKET_USERS_FAILURE,
} from '../actions/ticketUsers';

export const initialState = {
    ticketUsers: [],
    fetching: false,
    filters: {
        searchText: "",
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKET_USERS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case GET_TICKET_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                ticketUsers: action.payload.ticketUsers,
            }
        case GET_TICKET_USERS_FAILURE:
            return {
                ...state,
                fetching: false
            }
        default:
            return state;
    }
};