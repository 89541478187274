import { lazy } from "react"; 
import { ICONS } from "./helpers/icons.js";

const EntryPoint = lazy(() => import("./components/EntryPoint/EntryPoint"));

const BusinessIntelligenceFeature = lazy(() =>
  import("./BusinessIntelligence.jsx")
);

export const defaultPath = `/business-intelligence`;

export const innerRoutes = [
  {
    path: `${defaultPath}/`,
    Component: BusinessIntelligenceFeature,
    exact: true,
    icon: ICONS.REPORTS,
    title: {
      key: "",
      plural: false,
    },
    subTitle: {
      key: "Visualizá tus reportes personalizados",
      plural: false,
    },
  },
];

const BusinessIntelligenceBase = {
  path: defaultPath,
  Component: EntryPoint,
  exact: false,
  icon: ICONS.REPORTS,
  title: {
    key: "Business Intelligence",
    plural: false,
  },
  subTitle: {
    key: "Visualizá tus reportes personalizados",
    plural: false,
  },
  innerRoutes: innerRoutes,
  pathChilds: innerRoutes.map((e) => e.path),
};

export default BusinessIntelligenceBase;
