export const CHANNELS_ICONS = {
  OMUNI: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.48028 10.4834C8.5466 10.3839 8.58199 10.2669 8.58199 10.1472C8.58199 9.98679 8.51841 9.8329 8.40523 9.71944C8.29206 9.60598 8.13855 9.54224 7.9785 9.54224C7.85914 9.54224 7.74246 9.57772 7.64321 9.6442C7.54397 9.71068 7.46662 9.80517 7.42094 9.91572C7.37526 10.0263 7.36331 10.1479 7.3866 10.2653C7.40988 10.3826 7.46736 10.4904 7.55176 10.5751C7.63616 10.6597 7.74369 10.7173 7.86076 10.7406C7.97783 10.764 8.09917 10.752 8.20944 10.7062C8.31972 10.6604 8.41397 10.5829 8.48028 10.4834Z" fill="#8C8C8C"/>
  <path d="M10.7125 10.1476C10.7142 10.2696 10.6795 10.3895 10.6129 10.4917C10.5463 10.5939 10.4508 10.6739 10.3386 10.7214C10.2264 10.7689 10.1026 10.7818 9.98308 10.7584C9.86354 10.735 9.75369 10.6764 9.66757 10.5901C9.58145 10.5037 9.52297 10.3936 9.49963 10.2737C9.47629 10.1539 9.48913 10.0298 9.53653 9.91734C9.58392 9.80486 9.66371 9.70912 9.76568 9.64235C9.86766 9.57559 9.98718 9.54083 10.109 9.54254C10.2683 9.54478 10.4205 9.60923 10.5332 9.72222C10.6459 9.8352 10.7102 9.98779 10.7125 10.1476Z" fill="#8C8C8C"/>
  <path d="M12.6287 10.4836C12.695 10.3841 12.7304 10.2672 12.7304 10.1475C12.7304 9.98703 12.6668 9.83315 12.5537 9.71968C12.4405 9.60622 12.287 9.54248 12.1269 9.54248C12.0076 9.54248 11.8909 9.57796 11.7916 9.64444C11.6924 9.71092 11.6151 9.80541 11.5694 9.91597C11.5237 10.0265 11.5117 10.1482 11.535 10.2655C11.5583 10.3829 11.6158 10.4907 11.7002 10.5753C11.7846 10.6599 11.8921 10.7175 12.0092 10.7409C12.1263 10.7642 12.2476 10.7522 12.3579 10.7065C12.4682 10.6607 12.5624 10.5831 12.6287 10.4836Z" fill="#8C8C8C"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.07712 19.5687C8.02093 19.856 8.99876 20.0003 9.97858 20C11.2856 20.0013 12.5866 19.7451 13.8058 19.2388C14.4519 18.9705 15.0647 18.6363 15.6354 18.2434L15.5927 18.2974C17.3687 17.0997 18.7133 15.361 19.4278 13.3383C20.1424 11.3156 20.189 9.11615 19.5607 7.06485C18.9325 5.01355 17.6627 3.21925 15.9391 1.94706C14.2201 0.678376 12.1407 -0.00397828 10.0065 1.74489e-05L9.9886 1.29178e-06L9.98148 3.84308e-06C8.40778 -0.00103273 6.85595 0.371231 5.45291 1.08662C4.03771 1.8082 2.81477 2.85804 1.88577 4.14884C0.956765 5.43964 0.348536 6.93411 0.111649 8.50801C-0.125238 10.0819 0.0160604 11.6898 0.523797 13.1979C1.03153 14.706 1.89104 16.0709 3.03085 17.1789C4.17067 18.287 5.55787 19.1063 7.07712 19.5687ZM8.41089 13.8029L8.40469 13.803H8.36708C8.06064 13.803 7.76087 13.7381 7.48606 13.6159C7.24891 13.5104 7.03231 13.3629 6.8467 13.1805C6.64037 12.9777 6.47647 12.7356 6.3646 12.4685C6.25273 12.2014 6.19514 11.9146 6.1952 11.6249V8.3751C6.1952 7.79745 6.4241 7.24346 6.83154 6.83499C7.15303 6.51269 7.56491 6.30183 8.00745 6.22722C8.01949 6.22519 8.03156 6.22326 8.04365 6.22143C8.15007 6.20537 8.25815 6.19717 8.36708 6.19717L11.6094 6.19706C12.1856 6.19706 12.7382 6.42653 13.1457 6.83499C13.5531 7.24346 13.782 7.79745 13.782 8.3751V15.1167C13.7833 15.1583 13.7726 15.1995 13.7512 15.2351C13.7297 15.2708 13.6984 15.2995 13.6611 15.3178C13.6237 15.3361 13.5819 15.3431 13.5406 15.3381C13.4994 15.3331 13.4604 15.3162 13.4285 15.2895L12.1439 14.2092C11.8213 13.9427 11.4153 13.7988 10.9973 13.8029H8.41089Z" fill="#8C8C8C"/>
  </svg>`
};

export const COMMON_ICONS = {
  EXPAND_ARROWS: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="arrow-expand">
  <path id="Vector" d="M7.5 15.75V14.25H4.8075L8.1825 10.875L7.125 9.8175L3.75 13.1925V10.5H2.25V15.75H7.5ZM10.875 8.1825L14.25 4.8075V7.5H15.75V2.25H10.5V3.75H13.1925L9.8175 7.125L10.875 8.1825Z" fill="#757575"/>
  </g>
  </svg>
  `,
  ALERT_ICON: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.41697 3L3.14697 8.27V15.73L8.41697 21H15.877C17.647 19.24 21.147 15.73 21.147 15.73V8.27L15.877 3M9.24697 5H15.047L19.147 9.1V14.9L15.047 19H9.24697L5.14697 14.9V9.1M11.147 15H13.147V17H11.147V15ZM11.147 7H13.147V13H11.147V7Z" fill="#FBD272"/>
  </svg>
  `,
  NO_PUBLICATION_IMAGE: `<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" width="64" height="64" rx="4" fill="#F4F4F4"/>
  <path d="M44.3257 43.2057H20.9924V19.8724H44.3257M44.3257 16.5391H20.9924C20.1083 16.5391 19.2605 16.8903 18.6354 17.5154C18.0102 18.1405 17.6591 18.9883 17.6591 19.8724V43.2057C17.6591 44.0898 18.0102 44.9376 18.6354 45.5627C19.2605 46.1879 20.1083 46.5391 20.9924 46.5391H44.3257C45.2098 46.5391 46.0576 46.1879 46.6827 45.5627C47.3079 44.9376 47.6591 44.0898 47.6591 43.2057V19.8724C47.6591 18.9883 47.3079 18.1405 46.6827 17.5154C46.0576 16.8903 45.2098 16.5391 44.3257 16.5391ZM35.9257 32.0224L31.3424 37.9224L28.0757 33.9891L23.4924 39.8724H41.8257L35.9257 32.0224Z" fill="#878787"/>
  </svg>
  `,
  PUBLICATION_VIDEO: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="64" height="64" rx="4" fill="#F4F4F4"/>
  <path d="M37 25.3333V38.6667H20.3333V25.3333H37ZM38.6667 22H18.6667C18.2246 22 17.8007 22.1756 17.4882 22.4882C17.1756 22.8007 17 23.2246 17 23.6667V40.3333C17 40.7754 17.1756 41.1993 17.4882 41.5118C17.8007 41.8244 18.2246 42 18.6667 42H38.6667C39.1087 42 39.5326 41.8244 39.8452 41.5118C40.1577 41.1993 40.3333 40.7754 40.3333 40.3333V34.5L47 41.1667V22.8333L40.3333 29.5V23.6667C40.3333 23.2246 40.1577 22.8007 39.8452 22.4882C39.5326 22.1756 39.1087 22 38.6667 22Z" fill="#878787"/>
  </svg>
  `,
  PRINT: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19ZM8 5V8H16V5H8ZM16 19V15H8V19H16ZM18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15ZM19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" fill="black"/>
  </svg>`,
  CLEAN: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.01 3L21.43 4.42L15.71 10.13C16.78 11.67 16.93 13.52 16.03 14.72L9.71 8.4C10.91 7.5 12.76 7.65 14.3 8.72L20.01 3ZM6.58 17.85C4.57 15.84 3.34 13.44 3 11.2L7.88 9.11L15.32 16.55L13.23 21.43C10.99 21.09 8.59 19.86 6.58 17.85Z" stroke="black" stroke-width="1.5"/>
  </svg>
  `,
  EXCEL:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.17 3.25C21.39 3.25 21.5867 3.33333 21.76 3.5C21.92 3.66 22 3.85333 22 4.08V19.92C22 20.1467 21.92 20.34 21.76 20.5C21.5867 20.6667 21.39 20.75 21.17 20.75H7.83C7.61 20.75 7.41333 20.6667 7.24 20.5C7.08 20.34 7 20.1467 7 19.92V17H2.83C2.61 17 2.41333 16.92 2.24 16.76C2.08 16.5867 2 16.39 2 16.17V7.83C2 7.61 2.08 7.41333 2.24 7.24C2.41333 7.08 2.61 7 2.83 7H7V4.08C7 3.85333 7.08 3.66 7.24 3.5C7.41333 3.33333 7.61 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03C6.88667 10.6767 6.7 10.32 6.5 9.96C6.33333 9.60667 6.15667 9.25 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7H20.75Z" fill="black"/>
  </svg>
  `,
  ADD_CONNECTION:`<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 17C2 9.92893 2 6.3934 4.1967 4.1967C6.3934 2 9.92893 2 17 2C24.0711 2 27.6066 2 29.8033 4.1967C32 6.3934 32 9.92893 32 17C32 24.0711 32 27.6066 29.8033 29.8033C27.6066 32 24.0711 32 17 32C9.92893 32 6.3934 32 4.1967 29.8033C2 27.6066 2 24.0711 2 17Z" fill="white" stroke="#1D6BD7" stroke-width="2.21053"/>
  <path d="M16.7933 10.4782V23.1098M23.1091 16.794L10.4775 16.794" stroke="#1D6BD7" stroke-width="2.21053" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`
};

export const COLOR_CHANNELS_ICONS = {
  OMUNI: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5004_21036)">
<path d="M12 3.29737e-06C9.62663 3.29737e-06 7.30655 0.703791 5.33316 2.02237C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519947 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693604 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24.0012 10.4238 23.6916 8.86285 23.0889 7.40642C22.4863 5.94998 21.6024 4.62665 20.4879 3.51211C19.3734 2.39758 18.05 1.51371 16.5936 0.911068C15.1371 0.308426 13.5762 -0.00116555 12 3.29737e-06Z" fill="url(#paint0_linear_5004_21036)"/>
<path d="M16.5341 17.6983C16.5345 17.7469 16.5212 17.7946 16.4956 17.836C16.47 17.8774 16.4333 17.9107 16.3896 17.9321C16.3459 17.9534 16.2971 17.962 16.2487 17.9568C16.2003 17.9516 16.1544 17.9328 16.1163 17.9026L14.5252 16.6053C14.138 16.2912 13.655 16.1187 13.1563 16.1166H10.0185C9.32777 16.1166 8.66531 15.8423 8.17689 15.3541C7.68846 14.8658 7.41406 14.2036 7.41406 13.5131V9.60348C7.41406 8.91299 7.68846 8.25079 8.17689 7.76254C8.66531 7.27429 9.32777 7 10.0185 7H13.9296C14.6204 7 15.2828 7.27429 15.7712 7.76254C16.2597 8.25079 16.5341 8.91299 16.5341 9.60348V17.6983Z" fill="white"/>
<g style="mix-blend-mode:multiply">
<path d="M10.2671 11.7687C10.2671 11.9726 10.188 12.1681 10.0472 12.3122C9.90645 12.4564 9.71549 12.5374 9.51638 12.5374C9.31727 12.5374 9.12631 12.4564 8.98552 12.3122C8.84472 12.1681 8.76562 11.9726 8.76562 11.7687C8.76562 11.5648 8.84472 11.3693 8.98552 11.2251C9.12631 11.081 9.31727 11 9.51638 11C9.71549 11 9.90645 11.081 10.0472 11.2251C10.188 11.3693 10.2671 11.5648 10.2671 11.7687Z" fill="#01A7E5"/>
<path d="M12.9155 11.7682C12.9173 11.9206 12.8749 12.0701 12.7935 12.1978C12.7121 12.3254 12.5955 12.4254 12.4585 12.485C12.3215 12.5446 12.1702 12.5612 12.024 12.5326C11.8778 12.504 11.7431 12.4315 11.6372 12.3244C11.5313 12.2173 11.4589 12.0803 11.4292 11.931C11.3995 11.7816 11.4138 11.6266 11.4703 11.4856C11.5269 11.3446 11.6231 11.224 11.7467 11.1391C11.8704 11.0542 12.0159 11.0089 12.1648 11.0088C12.2629 11.0076 12.3603 11.0263 12.4514 11.0639C12.5424 11.1015 12.6252 11.1573 12.6951 11.2279C12.7649 11.2985 12.8204 11.3827 12.8582 11.4754C12.896 11.5681 12.9155 11.6677 12.9155 11.7682Z" fill="#01A7E5"/>
<path d="M15.3999 11.7681C15.4017 11.9205 15.3592 12.0701 15.2778 12.1977C15.1965 12.3253 15.0799 12.4253 14.9428 12.4849C14.8058 12.5446 14.6546 12.5611 14.5084 12.5325C14.3621 12.5039 14.2275 12.4315 14.1216 12.3244C14.0157 12.2172 13.9433 12.0803 13.9135 11.9309C13.8838 11.7816 13.8982 11.6265 13.9547 11.4855C14.0112 11.3445 14.1075 11.2239 14.2311 11.139C14.3548 11.0541 14.5003 11.0088 14.6491 11.0088C14.8467 11.0088 15.0363 11.0885 15.1768 11.2306C15.3173 11.3727 15.3975 11.5659 15.3999 11.7681Z" fill="#01A7E5"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_5004_21036" x1="2.98667" y1="2.98667" x2="19.9644" y2="19.9644" gradientUnits="userSpaceOnUse">
<stop stop-color="#C3D500"/>
<stop offset="0.19" stop-color="#90CC3E"/>
<stop offset="0.41" stop-color="#5DC37C"/>
<stop offset="0.6" stop-color="#35BCAD"/>
<stop offset="0.77" stop-color="#18B7D1"/>
<stop offset="0.91" stop-color="#06B4E6"/>
<stop offset="1" stop-color="#00B3EE"/>
</linearGradient>
<clipPath id="clip0_5004_21036">
<rect width="24" height="24" rx="12" fill="white"/>
</clipPath>
</defs>
</svg>`,
};
