import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getIntentsServices = (token, botId) =>
    axios({
        method: 'get',
        url: `${api}/v1/chatbot/bots/${botId}/intents/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const postIntentServices = (token, botId, intent) =>
    axios({
        method: 'post',
        url: `${api}/v1/chatbot/bots/${botId}/intents/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:intent
});

export const putIntentServices = (token, botId, intentId, intent) =>
    axios({
        method: 'put',
        url: `${api}/v1/chatbot/bots/${botId}/intents/${intentId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:intent
});

export const deleteIntentServices = (token, botId, id) =>
    axios({
        method: 'delete',
        url: `${api}/v1/chatbot/bots/${botId}/intents/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });