import {
    DELETE_WORKFLOW_TRANSITION_REQUEST,
    DELETE_WORKFLOW_TRANSITION_SUCCESS,
    DELETE_WORKFLOW_TRANSITION_FAILURE,
    GET_WORKFLOW_TRANSITIONS_REQUEST,
    GET_WORKFLOW_TRANSITIONS_SUCCESS,
    GET_WORKFLOW_TRANSITIONS_FAILURE,
    POST_WORKFLOW_TRANSITION_REQUEST,
    POST_WORKFLOW_TRANSITION_SUCCESS,
    POST_WORKFLOW_TRANSITION_FAILURE,
    GET_ALL_WORKFLOWS_REQUEST,
    GET_ALL_WORKFLOWS_SUCCESS,
    GET_ALL_WORKFLOWS_FAILURE,
    GET_COMPANY_WORKFLOWS_REQUEST,
    GET_COMPANY_WORKFLOWS_SUCCESS,
    GET_COMPANY_WORKFLOWS_FAILURE,
    GET_WORKFLOW_REQUEST,
    GET_WORKFLOW_SUCCESS,
    GET_WORKFLOW_FAILURE,
    POST_WORKFLOW_REQUEST,
    POST_WORKFLOW_SUCCESS,
    POST_WORKFLOW_FAILURE,
    PUT_WORKFLOW_REQUEST,
    PUT_WORKFLOW_SUCCESS,
    PUT_WORKFLOW_FAILURE,
    DELETE_WORKFLOW_REQUEST,
    DELETE_WORKFLOW_SUCCESS,
    DELETE_WORKFLOW_FAILURE
} from '../actions/workflows';

const initialState = {
    workflowTransitions: [],
    workflowTransition: null,
    workflowTransitionDeleted: null,
    fetching: false,
    postTransitionSuccess: false,
    deleteTransitionSuccess:false,
    postWorkflowSuccess:false,
    deleteWorkflowSuccess:false,
    workflow:{},
    deleteFetching:false,
    putWorkflowSuccess:false,
    putFetching:false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DELETE_WORKFLOW_TRANSITION_REQUEST: {
            return {
                ...state,
                fetching: true,
                deleteTransitionSuccess: false,
            };
        }

        case DELETE_WORKFLOW_TRANSITION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                deleteTransitionSuccess: true,
                workflowTransitionDeleted: action.payload.workflowTransitionDeleted,
            };
        }

        case DELETE_WORKFLOW_TRANSITION_FAILURE: {
            return {
                ...state,
                fetching: false,
                deleteTransitionSuccess: false,
            };
        }

        case GET_WORKFLOW_TRANSITIONS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_WORKFLOW_TRANSITIONS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                workflowTransitions: action.payload.workflowTransitions,
            };
        }

        case GET_WORKFLOW_TRANSITIONS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case POST_WORKFLOW_TRANSITION_REQUEST: {
            return {
                ...state,
                fetching: true,
                postTransitionSuccess: false,
            };
        }

        case POST_WORKFLOW_TRANSITION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                postTransitionSuccess: true,
                workflowTransition: action.payload.workflowTransition,
            };
        }

        case POST_WORKFLOW_TRANSITION_FAILURE: {
            return {
                ...state,
                fetching: false,
                postTransitionSuccess: false,
            };
        }

        case GET_ALL_WORKFLOWS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_ALL_WORKFLOWS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                workflows: action.payload.workflows,
            };
        }

        case GET_ALL_WORKFLOWS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }
        case GET_COMPANY_WORKFLOWS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_COMPANY_WORKFLOWS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                workflows: action.payload.workflows,
            };
        }

        case GET_COMPANY_WORKFLOWS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case POST_WORKFLOW_REQUEST: {
            return {
                ...state,
                fetching: true,
                postWorkflowSuccess: false,
            };
        }

        case POST_WORKFLOW_SUCCESS: {
            return {
                ...state,
                fetching: false,
                postWorkflowSuccess: true,
                workflows: action.payload.workflows,
            };
        }

        case POST_WORKFLOW_FAILURE: {
            return {
                ...state,
                fetching: false,
                postWorkflowSuccess: false,
            };
        }

        case PUT_WORKFLOW_REQUEST: {
            return {
                ...state,
                putFetching: true,
                putWorkflowSuccess: false,
            };
        }

        case PUT_WORKFLOW_SUCCESS: {
            return {
                ...state,
                putFetching: false,
                putWorkflowSuccess: true,
                workflow: action.payload.workflow,
                // workflows: state.workflows.map((workflow) =>
                //     workflow._id === action.payload._id ? (workflow = action.payload) : workflow,
                // )
            };
        }

        case PUT_WORKFLOW_FAILURE: {
            return {
                ...state,
                putFetching: false,
                putWorkflowSuccess: false,
            };
        }

        case DELETE_WORKFLOW_REQUEST: {
            return {
                ...state,
                deleteFetching: true,
                deleteWorkflowSuccess: false,
            };
        }

        case DELETE_WORKFLOW_SUCCESS: {
            return {
                ...state,
                deleteFetching: false,
                deleteWorkflowSuccess: true,
            };
        }

        case DELETE_WORKFLOW_FAILURE: {
            return {
                ...state,
                deleteFetching: false,
                deleteWorkflowSuccess: false,
            };
        }

        case GET_WORKFLOW_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_WORKFLOW_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                workflow: action.payload.workflow,
            };
        }

        case GET_WORKFLOW_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }
        default:
            return state;
    }
}