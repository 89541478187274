import {
    POST_WEBCHAT_REQUEST,
    POST_WEBCHAT_SUCCESS,
    POST_WEBCHAT_FAILURE,
    DELETE_WEBCHAT_REQUEST,
    DELETE_WEBCHAT_SUCCESS,
    DELETE_WEBCHAT_FAILURE,
} from '../actions/webchatChannel';

const initialState = {
    fetching: true,
    webchat: {},
    webchatDeleted:[]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_WEBCHAT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_WEBCHAT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                webchat: action.payload.webchat
            };
        }
        case POST_WEBCHAT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_WEBCHAT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_WEBCHAT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                webchatDeleted: action.payload.webchatDeleted,
                webchat: {}
            };
        }
        case DELETE_WEBCHAT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
}