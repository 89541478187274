export const SET_FILTERS_STATISTICS = 'SET_FILTERS_STATISTICS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFiltersStatistics = ({ channel, dateFrom, dateTo }) => ({
    type: SET_FILTERS_STATISTICS,
    payload: {
        channel,
        dateFrom,
        dateTo,
    }
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});
