import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getWebchatService = (token, connectionId) =>
    axios({
        method: 'get',
        url: `${api}/v1/channels/config/webchat/${connectionId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })
export const putWebchatService = (token, data, connectionId) => {
    return axios({
        method: 'put',
        url: `${api}/v1/channels/config/webchat/${connectionId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    })
}