import { takeLatest, put, call, select } from 'redux-saga/effects';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';
import { 
    getAreasSuccess, 
    getAreasFailure, 
    GET_AREAS_REQUEST, 
    submitAreaSuccess,
    submitAreaFailure, 
    SUBMIT_AREA_REQUEST, 
    DELETE_AREA_REQUEST, 
    deleteAreaFailure, 
    deleteAreaSuccess 
} from '../actions/areas';
import { getAreasService, submitAreaService, editAreaService, deleteAreaService } from '../services/areas';

export const getAreasWorker = function* ({ payload: { filters } }){
    try {

        const token = yield select(accessTokenSelector);
        const { data: {projects} }  = yield call(getAreasService, token, filters);

        yield put(getAreasSuccess(projects));
        
    } catch (error) {
        console.log(error);
        yield put(getAreasFailure(error));
        yield put(showGlobalError(true,error));
    }
}


export const submitAreaWorker = function* ({ payload: { area, id } }){
    try {

        const token = yield select(accessTokenSelector);

        if(id){
            let data = { ...area };
            
            yield call(editAreaService, token , data , id);
            yield put(showGlobalError(true, 200, 'updated'));
        }
        else{
            yield call(submitAreaService, token , area);
            yield put(showGlobalError(true, 200, 'created'));

        }

        yield put(submitAreaSuccess());
        
    } catch (error) {
        console.log(error);
        yield put(submitAreaFailure(error));
        yield put(showGlobalError(true,error));
    }
}

const deleteAreaWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteAreaService, token, id);
        yield put(deleteAreaSuccess());
        yield put(showGlobalError(true, 200, 'area_deleted'));

    } catch(err) {
        console.log(err)
        const { response, request } = err;
        const statusNumber = Number.isInteger(err) ? err : response ? response.status : request.status;
        const messageError = statusNumber === 412 ? "El área no puede eliminarse, tiene datos asociados. Pruebe deshabilitandolá." : "Error al eliminar el área"
        
        yield put(deleteAreaFailure(err));
        yield put(showGlobalError(true, messageError));

    }
}


export default [
    takeLatest(GET_AREAS_REQUEST, getAreasWorker),
    takeLatest(SUBMIT_AREA_REQUEST, submitAreaWorker),
    takeLatest(DELETE_AREA_REQUEST, deleteAreaWorker)
]