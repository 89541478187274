export const GET_TRANSITIONS_REQUEST = 'GET_TRANSITIONS_REQUEST';
export const GET_TRANSITIONS_SUCCESS = 'GET_TRANSITIONS_SUCCESS';
export const GET_TRANSITIONS_FAILURE = 'GET_TRANSITIONS_FAILURE';

export const GET_TRANSITION_BY_ID_REQUEST = 'GET_TRANSITION_BY_ID_REQUEST';
export const GET_TRANSITION_BY_ID_SUCCESS = 'GET_TRANSITION_BY_ID_SUCCESS';
export const GET_TRANSITION_BY_ID_FAILURE = 'GET_TRANSITION_BY_ID_FAILURE';

export const POST_TRANSITION_REQUEST = 'POST_TRANSITION_REQUEST';
export const POST_TRANSITION_SUCCESS = 'POST_TRANSITION_SUCCESS';
export const POST_TRANSITION_FAILURE = 'POST_TRANSITION_FAILURE';

export const PUT_TRANSITION_REQUEST = 'PUT_TRANSITION_REQUEST';
export const PUT_TRANSITION_SUCCESS = 'PUT_TRANSITION_SUCCESS';
export const PUT_TRANSITION_FAILURE = 'PUT_TRANSITION_FAILURE';

export const DELETE_TRANSITION_REQUEST = 'DELETE_TRANSITION_REQUEST';
export const DELETE_TRANSITION_SUCCESS = 'DELETE_TRANSITION_SUCCESS';
export const DELETE_TRANSITION_FAILURE = 'DELETE_TRANSITION_FAILURE';
export const DELETE_TRANSITION_RESET = 'DELETE_TRANSITION_RESET';


export const getTransitionsRequest = (agentId = '') => ({
    type: GET_TRANSITIONS_REQUEST,
    payload: {
        agentId
    }
});

export const getTransitionsSuccess = (transitions) => ({
    type: GET_TRANSITIONS_SUCCESS,
    payload: {
        transitions
    }
});

export const getTransitionsFailure = err => ({
    type: GET_TRANSITIONS_FAILURE,
    error: err
});

export const getTransitionByIdRequest = (agentId = '', transitionId) => ({
    type: GET_TRANSITION_BY_ID_REQUEST,
    payload: {
        agentId,
        transitionId
    }
});

export const getTransitionByIdSuccess = (transition) => ({
    type: GET_TRANSITION_BY_ID_SUCCESS,
    payload: {
        transition
    }
});

export const getTransitionByIdFailure = err => ({
    type: GET_TRANSITION_BY_ID_FAILURE,
    error: err
});

export const postTransitionRequest = (agentId = '', data) => ({
    type: POST_TRANSITION_REQUEST,
    payload: {
        agentId, transition: data
    }
});

export const postTransitionSuccess = (transition) => ({
    type: POST_TRANSITION_SUCCESS,
    payload: {
        transition
    }
});

export const postTransitionFailure = err => ({
    type: POST_TRANSITION_FAILURE,
    error: err
});

export const deleteTransitionRequest = (agentId, transitionId) => ({
    type: DELETE_TRANSITION_REQUEST,
    payload: {
        agentId, transitionId
    }
});

export const deleteTransitionSuccess = (transitionId) => ({
    type: DELETE_TRANSITION_SUCCESS,
    payload: {
        transitionId
    }
});

export const deleteTransitionFailure = (err) => ({
    type: DELETE_TRANSITION_FAILURE,
    error: err
});

export const putTransitionRequest = (agentId = '', transitionId, data) => ({
    type: PUT_TRANSITION_REQUEST,
    payload: {
        agentId, transitionId, transition: data
    }
});

export const putTransitionSuccess = (transition) => ({
    type: PUT_TRANSITION_SUCCESS,
    payload: {
        transition
    }
});

export const putTransitionFailure = err => ({
    type: PUT_TRANSITION_FAILURE,
    error: err
});