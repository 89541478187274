import auth from '../auth/routes';
import home from '../home/routes';
import terms from '../terms/routes';
import redirectLogin from '../redirect-login/routes';


const routes = [
    redirectLogin,
    auth,
    terms,
    home,
];

export default routes;