const initialState = {
    fetching: false,
    submitFetching: false,
    submitSuccess: false,
    bots: [],
    bot: {
        bot:{},
        intents:[],
        states: [],
        variables: [],
        stateId: '',
        stateObject: null,
        submitStateSuccess: false,
        stateWithoutConnectionDeleted: false,
        deleteTransitionSuccess: false,
        submitTransitionSuccess: false,
        transitions: [],
        transitionId: '',
        transition: null,
        actions:[],
        responses:[],
        fetching: false,
        submitFetching: false,
        submitSuccess: false,
        submitIntentFetching: false,
        submitIntentSuccess: false,
        deleteSuccess: false,
        intentsFetching: false,
        statesFetching: false,
        transitionsFetching: false,
        editIntentFetching: false,
        editIntentSuccess: false,
        testConversation: {
            fetchingReply:false,
            submitReplySuccess: false,
            chat:[],
            currentIntent: null
        }
    },
    total: 0,
    deleteFetching: false,
    deleteSuccess: false,
    profiles: [],
    page:0,
    limit:10
};

export default initialState;