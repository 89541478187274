import {
    CLEAN_LOGIN_DEPENDENCES,
    GET_CHANNELS_LOGINS_REQUEST,
    GET_CHANNELS_LOGINS_SUCCESS,
    GET_CHANNELS_LOGINS_FAILURE,
    GET_CHANNELS_LOGINS_STATUS_REQUEST,
    GET_CHANNELS_LOGINS_STATUS_SUCCESS,
    GET_CHANNELS_LOGINS_STATUS_FAILURE,
    POST_CHANNELS_LOGINS_REQUEST,
    POST_CHANNELS_LOGINS_SUCCESS,
    POST_CHANNELS_LOGINS_FAILURE,
    GET_CHANNELS_LOGINS_STATUS_REQUEST_V2,
    GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2,
    GET_CHANNELS_LOGINS_STATUS_FAILURE_V2,
} from '../actions/channelsLogins';

const initialState = {
    fetching: false,
    channelToConnect:'',
    channelLogin: {},
    loginStatus: {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_CHANNELS_LOGINS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                channelToConnect: action.payload.channelName
            };
        }

        case GET_CHANNELS_LOGINS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channelLogin: action.payload.channelLogin,
            };
        }

        case GET_CHANNELS_LOGINS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case POST_CHANNELS_LOGINS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                channelToConnect: action.payload.channelName
            };
        }

        case POST_CHANNELS_LOGINS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channelLogin: action.payload.channelLogin,
            };
        }

        case POST_CHANNELS_LOGINS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_REQUEST_V2: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2: {
            return {
                ...state,
                fetching: false,
                success: true,
                loginStatus: action.payload.login,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_FAILURE_V2: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }

        case POST_CHANNELS_LOGINS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                channelToConnect: action.payload.channelName
            };
        }

        case POST_CHANNELS_LOGINS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                channelLogin: action.payload.channelLogin,
            };
        }

        case POST_CHANNELS_LOGINS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_REQUEST_V2: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2: {
            return {
                ...state,
                fetching: false,
                success: true,
                loginStatus: action.payload.login,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_FAILURE_V2: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }


        case GET_CHANNELS_LOGINS_STATUS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                loginStatus: action.payload.loginStatus,
            };
        }

        case GET_CHANNELS_LOGINS_STATUS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        case CLEAN_LOGIN_DEPENDENCES: {
            return {
                ...state,
                channelLogin: {},
                loginStatus: {},
                fetching: false,
                channelToConnect:'',
            };
        }
        
        default:
            return state;
    }
};