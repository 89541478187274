import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getContactsService = (token,filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/contacts?${makeQuery(filters)}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

export const getContactsV2Service = (token,filters) => 
    axios({
        method: 'get',
        url: `${api}/v2/contacts?${makeQuery(filters)}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })    

export const getContactService = (token,id) => 
    axios({
        method: 'get',
        url: `${api}/v2/contacts/${id}`,
        headers: {
            ...headers,
            'access-token': token
        }
})

export const editContactService = (token,user,id) => 
    axios({
        method: 'put',
        url: `${api}/v2/contacts/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: user
    })

export const postContactService = (token,user) => 
    axios({
        method: 'post',
        url: `${api}/v2/contacts`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: user
    })

export const getHistoryService = (token, contactId) => 
    axios({
        method: 'get',
        url: `${api}/v1/contacts/${contactId}/taskHistory`,
        headers: {
            ...headers,
            'access-token': token
        }
    })


export const deleteContactService = (token, id) => 
axios({
    method: 'delete',
    url: `${api}/v1/contacts/${id}`,
    headers: {
        ...headers,
        'access-token': token
    },
})