import {
  SET_CREATE_CUSTOM_ATTRIBUTE_POPUP_STATUS,
  SET_UPDATE_CUSTOM_ATTRIBUTE_POPUP_STATUS,
} from "../actions/popup";

const INITIAL_STATE = {
  createCustomAttributePopupIsOpen: false,
  updateCustomAttributePopupIsOpen: false,
};

const PopupState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CREATE_CUSTOM_ATTRIBUTE_POPUP_STATUS: {
      return {
        ...state,
        createCustomAttributePopupIsOpen: action.payload.isOpen,
      };
    }
    case SET_UPDATE_CUSTOM_ATTRIBUTE_POPUP_STATUS: {
      return {
        ...state,
        updateCustomAttributePopupIsOpen: action.payload.isOpen,
      };
    }

    default:
      return state;
  }
};

export default PopupState;
