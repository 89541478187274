export const GET_RESPONSE_TIME_REQUEST = 'GET_RESPONSE_TIME_REQUEST';
export const GET_RESPONSE_TIME_SUCCESS = 'GET_RESPONSE_TIME_SUCCESS';
export const GET_RESPONSE_TIME_FAILURE = 'GET_RESPONSE_TIME_FAILURE';


export const getResponseTimeRequest = (filters = {}) => ({
    type: GET_RESPONSE_TIME_REQUEST,
    payload: {
        filters
    }
});

export const getResponseTimeSuccess = (statistics) => ({
    type: GET_RESPONSE_TIME_SUCCESS,
    payload: {
        statistics
    }
});

export const getResponseTimeFailure = (err = {}) => ({
    type: GET_RESPONSE_TIME_FAILURE,
    payload: {
        err
    }
});