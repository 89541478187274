import {
  ADD_FILE_TO_BE_UPLOAD,
  SET_SELECTED_FILE,
  POST_FILE_FAILURE,
  POST_FILE_REQUEST,
  POST_FILE_SUCCESS,
  UPLOAD_FILE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
} from "../actions/files";
import { FILE_STATUS } from "../components/File/types";

const INITIAL_STATE = {
  selected: null,
  delete: {
    deleting: false,
    success: null,
    error: null,
  },
  upload: {
    filesPendingUpload: null,
    filesWithFailedUpload: null,
    uploading: false,
    success: null,
    error: null,
  },
};

const FileState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_FILE: {
      return {
        ...state,
        selected: action.payload.file,
      };
    }
    case POST_FILE_REQUEST: {
      const updatedFilesWithFailedUpload =
        state.upload.filesWithFailedUpload?.map((file) => {
          const findedById = file.uuid === action.payload.information.uuid;

          if (!findedById) return file;
          return { ...file, status: FILE_STATUS.UPLOADING };
        });

      const updatedFilesPendingUpload = state.upload.filesPendingUpload?.map(
        (file) => {
          const findedById = file.uuid === action.payload.information.uuid;

          if (!findedById) return file;
          return { ...file, status: FILE_STATUS.UPLOADING };
        }
      );

      return {
        ...state,
        upload: {
          ...state.upload,
          filesPendingUpload: updatedFilesPendingUpload,
          filesWithFailedUpload: updatedFilesWithFailedUpload,
          success: null,
          error: null,
        },
      };
    }

    case POST_FILE_SUCCESS: {
     

      const updatedFilesWithFailedUpload = state.upload.filesWithFailedUpload
        ? state.upload.filesWithFailedUpload.filter(
            (file) => file.uuid !== action.payload.fileId
          )
        : null;

      const updatedFilesPendingUpload = state.upload?.filesPendingUpload
        ? state.upload?.filesPendingUpload.filter(
            (file) => file.uuid !== action.payload.fileId
          )
        : null;

      return {
        ...state,
        upload: {
          ...state.upload,
          uploading: null,
          success: true,
          filesPendingUpload: updatedFilesPendingUpload,
          filesWithFailedUpload: updatedFilesWithFailedUpload,
        },
      };
    }

    case POST_FILE_FAILURE: { 
      const fileId = action.payload.fileId;

      const fileToBeRemoved = state.upload.filesPendingUpload?.find(
        (file) => file.uuid === fileId
      );

      if (!fileToBeRemoved) {
        const updatedFilesWithFailedUpload =
          state.upload.filesWithFailedUpload?.map((file) => {
            const findedById = file.uuid === action.payload.fileId;

            if (!findedById) return file;
            return { ...file, status: FILE_STATUS.ERROR };
          });

        return {
          ...state,
          upload: {
            ...state.upload,
            uploading: null,
            filesWithFailedUpload: updatedFilesWithFailedUpload,
            error: true,
          },
        };
      }

      const updatedFilesPendingUpload = state.upload.filesPendingUpload.filter(
        (file) => file.uuid !== fileId
      );

      const updatedStateFile = {
        ...fileToBeRemoved,
        status: FILE_STATUS.ERROR,
      };
      const updatedFilesWithFailedUpload = state.upload.filesWithFailedUpload
        ? [...state.upload.filesWithFailedUpload, updatedStateFile]
        : [updatedStateFile];

      return {
        ...state,
        upload: {
          ...state.upload,
          uploading: null,
          filesPendingUpload: updatedFilesPendingUpload,
          filesWithFailedUpload: updatedFilesWithFailedUpload,
          error: true,
        },
      };
    }

    case DELETE_FILE_REQUEST: {
      return {
        ...state,
        delete: {
          ...state.delete,
          deleting: action.payload.fileId,
          error: null,
          success: null,
        },
      };
    }

    case DELETE_FILE_SUCCESS: {
      return {
        ...state,
        delete: {
          ...state.delete,
          deleting: null,
          success: true,
        },
      };
    }

    case DELETE_FILE_FAILURE: {
      return {
        ...state,
        delete: {
          ...state.delete,
          deleting: null,
          error: true,
        },
      };
    }

    case ADD_FILE_TO_BE_UPLOAD: {
      const newFile = action.payload.newFile;

      const fileToBeUpload = state.upload.filesWithFailedUpload?.find(
        (file) => file.uuid === newFile.uuid
      );

      if (fileToBeUpload) {
        const updatedFilesWithFailedUpload =
          state.upload.filesWithFailedUpload.filter(
            (file) => file.uuid !== newFile.uuid
          );

        const updatedFilesPendingUpload = state.upload.filesPendingUpload
          ? [...state.upload.filesPendingUpload, action.payload.newFile]
          : [action.payload.newFile];

        return {
          ...state,
          upload: {
            ...state.upload,
            filesWithFailedUpload: updatedFilesWithFailedUpload,
            filesPendingUpload: updatedFilesPendingUpload,
          },
        };
      }

      const updatedFilesPendingUpload = state.upload.filesPendingUpload
        ? [...state.upload.filesPendingUpload, action.payload.newFile]
        : [action.payload.newFile];

      return {
        ...state,
        upload: {
          ...state.upload,
          filesPendingUpload: updatedFilesPendingUpload,
        },
      };
    }

    case UPLOAD_FILE: {
      const updatedFilesPendingUpload = state.upload.filesPendingUpload.map(
        (file) => {
          const findedById = file.uuid === action.payload.fileId;

          if (!findedById) return file;
          return { ...file, status: FILE_STATUS.UPLOADING };
        }
      );

      return {
        ...state,
        upload: {
          ...state.upload,
          uploading: action.payload.fileId,
          filesPendingUpload: updatedFilesPendingUpload,
        },
      };
    }

    default:
      return state;
  }
};

export default FileState;
