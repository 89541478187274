export const SET_FILTERS_PUBLICATIONS = "SET_FILTERS_PUBLICATIONS";
export const SET_SOCIALS_FILTERS = "SET_SOCIALS_FILTERS";

export const setFiltersPublications = ({ text }) => ({
  type: SET_FILTERS_PUBLICATIONS,
  payload: {
    text,
  },
});

export const setSocialsFilters = (filters) => ({
  type: SET_SOCIALS_FILTERS,
  payload: {
    filters,
  },
});
