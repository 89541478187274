import axios from "axios";
import { api, headers } from "../../app/conf";
import { makeQuery } from '../../shared/helpers/commons';
import { filterOmmittingKeys } from "../../shared/helpers/commons";

export const getTicketsService = (token, filters) =>
    axios({
        method: "get",
        url: `${api}/v3/tasks`,
        params: filters,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

export const getTicketsServiceV1 = (token, filters) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

export const getTicketService = (token, ticketId) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks/${ticketId}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

    export const getTicketServicev3 = (token, ticketId) =>
    axios({
        method: "get",
        url: `${api}/v3/tasks/${ticketId}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });


export const getTicketServiceV3 = (token, ticketId) =>
axios({
    method: "get",
    url: `${api}/v3/tasks/${ticketId}`,
    headers: {
        ...headers,
        "access-token": token,
    },
});

export const postTicketService = (token, data) =>
    axios({
        method: "post",
        url: `${api}/v1/tasks`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data,
    });

export const putTicketService = (token, data) =>
    axios({
        method: "put",
        url: `${api}/v1/tasks/${data._id}`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data: filterOmmittingKeys(data, ['score']),
    });

export const putStatusTicketService = (token, data, status) =>
    axios({
        method: "put",
        url: `${api}/v1/tasks/${data._id}/transition/${status}`,
        headers: {
            ...headers,
            "access-token": token,
        }
    });

export const getTicketsScore = (token, ticketId) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks/${ticketId}/scores`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

export const putTicketsScore = (token, data, ticketId) =>
    axios({
        method: "put",
        url: `${api}/v1/tasks/${ticketId}/scores`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data,
    });

export const putTicketAssignedService = (token, ticketId, data) => 
    axios({
        method: "put",
        url: `${api}/v1/tasks/${ticketId}/assigned`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data,
    });

export const putTicketAreaService = (token, ticketId, data) =>
    axios({
        method: "put",
        url: `${api}/v1/tasks/${ticketId}/project`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data,
    });


export const postTagsService = (token, tags, ticketId) => {
    axios({
        method: "post",
        url: `${api}/v1/tasks/${ticketId}/tags`,
        headers: {
            ...headers,
            "access-token": token,
        },
        data: { tags },
    });
}
export const getTagsService = (token, ticketId) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks/${ticketId}/tags`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });


export const getFollowingStatesService = (token, status) =>
    axios({
        method: "get",
        url: `${api}/v1/statuses/transitions/${status}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    })

export const getTicketHistoryService = (token, idTicket, activePopulate=true, allFields=false) =>
    axios({
        method: "get",
        url: `${api}/v1/tasks/${idTicket}/history?${makeQuery({activePopulate, allFields})}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    })

export const putMassiveCloseTicketsService = (token, ticketIds, tags, comment, commentType, destinationStatus) =>
axios({
    method: "put",
    url: `${api}/v1/tasks/massive/close`,
    headers: {
        ...headers,
        "access-token": token,
    },
    data: { taskIds: ticketIds, tags, comment, commentType, destinationStatus }
})

export const getReportService = (token, filters) =>
    axios({
        method: "get",
        url: `${api}/v1/dataWarehouse/report?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });