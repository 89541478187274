import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { companySelector } from '../../../../selectors/company';
import { setCompany } from '../../../../actions/company';

import Company from './Company';

const mapStateToProps = createStructuredSelector({
    company: companySelector
});
const mapDispatchToProps = {
    setCompany
};

export default connect(mapStateToProps,mapDispatchToProps)(Company);
