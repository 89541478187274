export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const addNotification = (notification) => ({
    type: ADD_NOTIFICATION,
    payload: {
        notification
    }
})

export const setNotifications = (notifications) => ({
    type: SET_NOTIFICATION,
    payload: {
        notifications
    }
})