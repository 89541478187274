export const GET_TASK_BY_CHANNEL_REQUEST = 'GET_TASK_BY_CHANNEL_REQUEST';
export const GET_TASK_BY_CHANNEL_SUCCESS = 'GET_TASK_BY_CHANNEL_SUCCESS';
export const GET_TASK_BY_CHANNEL_FAILURE = 'GET_TASK_BY_CHANNEL_FAILURE';


export const getTaskByChannelRequest = (filters = {}) => ({
    type: GET_TASK_BY_CHANNEL_REQUEST,
    payload: {
        filters
    }
});

export const getTaskByChannelSuccess = (statistics) => ({
    type: GET_TASK_BY_CHANNEL_SUCCESS,
    payload: {
        statistics
    }
});

export const getTaskByChannelFailure = (err = {}) => ({
    type: GET_TASK_BY_CHANNEL_FAILURE,
    payload: {
        err
    }
});