import { environment } from "../../app/conf";

export const getDomain = () => {
  switch (environment) {
    case "LOCAL":
      return "localhost";
    case "SANDBOX":
      return ".denwaip.com"; 
    case "TEST":
      return ".denwaip.com"; 
    case "STAGING":
      return ".denwaip.com";
    case "PRODUCTION":
      return ".denwaip.com";
    default:
      return "localhost";
  }
};

export const generateDuration = () => {
  // Duration of the cookie in hours
  const cookieDurationHours = 24;

  // Calculate the expiration date
  const expirationDate = new Date();

  expirationDate.setTime(
    expirationDate.getTime() + cookieDurationHours * 60 * 60 * 1000
  );

  return expirationDate;
};
