export const GET_CUSTOM_ATTRIBUTES = 'GET_CUSTOM_ATTRIBUTES';
export const getCustomAttributes = () => ({
    type: GET_CUSTOM_ATTRIBUTES,
    payload: {  }
});

export const GET_CUSTOM_ATTRIBUTES_SUCCESS = 'GET_CUSTOM_ATTRIBUTES_SUCCESS';
export const getCustomAttributesSuccess = (customAttributes=[]) => ({
    type: GET_CUSTOM_ATTRIBUTES_SUCCESS,
    payload: { customAttributes }
});

export const GET_CUSTOM_ATTRIBUTES_FAILURE = 'GET_CUSTOM_ATTRIBUTES_FAILURE';
export const getCustomAttributesFailure = (error={}) => ({
    type: GET_CUSTOM_ATTRIBUTES_FAILURE,
    payload: { error }
});

export const SET_CUSTOM_ATTRIBUTES = 'SET_CUSTOM_ATTRIBUTES';
export const setCustomAttributes = (customAttributes = []) => ({
    type: SET_CUSTOM_ATTRIBUTES,
    payload: {
        customAttributes
    }
});

export const CLEAN_CUSTOM_ATTRIBUTES = 'CLEAN_CUSTOM_ATTRIBUTES';
export const cleanCustomAttributes = () => ({
    type: CLEAN_CUSTOM_ATTRIBUTES,
});