import {
    GET_NOTIFICATION_CHANNELS_REQUEST,
    GET_NOTIFICATION_CHANNELS_SUCCESS,
    GET_NOTIFICATION_CHANNELS_FAILURE,
    SUBMIT_NOTIFICATION_CHANNELS_REQUEST,
    SUBMIT_NOTIFICATION_CHANNELS_SUCCESS,
    SUBMIT_NOTIFICATION_CHANNELS_FAILURE
} from '../actions/notificationChannels';

const initialState = {
    fetching: false,
    notificationChannels: [],
    fetchingPut:false,
    submitSuccess:false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_CHANNELS_REQUEST: {
            return {
                ...state,
                fetching: true,
                submitSuccess:false,
            };
        }
        case GET_NOTIFICATION_CHANNELS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                notificationChannels: action.payload
            };
        }
        case GET_NOTIFICATION_CHANNELS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case SUBMIT_NOTIFICATION_CHANNELS_REQUEST: {
            return {
                ...state,
                fetchingPut:true,
                submitSuccess:false
            };
        }
        case SUBMIT_NOTIFICATION_CHANNELS_SUCCESS: {
            return {
                ...state,
                notificationChannels: action.payload,
                fetchingPut:false,
                submitSuccess:true
            };
        }
        case SUBMIT_NOTIFICATION_CHANNELS_FAILURE: {
            return {
                ...state,
                fetchingPut:false,
                submitSuccess:false
            };
        }
        default:
            return state
    }
}
