import axios from 'axios';
import { api, headers } from '../../app/conf';

export const signOutService = (token) => axios({
    method: 'post',
    url: `${api}/logout`,
    data: {},
    headers: {
        ...headers,
        'access-token': token
    }
});


