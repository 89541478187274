import { combineReducers } from 'redux';
import token from './token';
import refresh from './refresh';
import permissions from './permissions';
import signinReducers from './signin';
import register from './register'
import view from './view'
import login from './login'


export default combineReducers({
    token,
    refresh,
    permissions,
    register,
    view,
    login
});

export const signin = signinReducers;