export const SET_TOKENS = 'SET_TOKENS';

export const setTokens = (accessToken = null, refreshToken = null, isProviderToken = false) => ({
    type: SET_TOKENS,
    payload: {
        accessToken,
        refreshToken,
        isProviderToken
    }
});
