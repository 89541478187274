import { takeLatest, put, call, select, debounce } from 'redux-saga/effects';
import {
    GET_APIKEYS_REQUEST,
    GET_TYPING_APIKEYS_REQUEST,
    getApikeysSuccess,
    getApikeysFailure,
    GET_APIKEY_REQUEST,
    getApikeySuccess,
    getApikeyFailure,
    SUBMIT_APIKEY_REQUEST,
    submitApikeysSuccess,
    submitApikeysFailure,
    DELETE_APIKEY_REQUEST,
    deleteApiKeySuccess,
    deleteApiKeyFailure
} from '../actions/apikeys';
import { 
    getApikeysServices, 
    getApikeyServices, 
    postApikeysServices, 
    putApikeysServices ,
    deleteApiKeyService
} from '../services/apikeys';
import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

export const getApikeysWorker = function* ({ payload: { filters } }) {
    try {
        const token = yield select(accessTokenSelector);

        const { data: { users, total }} = yield call(getApikeysServices, token, filters);
        
        yield put(getApikeysSuccess(users, total));
    } catch (err) {
        console.log(err);

        yield put(getApikeysFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getApikeyWorker = function* ({ payload: { filters } }) {
    try {
        const token = yield select(accessTokenSelector);


        const { data: { apikeys }} = yield call(getApikeyServices, token, filters);

        
        yield put(getApikeySuccess(apikeys));
    } catch (err) {
        console.log(err);

        yield put(getApikeyFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const submitApikeysWorker = function* ({ payload: { apikey, id } }) {
    try {
        const token = yield select(accessTokenSelector);

        if (!id) {
            yield call(postApikeysServices, token, apikey);
            yield put(showGlobalError(true, 200, 'created'));

        } else {
            yield call(putApikeysServices, token, apikey,id);
            yield put(showGlobalError(true, 200, 'updated'));
        }

        yield put(submitApikeysSuccess());

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'apikey_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(submitApikeysFailure(err));
    }
};

export const getTypingApikeysWorker = function* ({ payload: { filters, onlyProfile } }) {
    try {

        let data = {
            payload: {
                filters: {
                    ...filters
                }
            }
        }

        if (onlyProfile) {
            data.payload.filters.role = onlyProfile;
        }

        yield call(getApikeysWorker, data);

    } catch (err) {
        console.log(err);

        yield put(getApikeysFailure(err));
        yield put(showGlobalError(true, err));
    }
};
export const deleteApiKeyWorker = function* ({payload: {id}}) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteApiKeyService,token,id);
        yield put(showGlobalError(true, 200, 'deleted'));
        yield put(deleteApiKeySuccess());
        
    } catch (err) {
        yield put(deleteApiKeyFailure(err));
        yield put(showGlobalError(true, err));
    }
}


export default [
    debounce(1000, GET_TYPING_APIKEYS_REQUEST, getTypingApikeysWorker),
    takeLatest(GET_APIKEYS_REQUEST, getApikeysWorker),
    takeLatest(GET_APIKEY_REQUEST, getApikeyWorker),
    takeLatest(SUBMIT_APIKEY_REQUEST, submitApikeysWorker),
    takeLatest(DELETE_APIKEY_REQUEST, deleteApiKeyWorker)
];
