export const GET_PUBLICATIONS_REQUEST = 'GET_PUBLICATIONS_REQUEST';
export const GET_PUBLICATIONS_SUCCESS = 'GET_PUBLICATIONS_SUCCESS';
export const GET_PUBLICATIONS_FAILURE = 'GET_PUBLICATIONS_FAILURE';

export const GET_PUBLICATION_REQUEST = 'GET_PUBLICATION_REQUEST';
export const GET_PUBLICATION_SUCCESS = 'GET_PUBLICATION_SUCCESS';
export const GET_PUBLICATION_FAILURE = 'GET_PUBLICATION_FAILURE';

export const GET_PUBLICATIONS_INCOMING_REQUEST = 'GET_PUBLICATIONS_INCOMING_REQUEST';
export const GET_PUBLICATIONS_INCOMING_SUCCESS = 'GET_PUBLICATIONS_INCOMING_SUCCESS';
export const GET_PUBLICATIONS_INCOMING_FAILURE = 'GET_PUBLICATIONS_INCOMING_FAILURE';

export const GET_PUBLICATION_INCOMING_REQUEST = 'GET_PUBLICATION_INCOMING_REQUEST';
export const GET_PUBLICATION_INCOMING_SUCCESS = 'GET_PUBLICATION_INCOMING_SUCCESS';
export const GET_PUBLICATION_INCOMING_FAILURE = 'GET_PUBLICATION_INCOMING_FAILURE';

export const POST_PUBLICATION_REQUEST = 'POST_PUBLICATION_REQUEST';
export const POST_PUBLICATION_SUCCESS = 'POST_PUBLICATION_SUCCESS';
export const POST_PUBLICATION_FAILURE = 'POST_PUBLICATION_FAILURE';

export const POST_PUBLICATION_COMMENT_REQUEST = 'POST_PUBLICATION_COMMENT_REQUEST';
export const POST_PUBLICATION_COMMENT_SUCCESS = 'POST_PUBLICATION_COMMENT_SUCCESS';
export const POST_PUBLICATION_COMMENT_FAILURE = 'POST_PUBLICATION_COMMENT_FAILURE';

export const POLLING_PUBLICATIONS_INCOMING_SUCCESS = 'POLLING_PUBLICATIONS_INCOMING_SUCCESS';
export const POLLING_PUBLICATIONS_SUCCESS = 'POLLING_PUBLICATIONS_SUCCESS';

export const getPublicationsRequest = (filters = {},polling = undefined) => ({
    type:GET_PUBLICATIONS_REQUEST,
    payload: {
        filters,
        polling
    }
});

export const getPublicationsSuccess = (publications) => ({
    type: GET_PUBLICATIONS_SUCCESS,
    payload: {
        publications
    }
});

export const getPublicationsFailure = (err) => ({
    type: GET_PUBLICATIONS_FAILURE,
    error: err
});

export const getPublicationRequest = (postId, filters = {}) => ({
    type:GET_PUBLICATION_REQUEST,
    payload: {
        filters,
        postId
    }
});

export const pollingPublicationSuccess = (publications) => ({
    type: POLLING_PUBLICATIONS_SUCCESS,
    payload: {
        publications
    }
});

export const getPublicationSuccess = (publication) => ({
    type: GET_PUBLICATION_SUCCESS,
    payload: {
        publication
    }
});

export const getPublicationFailure = (err) => ({
    type: GET_PUBLICATION_FAILURE,
    error: err
});

export const getPublicationsIncomingRequest = (filters = {},polling = undefined, manualRefresh = false) => ({
    type:GET_PUBLICATIONS_INCOMING_REQUEST,
    payload: {
        filters,
        polling,
        manualRefresh
    }
});

export const getPublicationsIncomingSuccess = (publicationsIncoming) => ({
    type: GET_PUBLICATIONS_INCOMING_SUCCESS,
    payload: {
        publicationsIncoming,
    }
});

export const pollingPublicationsIncomingSuccess = (publicationsIncoming) => ({
    type: POLLING_PUBLICATIONS_INCOMING_SUCCESS,
    payload: {
        publicationsIncoming
    }
});

export const getPublicationsIncomingFailure = (err) => ({
    type: GET_PUBLICATIONS_INCOMING_FAILURE,
    error: err
});

export const getPublicationIncomingRequest = (postId, filters = {}) => ({
    type:GET_PUBLICATION_INCOMING_REQUEST,
    payload: {
        filters,
        postId
    }
});

export const getPublicationIncomingSuccess = (publicationIncoming) => ({
    type: GET_PUBLICATION_INCOMING_SUCCESS,
    payload: {
        publicationIncoming
    }
});

export const getPublicationIncomingFailure = (err) => ({
    type: GET_PUBLICATION_INCOMING_FAILURE,
    error: err
});

export const postPublicationRequest = (publication) => ({
    type: POST_PUBLICATION_REQUEST,
    payload: {
        publication
    }
});

export const postPublicationFailure = (err) => ({
    type: POST_PUBLICATION_FAILURE,
    error: err
});

export const postPublicationSuccess = (ids) => ({
    type: POST_PUBLICATION_SUCCESS,
    payload: {
        ids
    }
});

export const postPublicationCommentRequest = (comment) => ({
    type: POST_PUBLICATION_COMMENT_REQUEST,
    payload: {
        comment
    }
});

export const postPublicationCommentFailure = (err) => ({
    type: POST_PUBLICATION_COMMENT_FAILURE,
    error: err
});

export const postPublicationCommentSuccess = (comment) => ({
    type: POST_PUBLICATION_COMMENT_SUCCESS,
    payload: {
        comment
    }
});