export const GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';

export const getFiltersRequest = (view, entity) => ({
    type: GET_FILTERS_REQUEST,
    payload: {
        view,
        entity,
    }
});

export const getFiltersSuccess = (filters, view, entity) => ({
    type: GET_FILTERS_SUCCESS,
    payload: {
        filters,
        view,
        entity,
    }
});

export const getFiltersFailure = err => ({
    type: GET_FILTERS_FAILURE,
    error: err
});