import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { errorSelector } from '../../selectors/app';
import Notification from './Notification';
import { changeConfigChildRoute } from '../../../home/actions/config';

const mapStateToProps = createStructuredSelector({
    app: errorSelector,
});

const mapDispatchToProps = { changeConfigChildRoute };

export default connect(mapStateToProps, mapDispatchToProps)(Notification);