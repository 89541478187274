export const GET_TICKETS_TYPES_REQUEST = 'GET_TICKETS_TYPES_REQUEST';
export const GET_TICKETS_TYPES_SUCCESS = 'GET_TICKETS_TYPES_SUCCESS';
export const GET_TICKETS_TYPES_FAILURE = 'GET_TICKETS_TYPES_FAILURE';

export const getTicketsTypesRequest = () => ({
    type: GET_TICKETS_TYPES_REQUEST,
});

export const getTicketsTypesSuccess = (ticketsTypes) => ({
    type: GET_TICKETS_TYPES_SUCCESS,
    payload: {
        ticketsTypes
    }
});

export const getTicketsTypesFailure = err => ({
    type: GET_TICKETS_TYPES_FAILURE,
    error: err
});