import { takeLatest, put, select } from "redux-saga/effects";

import {
  GET_FOLDER_REQUEST,
  POST_FOLDER_REQUEST,
  addFileToFolder,
  getFolderFailure,
  getFolderSuccess,
  postFolderFailure,
  postFolderSuccess,
} from "../actions/folder";
import FolderService from "../../domain/services/Folder/Folder.service";
import { accessTokenSelector } from "../../auth/selectors/token";

const Service = new FolderService();

const getFolderWorker = function* (getFolderWorkerParams) {
  const { payload } = getFolderWorkerParams;

  const token = yield select(accessTokenSelector);

  if (!token) {
    throw new Error("Missign token");
  }

  try {
    const response = yield Service.getFolder({
      folderId: payload.folderId,
      token,
      signal: payload.signal,
      folderName: payload.folderName,
      params: { withoutTask: payload.withoutTask },
    });

    yield put(getFolderSuccess(response));
  } catch (error) {
    yield put(getFolderFailure(error));
  }
};

const postFolderWorker = function* (postFileWorkerParams) {
  const { payload } = postFileWorkerParams;

  const token = yield select(accessTokenSelector);

  if (!token) {
    throw new Error("Missign token");
  }

  try {
    const information = {
      name: payload.information.name,
      visible: payload.information.isVisible,
      description: payload.information.description,
      folderId: payload.information.folderId,
    };

    const response = yield Service.postFolder({
      token,
      information,
      signal: payload.signal,
    });

    yield put(postFolderSuccess(response));
    yield put(addFileToFolder(response.folder));
  } catch (error) {
    yield put(postFolderFailure());
  }
};

export default [
  takeLatest(GET_FOLDER_REQUEST, getFolderWorker),
  takeLatest(POST_FOLDER_REQUEST, postFolderWorker),
];
