import React, { useEffect, useState } from 'react';
import { notificationErrorSVG, notificationSuccessSVG, notificationInfoSVG, notificationWarningSVG } from '../../helpers/icons';
import SVG from 'react-inlinesvg';
import styles from './customToast.module.scss';

const CustomToast = ({ type = 'warning', message = "Mensaje...", onClick, icon, title, className, iconStyle, bottomActions, hideTitle = false }) => {

    const toastTypes = {
        error:{
            icon: notificationErrorSVG,
            backgroundColor: "#FCECEB",
            title: 'Error',
            borderColor: '#DF393B'
        },
        warning:{
            icon: notificationWarningSVG,
            backgroundColor: "#FFF9E6",
            title: 'Advertencia',
            borderColor: '#FFC15E'
        },
        info:{
            icon: notificationInfoSVG,
            backgroundColor: "#EBF3FE",
            title: 'Información',
            borderColor: '#3F87F5'
        },
        success:{
            icon: notificationSuccessSVG,
            backgroundColor: "#E5F9F6",
            title: '¡Bien hecho!',
            borderColor: '#00C9A7'
        }
    };

    const [toastStyle,setToastStyle] = useState(toastTypes['warning']);

    useEffect(() => {
        if(type){
            setToastStyle(toastTypes[type]);
        }
        else{
            setToastStyle(toastTypes['warning']);
        }
    },[type]);

    return(
        <div
            onClick={onClick}
            className={`${styles.customToastContainer} ${className}`} 
            style={{
                backgroundColor:toastStyle.backgroundColor, 
                borderColor:toastStyle.borderColor
            }}
        >
            <SVG className={`${styles.icon} ${iconStyle}`} src={icon ?? toastStyle.icon}/>
            <div className={`${styles.messageContainer} ${bottomActions && styles.bottomActions}`}>
                { (!hideTitle && (title || toastStyle.title)) && <span>{title ?? toastStyle.title}</span> }
                <p>{message}</p>
                {bottomActions &&
                    <div className={styles.buttonsContainer}>
                        {bottomActions}
                    </div>
                }
            </div>
        </div>
    )
}

export default CustomToast;