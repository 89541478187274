import { takeLatest, put, call, select } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import { 
    editContactService, 
    getContactsService, 
    getContactsV2Service, 
    postContactService,
    getContactService
} from "../../contacts/services/contacts";
import { 
    getContactTicketFailure,
    getContactTicketSuccess,
    getContactsTicketFailure,
    getContactsTicketSuccess,
    GET_CONTACTS_TICKET_REQUEST,
    GET_CONTACT_TICKET_REQUEST,
    EDIT_CONTACT_TICKET_REQUEST,
    POST_CONTACT_TICKET_REQUEST,
    postContactTicketSuccess,
    postContactTicketFailure,
    editContactTicketSuccess,
    editContactTicketFailure,
    PUT_TICKET_CONTACT_REQUEST,
    putTicketContactFailure,
    putTicketContactSuccess,
    getContactSelectedSuccess,
    getContactSelectedFailure,
    GET_CONTACT_SELECTED_REQUEST,
    POST_CONTACT_CONNECTIONS_REQUEST,
    postContactConnectionsSuccess,
    postContactConnectionsFailure
} from "../actions/contacts";
import { makeFilters } from "../../shared/helpers/commons";
import { showGlobalError } from "../../app/actions/app";
import { postContactConnectionsService, putTicketContactService } from "../services/contacts";


export const getContactTicketWorker = function* ({ payload: { idContact } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data : {contact} }  = yield call(getContactService, token, idContact._id);
        yield put(getContactTicketSuccess(contact));
        
    } catch (error) {
        console.log(error);
        yield put(getContactTicketFailure(error));
       
    }
};

export const getContactSelectedWorker = function* ({ payload: { idContact } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data : {contact} }  = yield call(getContactService, token, idContact._id);
        yield put(getContactSelectedSuccess(contact));
        
    } catch (error) {
        console.log(error);
        yield put(getContactSelectedFailure(error));
       
    }
};


export const getContactsTicketWorker = function* ({ payload: { filters, suggestedContactsFilters } }) {
    try {
        const token = yield select(accessTokenSelector);
        let dataAux;
        let areContactsSuggested;

        if (Object.keys(filters).length) {
            const {data : {contacts}}  = yield call(getContactsService, token, filters);
            dataAux = contacts;
            areContactsSuggested = false;

        } else {
            const parsedFilters = { suggestedContacts: makeFilters(suggestedContactsFilters)}

            const {data : {contacts}}  = yield call(getContactsService, token, parsedFilters);
            dataAux = contacts;
            areContactsSuggested = true;
        }
        yield put(getContactsTicketSuccess(dataAux, 0, areContactsSuggested));
        
    } catch (error) {
        console.log(error);
        yield put(getContactsTicketFailure(error));
        yield put(showGlobalError(true, error));
    }
};

export const putTicketContactWorker = function* ({ payload: { ticketId, contactId } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(putTicketContactService, token, ticketId, contactId);
        if(contactId){
            //link contact
            yield put(putTicketContactSuccess(null));
        };
        if(!contactId){
            //unlink contact
            yield put(putTicketContactSuccess({}));
        }
        yield put(showGlobalError(true, 200, contactId ? "contact_linked" : "contact_unlinked"));
    } catch (error) {
        console.log(error);
        yield put(putTicketContactFailure(error));
        yield put(showGlobalError(true, error));
    }
};


export const editContactTicketWorker = function* ({ payload: { contact, id } }) {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(editContactService, token , contact, id);
        yield put(editContactTicketSuccess(data?.contact));
    } catch (error) {
        console.log(error);        
        
        yield put(editContactTicketFailure(error));  

        const responseData=error?.response?.data?.error || null
 
        if(responseData && responseData.statusCode === 409){             
            yield put(showGlobalError(true,409, "email_already_exist"));
            return
        }  
        if(responseData && responseData.statusCode === 400){             
            yield put(showGlobalError(true,409, "incorrect_data_contact"));
            return
        }  
        yield put(showGlobalError(true, error));
    }
};

export const postContactTicketWorker = function* ({ payload: { contact } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(postContactService, token, contact);
        
        yield put(postContactTicketSuccess(data?.contact));
        yield put(showGlobalError(true, 200, "created"));
    } catch (error) {

        console.log("error",error);
        const { response, request } = error;

        if(response){
            if(response.status === 409){
                yield put(showGlobalError(true, response.status, 'contact_duplicated'));
            }
            if(response.data.error && response.data.error.subCode === 'name_can_not_be_empty'){
                yield put(showGlobalError(true, response.status, 'contact_without_name'));
            }
            if(response.status === 400){
                yield put(showGlobalError(true, response.status, 'contact_creation_failure'));
            }
        }
        else{
            yield put(showGlobalError(true, response));
        }
        yield put(postContactTicketFailure(response));
    }
};

export const postContactConnectionsWorker = function* ({ payload: { contactId, channels } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(postContactConnectionsService, token, contactId, channels);
        
        yield put(postContactConnectionsSuccess(data.contact));
    } catch (error) {

        const { response, request } = error;

        if(response){
            if(response.status === 409){
                yield put(showGlobalError(true, response.status, 'contact_cant_be_associated'));
            }
            if(response.data.error && response.data.error.subCode === 'name_can_not_be_empty'){
                yield put(showGlobalError(true, response.status, 'contact_without_name'));
            }
        }
        else{
            yield put(showGlobalError(true, response));
        }
        yield put(postContactConnectionsFailure(response));
    }
};

export default [
    takeLatest(GET_CONTACTS_TICKET_REQUEST, getContactsTicketWorker),
    takeLatest(GET_CONTACT_TICKET_REQUEST, getContactTicketWorker),
    takeLatest(GET_CONTACT_SELECTED_REQUEST, getContactSelectedWorker),
    takeLatest(PUT_TICKET_CONTACT_REQUEST, putTicketContactWorker),
    takeLatest(EDIT_CONTACT_TICKET_REQUEST, editContactTicketWorker),
    takeLatest(POST_CONTACT_TICKET_REQUEST, postContactTicketWorker),
    takeLatest(POST_CONTACT_CONNECTIONS_REQUEST, postContactConnectionsWorker)
];
