export const GET_TASK_BY_AREA_REQUEST = 'GET_TASK_BY_AREA_REQUEST';
export const GET_TASK_BY_AREA_SUCCESS = 'GET_TASK_BY_AREA_SUCCESS';
export const GET_TASK_BY_AREA_FAILURE = 'GET_TASK_BY_AREA_FAILURE';


export const getTaskByAreaRequest = (filters = {}) => ({
    type: GET_TASK_BY_AREA_REQUEST,
    payload: {
        filters
    }
});

export const getTaskByAreaSuccess = (statistics) => ({
    type: GET_TASK_BY_AREA_SUCCESS,
    payload: {
        statistics
    }
});

export const getTaskByAreaFailure = (err = {}) => ({
    type: GET_TASK_BY_AREA_FAILURE,
    payload: {
        err
    }
});