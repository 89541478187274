export const GET_PROFILES_SEL_REQUEST = 'GET_PROFILES_SEL_REQUEST';
export const GET_PROFILES_SEL_SUCCESS = 'GET_PROFILES_SEL_SUCCESS';
export const GET_PROFILES_SEL_FAILURE = 'GET_PROFILES_SEL_FAILURE';

export const getProfilesSelRequest = (filters = {}) => ({
    type: GET_PROFILES_SEL_REQUEST,
    payload: {
        filters
    }
});

export const getProfilesSelSuccess = (profiles) => ({
    type: GET_PROFILES_SEL_SUCCESS,
    payload: {
        profiles
    }
});

export const getProfilesSelFailure = (err) => ({
    type: GET_PROFILES_SEL_FAILURE,
    error: err
});