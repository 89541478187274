import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postTwitterConnectionsServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/twitter`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const putTwitterConnectionsServices = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/twitter/${data.pageId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const getTwitterConnectionsServices = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/twitter`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const deleteTwitterConnectionsServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/twitter/${data.pageId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });