import { 
    GET_SUGGESTED_TAGS_FAILURE, 
    GET_SUGGESTED_TAGS_REQUEST, 
    GET_SUGGESTED_TAGS_SUCCESS, 
    POST_SUGGESTED_TAGS_FAILURE, 
    POST_SUGGESTED_TAGS_REQUEST, 
    POST_SUGGESTED_TAGS_SUCCESS 
} from "../actions/tags";

export const initialState = {
    suggestedTags: [],
    fetching: false,
    postSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SUGGESTED_TAGS_REQUEST: {
            return {
                ...state,
                fetching: true,
                postSuccess: false
            }
        }
        case GET_SUGGESTED_TAGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                suggestedTags: action.payload.tags
            }
        case GET_SUGGESTED_TAGS_FAILURE:
            return{
                ...state,
                fetching: false
            }
        case POST_SUGGESTED_TAGS_REQUEST:
            return {
                ...state,
                postSuccess: false
            }
        case POST_SUGGESTED_TAGS_SUCCESS:
            return {
                ...state,
                postSuccess: true,
                suggestedTags: [...state.suggestedTags, action.payload.tag.tag]
            }
        case POST_SUGGESTED_TAGS_FAILURE:
            return {
                ...state,
                postSuccess: false
            }
        default:
            return state;
    }
};