export const GET_BOTS_REQUEST = "GET_BOTS_REQUEST";
export const GET_BOTS_SUCCESS = "GET_BOTS_SUCCESS";
export const GET_BOTS_FAILURE = "GET_BOTS_FAILURE";

export const SET_BOTS = "SET_BOTS";
export const SET_BOT = "SET_BOT";

export const SUBMIT_BOTS_REQUEST = "SUBMIT_BOTS_REQUEST";
export const SUBMIT_BOTS_SUCCESS = "SUBMIT_BOTS_SUCCESS";
export const SUBMIT_BOTS_FAILURE = "SUBMIT_BOTS_FAILURE";
export const UPDATE_BOT_SUCCESS = "UPDATE_BOT_SUCCESS";

export const DELETE_BOT_REQUEST = "DELETE_BOT_REQUEST";
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS";
export const DELETE_BOT_FAILURE = "DELETE_BOT_FAILURE";

export const SET_BOTS_LIMIT = "SET_BOTS_LIMIT";

export const deleteBotRequest = (id) => ({
  type: DELETE_BOT_REQUEST,
  payload: {
    id,
  },
});

export const deleteBotSuccess = (id) => ({
  type: DELETE_BOT_SUCCESS,
  payload: {
    id,
  },
});

export const deleteBotFailure = (err) => ({
  type: DELETE_BOT_FAILURE,
  error: err,
});

export const getBotsRequest = (filters = {}) => ({
  type: GET_BOTS_REQUEST,
  payload: {
    filters,
  },
});

export const getBotsSuccess = (bots, total, profiles) => ({
  type: GET_BOTS_SUCCESS,
  payload: {
    bots,
    total,
    profiles,
  },
});

export const getBotsFailure = (err) => ({
  type: GET_BOTS_FAILURE,
  error: err,
});

export const setBots = (bots = [], total = 0) => ({
  type: SET_BOTS,
  payload: {
    bots,
    total,
  },
});

export const setBot = (bot = {}) => ({
  type: SET_BOT,
  payload: {
    bot,
  },
});

export const setBotsLimit = (limit) => ({
  type: SET_BOTS_LIMIT,
  payload: { limit },
});

export const submitBotsRequest = (bot, history) => ({
  type: SUBMIT_BOTS_REQUEST,
  payload: { bot },
  history,
});

export const submitBotsSuccess = (bot) => ({
  type: SUBMIT_BOTS_SUCCESS,
  payload: {
    bot,
  },
});

export const updateBotSuccess = (bot) => ({
  type: UPDATE_BOT_SUCCESS,
  payload: {
    bot,
  },
});

export const submitBotsFailure = (err) => ({
  type: SUBMIT_BOTS_FAILURE,
  error: err,
});
