import {
    GET_SOCIALS_REQUEST,
    GET_SOCIALS_SUCCESS,
    GET_SOCIALS_FAILURE,
    GET_SOCIAL_REQUEST,
    GET_SOCIAL_SUCCESS,
    GET_SOCIAL_FAILURE,
    GET_SOCIALS_INCOMING_REQUEST,
    GET_SOCIALS_INCOMING_SUCCESS,
    GET_SOCIALS_INCOMING_FAILURE,
    GET_SOCIAL_INCOMING_REQUEST,
    GET_SOCIAL_INCOMING_SUCCESS,
    GET_SOCIAL_INCOMING_FAILURE,
    GET_SOCIALS_OUTGOING_REQUEST,
    GET_SOCIALS_OUTGOING_SUCCESS,
    GET_SOCIALS_OUTGOING_FAILURE,
    GET_SOCIAL_OUTGOING_REQUEST,
    GET_SOCIAL_OUTGOING_SUCCESS,
    GET_SOCIAL_OUTGOING_FAILURE,
    GET_GEOLOCATIONS_REQUEST,
    GET_GEOLOCATIONS_SUCCESS,
    GET_GEOLOCATIONS_FAILURE,
    POST_SOCIAL_REQUEST,
    PUT_SOCIAL_LIKE,
    POST_SOCIAL_SUCCESS,
    POST_SOCIAL_FAILURE,
    SET_LINK_SOCIAL,
    SET_TICKET_GO_BACK,
    CLEAN_TICKET_GO_BACK,
    DELETE_SOCIAL_OUTGOING_REQUEST,
    DELETE_SOCIAL_OUTGOING_SUCCESS,
    DELETE_SOCIAL_OUTGOING_FAILURE,
    PUT_SOCIAL_REQUEST,
    PUT_SOCIAL_SUCCESS,
    PUT_SOCIAL_FAILURE,
    SET_SOCIALS_OUTGOING_PAGE,
    SET_SOCIALS_OUTGOING_LIMIT,
    PUT_SOCIAL_LIKE_SUCCESS,
    PUT_SOCIAL_SHARE,
    PUT_SOCIAL_SHARE_SUCCESS,
    PUT_SOCIAL_SHARE_FAILURE,
    CLEAN_SOCIAL,
    SET_PAGINATION
} from '../actions/socials';

const initialState = {
    fetching: false,
    fetchingSocial: false,
    fetchingIncoming: false,
    socials: [],
    socialsIncoming: [],
    socialsOutgoing: [],
    geoLocations: [],
    social: {},
    socialIncoming: {},
    socialOutgoing: {},
    ids: [],
    link: '',
    ticketId: '',
    deleteFetching: false,
    deleteSuccess: false,
    postSuccess: false,
    putSuccess: false,
    putFetching : false,
    totalItems: 0,
    page: 1,
    limit: 10
}

export default (state = initialState, action) => {
    
    switch (action.type) {

        case GET_SOCIALS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            }
        }

        case GET_SOCIALS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                socials: action.payload.socials,
            }
        }
        
        case GET_SOCIALS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case GET_SOCIAL_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                putFetching:false,
                fetchingSocial: true
            }
        }
        
        case GET_SOCIAL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                social: action.payload.social,
                fetchingSocial: false
            }
        }

        case GET_SOCIAL_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
                fetchingSocial: false
            }
        }

        case GET_SOCIALS_INCOMING_REQUEST: {
            return {
                ...state,
                fetchingIncoming: true,
                success: false,
            }
        }

        case GET_SOCIALS_INCOMING_SUCCESS: {
            return {
                ...state,
                fetchingIncoming: false,
                success: true,
                socialsIncoming: action.payload.socialsIncoming,
            }
        }
        
        case GET_SOCIALS_INCOMING_FAILURE: {
            return {
                ...state,
                fetchingIncoming: false,
                success: false,
            }
        }

        case GET_SOCIAL_INCOMING_REQUEST: {
            return {
                ...state,
                fetchingIncoming: true,
                success: false,
                social:{}
            }
        }
        
        case GET_SOCIAL_INCOMING_SUCCESS: {
            return {
                ...state,
                fetchingIncoming: false,
                success: true,
                social: action.payload.social,
            }
        }

        case GET_SOCIAL_INCOMING_FAILURE: {
            return {
                ...state,
                fetchingIncoming: false,
                success: false,
            }
        }

        case GET_SOCIALS_OUTGOING_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            }
        }

        case GET_SOCIALS_OUTGOING_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                socialsOutgoing: action.payload.socialsOutgoing,
                totalItems: action.payload.totalItems,
            }
        }
        
        case GET_SOCIALS_OUTGOING_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case GET_SOCIAL_OUTGOING_REQUEST: {
            return {
                ...state,
                success: false,
                postSuccess: false,
                putSuccess: false,
                fetchingSocial: true
            }
        }
        
        case GET_SOCIAL_OUTGOING_SUCCESS: {
            return {
                ...state,
                success: true,
                social: action.payload.social,
                fetchingSocial: false
            }
        }

        case GET_SOCIAL_OUTGOING_FAILURE: {
            return {
                ...state,
                success: false,
                fetchingSocial: false
            }
        }

        case GET_GEOLOCATIONS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            }
        }
        
        case GET_GEOLOCATIONS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                geoLocations: action.payload.geoLocations,
            }
        }

        case GET_GEOLOCATIONS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case POST_SOCIAL_REQUEST: {
            return {
                ...state,
                success: false,
                postSuccess: false,
            }
        }

        case PUT_SOCIAL_LIKE: {
            return {
                ...state,
                fetching: true,
                success: false,
                putSuccess: false,
            }
        }

        case PUT_SOCIAL_LIKE_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                postSuccess: true,
                putSuccess: true,
                social: action.payload.social
            }
        }

        case POST_SOCIAL_SUCCESS: {
            return {
                ...state,
                success: true,
                postSuccess: true,
            }
        }

        case POST_SOCIAL_FAILURE: {
            return {
                ...state,
                success: false,
                postSuccess: false
            }
        }
        case SET_LINK_SOCIAL: {
            return {
                ...state,
                link: action.payload.id,
            }
        }
        case SET_TICKET_GO_BACK: {
            return {
                ...state,
                ticketId: action.payload.ticketId
            }
        }
        case CLEAN_TICKET_GO_BACK: {
            return {
                ...state,
                ticketId: initialState.ticketId,
                link: initialState.link
            }
        }
        case DELETE_SOCIAL_OUTGOING_REQUEST: {
            return {
                ...state,
                deleteFetching: true,
                deleteSuccess: false

            }
        }
        case DELETE_SOCIAL_OUTGOING_SUCCESS: {
            return {
                ...state,
                deleteFetching: false,
                deleteSuccess: true
            }
        }
        case DELETE_SOCIAL_OUTGOING_FAILURE: {
            return {
                ...state,
                deleteFetching: false
            }
        }
        case PUT_SOCIAL_REQUEST: {
            return {
                ...state,
                success: false,
                putSuccess: false,
                putFetching: true
            }
        }

        case PUT_SOCIAL_SUCCESS: {
            return {
                ...state,
                success: true,
                putSuccess: true, 
                putFetching: false,
            }
        }

        case PUT_SOCIAL_FAILURE: {
            return {
                ...state,
                success: false,
                putSuccess: false,
                putFetching: false
            }
        }
        case  SET_SOCIALS_OUTGOING_PAGE:
            return {
                ...state,
                page: action.payload.page,
            }
        
        case  SET_SOCIALS_OUTGOING_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }

        case PUT_SOCIAL_SHARE: {
            return {
                ...state,
                fetching: true,
                success: false,
                putSuccess: false,
            }
        }
    
        case PUT_SOCIAL_SHARE_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                postSuccess: true,
                social: action.payload.social
            }
        }

        case PUT_SOCIAL_SHARE_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false            
            }
        }

        case CLEAN_SOCIAL: {
            return {
                ...state,
                social: {}          
            }
        }
        case SET_PAGINATION: {
            return {
                ...state,
                limit: action.payload.limit,         
                page: action.payload.page,         
            }
        }

        default:
            return state;
    }
}