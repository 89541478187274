import {
  SET_INTEGRATIONS,
  SET_SELECTED_INTEGRATION,
} from "../actions/integrations";

const INITIAL_STATE = {
  integrations: null,
  selected: null,
};

const IntegrationsState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INTEGRATIONS: {
      return {
        ...state,
        integrations: action.payload.integrations,
      };
    }
    case SET_SELECTED_INTEGRATION: {
      return {
        ...state,
        selected: action.payload.selectedIntegration,
      };
    }

    default:
      return state;
  }
};

export default IntegrationsState;
