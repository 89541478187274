import {
    GET_TASK_BY_AREA_REQUEST,
    GET_TASK_BY_AREA_SUCCESS,
    GET_TASK_BY_AREA_FAILURE
} from '../actions/taskByArea'

const initialState = {
    statistics: {},
    fetching: false,
    getTaskByAreaSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TASK_BY_AREA_REQUEST: {
            return {
                ...state,
                fetching: true,
                getTaskByAreaSuccess: false
            };
        }
        case GET_TASK_BY_AREA_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getTaskByAreaSuccess: true
            };
        }
        case GET_TASK_BY_AREA_FAILURE: {
            return {
                ...state,
                fetching: false,
                getTaskByAreaSuccess: false
            };
        }
        default:
            return state;
    }
}