export const POST_TELEGRAM_BOT_REQUEST = 'POST_TELEGRAM_BOT_REQUEST';
export const POST_TELEGRAM_BOT_SUCCESS = 'POST_TELEGRAM_BOT_SUCCESS';
export const POST_TELEGRAM_BOT_FAILURE = 'POST_TELEGRAM_BOT_FAILURE';

export const GET_TELEGRAM_BOT_REQUEST = 'GET_TELEGRAM_BOT_REQUEST';
export const GET_TELEGRAM_BOT_SUCCESS = 'GET_TELEGRAM_BOT_SUCCESS';
export const GET_TELEGRAM_BOT_FAILURE = 'GET_TELEGRAM_BOT_FAILURE';

export const PUT_TELEGRAM_BOT_REQUEST = 'PUT_TELEGRAM_BOT_REQUEST';
export const PUT_TELEGRAM_BOT_SUCCESS = 'PUT_TELEGRAM_BOT_SUCCESS';
export const PUT_TELEGRAM_BOT_FAILURE = 'PUT_TELEGRAM_BOT_FAILURE';

export const DELETE_TELEGRAM_BOT_REQUEST = 'DELETE_TELEGRAM_BOT_REQUEST';
export const DELETE_TELEGRAM_BOT_SUCCESS = 'DELETE_TELEGRAM_BOT_SUCCESS';
export const DELETE_TELEGRAM_BOT_FAILURE = 'DELETE_TELEGRAM_BOT_FAILURE';

export const postTelegramBotRequest = telegramBot => ({
    type: POST_TELEGRAM_BOT_REQUEST,
    payload: {
        telegramBot
    }  
});

export const postTelegramBotSuccess = telegramBot => ({
    type: POST_TELEGRAM_BOT_SUCCESS,
    payload: {
        telegramBot
    }  
});

export const postTelegramBotFailure = err => ({
    type: POST_TELEGRAM_BOT_FAILURE,
    error: err
});

export const getTelegramBotRequest = (filters = {}) => ({
    type: GET_TELEGRAM_BOT_REQUEST,
    payload: {
        filters
    }  
});

export const getTelegramBotSuccess = telegramBot => ({
    type: GET_TELEGRAM_BOT_SUCCESS,
    payload: {
        telegramBot
    }  
});

export const getTelegramBotFailure = err => ({
    type: GET_TELEGRAM_BOT_FAILURE,
    error: err
});

export const putTelegramBotRequest = telegramBot => ({
    type: PUT_TELEGRAM_BOT_REQUEST,
    payload: {
        telegramBot
    }  
});

export const putTelegramBotSuccess = telegramBot => ({
    type: PUT_TELEGRAM_BOT_SUCCESS,
    payload: {
        telegramBot
    }  
});

export const putTelegramBotFailure = err => ({
    type: PUT_TELEGRAM_BOT_FAILURE,
    error: err
});

export const deleteTelegramBotRequest = telegramBotDeleted => ({
    type: DELETE_TELEGRAM_BOT_REQUEST,
    payload: {
        telegramBotDeleted
    }  
});

export const deleteTelegramBotSuccess = telegramBotDeleted => ({
    type: DELETE_TELEGRAM_BOT_SUCCESS,
    payload: {
        telegramBotDeleted,
        telegramBot: {}
    }  
});

export const deleteTelegramBotFailure = err => ({
    type: DELETE_TELEGRAM_BOT_FAILURE,
    error: err
});