export const GET_INTENTS_REQUEST = 'GET_INTENTS_REQUEST';
export const GET_INTENTS_SUCCESS = 'GET_INTENTS_SUCCESS';
export const GET_INTENTS_FAILURE = 'GET_INTENTS_FAILURE';

export const POST_INTENT_REQUEST = 'POST_INTENT_REQUEST';
export const POST_INTENT_SUCCESS = 'POST_INTENT_SUCCESS';
export const POST_INTENT_FAILURE = 'POST_INTENT_FAILURE';

export const PUT_INTENT_REQUEST = 'PUT_INTENT_REQUEST';
export const PUT_INTENT_SUCCESS = 'PUT_INTENT_SUCCESS';
export const PUT_INTENT_FAILURE = 'PUT_INTENT_FAILURE';

export const DELETE_INTENT_REQUEST = 'DELETE_INTENT_REQUEST';
export const DELETE_INTENT_SUCCESS = 'DELETE_INTENT_SUCCESS';
export const DELETE_INTENT_FAILURE = 'DELETE_INTENT_FAILURE';
export const DELETE_INTENT_RESET = 'DELETE_INTENT_RESET';


export const getIntentsRequest = (botId = '') => ({
    type: GET_INTENTS_REQUEST,
    payload: {
        botId
    }
});

export const getIntentsSuccess = (intents) => ({
    type: GET_INTENTS_SUCCESS,
    payload: {
        intents
    }
});

export const getIntentsFailure = err => ({
    type: GET_INTENTS_FAILURE,
    error: err
});

export const postIntentRequest = (botId = '', data) => ({
    type: POST_INTENT_REQUEST,
    payload: {
        botId, intent: data
    }
});

export const postIntentSuccess = (intent) => ({
    type: POST_INTENT_SUCCESS,
    payload: {
        intent
    }
});

export const postIntentFailure = err => ({
    type: POST_INTENT_FAILURE,
    error: err
});

export const deleteIntentRequest = (botId, id) => ({
    type: DELETE_INTENT_REQUEST,
    payload: {
        botId, id
    }
});

export const deleteIntentSuccess = (id) => ({
    type: DELETE_INTENT_SUCCESS,
    payload: {
        id
    }
});

export const deleteIntentFailure = (err) => ({
    type: DELETE_INTENT_FAILURE,
    error: err
});

export const putIntentRequest = (botId = '', intentId, data) => ({
    type: PUT_INTENT_REQUEST,
    payload: {
        botId, intentId, intent: data
    }
});

export const putIntentSuccess = (intent) => ({
    type: PUT_INTENT_SUCCESS,
    payload: {
        intent
    }
});

export const putIntentFailure = err => ({
    type: PUT_INTENT_FAILURE,
    error: err
});
