export const GET_TICKET_USERS_REQUEST = 'GET_TICKET_USERS_REQUEST';
export const GET_TICKET_USERS_SUCCESS = 'GET_TICKET_USERS_SUCCESS';
export const GET_TICKET_USERS_FAILURE = 'GET_TICKET_USERS_FAILURE';

export const getTicketUsersRequest = (ticketId, filters = {}) => ({
    type: GET_TICKET_USERS_REQUEST,
    payload: {
        ticketId,
        filters
    }
});

export const getTicketUsersSuccess = (ticketUsers) => ({
    type: GET_TICKET_USERS_SUCCESS,
    payload: {
        ticketUsers
    }
});

export const getTicketUsersFailure = err => ({
    type: GET_TICKET_USERS_FAILURE,
    error: err
});