export const SET_CONTENT_VIEW_EMAIL_MODAL = "SET_CONTENT_VIEW_EMAIL_MODAL";
export const SET_OPEN_VIEW_EMAIL_MODAL = "SET_OPEN_VIEW_EMAIL_MODAL";

export const setOpenViewEmailModal = (isVisible) => ({
  type: SET_OPEN_VIEW_EMAIL_MODAL,
  payload: {
    isVisible,
  },
});

export const setContentViewEmailModal = ({
  content,
  receiver,
  subject,
  transmitterName,
  transmitterEmail,
}) => ({
  type: SET_CONTENT_VIEW_EMAIL_MODAL,
  payload: {
    content,
    receiver,
    subject,
    transmitterName,
    transmitterEmail,
  },
});
