import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { showGlobalError } from "../../app/actions/app";
import { deleteActionFailure, deleteActionSuccess, DELETE_ACTION_REQUEST, getActionsFailure, getActionsSuccess, GET_ACTIONS_REQUEST, postActionFailure, postActionsFailure, postActionsSuccess, postActionSuccess, POST_ACTIONS_REQUEST, POST_ACTION_REQUEST } from "../actions/actions";
import { deleteActionServices, getActionsServices, postActionServices } from "../services/actions";


export const getActionsWorker = function* ({ payload: { botId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { actions }} = yield call(getActionsServices, token, botId);
        
        yield put(getActionsSuccess(actions));
    } catch (err) {
        console.log(err);

        yield put(getActionsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const postActionWorker = function* ({ payload: { botId, action: newAction } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { action }} = yield call(postActionServices, token, botId, newAction);
        
        yield put(postActionSuccess(action));
    } catch (err) {
        console.log(err);

        yield put(postActionFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteActionWorker = function* ({ payload: { botId, id } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { action } } = yield call(deleteActionServices, token, botId, id);
        
        yield put(deleteActionSuccess(action._id));
    } catch (err) {
        console.log(err);

        yield put(deleteActionFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(GET_ACTIONS_REQUEST, getActionsWorker),
    takeLatest(POST_ACTION_REQUEST, postActionWorker),
    takeLatest(DELETE_ACTION_REQUEST, deleteActionWorker)
]