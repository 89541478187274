import {
    GET_CHANNEL_STATISTICS_REQUEST,
    GET_CHANNEL_STATISTICS_FAILURE,
    GET_CHANNEL_STATISTICS_SUCCESS,
    GET_ALL_CASES_REQUEST,
    GET_ALL_CASES_SUCCESS,
    GET_ALL_CASES_FAILURE,
    GET_SCORES_AVERAGE_REQUEST,
    GET_SCORES_AVERAGE_FAILURE,
    GET_SCORES_AVERAGE_SUCCESS,
    GET_TASK_TYPING_REQUEST,
    GET_TASK_TYPING_FAILURE,
    GET_TASK_TYPING_SUCCESS,
    GET_ABANDONED_CASES_REQUEST,
    GET_ABANDONED_CASES_FAILURE,
    GET_ABANDONED_CASES_SUCCESS,
    GET_DERIVED_CASES_REQUEST,
    GET_DERIVED_CASES_FAILURE,
    GET_DERIVED_CASES_SUCCESS,
    GET_DOWNLOAD_STATISTICS_REQUEST,
    GET_DOWNLOAD_STATISTICS_FAILURE,
    GET_DOWNLOAD_STATISTICS_SUCCESS,
    GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST,
    GET_DOWNLOAD_SYSTEM_STATISTICS_FAILURE,
    GET_DOWNLOAD_SYSTEM_STATISTICS_SUCCESS,
    GET_CASES_BY_DATE_REQUEST,
    GET_CASES_BY_DATE_SUCCESS,
    GET_CASES_BY_DATE_FAILURE,
    GET_CASES_BY_AGENT_FAILURE,
    GET_CASES_BY_AGENT_SUCCESS,
    GET_CASES_BY_AGENT_REQUEST,
    GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST,
    GET_TIME_ASSOCIATED_AGENT_TASK_SUCCESS,
    GET_TIME_ASSOCIATED_AGENT_TASK_FAILURE,
    GET_SESSIONS_STATISTICS_REQUEST,
    GET_SESSIONS_STATISTICS_SUCCESS,
    GET_SESSIONS_STATISTICS_FAILURE,
    GET_INACTIVITY_TIME_BY_AGENT_FAILURE,
    GET_INACTIVITY_TIME_BY_AGENT_SUCCESS,
    GET_INACTIVITY_TIME_BY_AGENT_REQUEST,
    GET_CUSTOM_ATTRIBUTES_STATISTICS_FAILURE,
    GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST,
    GET_CUSTOM_ATTRIBUTES_STATISTICS_SUCCESS
} from '../actions/statistics';
import {buildStatistics,parseCasesStatistics} from '../helpers/statistics';

const initialState = {
    fetchingStatistics: false,
    successStatistics: false,
    statistics: {},
    fetchingCases: false,
    successCases: false,
    cases: [],
    scores: {},
    fetchingScores: false,
    successScores: false,
    fetchingTaskTyping: false,
    successTaskTyping: false,
    statisticsTasks: {},
    fetchingAbandoned: false,
    successAbandoned: false,
    fetchingDerived: false,
    successDerived: false,
    abandonedCases: {},
    derivedCases: {},
    urlReport: null,
    fetchingUrl:false,
    urlSuccess:false,
    casesByDate:[],
    casesByAgent:[],
    agentSessions: [],
    fetchingAgentSessions: false,
    timeAssociatedAgentTask:[],
    fetchingAssociatedAgentTask: false,
    inactivityTime: [],
    fetchingInactivityTime: false,
    customAttributesStatistics: {},
    fetchingCustomStatistics: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SCORES_AVERAGE_REQUEST: {
            return {
                ...state,
                fetchingScores: true,
                successScores: false
            }
        }
        case GET_SCORES_AVERAGE_SUCCESS: {
            return {
                ...state,
                fetchingScores: false,
                scores: action.payload.scores,
                successScores: true
            }
        }
        case GET_SCORES_AVERAGE_FAILURE: {
            return {
                ...state,
                fetchingScores: false,
                successScores: false
            }
        }
        case GET_CHANNEL_STATISTICS_REQUEST: {
            return {
                ...state,
                fetchingStatistics: true,
                successStatistics: false
            }
        }
        case GET_CHANNEL_STATISTICS_SUCCESS: {
            return {
                ...state,
                fetchingStatistics: false,
                statistics: buildStatistics(action.payload.statistics),
                successStatistics: true
            }
        }
        case GET_CHANNEL_STATISTICS_FAILURE: {
            return {
                ...state,
                fetchingStatistics: false,
                successStatistics: false,
            }
        }
        case GET_ALL_CASES_REQUEST: {
            return {
                ...state,
                fetchingCases: true,
                successCases: false
            }
        }
        case GET_ALL_CASES_SUCCESS: {
            return {
                ...state,
                fetchingCases: false,
                cases: parseCasesStatistics(action.payload.cases),
                successCases: true
            }
        }
        case GET_ALL_CASES_FAILURE: {
            return {
                ...state,
                fetchingCases: false,
                successCases: false
            }
        }
        case GET_TASK_TYPING_REQUEST: {
            return {
                ...state,
                fetchingTaskTyping: true,
                successTaskTyping: false
            }
        }
        case GET_TASK_TYPING_SUCCESS: {
            return {
                ...state,
                fetchingTaskTyping: false,
                statisticsTasks: action.payload.statisticsTasks,
                successTaskTyping: true
            }
        }
        case GET_TASK_TYPING_FAILURE: {
            return {
                ...state,
                fetchingTaskTyping: false,
                successTaskTyping: false
            }
        }
        case GET_ABANDONED_CASES_REQUEST: {
            return {
                ...state,
                fetchingAbandoned: true,
                successAbandoned: false
            }
        }
        case GET_ABANDONED_CASES_SUCCESS: {
            return {
                ...state,
                fetchingAbandoned: false,
                abandonedCases: action.payload.abandonedCases,
                successAbandoned: true
            }
        }
        case GET_ABANDONED_CASES_FAILURE: {
            return {
                ...state,
                fetchingAbandoned: false,
                successAbandoned: false
            }
        }
        case GET_DERIVED_CASES_REQUEST: {
            return {
                ...state,
                fetchingDerived: true,
                successDerived: false
            }
        }
        case GET_DERIVED_CASES_SUCCESS: {
            return {
                ...state,
                fetchingDerived: false,
                derivedCases: action.payload.derivedCases,
                successDerived: true
            }
        }
        case GET_DERIVED_CASES_FAILURE: {
            return {
                ...state,
                fetchingDerived: false,
                successDerived: false
            }
        }
        case GET_DOWNLOAD_STATISTICS_REQUEST: {
            return {
                ...state,
                fetchingUrl: true,
                urlSuccess: false
            }
        }
        case GET_DOWNLOAD_STATISTICS_SUCCESS: {
            return {
                ...state,
                urlReport: action.payload.urlReport,
                fetchingUrl: false,
                urlSuccess: true
            }
        }
        case GET_DOWNLOAD_STATISTICS_FAILURE: {
            return {
                ...state,
                fetchingUrl: false,
                urlSuccess: false
            }
        }
        case GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST: {
            return {
                ...state,
                fetchingUrl: true,
                urlSuccess: false
            }
        }
        case GET_DOWNLOAD_SYSTEM_STATISTICS_SUCCESS: {
            return {
                ...state,
                urlReport: action.payload.urlReport,
                fetchingUrl: false,
                urlSuccess: true
            }
        }
        case GET_DOWNLOAD_SYSTEM_STATISTICS_FAILURE: {
            return {
                ...state,
                fetchingUrl: false,
                urlSuccess: false
            }
        }
        case GET_CASES_BY_DATE_REQUEST: {
            return {
                ...state,
                casesByDate:[],
                fetchingCasesByDate: true,
                successCasesByDate: false
            }
        }
        case GET_CASES_BY_DATE_SUCCESS: {
            return {
                ...state,
                fetchingCasesByDate: false,
                casesByDate: action.payload.casesByDate,
                successCasesByDate: true
            }
        }
        case GET_CASES_BY_DATE_FAILURE: {
            return {
                ...state,
                fetchingCasesByDate: false,
                successCasesByDate: false
            }
        }
        case GET_CASES_BY_AGENT_REQUEST: {
            return {
                ...state,
                casesByAgent:[],
                fetchingCasesByAgent: true,
                successCasesByAgent: false
            }
        }
        case GET_CASES_BY_AGENT_SUCCESS: {
            return {
                ...state,
                fetchingCasesByAgent: false,
                casesByAgent: action.payload.casesByAgent,
                successCasesByAgent: true
            }
        }
        case GET_CASES_BY_AGENT_FAILURE: {
            return {
                ...state,
                fetchingCasesByAgent: false,
                successCasesByAgent: false
            }
        }
        case GET_SESSIONS_STATISTICS_REQUEST: {
            return {
                ...state,
                fetchingAgentSessions: true
            }
        }
        case GET_SESSIONS_STATISTICS_SUCCESS: {
            return {
                ...state,
                agentSessions: action.payload.statistics,
                fetchingAgentSessions: false
            }
        }
        case GET_SESSIONS_STATISTICS_FAILURE: {
            return {
                ...state,
                fetchingAgentSessions: false
            }
        }
        case GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST: {
            return {
                ...state,
                fetchingAssociatedAgentTask: true
            }
        }
        case GET_TIME_ASSOCIATED_AGENT_TASK_SUCCESS: {
            return {
                ...state,
                timeAssociatedAgentTask: action.payload.statistics,
                fetchingAssociatedAgentTask: false
            }
        }
        case GET_TIME_ASSOCIATED_AGENT_TASK_FAILURE: {
            return {
                ...state,
                fetchingAssociatedAgentTask: false
            }
        }
        case GET_INACTIVITY_TIME_BY_AGENT_REQUEST: {
            return {
                ...state,
                fetchingInactivityTime: true
            }
        }
        case GET_INACTIVITY_TIME_BY_AGENT_SUCCESS: {
            return {
                ...state,
                inactivityTime: action.payload.statistics,
                fetchingInactivityTime: false
            }
        }
        case GET_INACTIVITY_TIME_BY_AGENT_FAILURE: {
            return {
                ...state,
                fetchingInactivityTime: false
            }
        }
        case GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST: {
            return {
                ...state,
                fetchingCustomStatistics: true
            }
        }
        case GET_CUSTOM_ATTRIBUTES_STATISTICS_SUCCESS: {
            return {
                ...state,
                customAttributesStatistics: action.payload.customAttributesStatistics,
                fetchingCustomStatistics: false
            }
        }
        case GET_CUSTOM_ATTRIBUTES_STATISTICS_FAILURE: {
            return {
                ...state,
                fetchingCustomStatistics: false
            }
        }
        default:
            return state;
    }
}