export const SET_FILTERS = 'SET_FILTERS';

export const setFilters = ({ name, profile, lastName, user, email, legalId}) => ({
    type: SET_FILTERS,
    payload: {
        name,
        lastName,
        user,
        profile,
        email,
        legalId
    }
});