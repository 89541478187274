export const DELETE_WORKFLOW_TRANSITION_REQUEST = 'DELETE_WORKFLOW_TRANSITION_REQUEST';
export const DELETE_WORKFLOW_TRANSITION_SUCCESS = 'DELETE_WORKFLOW_TRANSITION_SUCCESS';
export const DELETE_WORKFLOW_TRANSITION_FAILURE = 'DELETE_WORKFLOW_TRANSITION_FAILURE';

export const GET_WORKFLOW_TRANSITIONS_REQUEST = 'GET_WORKFLOW_TRANSITIONS_REQUEST';
export const GET_WORKFLOW_TRANSITIONS_SUCCESS = 'GET_WORKFLOW_TRANSITIONS_SUCCESS';
export const GET_WORKFLOW_TRANSITIONS_FAILURE = 'GET_WORKFLOW_TRANSITIONS_FAILURE';

export const POST_WORKFLOW_TRANSITION_REQUEST = 'POST_WORKFLOW_TRANSITION_REQUEST';
export const POST_WORKFLOW_TRANSITION_SUCCESS = 'POST_WORKFLOW_TRANSITION_SUCCESS';
export const POST_WORKFLOW_TRANSITION_FAILURE = 'POST_WORKFLOW_TRANSITIONS_FAILURE';

export const GET_ALL_WORKFLOWS_REQUEST = 'GET_ALL_WORKFLOWS_REQUEST';
export const GET_ALL_WORKFLOWS_SUCCESS = 'GET_ALL_WORKFLOWS_SUCCESS';
export const GET_ALL_WORKFLOWS_FAILURE = 'GET_ALL_WORKFLOWS_FAILURE';

export const GET_COMPANY_WORKFLOWS_REQUEST = 'GET_COMPANY_WORKFLOWS_REQUEST';
export const GET_COMPANY_WORKFLOWS_SUCCESS = 'GET_COMPANY_WORKFLOWS_SUCCESS';
export const GET_COMPANY_WORKFLOWS_FAILURE = 'GET_COMPANY_WORKFLOWS_FAILURE';

export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';

export const POST_WORKFLOW_REQUEST = 'POST_WORKFLOW_REQUEST';
export const POST_WORKFLOW_SUCCESS = 'POST_WORKFLOW_SUCCESS';
export const POST_WORKFLOW_FAILURE = 'POST_WORKFLOW_FAILURE';

export const PUT_WORKFLOW_REQUEST = 'PUT_WORKFLOW_REQUEST';
export const PUT_WORKFLOW_SUCCESS = 'PUT_WORKFLOW_SUCCESS';
export const PUT_WORKFLOW_FAILURE = 'PUT_WORKFLOW_FAILURE';

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE';

export const LOGIC_DELETE_WORKFLOW_REQUEST = 'LOGIC_DELETE_WORKFLOW_REQUEST';
export const LOGIC_DELETE_WORKFLOW_SUCCESS = 'LOGIC_DELETE_WORKFLOW_SUCCESS';
export const LOGIC_DELETE_WORKFLOW_FAILURE = 'LOGIC_DELETE_WORKFLOW_FAILURE';

export const deleteWorkflowTransitionRequest = (workflowTransitionId, filters = {}) => ({
    type: DELETE_WORKFLOW_TRANSITION_REQUEST,
    payload: {
        workflowTransitionId,
        filters,
    }
});

export const deleteWorkflowTransitionSuccess = (workflowTransitionDeleted) => ({
    type: DELETE_WORKFLOW_TRANSITION_SUCCESS,
    payload: {
        workflowTransitionDeleted,
    }
});

export const deleteWorkflowTransitionFailure = err => ({
    type: DELETE_WORKFLOW_TRANSITION_FAILURE,
    error: err
});

export const getWorkflowTransitionsRequest = () => ({
    type: GET_WORKFLOW_TRANSITIONS_REQUEST,
});

export const getWorkflowTransitionsSuccess = (workflowTransitions) => ({
    type: GET_WORKFLOW_TRANSITIONS_SUCCESS,
    payload: {
        workflowTransitions,
    }
});

export const getWorkflowTransitionsFailure = err => ({
    type: GET_WORKFLOW_TRANSITIONS_FAILURE,
    error: err
});

export const postWorkflowTransitionRequest = (workflowTransition) => ({
    type: POST_WORKFLOW_TRANSITION_REQUEST,
    payload: {
        workflowTransition,
    }
});

export const postWorkflowTransitionSuccess = (workflowTransition) => ({
    type: POST_WORKFLOW_TRANSITION_SUCCESS,
    payload: {
        workflowTransition,
    }
});

export const postWorkflowTransitionFailure = err => ({
    type: POST_WORKFLOW_TRANSITION_FAILURE,
    error: err
});


export const getAllWorkflowsRequest = () => ({
    type: GET_ALL_WORKFLOWS_REQUEST,
});

export const getAllWorkflowsSuccess = (workflows) => ({
    type: GET_ALL_WORKFLOWS_SUCCESS,
    payload: {
        workflows,
    }
});

export const getAllWorkflowsFailure = err => ({
    type: GET_ALL_WORKFLOWS_FAILURE,
    error: err
});

export const getCompanyWorkflowsRequest = () => ({
    type: GET_COMPANY_WORKFLOWS_REQUEST,
});

export const getCompanyWorkflowsSuccess = (workflows) => ({
    type: GET_COMPANY_WORKFLOWS_SUCCESS,
    payload: {
        workflows,
    }
});

export const getCompanyWorkflowsFailure = err => ({
    type: GET_COMPANY_WORKFLOWS_FAILURE,
    error: err
});

export const getWorkflowRequest = ( workflowId ) => ({
    type: GET_WORKFLOW_REQUEST,
    payload: {
        workflowId,
    }
});

export const getWorkflowSuccess = (workflow) => ({
    type: GET_WORKFLOW_SUCCESS,
    payload: {
        workflow,
    }
});

export const getWorkflowFailure = err => ({
    type: GET_WORKFLOW_FAILURE,
    error: err
});

export const postWorkflowRequest = ( workflow ) => ({
    type: POST_WORKFLOW_REQUEST,
    payload: {
        workflow,
    }
});

export const postWorkflowSuccess = (workflow) => ({
    type: POST_WORKFLOW_SUCCESS,
    payload: {
        workflow,
    }
});

export const postWorkflowFailure = err => ({
    type: POST_WORKFLOW_FAILURE,
    error: err
});

export const putWorkflowRequest = ( workflow, workflowId ) => ({
    type: PUT_WORKFLOW_REQUEST,
    payload: {
        workflow,
        workflowId
    }
});

// export const putWorkflowSuccess = (workflow) => ({
//     type: PUT_WORKFLOW_SUCCESS,
//     payload: {
//         workflow
//     }
// });

export const putWorkflowSuccess = (workflow) => ({
    type: PUT_WORKFLOW_SUCCESS,
    payload: {
        workflow
    }
});

export const putWorkflowFailure = err => ({
    type: PUT_WORKFLOW_FAILURE,
    error: err
});

export const deleteWorkflowRequest = ( workflowId ) => ({
    type: DELETE_WORKFLOW_REQUEST,
    payload: {
        workflowId
    }
});

export const deleteWorkflowSuccess = () => ({
    type: DELETE_WORKFLOW_SUCCESS,
});

export const deleteWorkflowFailure = err => ({
    type: DELETE_WORKFLOW_FAILURE,
    error: err
});

export const logicDeleteWorkflowRequest = ( workflowId ) => ({
    type: LOGIC_DELETE_WORKFLOW_REQUEST,
    payload: {
        workflowId
    }
});

export const logicDeleteWorkflowSuccess = () => ({
    type: LOGIC_DELETE_WORKFLOW_SUCCESS,
});

export const logicDeleteWorkflowFailure = err => ({
    type: LOGIC_DELETE_WORKFLOW_FAILURE,
    error: err
});




