import { lazy } from "react";
import { dashboardSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Dashboard - EntryPoint" */ './components/EntryPoint'));
const ExportableDashboard = lazy(() => import(/* webpackChunkName: "Dashboard - ExportableDashboard" */ './components/ExportableDashboard'));
const MainDashboard = lazy(() => import(/* webpackChunkName: "Dashboard - MainDashboard" */ './components/Dashboard'));

export const defaultPath = `/dashboard`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: MainDashboard,
        exact: true,
        icon: dashboardSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: '',
            plural: false
        }
    },
    {
        path: `${defaultPath}/export`,
        Component: ExportableDashboard,
        exact: true,
        icon: dashboardSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: '',
            plural: false
        }
    }
];

const Dashboard = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: dashboardSVG,
    title: {
        key: 'dashboard',
        plural: false
    },
    subTitle: {
        key: 'dashboard_detail',
        plural: false
    },
    innerRoutes: innerRoutes
};

export default Dashboard;
