import React, { memo } from "react";
import styles from "./body.module.scss";
import { environment as envConfig } from "../../conf";
import DatadogInit from "../../../shared/components/Datadog/DatadogInit";
import { ENVIRONMENTS } from "../../../home/components/NewSideBar/types";

const Body = ({ children }) => {
  const environment = envConfig;

  return (
    <>
      <div className={styles.Body}>{children}</div>
      {environment === ENVIRONMENTS.PRODUCTION && <DatadogInit />}
    </>
  );
};

export default memo(Body);
