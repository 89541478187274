export const POST_INSTAGRAM_USER_REQUEST = 'POST_INSTAGRAM_USER_REQUEST';
export const POST_INSTAGRAM_USER_SUCCESS = 'POST_INSTAGRAM_USER_SUCCESS';
export const POST_INSTAGRAM_USER_FAILURE = 'POST_INSTAGRAM_USER_FAILURE';

export const GET_INSTAGRAM_USER_REQUEST = 'GET_INSTAGRAM_USER_REQUEST';
export const GET_INSTAGRAM_USER_SUCCESS = 'GET_INSTAGRAM_USER_SUCCESS';
export const GET_INSTAGRAM_USER_FAILURE = 'GET_INSTAGRAM_USER_FAILURE';

export const PUT_INSTAGRAM_USER_REQUEST = 'PUT_INSTAGRAM_USER_REQUEST';
export const PUT_INSTAGRAM_USER_SUCCESS = 'PUT_INSTAGRAM_USER_SUCCESS';
export const PUT_INSTAGRAM_USER_FAILURE = 'PUT_INSTAGRAM_USER_FAILURE';

export const DELETE_INSTAGRAM_USER_REQUEST = 'DELETE_INSTAGRAM_USER_REQUEST';
export const DELETE_INSTAGRAM_USER_SUCCESS = 'DELETE_INSTAGRAM_USER_SUCCESS';
export const DELETE_INSTAGRAM_USER_FAILURE = 'DELETE_INSTAGRAM_USER_FAILURE';

export const postInstagramUserRequest = instagramUser => ({
    type: POST_INSTAGRAM_USER_REQUEST,
    payload: {
        instagramUser
    }  
});

export const postInstagramUserSuccess = instagramUser => ({
    type: POST_INSTAGRAM_USER_SUCCESS,
    payload: {
        instagramUser
    }  
});

export const postInstagramUserFailure = err => ({
    type: POST_INSTAGRAM_USER_FAILURE,
    error: err
});

export const getInstagramUserRequest = (filters = {}) => ({
    type: GET_INSTAGRAM_USER_REQUEST,
    payload: {
        filters
    }  
});

export const getInstagramUserSuccess = instagramUser => ({
    type: GET_INSTAGRAM_USER_SUCCESS,
    payload: {
        instagramUser
    }  
});


export const getInstagramUserFailure = err => ({
    type: GET_INSTAGRAM_USER_FAILURE,
    error: err
});

export const putInstagramUserRequest = instagramUser => ({
    type: PUT_INSTAGRAM_USER_REQUEST,
    payload: {
        instagramUser
    }  
});

export const putInstagramUserSuccess = instagramUser => ({
    type: PUT_INSTAGRAM_USER_SUCCESS,
    payload: {
        instagramUser
    }  
});

export const putInstagramUserFailure = err => ({
    type: PUT_INSTAGRAM_USER_FAILURE,
    error: err
});

export const deleteInstagramUserRequest = instagramUserDeleted => ({
    type: DELETE_INSTAGRAM_USER_REQUEST,
    payload: {
        instagramUserDeleted
    }  
});

export const deleteInstagramUserSuccess = instagramUserDeleted => ({
    type: DELETE_INSTAGRAM_USER_SUCCESS,
    payload: {
        instagramUserDeleted,
        instagramUser: {}
    }  
});

export const deleteInstagramUserFailure = err => ({
    type: DELETE_INSTAGRAM_USER_FAILURE,
    error: err
});