export const POST_TWITTER_CONNECTION_REQUEST = 'POST_TWITTER_CONNECTION_REQUEST';
export const POST_TWITTER_CONNECTION_SUCCESS = 'POST_TWITTER_CONNECTION_SUCCESS';
export const POST_TWITTER_CONNECTION_FAILURE = 'POST_TWITTER_CONNECTION_FAILURE';

export const GET_TWITTER_CONNECTION_REQUEST = 'GET_TWITTER_CONNECTION_REQUEST';
export const GET_TWITTER_CONNECTION_SUCCESS = 'GET_TWITTER_CONNECTION_SUCCESS';
export const GET_TWITTER_CONNECTION_FAILURE = 'GET_TWITTER_CONNECTION_FAILURE';

export const PUT_TWITTER_CONNECTION_REQUEST = 'PUT_TWITTER_CONNECTION_REQUEST';
export const PUT_TWITTER_CONNECTION_SUCCESS = 'PUT_TWITTER_CONNECTION_SUCCESS';
export const PUT_TWITTER_CONNECTION_FAILURE = 'GET_TWITTER_CONNECTION_FAILURE';

export const DELETE_TWITTER_CONNECTION_REQUEST = 'DELETE_TWITTER_CONNECTION_REQUEST';
export const DELETE_TWITTER_CONNECTION_SUCCESS = 'DELETE_TWITTER_CONNECTION_SUCCESS';
export const DELETE_TWITTER_CONNECTION_FAILURE = 'DELETE_TWITTER_CONNECTION_FAILURE';

export const postTwitterConnectionRequest = twitterConnection => ({
    type: POST_TWITTER_CONNECTION_REQUEST,
    payload: {
        twitterConnection
    }  
});

export const postTwitterConnectionSuccess = twitterConnection => ({
    type: POST_TWITTER_CONNECTION_SUCCESS,
    payload: {
        twitterConnection
    }  
});

export const postTwitterConnectionFailure = err => ({
    type: POST_TWITTER_CONNECTION_FAILURE,
    error: err
});

export const getTwitterConnectionRequest = (filters = {}) => ({
    type: GET_TWITTER_CONNECTION_REQUEST,
    payload: {
        filters
    }  
});

export const getTwitterConnectionSuccess = twitterConnection => ({
    type: GET_TWITTER_CONNECTION_SUCCESS,
    payload: {
        twitterConnection
    }  
});

export const getTwitterConnectionFailure = err => ({
    type: GET_TWITTER_CONNECTION_FAILURE,
    error: err
});

export const putTwitterConnectionRequest = twitterConnection => ({
    type: PUT_TWITTER_CONNECTION_REQUEST,
    payload: {
        twitterConnection
    }  
});

export const putTwitterConnectionSuccess = twitterConnection => ({
    type: PUT_TWITTER_CONNECTION_SUCCESS,
    payload: {
        twitterConnection
    }  
});

export const putTwitterConnectionFailure = err => ({
    type: PUT_TWITTER_CONNECTION_FAILURE,
    error: err
});

export const deleteTwitterConnectionRequest = twitterConnectionDeleted => ({
    type: DELETE_TWITTER_CONNECTION_REQUEST,
    payload: {
        twitterConnectionDeleted
    }  
});

export const deleteTwitterConnectionSuccess = twitterConnectionDeleted => ({
    type: DELETE_TWITTER_CONNECTION_SUCCESS,
    payload: {
        twitterConnectionDeleted,
        twitterConnection: {}
    }  
});

export const deleteTwitterConnectionFailure = err => ({
    type: DELETE_TWITTER_CONNECTION_FAILURE,
    error: err
});