import { POST_LOGIN_REQUEST, POST_LOGIN_SUCCESS, POST_LOGIN_FAILURE, GET_LOGIN_REQUEST, GET_LOGIN_SUCCESS, GET_LOGIN_FAILURE, CLEAN_LOGIN } from '../actions/login';

const initialState = {
    fetchingLogin: false,
    fetchingPost: false,
    type:'',
    login: null,
    provider:''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGIN_REQUEST: {
            return {
                ...state,
                fetchingLogin: true
            };
        }
        case GET_LOGIN_SUCCESS: {
            return {
                ...state,
                fetchingLogin: false,
                login: action.payload.login
            };
        }
        case GET_LOGIN_FAILURE: {
            return {
                ...state,
                fetchingLogin: false
            };
        }
        case POST_LOGIN_REQUEST: {
            return {
                ...state,
                fetchingPost: true,
            };
        }
        case POST_LOGIN_SUCCESS: {
            return {
                ...state,
                fetchingPost: false,
                login: action.payload.login
            };
        }
        case POST_LOGIN_FAILURE: {
            return {
                ...state,
                fetchingPost: true
            };
        }
        case CLEAN_LOGIN:{
            return initialState
        }
        default: return state;
    }
};