import {
    GET_APIKEYS_REQUEST,
    GET_APIKEYS_SUCCESS,
    GET_APIKEYS_FAILURE,
    GET_TYPING_APIKEYS_REQUEST,
    SET_APIKEYS,
    SET_APIKEY,
    SUBMIT_APIKEY_SUCCESS,
    SUBMIT_APIKEY_REQUEST,
    SUBMIT_APIKEY_FAILURE,
    DELETE_APIKEY_REQUEST,
    DELETE_APIKEY_SUCCESS,
    DELETE_APIKEY_FAILURE,
    GET_APIKEY_REQUEST,
    GET_APIKEY_FAILURE,
    GET_APIKEY_SUCCESS
} from '../actions/apikeys';
import { buildApikeys } from '../helpers/apikeys';
import _ from 'lodash';

const initialState = {
    fetching: true,
    typingFetching: false,
    submitFetching: false,
    submitSuccess: false,
    apikeys: [],
    apikey: {},
    total: 0,
    deleteFetching: 'unknown'
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_APIKEYS_REQUEST: {
            return {
                ...state,
                fetching: true,
                submitSuccess: false
            };
        }

        case GET_TYPING_APIKEYS_REQUEST: {
            return {
                ...state,
                typingFetching: true,
                apikeys: [],
                total: 0
            };
        }

        case GET_APIKEYS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                typingFetching: false,
                apikeys: action.payload.apikeys,
                total: (!action.payload.total || action.payload.total === 0) ? state.total : action.payload.total
            };
        }

        case GET_APIKEYS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }

        case SET_APIKEYS: {
            return {
                ...state,
                apikeys: action.payload.apikeys,
                total: action.payload.total
            };
        }

        case SET_APIKEY: {
            return {
                ...state,
                apikey: action.payload.apikey
            };
        }

        case SUBMIT_APIKEY_REQUEST: {
            return {
                ...state,
                submitFetching: true,
                submitSuccess: false
            };
        }

        case SUBMIT_APIKEY_SUCCESS: {
            return {
                ...state,
                submitFetching: false,
                submitSuccess: true
            };
        }

        case SUBMIT_APIKEY_FAILURE: {
            return {
                ...state,
                submitFetching: false,
                submitSuccess: false
            };
        }
        case DELETE_APIKEY_REQUEST: {
            return {
                ...state,
                deleteFetching: 'loading'
            }
        }
        case DELETE_APIKEY_SUCCESS: {
            return {
                ...state,
                deleteFetching: 'success'
            }
        }
        case DELETE_APIKEY_FAILURE: {
            return {
                ...state,
                deleteFetching: 'failure'
            }
        }
        case GET_APIKEY_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }

        case GET_APIKEY_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
                submitSuccess:false
            };
        }

        case GET_APIKEY_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                apikey: _.get(action.payload, 'apikey[0]', initialState.apikey),
                apikeys: action.payload.apikey
            };
        }

        default:
            return state;
    }
};
