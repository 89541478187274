import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getVariablesServices = (token, agentId) =>
    axios({
        method: 'get',
        url: `${api}/v2/chatbot/bots/${agentId}/variables`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});
