import { memo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "../../../shared/helpers/toast";
import { cdnLogin } from "../../conf";
const EntryPoint = withRouter(({ changeConfigChildRoute, app, history }) => {
    const { t } = useTranslation(["notification", "login"]);

    useEffect(() => {
        //error is the field to use in the new standard error handler   
        const { showError, status, key, error } = app;

        if(error && Object.keys(error).length>0){
            if(error.statusCode >= 300){
                toast(t(error.code+"_"+error.errorKey),'error', error.statusCode)
            };
            if(error.errorKey==='user_expired_credentials'){
                localStorage.clear();
                sessionStorage.clear();
                changeConfigChildRoute();
                history.push(cdnLogin);
            };
            return;
        };  

        if (showError) {
            try {
                let statusNumber = {};

                if (typeof status === "object" && (status.hasOwnProperty("response") || status.hasOwnProperty("request"))) {
                    const { response, request } = status;
                    statusNumber = Number.isInteger(status) ? status : response ? response.status : request.status;
                } else {
                    statusNumber = status;
                }
                switch (statusNumber) {
                    case 200:
                        if (key) {
                            toast(t(key),'success',key);
                        }
                        break;
                    case 413:
                        if (key) {
                            toast(t(key),'error',key);
                        }
                        break;
                    case 405:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 410:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 412:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 400:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 406:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 503:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        break;
                    case 403:
                        if(key){
                            toast(t(key),'error',key);
                        }
                        toast(t("without_permissions"),'error',key);
                        break;
                    case 429:
                        if(key){
                            toast(t(key),'warning',key);
                        }
                        break;
                    case 401:
                        //TODO: Remove sentences inside else condition
                        //we have to make all 401 wich get into this, come in the 
                        //above validation using showGlobalErrorV2
                        if(key === "twitter_failed_authentication"){
                            toast(t(key),'error',key);
                        }else {
                            localStorage.clear();
                            sessionStorage.clear();
                            changeConfigChildRoute();
                            toast(t("without_authorization"),'error',key);
                            history.push(cdnLogin);
                        }
                        break;
                    case 404:
                        toast(t("wrong_credentials"),'error',key);
                        break;
                    case 409:
                        if (key) {
                            toast(t(key),'error',key);
                        } else {
                            toast(t("error_ocurred"),'error',key);
                        }
                        break;
                    default:
                        if (status && status.message && status.message.key) {
                            toast(t(status.message.key),'error',status.message.key);
                        } else {
                            if (typeof status === "string" ) {
                                toast(status);
                            } else {
                                toast(t("error_ocurred"),'error',key);
                            }
                        }
                        break;
                }
                
            } catch (err) {
                console.error(err);
            }
        }
    }, [app, history, changeConfigChildRoute, t]);

    return null;
});

export default memo(EntryPoint);
