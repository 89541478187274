import { createSelector } from 'reselect';

export const getAccessToken = state => state.auth.token.access;
export const getRefreshToken = state => state.auth.token.refresh;
export const getIsProviderToken = state => state.auth.token.isProviderToken;

export const accessTokenSelector = createSelector(getAccessToken, access => access);
export const refreshTokenSelector = createSelector(getRefreshToken, refresh => refresh);
export const isProviderTokenSelector = createSelector(getIsProviderToken, state => state);

export const validTokensSelector = createSelector(
    getAccessToken,
    getRefreshToken,
    (accessToken, refreshToken) => accessToken !== null && refreshToken !== null
);
