import { takeLatest, put } from 'redux-saga/effects';
import { GET_PROFILES_SEL_REQUEST, getProfilesSelFailure, getProfilesSelSuccess } from '../actions/profilesSel';
import { showGlobalError } from '../../app/actions/app';
import { profilesOptions } from '../helpers/profilesSel';

export const getProfilesWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        yield put(getProfilesSelSuccess(profilesOptions));
    } catch (err) {
        console.log(err);

        yield put(getProfilesSelFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [takeLatest(GET_PROFILES_SEL_REQUEST, getProfilesWorker)];
