import {
    GET_GENERAL_STATS_REQUEST,
    GET_GENERAL_STATS_SUCCESS,
    GET_GENERAL_STATS_FAILURE
} from '../actions/generalStats'

const initialState = {
    statistics: {},
    fetching: false,
    getGeneralStatsSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GENERAL_STATS_REQUEST: {
            return {
                ...state,
                fetching: true,
                getGeneralStatsSuccess: false
            };
        }
        case GET_GENERAL_STATS_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getGeneralStatsSuccess: true
            };
        }
        case GET_GENERAL_STATS_FAILURE: {
            return {
                ...state,
                fetching: false,
                getGeneralStatsSuccess: false
            };
        }
        default:
            return state;
    }
}