import axios from 'axios';
import { api } from '../../app/conf';

export const postLoginService = (provider, loginId, redirectUrl) => axios({
    method: 'post',
    url: `${api}/v2/login/${provider}`,
    data: {
        loginId,
        redirectUrl
    }
});

export const getLoginStatusService = (provider, loginId) => axios({
    method: 'get',
    url: `${api}/v2/login/${provider}/status/${loginId}`,
});
