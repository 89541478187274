//TODO SACAR ADMINISTRATOR
export const buildLoggedUser = user => ({
    ...user,
    lastName: user.lastName,
    profile: buildProfile(user.projects)
});

const buildProfile = (projects) => {
    if (Array.isArray(projects) && projects.length) {
        const profiles = [];

        projects.forEach(({ profile }) => {
            profiles.push(profile.code);
        });

        return profiles;
    } else {
        return ["administrator"]
    }

}