import { accessTokenSelector } from "../../auth/selectors/token";
import { deleteIntentFailure, deleteIntentSuccess, DELETE_INTENT_REQUEST, getIntentsFailure, getIntentsSuccess, GET_INTENTS_REQUEST, postIntentFailure, postIntentSuccess, POST_INTENT_REQUEST, putIntentFailure, putIntentSuccess, PUT_INTENT_REQUEST } from "../actions/intents";
import { deleteIntentServices, getIntentsServices, postIntentServices, putIntentServices } from "../services/intents";
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { showGlobalError } from "../../app/actions/app";


export const getIntentsWorker = function* ({ payload: { botId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { intents } } = yield call(getIntentsServices, token, botId);
        yield put(getIntentsSuccess(intents));
    } catch (err) {
        console.log(err);
        yield put(getIntentsFailure(err));
    }
};

export const postIntentWorker = function* ({ payload: { botId, intent: newIntent } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { intent } } = yield call(postIntentServices, token, botId, newIntent);
        yield put(postIntentSuccess(intent));
    } catch (err) {
        console.log(err);

        yield put(postIntentFailure(err));
    }
};

export const putIntentWorker = function* ({ payload: { botId, intentId, intent: newIntent } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { intent } } = yield call(putIntentServices, token, botId, intentId, newIntent);
        yield put(putIntentSuccess(intent));

    } catch (err) {
        console.log(err);
        yield put(putIntentFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteIntentWorker = function* ({ payload: { botId, id } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { intent } } = yield call(deleteIntentServices, token, botId, id);
        
        yield put(deleteIntentSuccess(intent._id));

    } catch (err) {
        console.log(err);

        yield put(deleteIntentFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(GET_INTENTS_REQUEST, getIntentsWorker),
    takeLatest(POST_INTENT_REQUEST, postIntentWorker),
    takeLatest(PUT_INTENT_REQUEST, putIntentWorker),
    takeLatest(DELETE_INTENT_REQUEST, deleteIntentWorker)
]