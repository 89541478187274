export const SET_FILTERS = 'SET_FILTERS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFilters = ({name,channels,department}) => ({
    type: SET_FILTERS,
    payload:{
        name,
        channels,
        department
    }
})

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});
