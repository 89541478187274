import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const postStatusService = (token, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/statuses/transitions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data
    })

export const putStatusService = (token, data, id) =>
    axios({
        method: 'put',
        url: `${api}/v1/statuses/transitions/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data
    })

export const deleteStatusService = (token, statusId, filters) =>
    axios({
        method: 'delete',
        url: `${api}/v1/statuses/transitions/${statusId}?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })