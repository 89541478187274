export const SHOW_GLOBAL_ERROR = 'SHOW_GLOBAL_ERROR';
export const SHOW_GLOBAL_ERROR_V2 = 'SHOW_GLOBAL_ERROR_V2';

export const showGlobalError = (showError, status, key) => ({
    type: SHOW_GLOBAL_ERROR,
    payload: {
        showError,
        status,
        key
        
    }
});

export const showGlobalErrorV2 = (error) =>({
    type: SHOW_GLOBAL_ERROR_V2,
    payload: {
        error
    }
});