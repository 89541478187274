import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loggedUserSelector } from '../../../home/selectors/loggedUser';
import { setVersion } from '../../actions/config';
import { versionSelector } from '../../selectors/config';
import Control from './Control';
import { validTokensSelector } from '../../../auth/selectors/token';

const mapStateToProps = createStructuredSelector({
    loggedUser: loggedUserSelector,
    currentVersion: versionSelector,
    token: validTokensSelector
});

const mapDispatchToProps = {
    setVersion
};

export default connect(mapStateToProps, mapDispatchToProps)(Control);