import { 
    GET_TICKETS_REASONS_REQUEST,
    GET_TICKETS_REASONS_SUCCESS,
    GET_TICKETS_REASONS_FAILURE,
} from '../actions/ticketsReasons';

const initialState = {
    ticketsReasons:[],
    fetching: false,
    success: false,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_TICKETS_REASONS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_TICKETS_REASONS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                ticketsReasons: action.payload.ticketsReasons,
            };
        }

        case GET_TICKETS_REASONS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        default:
            return state;
    }
}