
import { lazy } from "react";

const SigninLoader = lazy(() => import(/* webpackChunkName: "SigninLoader" */ './components/SigninLoader'));

const signing = {
    path: "/signin",
    Component: SigninLoader,
    exact: false,
    innerRoutes: []
};

export default signing;
