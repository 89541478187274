import {
    GET_PUBLICATIONS_REQUEST,
    GET_PUBLICATIONS_SUCCESS,
    GET_PUBLICATIONS_FAILURE,
    GET_PUBLICATION_REQUEST,
    GET_PUBLICATION_SUCCESS,
    GET_PUBLICATION_FAILURE,
    GET_PUBLICATIONS_INCOMING_REQUEST,
    GET_PUBLICATIONS_INCOMING_SUCCESS,
    GET_PUBLICATIONS_INCOMING_FAILURE,
    GET_PUBLICATION_INCOMING_REQUEST,
    GET_PUBLICATION_INCOMING_SUCCESS,
    GET_PUBLICATION_INCOMING_FAILURE,
    POST_PUBLICATION_REQUEST,
    POST_PUBLICATION_SUCCESS,
    POST_PUBLICATION_FAILURE,
    POST_PUBLICATION_COMMENT_REQUEST,
    POST_PUBLICATION_COMMENT_SUCCESS,
    POST_PUBLICATION_COMMENT_FAILURE,
    POLLING_PUBLICATIONS_SUCCESS,
    POLLING_PUBLICATIONS_INCOMING_SUCCESS
} from '../actions/publications';

const initialState = {
    fetching: false,
    publications: [],
    total: 0,
    fetchingPost: false,
    events: [],
    fetchingEvent: false,
    fetchingIncoming: false
}

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_PUBLICATIONS_REQUEST: {
            if(action.payload.polling){
                return {
                    ...state
                }
            }
            else{
                return {
                    ...state,
                    fetching: true,
                    success: false,
                }
            }
        }
        case GET_PUBLICATIONS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                publications: action.payload.publications,
                // total: (!action.payload.total || action.payload.total === 0) ? state.total : action.payload.total
            }
        }
        case GET_PUBLICATIONS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case GET_PUBLICATION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            }
        }
        
        case GET_PUBLICATION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                publication: action.payload.publication,
            }
        }

        case GET_PUBLICATION_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case GET_PUBLICATIONS_INCOMING_REQUEST: {
            if(action.payload.polling){
                return {
                    ...state
                }
            }
            else{
                return {
                    ...state,
                    fetching: true,
                    success: false,
                }
            }
 
        }
        case GET_PUBLICATIONS_INCOMING_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                publicationsIncoming: action.payload.publicationsIncoming,
                // total: (!action.payload.total || action.payload.total === 0) ? state.total : action.payload.total
            }
        }
        case GET_PUBLICATIONS_INCOMING_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case GET_PUBLICATION_INCOMING_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            }
        }
        
        case GET_PUBLICATION_INCOMING_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                publicationIncoming: action.payload.publicationIncoming,
            }
        }

        case GET_PUBLICATION_INCOMING_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            }
        }

        case POST_PUBLICATION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            }
        }
        case POST_PUBLICATION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                ids: action.payload.ids
            }
        }
        case POST_PUBLICATION_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            }
        }

        case POST_PUBLICATION_COMMENT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            }
        }
        case POST_PUBLICATION_COMMENT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                comment: action.payload.comment
            }
        }
        case POST_PUBLICATION_COMMENT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            }
        }
        case POLLING_PUBLICATIONS_SUCCESS: {
            return {
                ...state,
                publications: action.payload.publications
            }
        }
        case POLLING_PUBLICATIONS_INCOMING_SUCCESS: {
            return {
                ...state,
                publicationsIncoming: action.payload.publicationsIncoming
            }
        }
        default:
            return state;
    }
}