import { call, put, select, takeLatest } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    getTaskByChannelSuccess,
    getTaskByChannelFailure,
    GET_TASK_BY_CHANNEL_REQUEST
} from '../actions/taskByChannel';
import {
    getTaskByChannelService
} from '../services/taskByChannel';

export const getTaskByChannelWorker = function* ({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
        
        const { data } = yield call(getTaskByChannelService, token, filters);
        yield put(getTaskByChannelSuccess(data));
    }
    catch (err) {
        console.log('ERROR', err)
        yield put(getTaskByChannelFailure(err))
    };
};

export default [
    takeLatest(GET_TASK_BY_CHANNEL_REQUEST, getTaskByChannelWorker)
];