import actions from './actions';
import bots from './bots';
import intents from './intents';
import responses from './responses';
import conversation from './conversation';
import states from './states';
import transitions from './transitions';
import variables from './variables';

export default function* () {
    yield* [
        ...bots,
        ...intents,
        ...responses,
        ...actions,
        ...conversation,
        ...states,
        ...transitions,
        ...variables
    ];
}