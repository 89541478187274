import { GET_CHANNELS_REQUEST, GET_CHANNELS_SUCCESS, GET_CHANNELS_FAILURE } from '../actions/channels';
const initialState = {
    fetching: false,
    channelsOptions: []
};

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_CHANNELS_REQUEST: {
            return {
                ...state,
                fetching: true
            };
        }
        case GET_CHANNELS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                channelsOptions: action.payload.channelsOptions
            };
        }
        case GET_CHANNELS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }

        default: return state;
    }
};