import { takeLatest, put, call, select, debounce } from 'redux-saga/effects';
import { getMentionsFailure, getMentionsSuccess, GET_MENTIONS_REQUEST } from '../actions/mentions';
import {getMentionsService} from '../services/mentions';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';

export const getMentionsWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);

        const { data: { mentions, total } } = yield call(getMentionsService, token);

        yield put(getMentionsSuccess(mentions,total));
        
    } catch (error) {
        console.log(error);
        yield put(getMentionsFailure(error));
        yield put(showGlobalError(true,error));
    }
}


export default [
    takeLatest(GET_MENTIONS_REQUEST, getMentionsWorker)
]