import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getTransitionsServices = (token, agentId) =>
    axios({
        method: 'get',
        url: `${api}/v2/chatbot/bots/${agentId}/transitions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const getTransitionByIdServices = (token, agentId, transitionId) =>
    axios({
        method: 'get',
        url: `${api}/v2/chatbot/bots/${agentId}/transitions/${transitionId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const postTransitionServices = (token, agentId, transition) =>
    axios({
        method: 'post',
        url: `${api}/v2/chatbot/bots/${agentId}/transitions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:transition
});

export const putTransitionServices = (token, agentId, transitionId, transition) =>
    axios({
        method: 'put',
        url: `${api}/v2/chatbot/bots/${agentId}/transitions/${transitionId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:transition
});

export const deleteTransitionServices = (token, agentId, transitionId) =>
    axios({
        method: 'delete',
        url: `${api}/v2/chatbot/bots/${agentId}/transitions/${transitionId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });