export const FILE_VARIANT = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  QUATERNARY: "QUATERNARY",
};

export const FILE_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  FILE: "file",
  FOLDER: "folder",
  AUDIO: "audio",
};

export const FILE_STATUS = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
};
