import {
  SET_UPLOAD_FOLDER_POPUP_STATUS,
  SET_DELETE_FILE_POPUP_STATUS,
  SET_MANAGE_VERSIONS_POPUP_STATUS,
} from "../actions/popup";

const INITIAL_STATE = {
  deleteFilePopupIsOpen: false,
  uploadFolderIsOpen: false,
  manageVersionsIsOpen: false
};

const PopupState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DELETE_FILE_POPUP_STATUS: {
      return {
        ...state,
        deleteFilePopupIsOpen: action.payload.isOpen,
      };
    }
    case SET_UPLOAD_FOLDER_POPUP_STATUS: {
      return {
        ...state,
        uploadFolderIsOpen: action.payload.isOpen,
      };
    }
    case SET_MANAGE_VERSIONS_POPUP_STATUS: {
      return {
        ...state,
        manageVersionsIsOpen: action.payload.isOpen,
      };
    }

    default:
      return state;
  }
};

export default PopupState;
