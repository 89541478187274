import moment from 'moment';
import 'moment/locale/es';
import { upperCaseFirstLetter } from '../../contacts/helpers/contacts';
import { CHANNELS_AVAILABLE } from '../../channels/constants';


export const configureStatistics = (options, channel) => {
    let parsedOptions = {};
    const positivekey = channel === 'twitter' ? 'followers' : 'likes';
    const negativeKey = channel === 'twitter' ? 'followers' : 'dislikes';
    if (Object.keys(options).length > 0) {
        options.reports.forEach((report, index) => {

            parsedOptions['likes'] = parsedOptions['likes'] ? parsedOptions['likes'] + report[`${positivekey}`] : report[`${positivekey}`];

            parsedOptions['disLikes'] = parsedOptions['disLikes'] ? parsedOptions['disLikes'] + report[`${negativeKey}`] : report[`${negativeKey}`];

            parsedOptions['newLikes'] = index !== 0 && ((parsedOptions['newLikes']) ? parsedOptions['newLikes'] + (options.reports[index][`${positivekey}`] ?? 0) : options.reports[index][`${positivekey}`]);
        })
        parsedOptions['averageLikes'] = isNaN(options.averageLikes) ? 0 : options.averageLikes;
        parsedOptions['averageDislikes'] = isNaN(options.averageDislikes) ? 0 : options.averageDislikes;
    }
    return parsedOptions;
}

export const channelOptionsDefault = {
        facebook:[
            'resume', 'tipification', 'audience','interactions', 'scope',
        ],
        twitter:[
            'resume', 'tipification', 'audience', 'interactions',
        ],
        instagram:[
            'resume', 'tipification', 'audience', 'interactions',
        ],
        web:[
            'resume', 'tipification',
        ],
        general:[
            'resume', 'tipification', 'agent_sessions', 'agent_associated_task',
        ],
        voiceChannel:[
            'resume', 'tipification',
        ],
        webchat:[
            'resume', 'tipification',
        ],
        whatsapp:[
            'resume', 'tipification',
        ],
        email:[
            'resume', 'tipification',
        ]
}

export const buildStatistics = (reports) => {
    let averageLikes = 0, averageDislikes = 0;
    reports.forEach(report => {
        averageLikes += report.likes;
        averageDislikes += report.dislikes

    });
    averageLikes = Math.ceil(averageLikes / reports.length);
    averageDislikes = Math.ceil(averageDislikes / reports.length);

    return { reports, averageLikes, averageDislikes }
};

export const parseCasesStatistics = (casesStatistics) => {
    let result = { ...casesStatistics };
    let percentageOpen = 0;
    let percentageClosed = 0;
    const countOpen = casesStatistics.abierto ?? 0;
    const countClosed = casesStatistics.cerrado ?? 0;
    percentageOpen = Math.round((countOpen * 100) / (countOpen + countClosed));
    percentageClosed = percentageOpen ? 100 - percentageOpen : Math.round((countOpen * 100) / (countOpen + countClosed));
    result['percentageClosed'] = percentageClosed;
    result['percentageOpened'] = percentageOpen;
    return result

}

export const buildReport = (report) => ({
    ...report,
    likes: report.likes,
    user: report.user,
    interactions: report.interactions,
    date: report.date
});

export const formatReportsDate = (date) => {
    const today = moment();
    const duration = moment.duration(today.diff(date));
    return duration.asHours() >= 24 ? moment(date).format('DD/MM/YYYY HH:mm') : moment(date).locale('es').fromNow();
};

export const statisticsChatsIds= [
    'exportStatistics'
]

export const resizeDimensions = (widthLim,heightLim,width,height) => { 
    let result = {'width': width, 'height': height}; 

    if (width / height > widthLim / heightLim && width > widthLim) { 
        result['width'] = widthLim; 
        result['height'] = widthLim / width * height; 
    } 
    else if (height > heightLim) { 
        result['width'] = heightLim/height * width; 
        result['height'] = heightLim; 
    } 

    return result; 
}

export const getColumnsByRows = (row, by, t) => { 

    let columns = [];
    columns.push(
        {
            key: by === 'assigned' ? 'assigned.displayName' : by,
            label:upperCaseFirstLetter(t(`statistics:${by}`)),
            align:'center',
            sortable: true,
            disablePadding: false
        }
    );
    if(row){
        Object.keys(row).forEach(field=> {
            if(field!== by && field!=='total' && field!=='cierreMasivo'){
                columns.push(
                    {
                        key: field,
                        label:upperCaseFirstLetter(t(`statistics:${field}`)),
                        align:'center',
                        sortable: true,
                        disablePadding: false
                    }
                )
            }
        });
    };

    columns.push(
        {
            key: 'cierreMasivo',
            label:upperCaseFirstLetter(t('statistics:cierreMasivo')),
            align:'center',
            sortable: true,
            disablePadding: false
        }
    );  
   
    columns.push(
        {
            key: 'total',
            label:upperCaseFirstLetter(t('statistics:total')),
            align:'center',
            sortable: true,
            disablePadding: false
        }
    );    

    return columns; 
}

/**
 * Generates an array of channels to request based on the given channel.
 *
 * @param {string} channel - The channel to base the request on.
 * @return {array} An array of channels to request.
 */
export const getChannelsToRequest = (channel, channels) => {
    if(!channel || !channels) return [];
    const channelsCode = []
    
    channels.filter(chan => {
        chan.type === channel && channelsCode.push(chan.code)
    });
    
    return channelsCode;
}