import publications from './publications';
import mentions from './mentions';
import socials from './socials';

export default function* (){
    yield* [
        ...publications, 
        ...mentions,
        ...socials
    ];
}