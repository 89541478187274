export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST'
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS'
export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE'
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST'
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS'
export const GET_LOGIN_FAILURE = 'GET_LOGIN_FAILURE'
export const CLEAN_LOGIN = 'CLEAN_LOGIN'

export const postLoginRequest = (provider, loginId, redirectUrl) => ({
    type: POST_LOGIN_REQUEST,
    payload: {
        provider,
        loginId,
        redirectUrl
    }
});

export const postLoginSuccess = (login) => ({
    type: POST_LOGIN_SUCCESS,
    payload: {
        login
    }
});

export const postLoginFailure = (error) => ({
    type: POST_LOGIN_FAILURE,
    payload: {
        error
    }
});

export const getLoginRequest = (provider, loginId, history) => ({
    type: GET_LOGIN_REQUEST,
    payload: {
        provider,
        loginId,
        history
    }
});

export const getLoginSuccess = (login) => ({
    type: GET_LOGIN_SUCCESS,
    payload: {
        login
    }
});

export const getLoginFailure = (error) => ({
    type: GET_LOGIN_FAILURE,
    payload: {
        error
    }
});

export const cleanLogin = () => ({
    type: CLEAN_LOGIN
});