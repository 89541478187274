export const ticketParsesV3ToV1 = (ticket) => {

  if (!ticket || Object.keys(ticket).length === 0) return null;
  const parsedObject = {
    ...ticket,
    caseDetail: ticket.caseDetail,
    caseType: ticket.caseType,
    caseReason: ticket.caseReason,
    ...(ticket.priority && { priority: ticket.priority.name } ),
    userName: ticket.contact.userName,
    userLastName: ticket.contact.userLastName,
    userIdentification: ticket?.contact?.userIdentification,
    serviceIdentification: ticket?.contact?.serviceIdentification ,
    userEmail: ticket?.contact?.userEmail,
    phone: ticket?.contact?.phone ,
    userDisplayName: ticket?.contact?.userDisplayName ,
    userProfilePicture: ticket?.contact?.userProfilePicture ,
    userId: ticket?.contact?.userId ,
    location: ticket.location?.position ? {
      position: {
        coordinates: ticket?.location?.position?.coordinates,
        type: ticket?.location?.position?.type,
      },
      text: ticket?.location?.text,
    } :
      {
        position: null,
        text: ""
      },
    score: 0,
    tags: ticket.tags,
    project: ticket.department.id,
    department: ticket.department.name,
    _id: ticket._id,
    taskNumber: ticket.taskId,
    created: {
      user: ticket.created.user,
      username: ticket.created.username,
      date: ticket.created.date,
    },
    updated: {
      user: ticket.updated.user,
      username: ticket.updated.username,
      date: ticket.updated.date,
    },
    source: ticket.source.name,
  };

  if(Array.isArray(ticket?.assigned) && ticket?.assigned?.length){
    parsedObject.assigned = ticket.assigned[0].id
  }

  if(typeof ticket?.assigned === 'string'){
    parsedObject.assigned = ticket.assigned
  }

  if(!ticket.description){
    delete parsedObject.description;
  }

  if(ticket.resolved){
    delete parsedObject.resolved;
  }

  if(ticket?.contact?.contactId){
    parsedObject.contact = ticket.contact.contactId;
  }else{
    delete parsedObject.contact;
  }

  if(ticket?.status?.name){
    parsedObject.status = ticket?.status?.name
  }

  if( ticket.status && typeof ticket?.status === 'string'){
    parsedObject.status = ticket.status
  }

  delete parsedObject.comments;
  delete parsedObject.subtaskCount;
  delete parsedObject.subTasks;

  return parsedObject;
}