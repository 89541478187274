import { combineReducers } from 'redux';
import generalStats from './generalStats';
import filters from './filters';
import creatorsRanking from './creatorsRanking';
import responseTime from './responseTime';
import resolutionTime from './resolutionTime';
import locations from './locations';
import taskByArea from './taskByArea.js';
import tasksResolved from './tasksResolved';
import taskByChannel from './taskByChannel'
import topTagsByChannel from './topTagsByChannel';

export default combineReducers({
    generalStats,
    filters,
    creatorsRanking,
    responseTime,
    resolutionTime,
    locations,
    taskByArea,
    tasksResolved,
    taskByChannel,
    topTagsByChannel
});
