import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    GET_CHANNELS_REQUEST, 
    getChannelsFailure, 
    getChannelsSuccess,
    GET_CHANNEL_REQUEST, 
    getChannelFailure, 
    getChannelSuccess,
    POST_CHANNEL_REQUEST, 
    postChannelFailure, 
    postChannelSuccess,
    PUT_CHANNEL_REQUEST, 
    putChannelFailure, 
    putChannelSuccess,
    DELETE_CHANNEL_REQUEST, 
    deleteChannelFailure, 
    deleteChannelSuccess,
} from '../actions/channels';
import { showGlobalError } from '../../app/actions/app';
import { 
    getChannelsServices,
    getChannelServices,
    postChannelServices,
    putChannelServices,
    deleteChannelServices,
} from '../services/channels';
import { accessTokenSelector } from '../../auth/selectors/token';

export const getChannelsWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const { data : { channels } } = yield call(getChannelsServices, token, filters);

        yield put(getChannelsSuccess(channels));
    } catch (err) {
        console.log(err);

        yield put(getChannelsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getChannelWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const { data : { channel } } = yield call(getChannelServices, token, filters);

        yield put(getChannelSuccess(channel));
    } catch (err) {
        console.log(err);

        yield put(getChannelFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const postChannelWorker = function* ({ payload: { channel } }) {
    try {
        const token = yield select(accessTokenSelector);

        let connectedChannel = null;

        if(channel.channel === 'playStore'){
            connectedChannel = {
                name:'playStore',
                displayName: channel.userName
            }
        }

        if(channel.channel === 'appStore'){
            connectedChannel = {
                name:'appStore',
                displayName: channel.userName
            }
        }

        if(channel.channel != 'playStore' && channel.channel != 'appStore'){
            const { data : { channel: ch } } = yield call(postChannelServices, token, channel);
            connectedChannel = ch
        };

        yield put(showGlobalError(true, 200, `${channel.channel}_created`));

        yield put(postChannelSuccess(connectedChannel));
    } catch (err) {
        console.log(err);
        const { data: errorData } = err.response; 
        yield put(postChannelFailure(err));
        yield put(showGlobalError(true, err));
        if(errorData && errorData.error && errorData.error.statusCode && errorData.error.errorKey){
            yield put(showGlobalError(true, errorData.error.statusCode, errorData.error.errorKey));
        }
    }
};

export const putChannelWorker = function* ({ payload: { channel } }) {
    try {

        const token = yield select(accessTokenSelector);

        const { data : { channel: ch } } = yield call(putChannelServices, token, channel);

        yield put(showGlobalError(true, 200, `${channel.channel}_updated`));

        yield put(putChannelSuccess(ch));
    } catch (err) {
        console.log(err);

        yield put(putChannelFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteChannelWorker = function* ({ payload: { channel } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { channel: cD } } = yield call(deleteChannelServices, token, channel);

        yield put(showGlobalError(true, 200, `${channel.channel}_deleted`));
        
        yield put(deleteChannelSuccess(cD));
    } catch (err) {
        console.log(err);

        yield put(deleteChannelFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(GET_CHANNELS_REQUEST, getChannelsWorker),
    takeLatest(GET_CHANNEL_REQUEST, getChannelWorker),
    takeLatest(POST_CHANNEL_REQUEST, postChannelWorker),
    takeLatest(PUT_CHANNEL_REQUEST, putChannelWorker),
    takeLatest(DELETE_CHANNEL_REQUEST, deleteChannelWorker),
];