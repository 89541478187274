export const ICONS = {
  FUEL: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" width="31.25" height="24.06" viewBox="0 0 31.25 24.06"><defs><style>.cls-1 {
    fill: #b9b9b9;
  }</style></defs><title>Body-Type</title><g><path class="cls-1" d="M27.13,23.87H22.59a1.16,1.16,0,0,1-1.22-1.16V19.29h1V22.7c0,.2.23.2.25.2h4.55s.25,0,.25-.2V19.29h1V22.7A1.16,1.16,0,0,1,27.13,23.87Z"/><path class="cls-1" d="M25.42,16.65H22.31a1.94,1.94,0,0,1-1.94-1.94V13.48a1.94,1.94,0,0,1,1.94-1.94h3.11a1.94,1.94,0,0,1,1.93,1.94v1.24A1.94,1.94,0,0,1,25.42,16.65ZM22.31,12.5a1,1,0,0,0-1,1v1.24a1,1,0,0,0,1,1h3.11a1,1,0,0,0,1-1V13.48a1,1,0,0,0-1-1Z"/><path class="cls-1" d="M29.58,9.3H27.17v-1h2.41A.45.45,0,0,0,30,7.89V6.38a.45.45,0,0,0-.45-.45H25.94V5h3.64A1.41,1.41,0,0,1,31,6.38V7.89A1.41,1.41,0,0,1,29.58,9.3Z"/><path class="cls-1" d="M29.06,20.31H2.29A1.29,1.29,0,0,1,1,19V11.66a1.3,1.3,0,0,1,.3-.83L4.19,8a1.25,1.25,0,0,1,1-.42H26.21a1.29,1.29,0,0,1,1,.46L30,10.79a1.32,1.32,0,0,1,.34.87V19A1.29,1.29,0,0,1,29.06,20.31ZM5.14,8.59a.33.33,0,0,0-.25.12L2,11.48a.38.38,0,0,0,0,.18V19a.33.33,0,0,0,.33.33H29.06a.33.33,0,0,0,.33-.33V11.66a.33.33,0,0,0-.08-.21L26.5,8.74a.38.38,0,0,0-.29-.16Z"/><path class="cls-1" d="M27.22,8.59H4.14L6,1.55A1.72,1.72,0,0,1,7.67.27h16a1.72,1.72,0,0,1,1.66,1.28Zm-21.83-1H26L24.41,1.8a.75.75,0,0,0-.73-.56h-16a.75.75,0,0,0-.73.56Z"/><path class="cls-1" d="M8.77,23.87H4.22A1.16,1.16,0,0,1,3,22.7V19.29H4V22.7c0,.2.23.2.25.2H8.77s.25,0,.25-.2V19.29h1V22.7A1.16,1.16,0,0,1,8.77,23.87Z"/><path class="cls-1" d="M9,16.65H5.93A1.94,1.94,0,0,1,4,14.71V13.48a1.94,1.94,0,0,1,1.94-1.94H9A1.94,1.94,0,0,1,11,13.48v1.24A1.94,1.94,0,0,1,9,16.65ZM5.93,12.5a1,1,0,0,0-1,1v1.24a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V13.48a1,1,0,0,0-1-1Z"/><path class="cls-1" d="M4.18,9.3H1.77A1.41,1.41,0,0,1,.36,7.89V6.38A1.41,1.41,0,0,1,1.77,5H5.42v1H1.77a.45.45,0,0,0-.45.45V7.89a.45.45,0,0,0,.45.45H4.18Z"/></g></svg>`,
  TRANSMISSION: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" width="22.04" height="26.75" viewBox="0 0 22.04 26.75"><defs><style>.cls-1 {
    fill: #b9b9b9;
  }</style></defs><title>Transmission</title><g><polygon class="cls-1" points="21.85 26.5 0.22 26.5 0.22 22.94 8.07 15.78 6.59 11.82 7.53 11.46 9.25 16.06 1.22 23.38 1.22 25.5 20.85 25.5 20.85 23.38 12.95 16.11 10.39 9.93 11.32 9.55 13.83 15.63 21.85 22.94 21.85 26.5"/><path class="cls-1" d="M7.49,11.19a5.7,5.7,0,0,1-2.34-.5,5.64,5.64,0,0,1-3-3.18,5.54,5.54,0,0,1,.39-4.76l.09-.16L9.27.1l.17.06a5.63,5.63,0,0,1,3.44,3.34,5.7,5.7,0,0,1-3.36,7.33A5.76,5.76,0,0,1,7.49,11.19ZM3.34,3.39A4.55,4.55,0,0,0,3.1,7.16,4.65,4.65,0,0,0,5.56,9.78a4.73,4.73,0,0,0,6.38-5.93A4.57,4.57,0,0,0,9.28,1.16Z"/><path class="cls-1" d="M11.19,16.77a6.31,6.31,0,0,1-3-.75l.51-.86a4.9,4.9,0,0,0,4.71,0L14,16A5.39,5.39,0,0,1,11.19,16.77Z"/></g></svg>`,
  ODOMETER: `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" width="27.29" height="21.5" viewBox="0 0 27.29 21.5"><defs><style>.cls-1 {
    fill: #b9b9b9;
  }</style></defs><title>Odometre</title><g><rect class="cls-1" x="13.15" y="0.69" width="1" height="2.95"/><rect class="cls-1" x="23.69" y="13.18" width="2.95" height="1"/><rect class="cls-1" x="0.66" y="13.18" width="2.95" height="1"/><rect class="cls-1" x="20.32" y="5.04" width="2.95" height="1" transform="translate(2.47 17.03) rotate(-45)"/><rect class="cls-1" x="5.01" y="4.06" width="1" height="2.95" transform="translate(-2.3 5.52) rotate(-45)"/><rect class="cls-1" x="11.28" y="5.23" width="1" height="6.41" transform="translate(-2.15 4.45) rotate(-19.66)"/><path class="cls-1" d="M24.81,21.27H2.5L2.35,21A13.49,13.49,0,1,1,25,21ZM3,20.27H24.26A12.49,12.49,0,1,0,3,20.27Z"/><path class="cls-1" d="M13.65,16.54a2.86,2.86,0,1,1,2.86-2.86A2.86,2.86,0,0,1,13.65,16.54Zm0-4.72a1.86,1.86,0,1,0,1.86,1.86A1.86,1.86,0,0,0,13.65,11.82Z"/></g></svg>`,
  LOGO: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img">
  
  <path data-name="layer1" d="M56 38a5.9 5.9 0 0 0-4.5 2c-1.4 1.4-5.5 2-5.5-2V18H30c-4 0-3.4-4.1-2-5.5a6 6 0 1 0-8 0c1.4 1.4 2 5.5-2 5.5H2v8c0 4 4.1 3.4 5.5 2a5.9 5.9 0 0 1 4.5-2 6 6 0 1 1-4.5 10C6.1 34.6 2 34 2 38v24h24c4 0 3.4-4.1 2-5.5a5.9 5.9 0 0 1-2-4.5 6 6 0 1 1 10 4.5c-1.4 1.4-2 5.5 2 5.5h8V50c0-4 4.1-3.4 5.5-2A6 6 0 1 0 56 38z" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
</svg>
  `,
  CHILE_AUTOS: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <rect width="24" height="24" rx="12" fill="black"/>
  <g clip-path="url(#clip0_4296_22440)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.10938 11.0421L6.21787 11.3429C6.41511 11.3556 6.39411 11.2209 6.77798 10.8978C7.61504 10.1931 8.00264 9.90415 9.07418 9.43385C9.33588 9.31896 9.8178 9.16837 10.1065 9.10409C12.2984 8.6161 14.2497 9.2458 15.9412 10.6288C16.0679 10.7324 16.0745 10.6806 16.4023 10.7124C17.3859 10.808 17.6543 10.8992 18.5843 11.1727C19.1662 11.3438 19.8323 11.7029 20.3221 12.0778C20.4183 12.1514 20.4216 12.1794 20.5395 12.2419C20.6579 12.1624 20.6814 12.0909 20.7778 11.9896L21.0024 11.7238L20.5825 11.3808C20.2492 11.1401 19.9525 10.9768 19.5866 10.7964C19.0074 10.5107 17.8276 10.1565 17.1647 10.0856C16.8515 10.0521 16.8531 10.0309 16.4551 10.0052C16.2318 9.99086 15.9025 9.68513 15.6252 9.50001C15.5706 9.46359 15.5464 9.45407 15.4901 9.4172C14.6152 8.84425 13.0249 8.23372 11.9606 8.23372C11.3009 8.23372 11.1527 8.20897 10.4976 8.296C9.08365 8.48378 7.70556 9.20407 6.60393 10.0964C6.53149 10.1551 6.47589 10.1931 6.41103 10.2577C5.97462 10.6927 6.06274 10.5969 5.72469 10.5779C5.10328 10.5427 4.09522 10.3936 3.46298 10.3605C3.29768 10.3518 3.23528 10.2994 3.19757 10.3921C3.17987 10.4358 3.10938 10.9709 3.10938 11.0421Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4983 13.6871C11.4983 13.4638 11.5836 13.2958 11.695 13.1752C12.1239 12.7102 12.8872 13.0205 12.8872 13.6871C12.8872 14.2656 12.1669 14.6452 11.7081 14.1859C11.6008 14.0785 11.4983 13.8895 11.4983 13.6871ZM11.0898 13.6325C11.0898 13.9745 11.1578 14.1883 11.3685 14.417C11.6876 14.7632 11.9426 14.7505 12.3154 14.7505C12.5503 14.7505 12.7484 14.5792 12.86 14.4505C12.86 14.5952 12.8613 14.6116 12.8872 14.7233H13.2685V12.651C12.7953 12.651 12.86 12.6156 12.86 12.8691C12.7195 12.7749 12.6716 12.6494 12.366 12.5999C11.6517 12.4841 11.0898 13.0185 11.0898 13.6325Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80824 12.9512C9.96579 12.9512 10.1644 13.0485 10.252 13.134C10.3558 13.2353 10.4577 13.3615 10.4619 13.5511H9.23631C9.054 13.5511 9.28744 13.2136 9.33851 13.1626C9.4317 13.0696 9.63676 12.9512 9.80824 12.9512ZM8.74609 13.6601C8.74609 13.9881 8.87629 14.2115 9.01738 14.3974C9.35659 14.8442 10.1281 14.8601 10.4828 14.5263C10.538 14.4743 10.6259 14.3916 10.6684 14.3303C10.7548 14.2056 10.786 14.1617 10.7887 14.0419C10.3523 14.0419 10.4393 14.0116 10.3294 14.1546C10.2846 14.213 10.2671 14.2279 10.202 14.2724C9.73935 14.5876 9.21291 14.2248 9.18187 13.851H10.8704C10.8704 13.4311 10.8697 13.204 10.5374 12.8756C10.3969 12.7368 10.0597 12.554 9.78802 12.576C9.51706 12.5981 9.23572 12.7064 9.0516 12.9025C8.90066 13.0635 8.74609 13.3597 8.74609 13.6601Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34375 12.0245V14.642C5.34375 14.7631 5.47088 14.7238 5.7523 14.7238V13.7149C5.7523 12.9818 6.22682 12.8381 6.54225 13.0058C6.87927 13.1851 6.78719 13.7133 6.78719 14.2057C6.78719 14.8157 6.68954 14.7238 7.19574 14.7238V13.3059C7.19574 13.0873 7.05293 12.8899 6.9304 12.7809C6.66855 12.5481 6.3165 12.518 5.99368 12.6478C5.86699 12.6987 5.846 12.7523 5.7523 12.8152V11.9426C5.47088 11.9426 5.34375 11.9033 5.34375 12.0245Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6799 14.3966C17.3645 14.3966 16.9991 14.0877 16.9991 13.7694C16.9991 13.532 16.9807 13.4152 17.1405 13.2294C17.6869 12.594 18.388 13.1965 18.388 13.5514C18.388 13.8193 18.3955 13.9856 18.1641 14.1996C18.0427 14.3119 17.8543 14.3966 17.6799 14.3966ZM17.571 14.7511C17.8529 14.7511 17.9408 14.775 18.18 14.6518C18.5019 14.486 18.7965 14.1359 18.7965 13.7967C18.7965 13.4153 18.8147 13.328 18.5521 12.9781C18.2137 12.527 17.3849 12.4223 16.8961 12.9029C16.1518 13.635 16.814 14.7511 17.571 14.7511Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5117 13.8511C13.5117 14.121 13.5743 14.3298 13.6971 14.4834C13.9447 14.793 14.6266 14.8795 14.9551 14.5328C14.9551 14.7568 14.914 14.7236 15.3364 14.7236V12.6514H14.9279V13.7692C14.9279 13.9607 14.894 14.1063 14.7995 14.2134C14.6425 14.3914 14.4439 14.4335 14.2084 14.3533C14.1209 14.3236 14.1039 14.3047 14.0483 14.2409C13.9667 14.147 13.9203 14.0172 13.9203 13.8511V12.6514H13.5117V13.8511Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13.6601C3 13.9888 3.09732 14.216 3.26334 14.4053L3.5279 14.6312C3.86246 14.8326 4.43385 14.7946 4.75194 14.5144C4.75959 14.5076 4.79361 14.4768 4.79497 14.4755C4.79899 14.4717 4.80469 14.466 4.80864 14.462L4.94977 14.3033C4.99681 14.2347 5.06213 14.1066 5.06978 14.0146H4.63407C4.57109 14.1087 4.52936 14.2035 4.42905 14.2728C3.72548 14.7591 3.06953 13.7527 3.5918 13.162C3.92162 12.7889 4.52955 12.9682 4.63407 13.3601H5.06978C5.06167 12.9935 4.54686 12.5967 4.14381 12.5967H4.00767C3.43849 12.5967 3 13.0862 3 13.6601Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9565 13.1419C18.9565 13.4838 19.0843 13.5876 19.4075 13.7265C19.7623 13.8791 19.9913 13.8944 19.9913 14.178C19.9913 14.3652 19.5248 14.5179 19.3019 14.2684C19.1053 14.0482 19.4438 14.0417 18.8203 14.0417C18.8203 14.391 19.1319 14.7507 19.474 14.7507H19.7735C20.2841 14.7507 20.754 13.92 19.9379 13.55C19.8293 13.5009 19.7343 13.4597 19.6136 13.4112C19.095 13.2025 19.4232 12.9511 19.5828 12.9511C19.7635 12.9511 19.9016 12.955 19.9641 13.2238C20.1777 13.2238 20.5696 13.332 20.2373 12.8684C19.8697 12.3554 18.9565 12.5628 18.9565 13.1419Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7727 12.6516H15.5275C15.5275 13.1019 15.4623 13.0333 15.8544 13.0333V14.7238C16.1357 14.7238 16.2629 14.7632 16.2629 14.642V13.0333C16.655 13.0333 16.5897 13.1019 16.5897 12.6516H16.2629C16.2629 11.8222 16.3537 11.9426 15.9361 11.9426C15.713 11.9426 15.9957 12.6516 15.7727 12.6516Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11719 14.7236H8.52568V11.9424H8.11719V14.7236Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.46484 14.7236H7.87336V12.624H7.46484V14.7236Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.46484 12.406H7.87336V11.9424H7.46484V12.406Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_4296_22440">
  <rect width="18" height="18" fill="white" transform="translate(3 2)"/>
  </clipPath>
  </defs>
  </svg>`,
  MERCADO_LIBRE: `<svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" data-name="Capa 1" viewBox="0 0 512 512">
  <defs><style>.cls-m10{fill:#f9d002}.cls-m4{fill:#fff}</style></defs><circle class="cls-m10" cx="256" cy="256" 
  r="255.5"/>
  <path d="M396.6 153.62a26 26 0 0 0 6.21-3.71c.87-.67 1.79-1.38 2.82-2.08a5 5 0 0 0 .25-8.13 17.7 17.7 0 0 0-10.36-4.1c2.5-3.33 3.47-6.48 3-9.58s-2.36-5.77-5.62-8c-2.18-1.52-4.37-3-6.57-4.49-3.65-2.45-7.31-4.9-10.81-7.56a14.84 14.84 0 0 0-9-3.42c-3.73 0-7.22 1.72-10.65 5.24-6.39 6.59-12.93 13-19.52 19.39a305.87 305.87 0 0 0-164.88.32c-4.53-4.61-8.86-9.47-13.46-13.89l-7.29-7c-2.56-2.52-7-3.83-10-3.83a8.46 8.46 0 0 0-4.42 1.12c-6.78 4.11-13.26 8.69-19.52 13.13l-6.53 4.6a5 5 0 0 0-1.55 6.53c1.44 2.64 2.87 5.08 4.33 7.26h-.2c-3.91 0-7.65 1.65-11.12 4.89a5 5 0 0 0 .62 7.84c1 .65 1.81 1.3 2.61 1.92a21.59 21.59 0 0 0 6.57 3.9l.45.14c-43.16 26.67-70.78 64.84-70.78 107.72 0 80.5 95.57 145.76 213.47 145.76s213.46-65.26 213.46-145.76c-.05-43.2-27.96-81.53-71.51-108.21Z" style="fill:#283483"/><path class="cls-m10" d="M424.69 321.07c-7.09 5.75-13.87 11.95-21.33 17.17-29.71 20.75-63.09 32.43-98.61 37.92-54.36 8.41-107.68 4.65-159.24-15.83a192.39 192.39 0 0 1-49.76-28.5A48.18 48.18 0 0 1 84 318.56c2.85.36 5.75.51 8.55 1.12 45.73 10.09 91.73 8.74 137.83 3.31 9.12-1.08 17.33-4 23.35-12.84 10.82 13.31 26 13.33 40.72 14.53 34.77 2.82 69.53 4 104.13-2.06 8.31-1.46 16.6-3 24.9-4.53.38.99.79 1.99 1.21 2.98ZM254 278.72c-13-30.21-26.09-60.5-39-90.85-8.55-20-18.77-39-35.76-56.4 50.52-12.61 98.93-12.13 147.89-.64-.43 1.51-.41 2.72-1 3.35-15.86 16.85-26.07 37.08-34.91 58.15q-17.94 42.72-36 85.39c-.22.54-.99.85-1.22 1ZM455.94 243.92c-44.19-6.37-83.32 6.56-121.79 19.81 0-21.42-.22-41.93.15-62.42.09-5.23 1.93-10.44 3.13-15.62 2.47-10.63 6-18.79 20.13-17.82 6.78.47 14.06-4.3 20.91-7.21 8.56-3.63 15.92-3.21 23.85 2.57 22.33 16.27 41 35.22 49.65 62.22 1.66 5.2 2.34 10.72 3.97 18.47ZM173.66 262.87c-19.47-5.52-38.95-12.65-59.07-16.28s-40.69-3.7-62.8-5.47c2.16-7.53 3.7-15.47 6.69-22.82 10.6-26.09 30.12-44.38 53.52-58.89 2.38-1.48 6.16-1.79 9-1.19 4.81 1 9.35 3.23 14 4.83 5.36 1.83 10.94 5.42 16.11 4.93 12.8-1.21 16.73 6.1 19 16.23 1.22 5.53 3.27 11 3.38 16.61.41 20.51.17 41.02.17 62.05Z"/>
  <path class="cls-m4" d="M72.17 310.6c-12.32-15.4-21-34.25-21.27-57.76a169.4 169.4 0 0 1 69.76 3.25c22.49 5.83 44.55 13.35 66.7 20.09-6.06-21.58-4.38-44.71-5-67.65a96.13 96.13 0 0 0-9.72-40.05c-2.63-5.4-6.07-8.24-12.49-7.57a32.07 32.07 0 0 1-11.52-1.31q-17.9-4.89-35.6-10.51c-2.61-.83-4.76-3.09-7.9-5.21 5.93-5.53 10.66-3.44 14.87-1.24 5.88 3.07 11.35 5.06 18.13 2.16 2-.86 5.75 1.25 8.21 2.78 3.13 2 5.77 4.7 9.42 6.37-3.58-9.28-15.92-13-14.78-24l-9.5-2.95-1.48 5.21 10.79 5c-12.11 8.32-18.37 5.61-27.73-11.58 8.41-5.85 16.84-12.14 25.79-17.56 1.79-1.09 6.5.22 8.32 2 20 19.56 41.37 37.86 52.62 64.86 13.81 33.1 29.17 65.54 43.27 98.53 3.13 7.33 4.38 15.64 5.47 23.64s-3.71 13.42-10.95 16.37a35 35 0 0 1-9.25 2.42c-45.11 5-90.06 5.13-134.76-4.22-5.98-1.21-12.34-.67-21.4-1.07ZM368 137.35l9.67-5.3-1.17-4.15-8.15 1.58c-2.35 4.46-4 9-6.85 12.57-3 3.72-7.19 6.49-10.86 9.68l2.19 2.68c2.58-1.87 5.61-3.35 7.64-5.71 3.48-4 7.21-5.73 12.13-3.24s8.78.47 13.06-1.62c4.86-2.37 9.93-5.79 17.19-.16-3.36 2.27-5.7 4.71-8.53 5.63-11.1 3.61-22.31 6.87-33.56 10-4 1.11-8.44 2.27-12.49 1.82-7-.79-10.38 2.46-13.14 8.2a91.29 91.29 0 0 0-9.3 36.94c-.33 10.66 0 21.34-.64 32-.71 12.67-2.08 25.31-3.24 39 42.86-18 86.84-31.14 135-24.57-.18 23.33-8.86 42.17-20.84 57.35-9.95.84-18 .75-25.7 2.3-43.36 8.69-86.93 8.28-130.58 3.45-17-1.88-22.82-10.63-19.46-27.28a69.55 69.55 0 0 1 4.37-14.21C280 239.44 295.53 204.76 310.9 170c6.29-14.21 15.47-26.25 26.59-37q11.14-10.73 21.95-21.78c4.14-4.26 8.06-5.08 13-1.32 5.66 4.29 11.7 8.07 17.52 12.15 4.33 3 4.74 6.29 1.34 10.71-7.51 9.87-11.66 11.37-23.3 4.59Z"/>
  </svg>`,
};
