import { takeLatest, put, call, select } from 'redux-saga/effects';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';
import { submitBotsFailure } from '../../bots/actions/bots';
import { 
    getRulesSuccess, 
    getRulesFailure, 
    GET_RULES_REQUEST,
    SUBMIT_RULE_REQUEST,
    submitRuleSuccess,
    submitRuleFailure,
    putRuleSuccess,
    putRuleFailure,
    PUT_RULE_REQUEST,
    deleteRuleFailure,
    deleteRuleSuccess,
    DELETE_RULE_REQUEST,
    postAffectedTasksSuccess,
    postAffectedTasksFailure, 
    POST_AFFECTED_TASKS_REQUEST
} from '../actions/rules';
import { deleteRulesService, getRulesService, postAffectedTasksService, postRulesService, putRulesService } from '../services/rules';

export const getRulesWorker = function* ({ payload: { filters } }){
    try {
        const token = yield select(accessTokenSelector);

        const { data: {rules} }  = yield call(getRulesService, token, filters);

        yield put(getRulesSuccess(rules));
        
    } catch (error) {
        console.log(error);
        yield put(getRulesFailure(error));
        yield put(showGlobalError(true,error));
    }
}

export const postRulesWorker = function* ({ payload: {rule}}){
    try {

        const token = yield select(accessTokenSelector);
        const { data }  = yield call(postRulesService, token, rule);

        yield put(submitRuleSuccess(data));
        
    } catch (error) {
        console.log(error);
        yield put(submitRuleFailure(error));
        yield put(showGlobalError(true,error));
    }
}

export const putRulesWorker = function* ({ payload: {rule}}){
    try {
        const token = yield select(accessTokenSelector);
        const { data }  = yield call(putRulesService, token, rule);

        yield put(putRuleSuccess(data));
        
    } catch (error) {
        console.log(error);
        yield put(putRuleFailure(error));
        yield put(showGlobalError(true,error));
    }
}

const deleteRulesWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteRulesService, token, id);
        yield put(deleteRuleSuccess());
        yield put(showGlobalError(true, 200, 'rule_deleted'));

    } catch(err) {
        console.log(err)
        yield put(deleteRuleFailure(err));
        yield put(showGlobalError(true, err));

    }
}

const postAffectedTasksWorker = function* ({ payload: { rule } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data }  = yield call(postAffectedTasksService, token, rule);
        yield put(postAffectedTasksSuccess(data));
    } catch(err) {
        console.log(err)
        yield put(postAffectedTasksFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export default [
    takeLatest(GET_RULES_REQUEST, getRulesWorker),
    takeLatest(SUBMIT_RULE_REQUEST, postRulesWorker),
    takeLatest(PUT_RULE_REQUEST, putRulesWorker),
    takeLatest(DELETE_RULE_REQUEST, deleteRulesWorker),
    takeLatest(POST_AFFECTED_TASKS_REQUEST, postAffectedTasksWorker)
]