import {
    SET_PROFILE,
    SET_PROFILES,
    SUBMIT_PROFILE_REQUEST,
    SUBMIT_PROFILE_SUCCESS,
    SUBMIT_PROFILE_FAILURE,
    GET_PROFILES_REQUEST,
    GET_PROFILES_FAILURE,
    GET_PROFILES_SUCCESS,
    SET_PROFILES_LIMIT
} from '../actions/profiles';

const initialState = {
    fetching: false,
    profile: {},
    profiles: [],
    submmitFetching: false,
    submitSuccess: false,
    page:0,
    limit:10
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_PROFILE_REQUEST:{
            return{
                ...state,
                submmitFetching: true,
                submitSuccess: false
            }
        }
        case SUBMIT_PROFILE_SUCCESS:{
            return{
                ...state,
                submmitFetching: false,
                submitSuccess: true
            }
        }
        case SUBMIT_PROFILE_FAILURE:{
            return{
                ...state,
                submmitFetching: false,
                submitSuccess: false
            }
        }
        case GET_PROFILES_REQUEST:{
            return{
                ...state,
                fetching: true,
                submitSuccess: false
            }
        }
        case GET_PROFILES_SUCCESS:{
            return{
                ...state,
                fetching: false,
                profiles: action.payload.profiles
            }
        }
        case GET_PROFILES_FAILURE:{
            return{
                ...state,
                fetching: false
            }
        }
        case SET_PROFILE:{
            return{
                ...state,
                profile: action.payload.profile
            }
        }
        case SET_PROFILES:{
            return{
                ...state,
                profiles: action.payload.profiles
            }
        }
        case SET_PROFILES_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }         
        default:
            return state;
    }
}