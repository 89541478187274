import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  GET_TICKETS,
  REFRESH_TICKETS,
  GET_TICKETS_SCORE_REQUEST,
  getTicketsSuccess,
  getTicketsFailure,
  GET_TICKET,
  getTicket,
  getTicketSuccess,
  getTicketFailure,
  POST_TICKET,
  postTicketFailure,
  postTicketSuccess,
  PUT_TICKET,
  putTicketFailure,
  putTicketSuccess,
  getTicketsScoreFailure,
  getTicketsScoreSuccess,
  putTicketsScoreSuccess,
  putTicketsScoreFailure,
  PUT_TICKET_ASSIGNED_REQUEST,
  putTicketAssignedFailure,
  putTicketAssignedSuccess,
  putTicketAreaSuccess,
  putTicketAreaFailure,
  PUT_TICKET_AREA_REQUEST,
  GET_TAGS_REQUEST,
  getTagsSuccess,
  getTagsFailure,
  POST_TAGS_REQUEST,
  postTagsFailure,
  postTagsSuccess,
  PUT_TICKETS_SCORE_REQUEST,
  GET_FOLLOWING_STATES_REQUEST,
  getFollowingStatesSuccess,
  getFollowingStatesFailure,
  getFollowingStatesRequest,
  GET_TICKET_HISTORY_REQUEST,
  getTicketHistorySuccess,
  getTicketHistoryFailure,
  putMassiveCloseTicketsSuccess,
  putMassiveCloseTicketsFailure,
  PUT_MASSIVE_CLOSE_TICKETS_REQUEST,
  GET_TICKET_BY_PHONE_REQUEST,
  getTicketByPhoneSuccess,
  getTicketByPhoneFailure,
  GET_TICKET_FIRST_TIME,
  getReportSuccess,
  getReportFailure,
  GET_REPORT_REQUEST,
  putTicketFetchingSuccess,
} from "../actions/tickets";
import { showGlobalError, showGlobalErrorV2 } from "../../app/actions/app";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
  getTicketsService,
  getTicketService,
  postTicketService,
  putTicketService,
  putStatusTicketService,
  getTicketsScore,
  putTicketsScore,
  putTicketAssignedService,
  putTicketAreaService,
  getTagsService,
  postTagsService,
  getFollowingStatesService,
  getTicketHistoryService,
  putMassiveCloseTicketsService,
  getReportService,
  getTicketServiceV3,
  getTicketsServiceV1,
} from "../services/tickets";
import { ticketParsesV3ToV1 } from "../helpers/parsers";

export const getTicketsWorker = function* ({ payload: { filters } }) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { tasks: tickets, total: totalItems },
    } = yield call(getTicketsService, token, filters);
    yield put(getTicketsSuccess([...tickets], totalItems));
  } catch (err) {
    console.log(err);
    yield put(getTicketsFailure(err));
    yield put(
      showGlobalError(
        true,
        err.response?.data?.error?.code,
        err.response?.data?.error?.errorKey
      )
    );
  }
};

export const getTicketWorker = function* ({ payload: { ticketId } }) {
  try {
    const token = yield select(accessTokenSelector);

    const { data: { task } } = yield call(getTicketServiceV3, token, ticketId);

    yield put(getTicketSuccess({ ...task }));

    // if (task?.status?.statusId) {
    //   yield put(getFollowingStatesRequest(task.status.statusId));
    // }
  } catch (err) {
    yield put(getTicketFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export const postTicketWorker = function* ({ payload: { ticket, tags } }) {
  try {
    const token = yield select(accessTokenSelector);

    const { data } = yield call(postTicketService, token, ticket);

    if (data._id && tags && tags.length > 0) {
      yield call(postTagsService, token, tags, data._id);
    }
    yield put(postTicketSuccess(data._id));
    yield put(showGlobalError(true, 200, "ticket_created"));
  } catch (err) {
    yield put(postTicketFailure({}));
    yield put(
      showGlobalError(
        true,
        err.response?.data?.error?.code,
        err.response?.data?.error?.errorKey
      )
    );
  }
};

export const putTicketWorker = function* ({
  payload: { ticket, type, status, score, ticketId },
}) {
  try {
    let newStatus;
    const token = yield select(accessTokenSelector);

    const parsedV1Ticket = ticketParsesV3ToV1(ticket);

    if (status) {
      yield call(putStatusTicketService, token, parsedV1Ticket, status);
      newStatus = status;
    } else {
      const { data: task } = yield call(putTicketService, token, parsedV1Ticket);
      newStatus = task.status.crmId;
    }

    yield put(putTicketFetchingSuccess());
    yield put(getFollowingStatesRequest(newStatus));
  } catch (err) {
    yield put(putTicketFailure({ type }));

    if(err.response?.data?.error?.errorKey === 'subtasks_not_closed'){
      return yield put(showGlobalErrorV2(err.response?.data?.error));
    }
    yield put(
      showGlobalError(
        true,
        err.response?.data?.error?.code,
        err.response?.data?.error?.errorKey
      )
    );
  }
};

export const putTicketAssignedWorker = function* ({ payload: { ticket } }) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { status: newStatus },
    } = yield call(putTicketAssignedService, token, ticket._id, ticket);

    yield put(putTicketAssignedSuccess(ticket, newStatus));
  } catch (err) {
    yield put(putTicketAssignedFailure(err));
  }
};

export const putTicketAreaWorker = function* ({
  payload: { idTicket, project },
}) {
  try {
    const token = yield select(accessTokenSelector);

    const { data } = yield call(putTicketAreaService, token, idTicket, project);

    yield put(showGlobalError(true, 200, "notification:derived_area"));
    yield put(putTicketAreaSuccess(data));
  } catch (err) {
    yield put(putTicketAreaFailure(err));
  }
};

export const getTagsWorker = function* ({ payload: { ticketId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const { data } = yield call(getTagsService, token, ticketId);
    yield put(getTagsSuccess(data));
  } catch (err) {
    yield put(getTagsFailure(err));
    yield put(showGlobalError(true, err));
  }
};
export const postTagsWorker = function* ({ payload: { ticketId, tags } }) {
  try {
    const token = yield select(accessTokenSelector);
    yield call(postTagsService, token, tags, ticketId);
    yield put(postTagsSuccess());
  } catch (err) {
    yield put(postTagsFailure(err));
    yield put(showGlobalError(true, err));
  }
};
export const putTicketScoreWorker = function* ({
  payload: { ticketId, score },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const newScore = { score };
    yield call(putTicketsScore, token, newScore, ticketId);
    yield put(putTicketsScoreSuccess());
  } catch (err) {
    yield put(putTicketsScoreFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export const getFollowingStatesWorker = function* ({ payload: { state } }) {
  try {
    const token = yield select(accessTokenSelector);

    const { data } = yield call(getFollowingStatesService, token, state);
    yield put(getFollowingStatesSuccess(data));
  } catch (err) {
    console.log(err);
    yield put(getFollowingStatesFailure(err));
  }
};

export const getTicketHistoryWorker = function* ({ payload: { idTicket } }) {
  try {
    const token = yield select(accessTokenSelector);

    const {
      data: { items },
    } = yield call(getTicketHistoryService, token, idTicket);
    yield put(getTicketHistorySuccess(items));
  } catch (err) {
    console.log(err);
    yield put(getTicketHistoryFailure(err));
  }
};

export const putMassiveCloseTicketsWorker = function* ({
  payload: { ticketIds, tags, comment,commentType, destinationStatus },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const { data } = yield call(
      putMassiveCloseTicketsService,
      token,
      ticketIds,
      tags,
      comment,
      commentType,
      destinationStatus
    );
    yield put(putMassiveCloseTicketsSuccess(data)); 
  } catch (err) {
    let error = err;
    if (err.response) error = err.response.data;
    yield put(putMassiveCloseTicketsFailure(error));
  }
};

export const getTicketByPhoneWorker = function* ({ payload: { phone } }) {
  try {
    const token = yield select(accessTokenSelector);
    const { data } = yield call(getTicketsServiceV1, token, phone);
    yield put(getTicketByPhoneSuccess(data.tasks));
  } catch (err) {
    console.log(err);
    yield put(getTicketByPhoneFailure());
  }
};

export const getReportWorker = function* ({
  payload: { dateFromReport, dateToReport },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const filters = {
      dateFrom: dateFromReport,
      dateTo: dateToReport,
    };
    yield put(showGlobalError(true, 200, "tickets:waiting_report_description"));
    const { data } = yield call(getReportService, token, filters);
    yield put(getReportSuccess(data.reportUrl));
  } catch (err) {
    console.log(err);
    yield put(getReportFailure());
    yield put(showGlobalError(true, 500));
  }
};

export default [
  takeLatest(GET_TICKETS, getTicketsWorker),
  takeLatest(REFRESH_TICKETS, getTicketsWorker),
  takeLatest(GET_TICKET, getTicketWorker),
  takeLatest(GET_TICKET_FIRST_TIME, getTicketWorker),
  takeLatest(POST_TICKET, postTicketWorker),
  takeLatest(PUT_TICKET, putTicketWorker),
  takeLatest(GET_TICKETS_SCORE_REQUEST, getTicketWorker),
  takeLatest(PUT_TICKET_ASSIGNED_REQUEST, putTicketAssignedWorker),
  takeLatest(PUT_TICKET_AREA_REQUEST, putTicketAreaWorker),
  takeLatest(GET_TAGS_REQUEST, getTagsWorker),
  takeLatest(POST_TAGS_REQUEST, postTagsWorker),
  takeLatest(PUT_TICKETS_SCORE_REQUEST, putTicketScoreWorker),
  takeLatest(GET_FOLLOWING_STATES_REQUEST, getFollowingStatesWorker),
  takeLatest(GET_TICKET_HISTORY_REQUEST, getTicketHistoryWorker),
  takeLatest(PUT_MASSIVE_CLOSE_TICKETS_REQUEST, putMassiveCloseTicketsWorker),
  takeLatest(GET_TICKET_BY_PHONE_REQUEST, getTicketByPhoneWorker),
  takeLatest(GET_REPORT_REQUEST, getReportWorker),
];
