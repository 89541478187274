import { call, put, select, takeLatest } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    getTaskByAreaSuccess,
    getTaskByAreaFailure,
    GET_TASK_BY_AREA_REQUEST
} from '../actions/taskByArea';
import {
    getTaskByAreaService
} from '../services/taskByArea';

export const getTaskByAreaWorker = function* ({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
        
        const { data } = yield call(getTaskByAreaService, token, filters);
        yield put(getTaskByAreaSuccess(data));
    }
    catch (err) {
        console.log('ERROR', err)
        yield put(getTaskByAreaFailure(err))
    };
};

export default [
    takeLatest(GET_TASK_BY_AREA_REQUEST, getTaskByAreaWorker)
];