export const METHODS = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
};

export const VERSION = {
  VERSION_1: "/v1",
  VERSION_2: "/v2",
  VERSION_3: "/v3",
};
