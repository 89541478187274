export const SET_INTEGRATIONS = "SET_INTEGRATIONS";
export const SET_SELECTED_INTEGRATION = "SET_SELECTED_INTEGRATION";

export const setIntegrations = (integrations) => ({
  type: SET_INTEGRATIONS,
  payload: {
    integrations,
  },
});

export const setSelectedIntegration = (selectedIntegration) => ({
  type: SET_SELECTED_INTEGRATION,
  payload: {
    selectedIntegration,
  },
});
