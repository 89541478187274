import React from "react";
import { ThreeDots } from "react-loader-spinner";

const ThreeDotsSpinner = ({ visible = true, color ="#7a7a7a", width=40, height=40 }) => {
    return (
        <ThreeDots 
            height={height} 
            width={width} 
            radius={9}
            color={color}
            visible={visible}
        />
    )
};

export default ThreeDotsSpinner;