import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getMentionsService = (token) => {
    axios({
        method: 'get',
        url: `${api}/v1/mentions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })
}