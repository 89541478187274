import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_WEBCHAT_REQUEST,
    postWebchatSuccess,
    postWebchatFailure,
    DELETE_WEBCHAT_REQUEST,
    deleteWebchatSuccess,
    deleteWebchatFailure,
} from '../actions/webchatChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postWebchatServices,
    deleteWebchatServices,
} from '../services/webchatChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postWebchatWorker = function* ({ payload: { webchat } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { webchat: tB } } = yield call(postWebchatServices, token, webchat);

        yield put(showGlobalError(true, 200, 'webchat_created'));

        yield put(postWebchatSuccess(tB));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'webchat_already_exists'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postWebchatFailure(err));
    }
};

export const deleteWebchatWorker = function* ({ payload: { webchatDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { tBD } } = yield call(deleteWebchatServices, token, webchatDeleted);

        yield put(showGlobalError(true, 200, 'webchat_deleted'));

        yield put(deleteWebchatSuccess(tBD));
    } catch (err) {
        console.log(err);

        yield put(deleteWebchatFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_WEBCHAT_REQUEST, postWebchatWorker),
    takeLatest(DELETE_WEBCHAT_REQUEST, deleteWebchatWorker),
];