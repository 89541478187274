import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loggedUserSelector } from '../../../../../home/selectors/loggedUser';
import { validTokensSelector } from '../../../../../auth/selectors/token';
import LoggedUser from './LoggedUser';

const mapStateToProps = createStructuredSelector({
    loggedUser: loggedUserSelector,
    validTokens: validTokensSelector
});

export default connect(mapStateToProps)(LoggedUser);