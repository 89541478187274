import { combineReducers } from 'redux';
import channels from './channels';
import channelsLogins from './channelsLogins';
import twitterChannel from './twitterChannel';
import telegramChannel from './telegramChannel';
import instagramChannel from './instagramChannel';
import linkedInChannel from './linkedInChannel';
import whatsAppChannel from './whatsAppChannel';
import webchatChannel from './webchatChannel';

export default combineReducers({ 
    channels,
    twitterChannel,
    telegramChannel,
    instagramChannel,  
    whatsAppChannel,
    linkedInChannel,
    channelsLogins,
    webchatChannel,  
});