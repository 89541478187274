import { SET_COMPANY } from '../actions/company';

const initialState = {
    company: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                company: action.payload.company
            };
        default: return state;
    }
};