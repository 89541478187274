import { toast as _toast } from 'react-toastify';
import CustomToast from '../components/CustomToast';
import React from 'react';

export const toast = (message, type = 'warning', toastId , autoClose = 20000, onClick = () => {}) => {
    if (!_toast.isActive(toastId)) {
        _toast(!React.isValidElement(message) ? <CustomToast type={type} message={message} onClick={onClick} /> : message, {
            position: _toast.POSITION.TOP_RIGHT,
            className: 'toastify',
            toastId: toastId || message,
            autoClose
        });
    }
}