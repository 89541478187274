import axios from "axios";
import { api, headers } from "../../app/conf";

export const getStatusesService = (token, resolveWhenHere) => 
    axios({
        method: 'get',
        url: `${api}/v1/statuses?resolveWhenHere=${resolveWhenHere}`,
        headers: {
        ...headers,
        "access-token": token,
        },
    })
