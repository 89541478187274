import Repository from "../../repository/Repository";

export default class PreferencesService {
  #endpoint;
  #repository;

  constructor() {
    this.#endpoint = `/configs?`;
    this.#repository = new Repository();
  }

  async getPreferences(getPreferencesParams) {
    try {
      const { token, signal } = getPreferencesParams;
      const endpoint = `${this.#endpoint}`;
      const data = await this.#repository.get({ endpoint, token, signal });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async putPreferences(updatePreferencesParams) {
    try {
      const { token, signal, data } = updatePreferencesParams;
      const endpoint = `${this.#endpoint}`;
      const response = await this.#repository.put({ endpoint, token, signal, information:data });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}