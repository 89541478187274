export const GET_TICKETS_DETAILS_REQUEST = 'GET_TICKETS_DETAILS_REQUEST';
export const GET_TICKETS_DETAILS_SUCCESS = 'GET_TICKETS_DETAILS_SUCCESS';
export const GET_TICKETS_DETAILS_FAILURE = 'GET_TICKETS_DETAILS_FAILURE';

export const getTicketsDetailsRequest = () => ({
    type: GET_TICKETS_DETAILS_REQUEST,
});

export const getTicketsDetailsSuccess = (ticketsDetails) => ({
    type: GET_TICKETS_DETAILS_SUCCESS,
    payload: {
        ticketsDetails,
    }
});

export const getTicketsDetailsFailure = err => ({
    type: GET_TICKETS_DETAILS_FAILURE,
    error: err
});