import { put, takeLatest, call } from 'redux-saga/effects';
import { showGlobalError } from "../../app/actions/app";
import { getLoginSuccess, GET_LOGIN_REQUEST, getLoginFailure, postLoginSuccess, postLoginFailure, POST_LOGIN_REQUEST } from '../actions/login';
import { getLoginStatusService, postLoginService } from '../services/login';


const getStatusLoginWorker = function* ({ payload: { provider, loginId, history } }) {
    try {
        const {data: { login } } = yield call(getLoginStatusService, provider, loginId);
        yield put(getLoginSuccess(login));

    } catch (err) {
        console.log(err)
        if(err.response.status === 404){
            //this happens when the login doesn't exists
            yield put(showGlobalError(true, 409, 'expirated_token'));
            yield put(getLoginFailure());
        }
        yield put(getLoginFailure());
    }
}

const postLoginWorker = function* ({ payload: { provider, loginId, redirectUrl } }) {
    try {
        yield call(postLoginService, provider, loginId, redirectUrl);
        const login = { provider, loginId, redirectUrl };
        yield put(postLoginSuccess(login));

    } catch (err) {
        console.log(err)
        yield put(postLoginFailure());
        yield put(showGlobalError(true, err));
    }
}


export default [
    takeLatest(POST_LOGIN_REQUEST, postLoginWorker),
    takeLatest(GET_LOGIN_REQUEST, getStatusLoginWorker),
];