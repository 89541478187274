import {
    GET_CREATORS_RANKING_REQUEST,
    GET_CREATORS_RANKING_SUCCESS,
    GET_CREATORS_RANKING_FAILURE
} from '../actions/creatorsRanking'

const initialState = {
    fetching: true,
    ranking: [],
    getCreatorRankingSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CREATORS_RANKING_REQUEST: {
            return {
                ...state,
                fetching: true,
                getCreatorRankingSuccess: false
            };
        }
        case GET_CREATORS_RANKING_SUCCESS: {
            return {
                ...state,
                ranking: action.payload.ranking,
                fetching: false,
                getCreatorRankingSuccess: true
            };
        }
        case GET_CREATORS_RANKING_FAILURE: {
            return {
                ...state,
                fetching: false,
                getCreatorRankingSuccess: false
            };
        }
        default: {
            return state;
        }
    }
}