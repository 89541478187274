import { lazy } from "react";
import { statisticsSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Statistics - EntryPoint" */ './components/EntryPoint'));
const StatisticsLoader = lazy(() => import(/* webpackChunkName: "Statistics - Loader" */ './components/ListLoader'));

export const defaultPath = `/statistics`;

export const innerRoutes = [{
    path: `${defaultPath}/`,
    Component: StatisticsLoader,
    exact: true,
    icon: statisticsSVG,
    title: {
        key: "",
        plural: false
    },
    subTitle: {
        key: 'statistics_detail',
        plural: false
    }
}];

const Statistics = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: statisticsSVG,
    title: {
        key: 'statistics',
        plural: false
    },
    subTitle: {
        key: 'statistics_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Statistics;
