export const GET_PROFILES_REQUEST = 'GET_PROFILES_REQUEST';
export const GET_PROFILES_FAILURE = 'GET_PROFILES_FAILURE';
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';

export const SET_PROFILES = 'SER_PROFILES';
export const SET_PROFILES_LIMIT = 'SER_PROFILES_LIMIT';

export const SET_PROFILE = 'SET_PROFILE';

export const SUBMIT_PROFILE_REQUEST = 'SUBMIT_PROFILE_REQUEST';
export const SUBMIT_PROFILE_FAILURE = 'SUBMIT_PROFILE_FAILURE';
export const SUBMIT_PROFILE_SUCCESS = 'SUBMIT_PROFILE_SUCCESS';

export const getProfilesRequest = (filters = {}) => ({
    type: GET_PROFILES_REQUEST,
    payload: {
        filters
    }
});
export const getProfilesSuccess = (profiles) => ({
    type: GET_PROFILES_SUCCESS,
    payload: {
        profiles
    }
});

export const getProfilesFailure = (err) => ({
    type: GET_PROFILES_FAILURE,
    error: err
});

export const setProfile = (profile) => ({
    type: SET_PROFILE,
    payload: {
        profile
    }
});

export const setProfiles = (profiles = []) => ({
    type: SET_PROFILES,
    payload: {
        profiles
    }
});

export const setProfilesLimit = (limit) => ({
    type: SET_PROFILES_LIMIT,
    payload: { limit }
})

export const submitProfileRequest = (profile,id) => ({
    type: SUBMIT_PROFILE_REQUEST,
    payload: {
        profile,
        id
    }
});

export const submitProfileSuccess = () => ({
    type: SUBMIT_PROFILE_SUCCESS
});

export const submitProfileFailure = (err) => ({
    type: SUBMIT_PROFILE_FAILURE,
    error: err
});