import { replaceFromArrayBy_id } from "../../shared/helpers/commons";
import {
  ADD_FILE_TO_FOLDER,
  GET_FOLDER_FAILURE,
  GET_FOLDER_REQUEST,
  GET_FOLDER_SUCCESS,
  REMOVE_FILE_TO_FOLDER,
  POST_FOLDER_REQUEST,
  POST_FOLDER_SUCCESS,
  POST_FOLDER_FAILURE,
  SET_CURRENT_FOLDER_NAME,
  SET_CURRENT_FOLDER_ID,
  UPDATE_FILE_IN_DATA,
} from "../actions/folder";

const INITIAL_STATE = {
  currentFolder: {
    id: null,
    name: null,
  },
  data: null,
  isLoading: false,
  error: null,
  upload: {
    isLoading: false,
    success: false,
    error: null,
  },
};

const FolderState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_FOLDER_REQUEST: {
      return {
        ...state,
        upload: {
          isLoading: true,
          success: null,
          error: null,
        },
      };
    }

    case POST_FOLDER_SUCCESS: {
      return {
        ...state,
        upload: {
          ...state.upload,
          isLoading: false,
          success: true,
        },
      };
    }

    case POST_FOLDER_FAILURE: {
      return {
        ...state,
        upload: {
          ...state.upload,
          isLoading: false,
          error: true,
        },
      };
    }

    case GET_FOLDER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_FOLDER_SUCCESS: { 
      return {
        ...state,
        isLoading: false,
        data: action.payload.response.files,
        currentFolder: {
          ...state.currentFolder,
          name: action.payload.response.name,
        },
      };
    }

    case GET_FOLDER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ADD_FILE_TO_FOLDER: {
      const updatedData = state.data
        ? [...state.data, action.payload.file]
        : [action.payload.file];

      return {
        ...state,
        data: updatedData,
      };
    }
    case REMOVE_FILE_TO_FOLDER: {
      const updatedData = state.data.filter(
        (file) => file._id !== action.payload.fileId
      );

      return {
        ...state,
        data: updatedData,
      };
    }
    case SET_CURRENT_FOLDER_ID: {
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          id: action.payload.folderId,
        },
      };
    }

    case SET_CURRENT_FOLDER_NAME: {
      return {
        ...state,
        currentFolder: {
          ...state.currentFolder,
          name: action.payload.folderName,
        },
      };
    }

    case UPDATE_FILE_IN_DATA: {

      const updatedData = replaceFromArrayBy_id(action.payload.file, state.data);

      return {
        ...state,
        data: updatedData
      };
    }

    default:
      return state;
  }
};

export default FolderState;
