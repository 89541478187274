import { SET_FILTERS_PROFILES, CLEAN_FILTERS } from "../actions/filters";

export const initialState = {
    text: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_PROFILES: {
            return {
                ...state,
                text: action.payload.text
            };
        }
        case CLEAN_FILTERS:{
            return{
                ...initialState,
            }
        }
        default:
            return state;
    }
};
