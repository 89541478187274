import React from 'react';
import { useSelector } from 'react-redux';
import { channelsDataSelector } from '../../../channels/selectors/channels';
import ChannelsDataContext from './ChannelsDataContext';
import { CHANNELS_ICONS, COLOR_CHANNELS_ICONS } from '../../../shared/helpers/Icons/icons';
import { capitalizeFirstLetter } from '../../../shared/helpers/commons';

const ChannelsContext=({children})=>{

    const channelsData = useSelector(channelsDataSelector);

    const getChannelTransparentIcon =(channelCode)=>{
        if(!channelsData || channelsData?.length === 0) return CHANNELS_ICONS.OMUNI;
        if(!channelCode) return CHANNELS_ICONS.OMUNI;

        const channel= channelsData.find(channel=>channel.code === channelCode || channel.type === channelCode.toLowerCase());
        if(!channel) return CHANNELS_ICONS.OMUNI;
        return channel.icons.transparent
    }

    const getChannelColorIcon =(channelCode)=>{
        if(!channelsData || channelsData?.length === 0) return COLOR_CHANNELS_ICONS.OMUNI;
        if(!channelCode) return CHANNELS_ICONS.OMUNI;

        const channel= channelsData.find(channel=>channel.code === channelCode || channel.type === channelCode.toLowerCase());
        if(!channel) return CHANNELS_ICONS.OMUNI;
        return channel.icons.colored
    }

    const getChannelProviderIcon =(channelCode)=>{
        if(!channelsData || channelsData?.length === 0) return COLOR_CHANNELS_ICONS.OMUNI;
        if(!channelCode) return CHANNELS_ICONS.OMUNI;

        const channel= channelsData.find(channel=>channel.code === channelCode);
        if(!channel) return CHANNELS_ICONS.OMUNI;
        return channel.icons.provider ?? channel.icons.colored
    }

    const getUppercaseChannelLabel =(channelCode)=>{
        if(!channelCode) return ""
        if(channelCode === 'sms') return "SMS";
        if(channelCode === 'voiceChannel') return "Canal de voz";

        return capitalizeFirstLetter(channelCode)
    };

    const getChannelLabel =(channelCode)=>{
        if(!channelCode) return "";

        const channel= channelsData.find(channel=>channel.code === channelCode || channel.type === channelCode.toLowerCase());
        if(!channel) return "";
        return getUppercaseChannelLabel(channel.type);
    };

    const data = {
        channelsData,
        getChannelTransparentIcon,
        getChannelColorIcon,
        getChannelProviderIcon,
        getChannelLabel
    };

    return(
        <ChannelsDataContext.Provider value={data}>
            {children}
        </ChannelsDataContext.Provider>
    )
}

export default ChannelsContext;