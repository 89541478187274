export const POST_COMPANY_REQUEST = 'POST_COMPANY_REQUEST';
export const POST_COMPANY_SUCCESS = 'POST_COMPANY_SUCCESS';
export const POST_COMPANY_FAILURE = 'POST_COMPANY_FAILURE';

export const postCompanyRequest = (company, adminUser) => ({
    type: POST_COMPANY_REQUEST,
    payload: {
        company,
        adminUser
    }
});

export const postCompanySuccess = (company, adminUser) => ({
    type: POST_COMPANY_SUCCESS,
    payload: {
        company,
        adminUser
    }
});

export const postCompanyFailure = (err) => ({
    type: POST_COMPANY_FAILURE,
    payload: {
        err
    }
});