export const GET_RESOLUTION_TIME_REQUEST = 'GET_RESOLUTION_TIME_REQUEST';
export const GET_RESOLUTION_TIME_SUCCESS = 'GET_RESOLUTION_TIME_SUCCESS';
export const GET_RESOLUTION_TIME_FAILURE = 'GET_RESOLUTION_TIME_FAILURE';


export const getResolutionTimeRequest = (filters = {}) => ({
    type: GET_RESOLUTION_TIME_REQUEST,
    payload: {
        filters
    }
});

export const getResolutionTimeSuccess = (statistics) => ({
    type: GET_RESOLUTION_TIME_SUCCESS,
    payload: {
        statistics
    }
});

export const getResolutionTimeFailure = (err = {}) => ({
    type: GET_RESOLUTION_TIME_FAILURE,
    payload: {
        err
    }
});