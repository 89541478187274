export const SET_FILTERS_CONTACTS = 'SET_FILTERS_CONTACTS';
export const CLEAN_FILTERS = "CLEAN_FILTERS";

export const setFilters = ({text,identifier,channels,phone,email,lastName}) => ({
    type:SET_FILTERS_CONTACTS,
    payload:{
        text,
        identifier,
        channels,
        phone,
        email,
        lastName
    }
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});