import { lazy } from "react";
import { rulesSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Rules - EntryPoint" */ './components/EntryPoint'));
const RulesLoaderList = lazy(() => import(/* webpackChunkName: "Rules - List" */ './components/ListLoader'));
const FormRuleLoader = lazy(() => import(/* webpackChunkName: "Rules - Loader" */ './components/FormRuleLoader'));

export const defaultPath = `/automation/rules`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: RulesLoaderList,
        exact: true,
        icon: rulesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'list_rules_detail',
            plural: false
        }
    },
    {
        path: `${defaultPath}/new`,
        Component: FormRuleLoader,
        exact: true,
        icon: rulesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'new_rule',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: FormRuleLoader,
        exact: true,
        icon: rulesSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'modify_rule',
            plural: false
        }
    }
];

const Rules = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: rulesSVG,
    title: {
        key: 'rules',
        plural: false
    },
    subTitle: {
        key: 'list_rules_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Rules;
