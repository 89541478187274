export const GET_CREATORS_RANKING_REQUEST = 'GET_CREATORS_RANKING_REQUEST';
export const GET_CREATORS_RANKING_SUCCESS = 'GET_CREATORS_RANKING_SUCCESS';
export const GET_CREATORS_RANKING_FAILURE = 'GET_CREATORS_RANKING_FAILURE';

export const getCreatorsRankingRequest = (filters = {}) => ({
    type: GET_CREATORS_RANKING_REQUEST,
    payload: {
        filters
    }
});

export const getCreatorsRankingSuccess = (ranking) => ({
    type: GET_CREATORS_RANKING_SUCCESS,
    payload: {
        ranking
    }
});

export const getCreatorsRankingFailure = (error = {}) => ({
    type: GET_CREATORS_RANKING_FAILURE,
    payload: {
        error
    }
});