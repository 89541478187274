import React, { Suspense } from "react";
import map from "lodash/map";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter, Switch } from "react-router-dom";
import RouteWithSubRoutes from "../RouteWithSubRoutes/RouteWithSubRoutes";
import store, { persistedStore } from "../../store";
import Body from "../Body";
import Notification from "../Notification";
import Control from "../Control";
import routes from "../../routes";
import Loading from "../Loading";
import { ToastContainer } from "react-toastify";
import SizeContext from "../SizeContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "../../conf";
import ChannelsContext from "../../contexts/ChannelsContext";
import PrefencesContext from "../../contexts/PreferencesContext";

const Root = () => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <HashRouter>
            <Control>
              <PrefencesContext>
                <SizeContext>
                  <ChannelsContext>
                    <Body>
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          {map(routes, (route, index) => (
                            <RouteWithSubRoutes key={index} {...route} />
                          ))}
                        </Switch>
                        <Notification />
                      </Suspense>
                      <ToastContainer
                        closeButton={false}
                        hideProgressBar={true}
                      />
                    </Body>
                  </ChannelsContext>
                </SizeContext>
              </PrefencesContext>
            </Control>
          </HashRouter>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
};
export default Root;
