export const SET_FILTERS_PROFILES = 'SET_FILTERS_PROFILES';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';

export const setFiltersProfiles = ({ text }) => ({
    type: SET_FILTERS_PROFILES,
    payload: {
        text
    },
});

export const cleanFilters = () => ({
    type: CLEAN_FILTERS
});

