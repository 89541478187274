import { GET_PROFILES_SEL_REQUEST, GET_PROFILES_SEL_SUCCESS, GET_PROFILES_SEL_FAILURE } from '../actions/profilesSel';
import { buildProfilesSel } from '../helpers/profilesSel';
const initialState = {
    fetching: false,
    profiles: []
};

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_PROFILES_SEL_REQUEST: {
            return {
                ...state,
                fetching: true
            };
        }
        case GET_PROFILES_SEL_SUCCESS: {
            return {
                ...state,
                fetching: false,
                profiles: buildProfilesSel(action.payload.profiles)
            };
        }
        case GET_PROFILES_SEL_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }

        default: return state;
    }
};