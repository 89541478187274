import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    GET_CHANNELS_LOGINS_REQUEST, 
    getChannelsLoginsFailure, 
    getChannelsLoginsSuccess,
    GET_CHANNELS_LOGINS_STATUS_REQUEST, 
    getChannelsLoginsStatusFailure, 
    getChannelsLoginsStatusSuccess,
    POST_CHANNELS_LOGINS_REQUEST,
    postChannelsLoginsSuccess,
    postChannelsLoginsFailure,
    getChannelsLoginsStatusSuccessV2,
    getChannelsLoginsStatusFailureV2,
    GET_CHANNELS_LOGINS_STATUS_REQUEST_V2,
    DELETE_CHANNEL_LOGIN_STATUS_REQUEST_V2,
    deleteChannelLoginStatusFailureV2,
} from '../actions/channelsLogins';
import { showGlobalError } from '../../app/actions/app';
import { 
    getChannelsLoginsServices,
    postChannelsLoginsServices,
    getChannelsLoginsStatusServices,
    getChannelsLoginsStatusServicesV2,
    deleteChannelLoginStatusServicesV2,
} from '../services/channelsLogins';
import { accessTokenSelector } from '../../auth/selectors/token';

//This GET creates the login for v1
export const getChannelsLoginsWorker = function* ({ payload: {channelName, filters = {} } = {} } = {}) {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(getChannelsLoginsServices, token, channelName, filters);

        yield put(getChannelsLoginsSuccess(data));
    } catch (err) {
        console.log(err);

        yield put(getChannelsLoginsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

//This is the POST of login for v2
export const postChannelsLoginsWorker = function* ({ payload: {channelName, loginId, redirectUrl, filters = {} } = {} } = {}) {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(postChannelsLoginsServices, token, channelName, loginId, redirectUrl, filters);

        yield put(postChannelsLoginsSuccess(data));
    } catch (err) {
        console.log(err);

        yield put(postChannelsLoginsFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getChannelsLoginsStatusWorker = function* ({ payload: {channelName, requestToken } = {} } = {}) {
    const token = yield select(accessTokenSelector);

    const data = {
        channelName,
        requestToken,
    };
    try {

        const { data: { loginStatus } } = yield call(getChannelsLoginsStatusServices, token, data);

        yield put(getChannelsLoginsStatusSuccess(loginStatus));
    } catch (err) {
        console.log(err);
        
        yield put(getChannelsLoginsStatusFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getChannelsLoginsStatusWorkerV2 = function* ({ payload: {channelName, loginId } = {} } = {}) {
    const token = yield select(accessTokenSelector);
    try {

        const { data: { login } } = yield call(getChannelsLoginsStatusServicesV2, token, channelName, loginId);

        yield put(getChannelsLoginsStatusSuccessV2(login));
    } catch (err) {
        console.log(err);

        yield call(deleteChannelLoginStatusServicesV2, token, channelName, loginId);
        yield put(getChannelsLoginsStatusFailureV2(err));
        yield put(showGlobalError(true, err));
    }
};
 
    
    export const deleteChannelLoginStatusWorkerV2 = function* ({
      payload: { channelName, loginId } = {},
    } = {}) {
      try {
        const token = yield select(accessTokenSelector);
    
        yield call(deleteChannelLoginStatusServicesV2, token, channelName, loginId);
      } catch (err) {
        console.trace(err);
    
        yield put(deleteChannelLoginStatusFailureV2(err));
        yield put(showGlobalError(true, err));
      }
    };
    

export default [
    takeLatest(GET_CHANNELS_LOGINS_REQUEST, getChannelsLoginsWorker),
    takeLatest(POST_CHANNELS_LOGINS_REQUEST, postChannelsLoginsWorker),
    takeLatest(GET_CHANNELS_LOGINS_STATUS_REQUEST, getChannelsLoginsStatusWorker),
    takeLatest(GET_CHANNELS_LOGINS_STATUS_REQUEST_V2, getChannelsLoginsStatusWorkerV2),
    takeLatest(DELETE_CHANNEL_LOGIN_STATUS_REQUEST_V2, deleteChannelLoginStatusWorkerV2),
];