import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';
import { getFinalStatusesRequest, getStatusesRequest } from '../../selectors/actions/status';

import {
    DELETE_STATUS_REQUEST,
    deleteStatusSuccess, 
    deleteStatusFailure,
    POST_STATUS_REQUEST, 
    postStatusSuccess, 
    postStatusFailure,
    PUT_STATUS_REQUEST, 
    putStatusSuccess, 
    putStatusFailure,
} from '../actions/statuses';

import { 
    postStatusService, 
    putStatusService,
    deleteStatusService,
} from '../services/statuses';

export const deleteStatusWorker = function* ({ payload: { statusId, filters }}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { statusDeleted } } = yield call(deleteStatusService, token, statusId, filters);

        yield put(deleteStatusSuccess(statusDeleted));    
        yield put(getStatusesRequest());  
        yield put(getFinalStatusesRequest());  
    } catch (err) {
        const { data: errorData } = err.response;

        if (errorData.error.statusCode === 409 && errorData.error.errorKey === 'exist_tasks_with_this_status') {
            yield put(deleteStatusFailure(err, true));
        } else {
            yield put(deleteStatusFailure(err, false));
            yield put(showGlobalError(true, errorData.statusCode, errorData.error.errorKey));
        }
    }
}

export const postStatusWorker = function* ({ payload: { status }}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { status: statusCreated } } = yield call(postStatusService, token, status);

        yield put(postStatusSuccess(statusCreated));        
        yield put(getStatusesRequest());  
        yield put(getFinalStatusesRequest());  
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(postStatusFailure(err));
        yield put(showGlobalError(true, errorData.statusCode, errorData.message));
    }
}

export const putStatusWorker = function* ({ payload: { status, id }}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { status: statusUpdated } } = yield call(putStatusService, token, status, id);

        yield put(putStatusSuccess(statusUpdated));      
    } catch (err) {
        const { data: errorData } = err.response;
        yield put(putStatusFailure(err));
        if(errorData?.error?.statusCode === 409){
            yield put(showGlobalError(true, 409, 'state_name_already_exists'));
        };
    }
}

export default [
    takeLatest(DELETE_STATUS_REQUEST, deleteStatusWorker),
    takeLatest(POST_STATUS_REQUEST, postStatusWorker),
    takeLatest(PUT_STATUS_REQUEST, putStatusWorker),
]