import { lazy } from "react";
import { channelsSVG, webchatSetSVG } from '../shared/helpers/icons';
import webchatRoutes from "../Webchat/routes"

const EntryPoint = lazy(() => import(/* webpackChunkName: "Channels - EntryPoint" */ './components/EntryPoint'));
const ChannelsLoader = lazy(() => import(/* webpackChunkName: "Channels - ListLoader" */ './components/ChannelsLoader'));
const WebchatLoader = lazy(() => import(/* webpackChunkName: "Channels - WebchatLoader" */ '../Webchat/components/WebchatLoader/WebchatLoader'));
const Hashtags = lazy(() => import(/* webpackChunkName: "Channels - WebchatLoader" */ '../hashtags/components/Hashtags/Hashtags'));

export const defaultPath = `/settings/channels`;

export const innerRoutes = [
    webchatRoutes,
    {
        path: `${defaultPath}/`,
        Component: ChannelsLoader,
        exact: true,
        icon: channelsSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'channels',
            plural: false
        }
    },
    {
        path: `${defaultPath}/webchat`,
        Component: WebchatLoader,
        exact: true,
        icon: webchatSetSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'webchat_settings',
            plural: false
        }
    },
    {
        path: `${defaultPath}/hashtags`,
        Component: Hashtags,
        exact: true,
        icon: webchatSetSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'hashtags_settings',
            plural: false
        }
    },
];

const Channels = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: channelsSVG,
    title: {
        key: 'channels',
        plural: false
    },
    subTitle: {
        key: 'channels_configuration',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path),
    availableSubMenu: true
};

export default Channels;
