import { takeLatest, put, call, select } from 'redux-saga/effects';
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    GET_CREATORS_RANKING_REQUEST,
    getCreatorsRankingSuccess,
    getCreatorsRankingFailure
} from '../actions/creatorsRanking';
import {
    getCreatorsRankingService
} from '../services/creatorsRanking';

export const getCreatorsRankingWorker = function*({ payload: { filters }}){
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }

        const { data } = yield call(getCreatorsRankingService, token, filters);
        yield put(getCreatorsRankingSuccess(data));
    } 
    catch (err) {
        console.log(err);
        yield put(getCreatorsRankingFailure(err));
    }
};

export default [
    takeLatest(GET_CREATORS_RANKING_REQUEST, getCreatorsRankingWorker)
];