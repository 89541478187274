export const GET_SUGGESTED_CONTACTS_TAGS_REQUEST = 'GET_SUGGESTED_CONTACTS_TAGS_REQUEST';
export const GET_SUGGESTED_CONTACTS_TAGS_SUCCESS = 'GET_SUGGESTED_CONTACTS_TAGS_SUCCESS';
export const GET_SUGGESTED_CONTACTS_TAGS_FAILURE = 'GET_SUGGESTED_CONTACTS_TAGS_FAILURE';

export const POST_SUGGESTED_CONTACTS_TAGS_REQUEST = 'POST_SUGGESTED_CONTACTS_TAGS_REQUEST';
export const POST_SUGGESTED_CONTACTS_TAGS_SUCCESS = 'POST_SUGGESTED_CONTACTS_TAGS_SUCCESS';
export const POST_SUGGESTED_CONTACTS_TAGS_FAILURE = 'POST_SUGGESTED_CONTACTS_TAGS_FAILURE'


export const getSuggestedContactsTagsRequest = (value) => ({
    type: GET_SUGGESTED_CONTACTS_TAGS_REQUEST,
    payload: {
        value
    }
});

export const getSuggestedContactsTagsSuccess = (tags) => ({
    type: GET_SUGGESTED_CONTACTS_TAGS_SUCCESS,
    payload: {
        tags
    }
});

export const getSuggestedContactsTagsFailure = () => ({
    type: GET_SUGGESTED_CONTACTS_TAGS_FAILURE
});

export const postSuggestedContactsTagsRequest = (value) => ({
    type: POST_SUGGESTED_CONTACTS_TAGS_REQUEST,
    payload: {
        value
    }
});

export const postSuggestedContactsTagsSuccess = (tag) => ({
    type: POST_SUGGESTED_CONTACTS_TAGS_SUCCESS,
    payload: {
        tag
    }
});

export const postSuggestedContactsTagsFailure = () => ({
    type: POST_SUGGESTED_CONTACTS_TAGS_FAILURE
});