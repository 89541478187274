export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';

export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const refreshTokenRequest = history => ({
    type: REFRESH_TOKEN_REQUEST,
    payload: { history }
});

export const refreshTokenSuccess = () => ({ type: REFRESH_TOKEN_SUCCESS });

export const refreshTokenFailure = () => ({
    type: REFRESH_TOKEN_FAILURE,
    payload: new Error(),
    error: true
});
