import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getWorkflowTransitionsService = (token) =>
    axios({
        method: 'get',
        url: `${api}/v1/workflowTransitions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const postWorkflowTransitionService = (token, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/workflowTransitions`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data
    });

export const deleteWorkflowTransitionService = (token, workflowTransitionId, filters) =>
    axios({
        method: 'delete',
        url: `${api}/v1/workflowTransitions/${workflowTransitionId}?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const getAllWorkflowsService = (token) =>
    axios({
        method: 'get',
        url: `${api}/v1/workflows/company/all`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const getCompanyWorkflowsService = (token) =>
    axios({
        method: 'get',
        url: `${api}/v1/workflows/`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const getWorkflowService = (token, workflowId) =>
    axios({
        method: 'get',
        url: `${api}/v1/workflows/${workflowId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const postWorkflowService = (token, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/workflows`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data,
    });

export const putWorkflowService = (token, data) =>
    axios({
        method: 'put',
        url: `${api}/v1/workflows/${data.id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data,
    });

export const deleteWorkflowService = (token, workflowId) =>
    axios({
        method: 'delete',
        url: `${api}/v1/workflows/${workflowId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
    });

export const logicDeleteWorkflowService = (token, workflowId) =>
    axios({
        method: 'delete',
        url: `${api}/v1/workflows/logic/${workflowId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
    });
