import React, { memo } from 'react';
import ThreeDotsSpinner from '../../../shared/components/ThreeDotsSpinner';
import styles from './loading.module.scss';

const Loading = ({ }) => (
    <div className={styles.generalThreeDotsSpinner}>
        <ThreeDotsSpinner/>
    </div>
);

export default memo(Loading);
