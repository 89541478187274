import {
    DELETE_STATUS_REQUEST,
    DELETE_STATUS_SUCCESS,
    DELETE_STATUS_FAILURE,
    POST_STATUS_REQUEST,
    POST_STATUS_SUCCESS,
    POST_STATUS_FAILURE,
    PUT_STATUS_REQUEST,
    PUT_STATUS_SUCCESS,
    PUT_STATUS_FAILURE,
    CLEAN_STATUS,
    RESET_EXIST_TASKS_WITH_THIS_STATUS
} from '../actions/statuses';

const initialState = {
    status: null,
    statusDeleted: null,
    existTasksWithThisStatus: false,
    fetchingStatusStatus: false,
    success: false,
    putSuccess:false,
    deleteSuccess: false,
    deleteFetching: false,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case DELETE_STATUS_REQUEST: {
            return {
                ...state,
                fetchingStatus: true,
                deleteSuccess: false,
                deleteFetching: true,
            };
        }

        case DELETE_STATUS_SUCCESS: {
            return {
                ...state,
                fetchingStatus: false,
                deleteSuccess: true,
                statusDeleted: action.payload.statusDeleted,
                deleteFetching: false,
            };
        }

        case DELETE_STATUS_FAILURE: {
            return {
                ...state,
                fetchingStatus: false,
                deleteSuccess: false,
                existTasksWithThisStatus: action.payload.existTasksWithThisStatus,
                deleteFetching: false,
            };
        }

        case POST_STATUS_REQUEST: {
            return {
                ...state,
                fetchingStatus: true,
                success: false,
            };
        }

        case POST_STATUS_SUCCESS: {
            return {
                ...state,
                fetchingStatus: false,
                success: true,
                status: action.payload.status,
            };
        }

        case POST_STATUS_FAILURE: {
            return {
                ...state,
                fetchingStatus: false,
                success: false,
            };
        }

        case PUT_STATUS_REQUEST: {
            return {
                ...state,
                fetchingStatus: true,
                putSuccess: false,
            };
        }

        case PUT_STATUS_SUCCESS: {
            return {
                ...state,
                fetchingStatus: false,
                putSuccess: true,
                status: action.payload.status,
            };
        }

        case PUT_STATUS_FAILURE: {
            return {
                ...state,
                fetchingStatus: false,
                putSuccess: false,
            };
        }

        case CLEAN_STATUS: {
            return {
                ...state,
                status: null,
            };
        }

        case RESET_EXIST_TASKS_WITH_THIS_STATUS: {
            return {
                ...state,
                existTasksWithThisStatus: false,
                deleteSuccess: false,
            };
        }

        default:
            return state;
    }
}