export const GET_TICKETS_REASONS_REQUEST = 'GET_TICKETS_REASONS_REQUEST';
export const GET_TICKETS_REASONS_SUCCESS = 'GET_TICKETS_REASONS_SUCCESS';
export const GET_TICKETS_REASONS_FAILURE = 'GET_TICKETS_REASONS_FAILURE';

export const getTicketsReasonsRequest = () => ({
    type: GET_TICKETS_REASONS_REQUEST,
});

export const getTicketsReasonsSuccess = (ticketsReasons) => ({
    type: GET_TICKETS_REASONS_SUCCESS,
    payload: {
        ticketsReasons,
    }
});

export const getTicketsReasonsFailure = err => ({
    type: GET_TICKETS_REASONS_FAILURE,
    error: err
});