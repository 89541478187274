import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loggedUserSelector } from "../../../home/selectors/loggedUser";
import packagejson from "../../../../package.json";

export default function DatadogInit() {
  const user = useSelector(loggedUserSelector);

  useEffect(() => {
    datadogRum.init({
      applicationId: "755e6e16-ec4c-4b79-9bb2-c0137fca594c",
      clientToken: "pub778a509e32362c0ea710b0999fbd41cb",
      site: "datadoghq.com",
      service: "omuni-web-standard",
      version: packagejson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
  }, [])

  useEffect(() => {
    if (!user) return;
    datadogRum.setUser({
      displayName: user.displayName,
      email: user.email,
      profile: user.profile,
      username: user.username,
      id: user._id,
      company: user.company,
    });
  }, [user]);

  return null;
}
