import { pendingSVG, inProgressSVG, derivedSVG, closedSVG, openedSVG, newSVG} from '../shared/helpers/icons';

const conf = {
    api: process.env.REACT_APP_URL, 
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    facebookAppScope: process.env.REACT_APP_FACEBOOK_APP_SCOPE,
    instagramAppId: process.env.REACT_APP_INSTAGRAM_APP_ID,
    linkedInAppId:process.env.REACT_APP_LINKEDIN_APP_ID,
    telegramBotFileUrl: process.env.REACT_APP_TELEGRAM_BOT_FILE_URL,
    instagramFileUrl: process.env.REACT_APP_INSTAGRAM_FILE_URL,
    twitterAppKey: process.env.REACT_APP_TWITTER_APP_KEY,
    twitterAppSecret: process.env.REACT_APP_TWITTER_APP_SECRET,
    //twitter auth2
    twitterCodeChallenge: process.env.REACT_APP_TWITTER_APP_CODE_CHALLENGE,
    twitterClientId: process.env.REACT_APP_TWITTER_APP_CLIENT_ID,
    twitterScope: process.env.REACT_APP_TWITTER_APP_TWITTER_SCOPE,
    //
    webhookAuth: process.env.REACT_APP_WEBHOOK_AUTH,
    webSocketEventsUrl: process.env.REACT_APP_WEBSOCKET_EVENTS_URL,
    slackAppId: process.env.REACT_APP_SLACK_APP_ID,
    slackAppScope: process.env.REACT_APP_SLACK_APP_SCOPE,
    mercadoLibreAppId: process.env.REACT_APP_MERCADO_LIBRE_APP_ID,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    cdnLogin: '/signin',
    cdnFiles: (companyID, filename) => `${conf.api}/${companyID}/uploads/${filename}`,
    auth: {},
    webchatChannel: process.env.REACT_APP_WEBCHAT_CHANNEL,
    facebookVersion: process.env.REACT_APP_FACEBOOK_VERSION,
    instagramVersion: process.env.REACT_APP_INSTAGRAM_VERSION,
    omuniReports: process.env.REACT_APP_REPORTS_URL,
    outlookAppId: process.env.REACT_APP_OUTLOOK_APP_ID,
    outlookAppScope: process.env.REACT_APP_OUTLOOK_APP_SCOPE, 
    omuniWeb: process.env.REACT_APP_OMUNI_WEB, 
    environment: process.env.REACT_APP_ENVIRONMENT, 
    apiGateway: process.env.REACT_APP_API_GATEWAY_URL,
    whatsAppCloudVersion: process.env.REACT_APP_WHATSAPP_CLOUD_VERSION,
    whatsAppCloudId: process.env.REACT_APP_WHATSAPP_CLOUD_ID,
    whatsAppCloudConfigId: process.env.REACT_APP_WHATSAPP_CLOUD_CONFIG_ID,
    omuniChannels: process.env.REACT_APP_OMUNI_OMUNI_CHANNELS, 
    omuniFormWeb: process.env.REACT_APP_OMUNI_FORM_WEB 
};

export const { 
    api,
    ip, 
    auth, 
    cdnLogin, 
    cdnFiles, 
    facebookAppId,
    facebookAppScope,
    instagramAppId,
    telegramBotFileUrl,
    instagramFileUrl, 
    twitterAppKey, 
    twitterAppSecret,
    webhookAuth,
    webSocketEventsUrl, 
    linkedInAppId,
    slackAppId,
    slackAppScope,
    mercadoLibreAppId,
    googleClientId,
    twitterCodeChallenge,
    twitterClientId,
    twitterScope,
    knowledgeBaseUrlApi,
    webchatChannel,
    facebookVersion,
    instagramVersion,
    omuniReports,
    outlookAppId,
    outlookAppScope,
    omuniWeb,
    environment,
    apiGateway,
    whatsAppCloudVersion,
    whatsAppCloudId,
    whatsAppCloudConfigId,
    omuniChannels,
    omuniFormWeb
} = conf;

export const googleMapApiKey = "AIzaSyAVN1L_R3vhbSmy2ClyLx7i6PjXTleRH7o";

export const googleMapEmbedApiKey = "AIzaSyB-Zz38SE4yv9oPInyAodAHlY9q2hOamds";

export const recaptchaSiteKey = '6LdLWlYmAAAAAJX0qhi3YfWbtj2C-yysSOxZtqZh'; 

export const headers = { 'Content-Type': 'application/json' };
export const headerFormData = { 'Content-Type': 'multipart/form-data' };

export const defaultPage = 0;

export const defaultLimit = 1000;

export const limitExport = 1000;

export const defaultOptionsForm = {
    value: null,
    label: 'home:none'
};

export const defaultEnabledOptionsForm = [
    {
        value: true,
        label: 'people:enabled',
        checked: true
    },
    {
        value: false,
        label: 'people:disabled',
        checked: false
    }
];

export const defaultEnabledPasswordOptionsForm = [
    {
        value: true,
        label: 'settings:enabled',
        checked: true
    },
    {
        value: false,
        label: 'settings:disabled',
        checked: false
    }
];

export const defaultGenderOptionsForm = [
    { ...defaultOptionsForm },
    {
        value: 'male',
        label: 'people:male'
    },
    {
        value: 'female',
        label: 'people:female'
    },
    {
        value: 'other',
        label: 'people:other'
    }
];

export const initialCases = [
    {
        key: "abierto",
        title: "dashboard:opened",
        total: 0,
        icon: openedSVG,
        background: "#FFFFFF",
        svgfill: "#EEA94E",
        svgbackgroud: "#FCE4D4",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"
    },
    {
        key: "pendiente",
        title: "dashboard:pending",
        total: 0,
        icon: pendingSVG,
        background: "#FFFFFF",
        svgfill: "#DAD045",
        svgbackgroud: "#F7F4C1",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"

    },
    {
        key: "derivado",
        title: "dashboard:derived",
        total: 0,
        icon: derivedSVG,
        background: "#FFFFFF",
        svgfill: "#DAA4D9",
        svgbackgroud: "#F9EDFC ",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"
    },
    {
        key: "cerrado",
        title: "dashboard:closed",
        total: 0,
        icon: closedSVG,
        background: "#FFFFFF",
        svgfill: "#8CC7B5",
        svgbackgroud: "#EBF7F5",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"
    },
    {
        key: "en curso",
        title: "dashboard:in_progress",
        total: 0,
        icon: inProgressSVG,
        background:  "#FFFFFF",
        svgfill: "#3CC2DD",
        svgbackgroud: "#C4EDF5",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"
    },
    {
        key: "nuevo",
        title: "dashboard:new",
        total: 0,
        icon: newSVG,
        background: "#FFFFFF",
        svgfill: "#E97676",
        svgbackgroud: "#F7DAE4",
        fontColor: "#72849A",
        counterColor: "#454545",
        markerIcon: "/assets/markers/red_marker.png"
    }
];

export const defaultOptionsQualities = [
    {
        title: "audits:bad",
        value: "bad",
        label: "bad",
        icon: "",
        color: "#d62828"
    },
    {
        title: "audits:regular",
        value: "regular",
        label: "regular",
        icon: "",
        color: "#f77f00"
    },
    {
        title: "audits:good",
        value: "good",
        label: "good",
        icon: "",
        color: "#fcbf49"
    },
    {
        title: "audits:verygood",
        value: "excellent",
        label: "verygood",
        icon: "",
        color: "#90be6d"
    }
]

export const defaultEnabledOptionsYesNoForm = [
    {
        value: true,
        label: 'home:yes',
        checked: true
    },
    {
        value: false,
        label: 'home:no',
        checked: false
    }
];