export const SET_FILTERS_TICKETS = 'SET_FILTERS_TICKETS';
export const SET_FILTERS_FOR_CONTACTS = 'SET_FILTERS_FOR_CONTACTS';
export const SET_FILTERS_FROM_REPORTS  = 'SET_FILTERS_FROM_REPORTS';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';
export const SET_TICKET_FILTERS_FROM_DASHBOARD = 'SET_TICKET_FILTERS_FROM_DASHBOARD';


export const setFilters = ({ text, dateFrom, dateTo, priority, area, status, assigned, source, taskId, userDisplayName, sourceName, caseReason, caseType, caseDetail, workflows, load, userIdentification, serviceIdentification, customAttributes, selectCustomAttributes }) => ({
    type: SET_FILTERS_TICKETS,
    payload: {
        text,
        dateFrom,
        dateTo,
        priority,
        source,
        area,
        status,
        assigned,
        taskId,
        userDisplayName,
        sourceName,
        caseReason,
        caseType,
        caseDetail,
        workflows,
        load,
        userIdentification,
        serviceIdentification,
        customAttributes,
        selectCustomAttributes
    },
});

export const setTicketFiltersFromDashboard = ( filters ) => ({
    type: SET_TICKET_FILTERS_FROM_DASHBOARD,
    payload: { filters }
});

export const setFiltersForContacts = ({ searchText }) => ({
    type: SET_FILTERS_FOR_CONTACTS,
    payload: {
        searchText
    },
}); 

export const setFiltersFromReports = ( filters ) => ({
    type: SET_FILTERS_FROM_REPORTS,
    payload: {
        filters
    }
})

export const cleanFilters = () => ({
    type: CLEAN_FILTERS,
})



