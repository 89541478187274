export const POST_FILE_REQUEST = "POST_FILE_REQUEST";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_FAILURE = "POST_FILE_FAILURE";
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
export const ADD_FILE_TO_BE_UPLOAD = "ADD_FILE_TO_BE_UPLOAD";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const SET_SELECTED_FILE = "SET_SELECTED_FILE";

export const setSelectedFile = (file) => ({
  type: SET_SELECTED_FILE,
  payload: {
    file,
  },
});

export const postFileRequest = (signal, information) => ({
  type: POST_FILE_REQUEST,
  payload: {
    signal,
    information,
  },
});

export const postFileSuccess = (response, fileId) => ({
  type: POST_FILE_SUCCESS,
  payload: {
    response,
    fileId,
  },
});

export const postFileFailure = (fileId) => ({
  type: POST_FILE_FAILURE,
  payload: {
    fileId,
  },
});

export const deleteFileRequest = (signal, fileId) => ({
  type: DELETE_FILE_REQUEST,
  payload: {
    signal,
    fileId,
  },
});

export const deleteFileSuccess = (response, fileId) => ({
  type: DELETE_FILE_SUCCESS,
  payload: {
    response,
    fileId,
  },
});

export const deleteFileFailure = (fileId) => ({
  type: DELETE_FILE_FAILURE,
  payload: {
    fileId,
  },
});

export const addFileToBeUpload = (newFile) => ({
  type: ADD_FILE_TO_BE_UPLOAD,
  payload: {
    newFile,
  },
});

export const uploadFile = (fileId) => ({
  type: UPLOAD_FILE,
  payload: {
    fileId,
  },
});
