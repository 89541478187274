export const GET_SOCIALS_REQUEST = 'GET_SOCIALS_REQUEST';
export const GET_SOCIALS_SUCCESS = 'GET_SOCIALS_SUCCESS';
export const GET_SOCIALS_FAILURE = 'GET_SOCIALS_FAILURE';

export const GET_SOCIAL_REQUEST = 'GET_SOCIAL_REQUEST';
export const GET_SOCIAL_SUCCESS = 'GET_SOCIAL_SUCCESS';
export const GET_SOCIAL_FAILURE = 'GET_SOCIAL_FAILURE';

export const GET_SOCIALS_INCOMING_REQUEST = 'GET_SOCIALS_INCOMING_REQUEST';
export const GET_SOCIALS_INCOMING_SUCCESS = 'GET_SOCIALS_INCOMING_SUCCESS';
export const GET_SOCIALS_INCOMING_FAILURE = 'GET_SOCIALS_INCOMING_FAILURE';

export const GET_SOCIAL_INCOMING_REQUEST = 'GET_SOCIAL_INCOMING_REQUEST';
export const GET_SOCIAL_INCOMING_SUCCESS = 'GET_SOCIAL_INCOMING_SUCCESS';
export const GET_SOCIAL_INCOMING_FAILURE = 'GET_SOCIAL_INCOMING_FAILURE';

export const GET_SOCIALS_OUTGOING_REQUEST = 'GET_SOCIALS_OUTGOING_REQUEST';
export const GET_SOCIALS_OUTGOING_SUCCESS = 'GET_SOCIALS_OUTGOING_SUCCESS';
export const GET_SOCIALS_OUTGOING_FAILURE = 'GET_SOCIALS_OUTGOING_FAILURE';

export const GET_SOCIAL_OUTGOING_REQUEST = 'GET_SOCIAL_OUTGOING_REQUEST';
export const GET_SOCIAL_OUTGOING_SUCCESS = 'GET_SOCIAL_OUTGOING_SUCCESS';
export const GET_SOCIAL_OUTGOING_FAILURE = 'GET_SOCIAL_OUTGOING_FAILURE';

export const GET_GEOLOCATIONS_REQUEST = 'GET_GEOLOCATIONS_REQUEST';
export const GET_GEOLOCATIONS_SUCCESS = 'GET_GEOLOCATIONS_SUCCESS';
export const GET_GEOLOCATIONS_FAILURE = 'GET_GEOLOCATIONS_FAILURE';

export const POST_SOCIAL_REQUEST = 'POST_SOCIAL_REQUEST';
export const PUT_SOCIAL_LIKE = 'PUT_SOCIAL_LIKE';
export const POST_SOCIAL_SUCCESS = 'POST_SOCIAL_SUCCESS';
export const POST_SOCIAL_FAILURE = 'POST_SOCIAL_FAILURE';

export const PUT_SOCIAL_SHARE = 'PUT_SOCIAL_SHARE';
export const PUT_SOCIAL_SHARE_SUCCESS = 'POST_SOCIAL_SHARE_SUCCESS';
export const PUT_SOCIAL_SHARE_FAILURE = 'POST_SOCIAL_SHARE_FAILURE';

export const SET_LINK_SOCIAL = 'SET_LINK_SOCIAL';
export const SET_TICKET_GO_BACK = 'SET_TICKET_GO_BACK';
export const CLEAN_TICKET_GO_BACK = 'CLEAN_TICKET_GO_BACK';

export const DELETE_SOCIAL_OUTGOING_REQUEST = 'DELETE_SOCIAL_OUTGOING_REQUEST';
export const DELETE_SOCIAL_OUTGOING_SUCCESS = 'DELETE_SOCIAL_OUTGOING_SUCCESS';
export const DELETE_SOCIAL_OUTGOING_FAILURE = 'DELETE_SOCIAL_OUTGOING_FAILURE';

export const PUT_SOCIAL_REQUEST = "PUT_SOCIAL_REQUEST"; 
export const PUT_SOCIAL_SUCCESS = "PUT_SOCIAL_SUCCESS";
export const PUT_SOCIAL_LIKE_SUCCESS = "PUT_SOCIAL_LIKE_SUCCESS";
export const PUT_SOCIAL_FAILURE = "PUT_SOCIAL_FAILURE";

export const  SET_SOCIALS_OUTGOING_PAGE = 'SET_SOCIALS_OUTGOING_PAGE';
export const  SET_SOCIALS_OUTGOING_LIMIT = 'SET_SOCIALS_OUTGOING_LIMIT';

export const CLEAN_SOCIAL = 'CLEAN_SOCIAL';

export const SET_PAGINATION = "SET_PAGINATION";

export const getSocialsRequest = (filters = {}) => ({
    type:GET_SOCIALS_REQUEST,
    payload: {
        filters
    }
});

export const getSocialsSuccess = (socials) => ({
    type: GET_SOCIALS_SUCCESS,
    payload: {
        socials
    }
});

export const getSocialsFailure = (err) => ({
    type: GET_SOCIALS_FAILURE,
    error: err
});

export const getSocialRequest = (socialId, filters = {}) => ({
    type: GET_SOCIAL_REQUEST,
    payload: {
        filters,
        socialId
    }
});

export const getSocialSuccess = (social) => ({
    type: GET_SOCIAL_SUCCESS,
    payload: {
        social
    }
});

export const getSocialFailure = (err) => ({
    type: GET_SOCIAL_FAILURE,
    error: err
});

export const getSocialsIncomingRequest = (filters = {}) => ({
    type:GET_SOCIALS_INCOMING_REQUEST,
    payload: {
        filters
    }
});

export const getSocialsIncomingSuccess = (socialsIncoming) => ({
    type: GET_SOCIALS_INCOMING_SUCCESS,
    payload: {
        socialsIncoming
    }
});

export const getSocialsIncomingFailure = (err) => ({
    type: GET_SOCIALS_INCOMING_FAILURE,
    error: err
});


export const getSocialIncomingRequest = (id, filters = {}) => ({
    type: GET_SOCIAL_INCOMING_REQUEST,
    payload: {
        filters,
        id
    }
});

export const getSocialIncomingSuccess = (social) => ({
    type: GET_SOCIAL_INCOMING_SUCCESS,
    payload: {
        social
    }
});

export const getSocialIncomingFailure = (err) => ({
    type: GET_SOCIAL_INCOMING_FAILURE,
    error: err
});

export const getSocialsOutgoingRequest = (filters = {}) => ({
    type:GET_SOCIALS_OUTGOING_REQUEST,
    payload: {
        filters
    }
});

export const getSocialsOutgoingSuccess = (socialsOutgoing, totalItems) => ({
    type: GET_SOCIALS_OUTGOING_SUCCESS,
    payload: {
        socialsOutgoing,
        totalItems,
    }
});

export const getSocialsOutgoingFailure = (err) => ({
    type: GET_SOCIALS_OUTGOING_FAILURE,
    error: err
});

export const getSocialOutgoingRequest = (id, filters = {}) => ({
    type: GET_SOCIAL_OUTGOING_REQUEST,
    payload: {
        filters,
        id
    }
});

export const getSocialOutgoingSuccess = (social) => ({
    type: GET_SOCIAL_OUTGOING_SUCCESS,
    payload: {
        social
    }
});

export const getSocialOutgoingFailure = (err) => ({
    type: GET_SOCIAL_OUTGOING_FAILURE,
    error: err
});

export const postSocialRequest = (social) => ({
    type: POST_SOCIAL_REQUEST,
    payload: {
        social
    }
});

export const putSocialLike = (social) => ({
    type: PUT_SOCIAL_LIKE,
    payload: {
        social
    }
});

export const getGeoLocationsRequest = (connectionId, geoLocation) => ({
    type: GET_GEOLOCATIONS_REQUEST,
    payload: {
        connectionId,
        geoLocation,
    }
});

export const getGeoLocationsSuccess = (geoLocations) => ({
    type: GET_GEOLOCATIONS_SUCCESS,
    payload: {
        geoLocations,
    }
});

export const getGeoLocationsFailure = err => ({
    type: GET_GEOLOCATIONS_FAILURE,
    error: err
});


export const postSocialSuccess = () => ({
    type: POST_SOCIAL_SUCCESS
});

export const postSocialFailure = (err) => ({
    type: POST_SOCIAL_FAILURE,
    error: err
});

export const setLinkSocial = (id = '', ticketId) => ({
    type: SET_LINK_SOCIAL,
    payload: {
        id,
        ticketId
    }
});

export const setTicketGoBack = (ticketId) => ({
    type: SET_TICKET_GO_BACK,
    payload: {
        ticketId
    }
});

export const cleanTicketGoBack = () => ({
    type: CLEAN_TICKET_GO_BACK,
});

export const deleteSocialOutgoingRequest = (id) => ({
    type: DELETE_SOCIAL_OUTGOING_REQUEST,
    payload: { id }
});

export const deleteSocialOutgoingSuccess = () => ({
    type: DELETE_SOCIAL_OUTGOING_SUCCESS,
    payload: { }
});

export const deleteSocialOutgoingFailure = (err) => ({
    type: DELETE_SOCIAL_OUTGOING_FAILURE,
    payload: { err }
});

export const putSocialRequest = (social) => (
    {
    type: PUT_SOCIAL_REQUEST,
    payload: {
        social
    }
})

export const putSocialSuccess = () => ({
    type: PUT_SOCIAL_SUCCESS
});

export const putSocialLikeSuccess = (social) => ({
    type: PUT_SOCIAL_LIKE_SUCCESS,
    payload: { 
        social
    }
});

export const putSocialFailure = (err) => ({
    type: PUT_SOCIAL_FAILURE,
    payload: { err }
});

export const setSocialsOutgoingPage = (page) => ({
    type: SET_SOCIALS_OUTGOING_PAGE,
    payload: { page }
})

export const setSocialsOutgoingLimit = (limit) => ({
    type: SET_SOCIALS_OUTGOING_LIMIT,
    payload: { limit }
})

export const putSocialShare = (social) => ({
    type: PUT_SOCIAL_SHARE,
    payload: {
        social
    }
});

export const putSocialShareSuccess = (social) => ({
    type: PUT_SOCIAL_SHARE_SUCCESS,
    payload: { 
        social
    }
});

export const putSocialShareFailure = (err) => ({
    type: PUT_SOCIAL_SHARE_FAILURE,
    payload: { err }
});

export const cleanSocial = () => ({
    type: CLEAN_SOCIAL,
    payload: { }
});

export const setPagination = (pagination) => ({
    type: SET_PAGINATION,
    payload: {
        limit: pagination.limit,
        page: pagination.page
    },
  });
