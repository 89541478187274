import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postInstagramUsersServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/instagram`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const putInstagramUsersServices = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/instagram/${data.botId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

// export const getInstagramUsersServices = (token, filters) => 
//     axios({
//         method: 'get',
//         url: `${api}/v1/instagram`,
//         headers: {
//             ...headers,
//             'X-Access-Token': token,
//         },
//     });

export const getInstagramUsersServices = (token, filters) => { 
    return {
        data : {
            instagramUser: {

            }
        }
    };
};

export const deleteInstagramUsersServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/instagram/${data.botId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
