export const GET_MENTIONS_REQUEST = 'GET_MENTIONS_REQUEST';
export const GET_MENTIONS_SUCCESS = 'GET_MENTIONS_SUCCESS';
export const GET_MENTIONS_FAILURE = 'GET_MENTIONS_FAILURE';

export const getMentionsRequest = () => ({
    type: GET_MENTIONS_REQUEST
});

export const getMentionsSuccess = (mentions, total) => ({
    type: GET_MENTIONS_SUCCESS,
    payload: {
        mentions,
        total
    }
});

export const getMentionsFailure = (err) => ({
    type: GET_MENTIONS_FAILURE,
    error: err
})