import {
    POST_TWITTER_CONNECTION_REQUEST,
    POST_TWITTER_CONNECTION_SUCCESS,
    POST_TWITTER_CONNECTION_FAILURE,
    PUT_TWITTER_CONNECTION_REQUEST,
    PUT_TWITTER_CONNECTION_SUCCESS,
    PUT_TWITTER_CONNECTION_FAILURE,
    GET_TWITTER_CONNECTION_REQUEST,
    GET_TWITTER_CONNECTION_SUCCESS,
    GET_TWITTER_CONNECTION_FAILURE,
    DELETE_TWITTER_CONNECTION_REQUEST,
    DELETE_TWITTER_CONNECTION_SUCCESS,
    DELETE_TWITTER_CONNECTION_FAILURE,
} from '../actions/twitterChannel';

const initialState = {
    fetching: true,
    twitterConnection: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case POST_TWITTER_CONNECTION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_TWITTER_CONNECTION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                twitterConnection: action.payload.twitterConnection
            };
        }
        case POST_TWITTER_CONNECTION_FAILURE: {
            return {
                ...state,
                submitFetching: false,
                submitSuccess: false
            };
        }

        case PUT_TWITTER_CONNECTION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case PUT_TWITTER_CONNECTION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                twitterConnection: action.payload.twitterConnection
            };
        }
        case PUT_TWITTER_CONNECTION_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case GET_TWITTER_CONNECTION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case GET_TWITTER_CONNECTION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                twitterConnection: action.payload.twitterConnection
            };
        }
        case GET_TWITTER_CONNECTION_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_TWITTER_CONNECTION_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_TWITTER_CONNECTION_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                twitterConnectionDeleted: action.payload.twitterConnectionDeleted,
                twitterConnection: {}
            };
        }
        case DELETE_TWITTER_CONNECTION_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
};