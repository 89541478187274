import { SET_TOKENS } from '../actions/token';
import { REFRESH_TOKEN_FAILURE } from '../actions/refresh';

const initialState = {
    access: null,
    refresh: null,
    isProviderToken: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKENS: {
            return {
                ...state,
                access: action.payload.accessToken,
                refresh: action.payload.refreshToken,
                isProviderToken: action.payload.isProviderToken
            };
        }
        case REFRESH_TOKEN_FAILURE: return initialState;
        default: return state;
    }
};
