import { all } from "redux-saga/effects";
import auth from "../auth/sagas";
import channels from "../channels/sagas";
import selectors from "../selectors/sagas";
import apikeys from "../apikeys/sagas";
import users from "../users/sagas";
import tickets from "../tickets/sagas";
import dashboard from "../dashboard/sagas";
import contacts from "../contacts/sagas";
import areas from "../areas/sagas";
import profiles from "../profiles/sagas";
import templates from "../templates/sagas";
import social from "../social/sagas";
import statistics from "../statistics/sagas";
import preferences from "../settings/sagas";
import home from "../home/sagas";
import filters from "../filters/sagas";
import bots from "../bots/sagas";
import workflows from "../workflows/sagas";
import rules from "../rules/sagas";
import companies from "../companies/sagas";
import webchat from "../Webchat/sagas";
import notificationChannels from "../notificationChannels/sagas";
import drive from "../drive/sagas";

export default function* () {
  yield all([
    auth(),
    users(),
    channels(),
    selectors(),
    apikeys(),
    tickets(),
    dashboard(),
    contacts(),
    areas(),
    profiles(),
    templates(),
    social(),
    statistics(),
    preferences(),
    home(),
    filters(),
    bots(),
    workflows(),
    rules(),
    companies(),
    webchat(),
    notificationChannels(),
    drive(),
  ]);
}
