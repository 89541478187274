export const GET_CHANNELS_REQUEST = 'GET_CHANNELS_REQUEST_TEMPLATES';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS_TEMPLATES';
export const GET_CHANNELS_FAILURE = 'GET_CHANNELS_FAILURE_TEMPLATES';

export const getChannelsRequest = (filters = {}) => ({
    type: GET_CHANNELS_REQUEST,
    payload: {
        filters
    }
});

export const getChannelsSuccess = (channelsOptions) => ({
    type: GET_CHANNELS_SUCCESS,
    payload: {
        channelsOptions
    }
});

export const getChannelsFailure = (err) => ({
    type: GET_CHANNELS_FAILURE,
    error: err
});