import { lazy } from "react";
import { ICONS } from "./helpers/Icons/icons";
 

const EntryPoint = lazy(() => import("./components/EntryPoint/EntryPoint"));

const DriveFeature = lazy(() =>
  import("./components/Drive/Drive")
);

export const defaultPath = `/drive`;

export const innerRoutes = [
  {
    path: `${defaultPath}/`,
    Component: DriveFeature,
    exact: true,
    icon: ICONS.LOGO,
    title: {
      key: "",
      plural: false,
    },
    subTitle: {
      key: "Gestioná tus archivos y/o documentos",
      plural: false,
    },
  },
];

const DriveBase = {
  path: defaultPath,
  Component: EntryPoint,
  exact: false,
  icon: ICONS.LOGO,
  title: {
    key: "Gestor de archivos",
    plural: false,
  },
  subTitle: {
    key: "Gestioná tus archivos y/o documentos",
    plural: false,
  },
  innerRoutes: innerRoutes, 
  pathChilds: innerRoutes.map((e) => e.path),
};

export default DriveBase;
