import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAILURE,
} from '../actions/companies';

const initialState = {
    company: null,
    fetching: false,
    success: false,
};

export default (state = initialState, action) => {
    switch (action.type) {


        case GET_COMPANY_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_COMPANY_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                company: action.payload.company,
            };
        }

        case GET_COMPANY_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        default:
            return state;
    }
}