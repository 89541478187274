import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "./index.scss";
import "react-day-picker/lib/style.css";
import "./shared/styles/themeColors.scss";
import "react-toastify/dist/ReactToastify.css";
import Root from "./app/components/Root";

ReactDOM.render(<Root />, document.getElementById("root"));
