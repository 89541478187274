import { SET_USER_LOGIN,
        UPDATE_LOGGED_USER, 
        GET_AGENT_STATUS_REQUEST,
        GET_AGENT_STATUS_FAILURE,
        GET_CALL_LOG_REQUEST,
        GET_CALL_LOG_SUCCESS,
        GET_CALL_LOG_FAILURE,
        CLEAN_CALL_LOG,
        CHANGE_EXPIRED_PASSWORD_REQUEST,
        CHANGE_EXPIRED_PASSWORD_SUCCESS,
        CHANGE_EXPIRED_PASSWORD_FAILURE,
        SET_AGENT_STATUS_SUCCESS,
        GET_AGENT_STATUS_SUCCESS,
        UPDATE_USER_PERMISSIONS
    } from '../actions/loggedUser';
import { buildLoggedUser } from '../helpers/loggedUser';
import { buildUser } from '../../users/helpers/users';
import { GET_TICKET } from '../../tickets/actions/tickets';

const initialState = {
    fetching: false,
    user: {
        permission: {},
        permisionEntities: {}
    },
    callLog: {},
    callFetching: false,
    fetchingChangingPsw: false,
    getSuccess: false,
    expiredPasswordChanged: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_LOGIN: {
            return {
                ...state,
                fetching: false,
                user: {
                    ...buildLoggedUser(buildUser(action.payload.user, action.payload.profiles)),
                    permission: action.payload.permission,
                    permisionEntities: action.payload.permisionEntities
                }
            };
        }
        case UPDATE_LOGGED_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    profiles: action.payload.user.profiles,
                    profile: action.payload.user.profiles,
                    agentCode: action.payload.user.agentCode,
                    agentStatus: action.payload.user.agentStatus,
                    telephonyStatus: action.payload.user.telephonyStatus,
                    picture: action.payload.user.picture,
                    name: action.payload.user.name,
                    lastName: action.payload.user.lastName
                }
            }
        }
        case GET_AGENT_STATUS_REQUEST: {
            if(action.payload.polling){
                return {
                    ...state,
                }
            }
        }
        case GET_AGENT_STATUS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            }
        }
        case SET_AGENT_STATUS_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    agentStatus: action.payload.user.agentStatus,
                    telephonyStatus: action.payload.user.telephonyStatus,
                }
            }
        }
        case UPDATE_USER_PERMISSIONS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    permission: action.payload.permission,
                    permisionEntities: action.payload.permisionEntities
                }
            }
        }
        case GET_CALL_LOG_REQUEST: {
            return {
                ...state,
                callFetching: true,
                getSuccess: false
            }
        }
        case GET_CALL_LOG_SUCCESS: {
            return {
                ...state,
                callFetching: false,
                callLog: action.payload.log,
                getSuccess: true
            }
        }
        case GET_CALL_LOG_FAILURE: {
            return {
                ...state,
                callFetching: false,
                getSuccess: false
            }
        }
        case CLEAN_CALL_LOG: {
            return {
                ...state,
                callLog: {},
            }
        }
        case CHANGE_EXPIRED_PASSWORD_REQUEST: {
            return {
                ...state,
                fetchingChangingPsw: true,
                expiredPasswordChanged: false,
            }
        }
        case CHANGE_EXPIRED_PASSWORD_SUCCESS: {
            return {
                ...state,
                fetchingChangingPsw: false,
                expiredPasswordChanged: true,
                user: {
                    ...state.user,
                    changePassword: action.payload.user.changePassword
                }
            }
        }
        case CHANGE_EXPIRED_PASSWORD_FAILURE: {
            return {
                ...state,
                fetchingChangingPsw: false,
                expiredPasswordChanged: false,
            }
        }
        case GET_TICKET: {
            return {
                ...state,
                getSuccess: false
            }
        }
        case GET_AGENT_STATUS_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    agentStatus: action.payload.user.agentStatus,
                    telephonyStatus: action.payload.user.telephonyStatus,
                }
            }
        }
        default:
            return state;
    }
};
