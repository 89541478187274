import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getUsersServices = (token, filters, type='human') =>
    axios({
        method: 'get',
        url: `${api}/v1/users?type=${type}&${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const postUsersServices = (token, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/users`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: {
            ...data
        }
    });

export const putUsersServices = (token, data) =>
    axios({
        method: 'put',
        url: `${api}/v1/users/${data._id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });

export const putPasswordUsersServices = (token, data) =>
    axios({
        method: 'put',
        url: `${api}/v1/users/password/${data._id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });

export const deleteUserService = (token, id) => 
    axios({
        method: 'delete',
        url: `${api}/v1/users/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
    })

    export const getAllUsersService = (token, filters) =>
    axios({
        method: 'get',
        url: `${api}/v1/users?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });