import { 
    GET_RESOLUTION_TIME_FAILURE, 
    GET_RESOLUTION_TIME_REQUEST,
    GET_RESOLUTION_TIME_SUCCESS 
} from '../actions/resolutionTime'


const initialState = {
    statistics: {},
    fetching: false,
    getResolutionTimeSuccess: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RESOLUTION_TIME_REQUEST: {
            return {
                ...state,
                fetching: true,
                getResolutionTimeSuccess: false
            };
        }
        case GET_RESOLUTION_TIME_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getResolutionTimeSuccess: true
            };
        }
        case GET_RESOLUTION_TIME_FAILURE: {
            return {
                ...state,
                fetching: false,
                getResolutionTimeSuccess: false
            };
        }
        default:
            return state;
    }
}