import { takeLatest, put, call, select } from 'redux-saga/effects';
import { getWebchatService, putWebchatService } from '../services/webchat';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';
import {getWebchatFailure, getWebchatSuccess, GET_WEBCHAT_REQUEST, setWebchatFailure, setWebchatSuccess, SET_WEBCHAT_REQUEST } from '../actions/webchat';

export const getWebchatWorker = function* ({ payload: { connectionId } }) {
    try {
        const token = yield select(accessTokenSelector);
        
        const { data: webchat } = yield call(getWebchatService, token, connectionId);
        yield put(getWebchatSuccess( webchat ));
        
    } catch (error) {
        console.log(error);
        yield put(getWebchatFailure(error));
        yield put(showGlobalError(true, 400));
    }
};

export const editWebchatWorker = function* ({payload: { webchat, connectionId }}){
    try {
        const token = yield select(accessTokenSelector);

            yield call(putWebchatService,token,webchat,connectionId);
            yield put(setWebchatSuccess());
            yield put(showGlobalError(true,200,'updated'));
        
        
    } catch (error) {
        yield put(setWebchatFailure(error));
        yield put(showGlobalError(true,error));
    }
}

export default [
    takeLatest(GET_WEBCHAT_REQUEST, getWebchatWorker),
    takeLatest(SET_WEBCHAT_REQUEST, editWebchatWorker)
]