export const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const GET_STATE_BY_ID_REQUEST = 'GET_STATE_BY_ID_REQUEST';
export const GET_STATE_BY_ID_SUCCESS = 'GET_STATE_BY_ID_SUCCESS';
export const GET_STATE_BY_ID_FAILURE = 'GET_STATE_BY_ID_FAILURE';

export const POST_STATE_REQUEST = 'POST_STATE_REQUEST';
export const POST_STATE_SUCCESS = 'POST_STATE_SUCCESS';
export const POST_STATE_FAILURE = 'POST_STATE_FAILURE';

export const PUT_STATE_REQUEST = 'PUT_STATE_REQUEST';
export const PUT_STATE_SUCCESS = 'PUT_STATE_SUCCESS';
export const PUT_STATE_FAILURE = 'PUT_STATE_FAILURE';

export const DELETE_STATE_REQUEST = 'DELETE_STATE_REQUEST';
export const DELETE_STATE_SUCCESS = 'DELETE_STATE_SUCCESS';
export const DELETE_STATE_FAILURE = 'DELETE_STATE_FAILURE';
export const DELETE_STATE_RESET = 'DELETE_STATE_RESET';

export const CLEAN_STATE_WITHOUT_CONNECTION_DELETED = 'CLEAN_STATE_WITHOUT_CONNECTION_DELETED';

export const SET_STATE_OBJECT = 'SET_STATE_OBJECT';

export const getStatesRequest = (agentId = '') => ({
    type: GET_STATES_REQUEST,
    payload: {
        agentId
    }
});

export const getStatesSuccess = (states) => ({
    type: GET_STATES_SUCCESS,
    payload: {
        states
    }
});

export const getStatesFailure = err => ({
    type: GET_STATES_FAILURE,
    error: err
});

export const getStateByIdRequest = (agentId = '', stateId) => ({
    type: GET_STATE_BY_ID_REQUEST,
    payload: {
        agentId,
        stateId
    }
});

export const getStateByIdSuccess = (stateObject) => ({
    type: GET_STATE_BY_ID_SUCCESS,
    payload: {
        stateObject
    }
});

export const getStateByIdFailure = err => ({
    type: GET_STATE_BY_ID_FAILURE,
    error: err
});

export const postStateRequest = (agentId = '', data) => ({
    type: POST_STATE_REQUEST,
    payload: {
        agentId, state: data
    }
});

export const postStateSuccess = (stateObject) => ({
    type: POST_STATE_SUCCESS,
    payload: {
        stateObject
    }
});

export const postStateFailure = err => ({
    type: POST_STATE_FAILURE,
    error: err
});

export const deleteStateRequest = (agentId, stateId) => ({
    type: DELETE_STATE_REQUEST,
    payload: {
        agentId, stateId
    }
});

export const deleteStateSuccess = (stateId, transitions) => ({
    type: DELETE_STATE_SUCCESS,
    payload: {
        stateId,
        transitions
    }
});

export const deleteStateFailure = (err) => ({
    type: DELETE_STATE_FAILURE,
    error: err
});

export const cleanStateWithoutConnectionDeleted = () => ({
    type: CLEAN_STATE_WITHOUT_CONNECTION_DELETED,
});

export const putStateRequest = (agentId = '', stateId, data) => ({
    type: PUT_STATE_REQUEST,
    payload: {
        agentId, stateId, state: data
    }
});

export const putStateSuccess = (stateObject) => ({
    type: PUT_STATE_SUCCESS,
    payload: {
        stateObject
    }
});

export const putStateFailure = err => ({
    type: PUT_STATE_FAILURE,
    error: err
});

export const setStateObject = (stateObject) => ({
    type: SET_STATE_OBJECT,
    payload: {
        stateObject
    }
});