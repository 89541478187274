import { GET_DEPARTMENTS_REQUEST, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAILURE } from '../actions/departments';
import { buildDepartments } from '../helpers/departments';
const initialState = {
    fetching: false,
    departments: []
};

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_DEPARTMENTS_REQUEST: {
            return {
                ...state,
                fetching: true
            };
        }
        case GET_DEPARTMENTS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                departments: buildDepartments(action.payload.departments)
            };
        }
        case GET_DEPARTMENTS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }

        default: return state;
    }
};