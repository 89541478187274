import {
    takeLatest,
    put,
    call,
    select,
} from 'redux-saga/effects';
import {
    GET_PREFERENCES_REQUEST,
    SUBMIT_PREFERENCES_REQUEST,
    getPreferencesSuccess,
    getPreferencesFailure,
    submitPreferencesSuccess,
    submitPreferencesFailure
} from '../actions/preferences';
import {
    getPreferencesService,
    putPreferencesService
} from '../services/preferences'
import { showGlobalError } from '../../app/actions/app';
import { setCompany } from '../../app/actions/company';
import { accessTokenSelector } from '../../auth/selectors/token';

export const submitPreferencesWorker = function* ({ payload: { company } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(putPreferencesService, token, company);
        yield put(setCompany(data));
        yield put(submitPreferencesSuccess(data));
        yield put(showGlobalError(true, 200, 'settings_updated'));
    }
    catch (err) {
        console.log(err);
        yield put(submitPreferencesFailure(err));
        yield put(showGlobalError(true, err.response?.data?.error?.statusCode, "settings_not_saved"));
    };
};
export default [
    takeLatest(SUBMIT_PREFERENCES_REQUEST, submitPreferencesWorker)
];