export const GET_NOTIFICATION_CHANNELS_REQUEST = 'GET_NOTIFICATION_CHANNELS_REQUEST';
export const getNotificationChannelsRequest = () => ({
    type: GET_NOTIFICATION_CHANNELS_REQUEST,
})
export const GET_NOTIFICATION_CHANNELS_SUCCESS = 'GET_NOTIFICATION_CHANNELS_SUCCESS';
export const getNotificationChannelsSuccess = (notificationChannels) => ({
    type: GET_NOTIFICATION_CHANNELS_SUCCESS,
    payload: notificationChannels
})
export const GET_NOTIFICATION_CHANNELS_FAILURE = 'GET_NOTIFICATION_CHANNELS_FAILURE';
export const getNotificationChannelsFailure = (err) => ({
    type: GET_NOTIFICATION_CHANNELS_FAILURE,
    error: err
})
export const SUBMIT_NOTIFICATION_CHANNELS_REQUEST = 'SUBMIT_NOTIFICATION_CHANNELS_REQUEST';
export const submitNotificationChannelsRequest = (notificationChannels) => ({
    type: SUBMIT_NOTIFICATION_CHANNELS_REQUEST,
    payload: { notificationChannels }
})
export const SUBMIT_NOTIFICATION_CHANNELS_SUCCESS = 'SUBMIT_NOTIFICATION_CHANNELS_SUCCESS';
export const submitNotificationChannelsSuccess = (notificationChannels) => ({
    type: SUBMIT_NOTIFICATION_CHANNELS_SUCCESS,
    payload: { notificationChannels }
})
export const SUBMIT_NOTIFICATION_CHANNELS_FAILURE = 'SUBMIT_NOTIFICATION_CHANNELS_FAILURE';
export const submitNotificationChannelsFailure = (err) => ({
    type: SUBMIT_NOTIFICATION_CHANNELS_FAILURE,
    payload: err
})