import { call, put, select, takeLatest } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    getGeneralStatsSuccess,
    getGeneralStatsFailure,
    GET_GENERAL_STATS_REQUEST
} from '../actions/generalStats';
import {
    getGeneralStats
} from '../services/generalStats';
import {showGlobalError, showGlobalErrorV2} from '../../app/actions/app';

export const getGeneralStatsWorker = function* ({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }

        const { data } = yield call(getGeneralStats, token, filters);
        yield put(getGeneralStatsSuccess(data));
    }
    catch (err) {
        console.log(err);
        if(err.response?.data?.error?.errorKey==='user_expired_credentials'){
            yield put(showGlobalErrorV2(err.response?.data?.error));
            yield put(getGeneralStatsFailure(err, false));
        }else{
            if (err?.response?.status === 429) {
                yield put(getGeneralStatsFailure(err, true));
                yield put(showGlobalError(true, 429, 'too_many_requests'));
            } else {
                yield put(getGeneralStatsFailure(err, false));
                yield put(showGlobalError(true, err));
            }
        } 
    };
};

export default [
    takeLatest(GET_GENERAL_STATS_REQUEST, getGeneralStatsWorker)
];