import { takeLatest, put, call, select } from 'redux-saga/effects';
import { accessTokenSelector } from '../../auth/selectors/token';
import { getSuggestedTagsFailure, getSuggestedTagsSuccess, GET_SUGGESTED_TAGS_REQUEST, postSuggestedTagsFailure, postSuggestedTagsSuccess, POST_SUGGESTED_TAGS_REQUEST } from '../actions/tags';
import { getSuggestedTagsService, postSuggestedTagsService } from '../services/tags';

export const getSuggestedTagsWorker = function* ({ payload: { value, type }}){
    try {
        const token = yield select(accessTokenSelector);
        const typeFilter = type?? 'task';
        const { data: { tags } } = yield call(getSuggestedTagsService, token, value, typeFilter);
        yield put(getSuggestedTagsSuccess(tags));
    } catch (err) {

        yield put(getSuggestedTagsFailure());
    }
}

export const postSuggestedTagsWorker = function* ({ payload: { value }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(postSuggestedTagsService, token, value);
        yield put(postSuggestedTagsSuccess(data));
    } catch (err) {

        yield put(postSuggestedTagsFailure());
    }
}

export default [
    takeLatest(GET_SUGGESTED_TAGS_REQUEST, getSuggestedTagsWorker),
    takeLatest(POST_SUGGESTED_TAGS_REQUEST, postSuggestedTagsWorker),
]