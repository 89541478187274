export const GET_GENERAL_STATS_REQUEST = 'GET_GENERAL_STATS_REQUEST';
export const GET_GENERAL_STATS_SUCCESS = 'GET_GENERAL_STATS_SUCCESS';
export const GET_GENERAL_STATS_FAILURE = 'GET_GENERAL_STATS_FAILURE';


export const getGeneralStatsRequest = (filters = {}) => ({
    type: GET_GENERAL_STATS_REQUEST,
    payload: {
        filters
    }
});

export const getGeneralStatsSuccess = (statistics) => ({
    type: GET_GENERAL_STATS_SUCCESS,
    payload: {
        statistics
    }
});

export const getGeneralStatsFailure = (err = {}) => ({
    type: GET_GENERAL_STATS_FAILURE,
    payload: {
        err
    }
});