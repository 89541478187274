import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getApikeysServices = (token, filters) =>
    axios({
        method: 'get',
        url: `${api}/v1/users?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });

export const getApikeyServices = (token, filters) =>
    axios({
        method: 'get',
        url: `${api}/v1/apikeys`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });  

export const postApikeysServices = (token, data) =>
    axios({
        method: 'post',
        url: `${api}/v1/apikeys`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: {
            ...data
        }
    });

export const putApikeysServices = (token, data, id) =>
    axios({
        method: 'put',
        url: `${api}/v1/apikeys/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });
    
export const deleteApiKeyService = (token, id) => 
    axios({
        method: 'delete',
        url: `${api}/v1/apikeys/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
    })
