export const SET_DELETE_FILE_POPUP_STATUS = "SET_DELETE_FILE_POPUP_STATUS";
export const SET_UPLOAD_FOLDER_POPUP_STATUS = "SET_UPLOAD_FOLDER_POPUP_STATUS";
export const SET_MANAGE_VERSIONS_POPUP_STATUS = "SET_MANAGE_VERSIONS_POPUP_STATUS";

export const setDeleteFilePopupStatus = ({ isOpen }) => ({
  type: SET_DELETE_FILE_POPUP_STATUS,
  payload: {
    isOpen,
  },
});

export const setUploadFolderPopupStatus = ({ isOpen }) => ({
  type: SET_UPLOAD_FOLDER_POPUP_STATUS,
  payload: {
    isOpen,
  },
});

export const setManageVersionsPopupStatus = ({ isOpen }) => ({
  type: SET_MANAGE_VERSIONS_POPUP_STATUS,
  payload: {
    isOpen,
  },
});
