import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

import {
    DELETE_WORKFLOW_TRANSITION_REQUEST, 
    deleteWorkflowTransitionSuccess, 
    deleteWorkflowTransitionFailure,
    GET_WORKFLOW_TRANSITIONS_REQUEST, 
    getWorkflowTransitionsSuccess, 
    getWorkflowTransitionsFailure,
    POST_WORKFLOW_TRANSITION_REQUEST, 
    postWorkflowTransitionSuccess, 
    postWorkflowTransitionFailure,
    GET_ALL_WORKFLOWS_REQUEST,
    getAllWorkflowsFailure,
    getAllWorkflowsSuccess,
    GET_COMPANY_WORKFLOWS_REQUEST,
    getCompanyWorkflowsFailure,
    getCompanyWorkflowsSuccess,
    GET_WORKFLOW_REQUEST,
    getWorkflowFailure,
    getWorkflowSuccess,
    POST_WORKFLOW_REQUEST,
    postWorkflowFailure,
    postWorkflowSuccess,
    PUT_WORKFLOW_REQUEST,
    putWorkflowFailure,
    putWorkflowSuccess,
    DELETE_WORKFLOW_REQUEST,
    deleteWorkflowFailure,
    deleteWorkflowSuccess,
    LOGIC_DELETE_WORKFLOW_REQUEST,
    logicDeleteWorkflowFailure,
    logicDeleteWorkflowSuccess,
} from '../actions/workflows';

import { 
    getWorkflowTransitionsService, 
    postWorkflowTransitionService, 
    deleteWorkflowTransitionService,
    getCompanyWorkflowsService,
    getAllWorkflowsService,
    getWorkflowService,
    postWorkflowService,
    putWorkflowService,
    deleteWorkflowService,
    logicDeleteWorkflowService
} from '../services/workflows';

export const getWorkflowTransitionsWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { workflowTransitions } } = yield call(getWorkflowTransitionsService, token);

        yield put(getWorkflowTransitionsSuccess(workflowTransitions));
        
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(getWorkflowTransitionsFailure(err));
        yield put(showGlobalError(true, errorData.statusCode, errorData.message));
    }
}

export const postWorkflowTransitionWorker = function* ({ payload: { workflowTransition }}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { workflowTransition: workflowTransitionCreated } } = yield call(postWorkflowTransitionService, token, workflowTransition);

        yield put(postWorkflowTransitionSuccess(workflowTransitionCreated));
        
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(postWorkflowTransitionFailure(err));
        yield put(showGlobalError(true, errorData.statusCode, errorData.message));
    }
}

export const deleteWorkflowTransitionWorker = function* ({ payload: { workflowTransitionId, filters }}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { workflowTransitionDeleted } } = yield call(deleteWorkflowTransitionService, token, workflowTransitionId, filters);

        yield put(deleteWorkflowTransitionSuccess(workflowTransitionDeleted));
        
    } catch (err) {
        yield put(deleteWorkflowTransitionFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getAllWorkflowsWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { workflows } } = yield call(getAllWorkflowsService, token);

        yield put(getAllWorkflowsSuccess(workflows));
        
    } catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getCompanyWorkflowsWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { workflows } } = yield call(getCompanyWorkflowsService, token);

        yield put(getCompanyWorkflowsSuccess(workflows));
        
    } catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const getWorkflowWorker = function* ({ payload: { workflowId }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data: { workflow } } = yield call(getWorkflowService, token, workflowId);

        yield put(getWorkflowSuccess(workflow));
        
    } catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
    }
}

export const postWorkflowWorker = function* ({ payload: { workflow } }) {
    try {
        const token = yield select(accessTokenSelector);
        const  data  = yield call(postWorkflowService, token, workflow);

        yield put(postWorkflowSuccess(data));
        yield put(showGlobalError(true, 200,'workflow_created'));
    }
    catch (err) {
        const { data: errorData } = err.response;
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
        if(errorData && errorData.error && errorData.error.statusCode && errorData.error.errorKey){
            yield put(showGlobalError(true, errorData.error.statusCode, errorData.error.errorKey));
        }
    };
};

export const putWorkflowWorker = function* ({ payload: { workflow, workflowId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const  data  = yield call(putWorkflowService, token, workflow, workflowId);

        // yield put(putWorkflowSuccess(data.data.workflow));
        yield put(putWorkflowSuccess(data));

    }
    catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
    };
};

export const deleteWorkflowWorker = function* ({ payload: { workflowId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const  data  = yield call(deleteWorkflowService, token, workflowId);

        yield put(deleteWorkflowSuccess(data));
        yield put(showGlobalError(true, 200,'workflow_deleted'));
    }
    catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
        const { data: errorData } = err.response;
        if(errorData && errorData.error && errorData.error.statusCode && errorData.error.errorKey){
            yield put(showGlobalError(true, errorData.error.statusCode, errorData.error.errorKey));
        }
    };
};

export const logicDeleteWorkflowWorker = function* ({ payload: { workflowId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const  data  = yield call(logicDeleteWorkflowService, token, workflowId);

        yield put(logicDeleteWorkflowSuccess(data));
        yield put(showGlobalError(true, 200,'workflow_deleted'));
    }
    catch (err) {
        yield put(deleteWorkflowFailure(err));
        yield put(showGlobalError(true,err));
    };
};

export default [
    takeLatest(DELETE_WORKFLOW_TRANSITION_REQUEST, deleteWorkflowTransitionWorker),
    takeLatest(GET_WORKFLOW_TRANSITIONS_REQUEST, getWorkflowTransitionsWorker),
    takeLatest(POST_WORKFLOW_TRANSITION_REQUEST, postWorkflowTransitionWorker),
    takeLatest(GET_ALL_WORKFLOWS_REQUEST, getAllWorkflowsWorker),
    takeLatest(GET_COMPANY_WORKFLOWS_REQUEST, getCompanyWorkflowsWorker),
    takeLatest(GET_WORKFLOW_REQUEST, getWorkflowWorker),
    takeLatest(POST_WORKFLOW_REQUEST, postWorkflowWorker),
    takeLatest(PUT_WORKFLOW_REQUEST, putWorkflowWorker),
    takeLatest(DELETE_WORKFLOW_REQUEST, deleteWorkflowWorker),
    takeLatest(LOGIC_DELETE_WORKFLOW_REQUEST, logicDeleteWorkflowWorker)
]