import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_INSTAGRAM_USER_REQUEST,
    postInstagramUserSuccess,
    postInstagramUserFailure,
    PUT_INSTAGRAM_USER_REQUEST,
    putInstagramUserSuccess,
    putInstagramUserFailure,
    GET_INSTAGRAM_USER_REQUEST,
    getInstagramUserSuccess,
    getInstagramUserFailure,
    DELETE_INSTAGRAM_USER_REQUEST,
    deleteInstagramUserSuccess,
    deleteInstagramUserFailure,
} from '../actions/instagramChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postInstagramUsersServices,
    getInstagramUsersServices,
    putInstagramUsersServices,
    deleteInstagramUsersServices,
} from '../services/instagramChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postInstagramUserWorker = function* ({ payload: { instagramUser } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { instagramUser: iU } } = yield call(postInstagramUsersServices, token, instagramUser);

        yield put(showGlobalError(true, 200, 'instagram_created'));

        yield put(postInstagramUserSuccess(iU));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'instagram_user_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postInstagramUserFailure(err));
    }
};

const putInstagramUserWorker = function* ({ payload: { instagramUser } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { instagramUser: iU } } = yield call(putInstagramUsersServices, token, instagramUser);

        yield put(showGlobalError(true, 200, 'instagram_updated'));

        yield put(putInstagramUserSuccess(iU));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'instagram_user_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(putInstagramUserFailure(err));
    }
};

export const getInstagramUserWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { instagramUser } } = yield call(getInstagramUsersServices, token, filters);
        yield put(getInstagramUserSuccess(instagramUser));
    } catch (err) {
        console.log(err);

        yield put(getInstagramUserFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteInstagramUserWorker = function* ({ payload: { instagramUserDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const { data: { iUD } } = yield call(deleteInstagramUsersServices, token, instagramUserDeleted);

        yield put(showGlobalError(true, 200, 'instagram_deleted'));

        yield put(deleteInstagramUserSuccess(iUD));
    } catch (err) {
        console.log(err);

        yield put(deleteInstagramUserFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_INSTAGRAM_USER_REQUEST, postInstagramUserWorker),
    takeLatest(GET_INSTAGRAM_USER_REQUEST, getInstagramUserWorker),
    takeLatest(PUT_INSTAGRAM_USER_REQUEST, putInstagramUserWorker),
    takeLatest(DELETE_INSTAGRAM_USER_REQUEST, deleteInstagramUserWorker),
];