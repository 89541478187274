export const GET_STATUSES_REQUEST = 'GET_STATUSES_REQUEST';
export const GET_STATUSES_SUCCESS = 'GET_STATUSES_SUCCESS';
export const GET_STATUSES_FAILURE = 'GET_STATUSES_FAILURE';

export const GET_FINAL_STATUSES_REQUEST = 'GET_FINAL_STATUSES_REQUEST';
export const GET_FINAL_STATUSES_SUCCESS = 'GET_FINAL_STATUSES_SUCCESS';
export const GET_FINAL_STATUSES_FAILURE = 'GET_FINAL_STATUSES_FAILURE';

export const getStatusesRequest = () => ({
    type: GET_STATUSES_REQUEST,
    payload: {}
});

export const getStatusesSuccess = ( statuses ) => ({
    type: GET_STATUSES_SUCCESS,
    payload: { statuses }
});

export const getStatusesFailure = ( err ) => ({
    type: GET_STATUSES_FAILURE,
    payload: { err }
});

export const getFinalStatusesRequest = () => ({
    type: GET_FINAL_STATUSES_REQUEST,
    payload: {}
});

export const getFinalStatusesSuccess = ( statuses ) => ({
    type: GET_FINAL_STATUSES_SUCCESS,
    payload: { statuses }
});

export const getFinalStatusesFailure = ( err ) => ({
    type: GET_FINAL_STATUSES_FAILURE,
    payload: { err }
});