export const GET_RESPONSES_REQUEST = 'GET_RESPONSES_REQUEST';
export const GET_RESPONSES_SUCCESS = 'GET_RESPONSES_SUCCESS';
export const GET_RESPONSES_FAILURE = 'GET_RESPONSES_FAILURE';

export const POST_RESPONSE_REQUEST = 'POST_RESPONSE_REQUEST';
export const POST_RESPONSE_SUCCESS = 'POST_RESPONSE_SUCCESS';
export const POST_RESPONSE_FAILURE = 'POST_RESPONSE_FAILURE';

export const PUT_RESPONSE_REQUEST = 'PUT_RESPONSE_REQUEST';
export const PUT_RESPONSE_SUCCESS = 'PUT_RESPONSE_SUCCESS';
export const PUT_RESPONSE_FAILURE = 'PUT_RESPONSE_FAILURE';

export const DELETE_RESPONSE_REQUEST = 'DELETE_RESPONSE_REQUEST';
export const DELETE_RESPONSE_SUCCESS = 'DELETE_RESPONSE_SUCCESS';
export const DELETE_RESPONSE_FAILURE = 'DELETE_RESPONSE_FAILURE';


export const getResponsesRequest = (botId = '', intentId='') => ({
    type: GET_RESPONSES_REQUEST,
    payload: {
        botId, intentId
    }
});

export const getResponsesSuccess = (responses) => ({
    type: GET_RESPONSES_SUCCESS,
    payload: {
        responses
    }
});

export const getResponsesFailure = err => ({
    type: GET_RESPONSES_FAILURE,
    error: err
});

export const postResponseRequest = (botId = '', response) => ({
    type: POST_RESPONSE_REQUEST,
    payload: {
        botId, response
    }
});

export const postResponseSuccess = (response) => ({
    type: POST_RESPONSE_SUCCESS,
    payload: {
        response
    }
});

export const postResponseFailure = err => ({
    type: POST_RESPONSE_FAILURE,
    error: err
});

export const putResponseRequest = (botId = '', respId, response) => ({
    type: PUT_RESPONSE_REQUEST,
    payload: {
        botId, respId, response
    }
});

export const putResponseSuccess = (response) => ({
    type: PUT_RESPONSE_SUCCESS,
    payload: {
        response
    }
});

export const putResponseFailure = err => ({
    type: PUT_RESPONSE_FAILURE,
    error: err
});

export const deleteResponseRequest = (botId, id) => ({
    type: DELETE_RESPONSE_REQUEST,
    payload: {
        botId, id
    }
});

export const deleteResponseSuccess = (id) => ({
    type: DELETE_RESPONSE_SUCCESS,
    payload: {
        id
    }
});

export const deleteResponseFailure = (err) => ({
    type: DELETE_RESPONSE_FAILURE,
    error: err
});