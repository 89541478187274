export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_PROVIDER_REQUEST = 'SIGN_IN_PROVIDER_REQUEST';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const CLEAN_FLAGS = 'CLEAN_FLAGS';

export const RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const SET_RECOVER_PASSWORD = 'SET_RECOVER_PASSWORD';

export const signInRequest = (username, password, history,recaptcha) => ({
    type: SIGN_IN_REQUEST,
    payload: {
        username,
        password,
        history,
        recaptcha
    }
});

export const signInProviderRequest = (tokenId, history) => ({
    type: SIGN_IN_PROVIDER_REQUEST,
    payload: {
        tokenId,
        history
    }
});
export const signInSuccess = () => ({ type: SIGN_IN_SUCCESS });

export const signInFailure = () => ({
    type: SIGN_IN_FAILURE,
});

export const recoverPasswordRequest = (email, url) => ({
    type: RECOVER_PASSWORD_REQUEST,
    payload: {
        email,
        url
    }
});

export const recoverPasswordSuccess = () => ({
    type: RECOVER_PASSWORD_SUCCESS,
});

export const recoverPasswordFailure = (err) => ({
    type: RECOVER_PASSWORD_FAILURE,
    payload: {
        err
    }
});

export const setRecoverPassword = (boolean) => ({
    type: SET_RECOVER_PASSWORD,
    payload: { boolean }
});



export const changePasswordRequest = ({ email, code, password }) => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload: {
        email,
        code,
        password
    }
});

export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (err) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: {
        err
    }
});

export const cleanFlags = () => ({
    type: CLEAN_FLAGS,
});
