export const STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DEMO: "demo",
  BETA: "beta",
  NEW: "new",
};

export const ENVIRONMENTS = {
  PRODUCTION: "PRODUCTION",
  STAGING: "STAGING",
  TEST: "TEST",
  LOCAL: "LOCAL",
};