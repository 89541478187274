import { takeLatest, put, call, select, debounce } from 'redux-saga/effects';
import { 
    GET_PUBLICATIONS_REQUEST, 
    getPublicationsSuccess, 
    getPublicationsFailure,
    GET_PUBLICATION_REQUEST, 
    getPublicationSuccess, 
    getPublicationFailure,
    GET_PUBLICATIONS_INCOMING_REQUEST, 
    getPublicationsIncomingSuccess, 
    getPublicationsIncomingFailure,
    GET_PUBLICATION_INCOMING_REQUEST, 
    getPublicationIncomingSuccess, 
    getPublicationIncomingFailure, 
    POST_PUBLICATION_REQUEST, 
    postPublicationSuccess, 
    postPublicationFailure,
    POST_PUBLICATION_COMMENT_REQUEST,
    postPublicationCommentSuccess,
    postPublicationCommentFailure,
    pollingPublicationSuccess,
    pollingPublicationsIncomingSuccess
} from '../actions/publications';
import {
    getPublicationsService,
    getPublicationServices,
    postPublicationService,
    getPublicationsIncomingService,
    getPublicationIncomingService,
    putPublicationService
} from '../services/publications';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';

export const getPublicationsWorker = function* ({payload: {filters,polling}}){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { publications } } = yield call(getPublicationsService, token, filters);

        if(polling){
            yield put(pollingPublicationSuccess(publications));
        }
        else{
            yield put(getPublicationsSuccess(publications));
        }
        
    } catch (err) {
        console.log(err);

        yield put(getPublicationsFailure(err));
        yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
    }
}

export const getPublicationWorker = function* ({ payload: { filters, postId } }) {
    try {
        const data = {
            postId, 
            filters
        };

        const token = yield select(accessTokenSelector);
        const { data: { publication } } = yield call(getPublicationServices, token, data);

        yield put(getPublicationSuccess(publication));
    } catch (err) {
        console.log(err);

        yield put(getPublicationFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const getPublicationsIncomingWorker = function* ({payload: {filters, polling}}){
    try {

        const token = yield select(accessTokenSelector);

        const { data: { publications: publicationsIncoming } } = yield call(getPublicationsIncomingService, token, filters);

        if(polling){
            yield put(pollingPublicationsIncomingSuccess(publicationsIncoming));
            
        }else{
            yield put(getPublicationsIncomingSuccess(publicationsIncoming));
        }
        
    } catch (err) {
        console.log(err);

        yield put(getPublicationsIncomingFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export const getPublicationIncomingWorker = function* ({ payload: { filters, postId } }) {
    try {
        const data = {
            postId, 
            filters
        };

        const token = yield select(accessTokenSelector);

        const { data: { publication: publicationIncoming } } = yield call(getPublicationIncomingService, token, data);

        yield put(getPublicationIncomingSuccess(publicationIncoming));
    } catch (err) {
        console.log(err);

        yield put(getPublicationIncomingFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const postPublicationWorker = function* ({ payload: { publication } }){
    try {
        const token = yield select(accessTokenSelector);

        if(publication._id){
            const {data: { publication: p } } = yield call(putPublicationService,token,publication);
            
            yield put(showGlobalError( true, 200, 'created' ));

            yield put(postPublicationSuccess(p));
        }
        else{
            const { data: { ids } } = yield call( postPublicationService, token, publication);

            if(!publication.programmed){
                if (ids.facebookId) yield put(showGlobalError( true, 200, 'facebook_publication_created'));

                if (ids.twitterId) yield put(showGlobalError( true, 200, 'twitter_publication_created'));
            } else {
                if (ids.facebookId) yield put(showGlobalError( true, 200, 'facebook_publication_programmed_created'));

                if (ids.twitterId) yield put(showGlobalError( true, 200, 'twitter_publication_programmed_created'));
            }

            yield put(postPublicationSuccess(ids));
        }
        
    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409)) {
            yield put(showGlobalError(true, 409, 'publication_error'));
        }
        else {
            yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
        }
        yield put(postPublicationFailure(err));
    }
}

export const postPublicationCommentWorker = function* ({ payload: { comment } }){
    try {
        const token = yield select(accessTokenSelector);

        const { data: { ids: c } } = yield call( postPublicationService, token, comment);

        yield put(showGlobalError( true, 200, 'publication_comment_created'));

        yield put(postPublicationCommentSuccess(c));
    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409)) {
            yield put(showGlobalError(true, 409, 'publication_error'));
        }
        else {
            yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
        }
        yield put(postPublicationFailure(err));
    }
}

export default [
    takeLatest(GET_PUBLICATIONS_REQUEST, getPublicationsWorker),
    takeLatest(GET_PUBLICATION_REQUEST, getPublicationWorker),
    takeLatest(GET_PUBLICATIONS_INCOMING_REQUEST, getPublicationsIncomingWorker),
    takeLatest(GET_PUBLICATION_INCOMING_REQUEST, getPublicationIncomingWorker),
    takeLatest(POST_PUBLICATION_REQUEST, postPublicationWorker),
    takeLatest(POST_PUBLICATION_COMMENT_REQUEST, postPublicationCommentWorker),
]