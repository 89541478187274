import {
    GET_TICKETS,
    REFRESH_TICKETS,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILURE,
    SET_SELECTED_TICKET,
    GET_TICKET,
    GET_TICKET_SUCCESS,
    GET_TICKET_FAILURE,
    POST_TICKET,
    POST_TICKET_FAILURE,
    POST_TICKET_SUCCESS,
    PUT_TICKET,
    PUT_TICKET_FAILURE,
    PUT_TICKET_SUCCESS,
    SET_TICKETS,
    GET_TICKETS_SCORE_FAILURE,
    GET_TICKETS_SCORE_REQUEST,
    GET_TICKETS_SCORE_SUCCESS,
    PUT_TICKETS_SCORE_FAILURE,
    PUT_TICKETS_SCORE_REQUEST,
    PUT_TICKETS_SCORE_SUCCESS,
    PUT_TICKET_ASSIGNED_REQUEST,
    PUT_TICKET_ASSIGNED_SUCCESS,
    PUT_TICKET_ASSIGNED_FAILURE,
    PUT_TICKET_AREA_REQUEST,
    PUT_TICKET_AREA_SUCCESS,
    PUT_TICKET_AREA_FAILURE,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILURE,
    GET_TAGS_REQUEST,
    POST_TAGS_FAILURE,
    POST_TAGS_REQUEST,
    POST_TAGS_SUCCESS,
    SET_CASE_LOCATION,
    GET_FOLLOWING_STATES_REQUEST,
    GET_FOLLOWING_STATES_SUCCESS,
    GET_FOLLOWING_STATES_FAILURE,
    GET_TICKET_HISTORY_SUCCESS,
    GET_TICKET_HISTORY_FAILURE,
    GET_TICKET_HISTORY_REQUEST,
    SET_TICKETS_PAGE,
    SET_TICKETS_LIMIT,
    CLEAN_TICKET,
    PUT_MASSIVE_CLOSE_TICKETS_REQUEST,
    PUT_MASSIVE_CLOSE_TICKETS_SUCCESS,
    PUT_MASSIVE_CLOSE_TICKETS_FAILURE,
    CLEAN_MASSIVE_CLOSE_DATA,
    GET_TICKET_BY_PHONE_REQUEST,
    GET_TICKET_BY_PHONE_SUCCESS,
    GET_TICKET_BY_PHONE_FAILURE,
    GET_TICKET_FIRST_TIME,
    GET_REPORT_REQUEST,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILURE,
    CLEAN_TICKET_BY_PHONE,
    PUT_TICKET_FETCHING_SUCCESS,
    CLEAN_NEW_TICKET,
    CLEAR_PARENT_TICKET,
    SET_PARENT_TICKET,
    SET_TYPE_VIEW,
    SET_TICKET,
    SET_TOTAL_ITEMS,
    LINK_CONTACT_TO_TICKET_SUCCESS
} from '../actions/tickets';
import { VIEW_TYPE } from '../components/ListLoader/types';

const initialState = {
    tickets: null,
    fetching: false,
    selectedTicket: {ticket:{}, score : null},
    aggentAssistance: {
        ticket: {},
        fetching: false,
        putTicketFetching: false,
        putAreaTicketFetching: false,
        putAssignedTicketFetching: false,
        postTicketFetching: false,
        submitSuccess: false,
        error: null,
        putAreaSuccess: false,
        ticketHistory: [],
        fetchingTicketHistory: false,
        fetchingPut: false,
        putTicketSuccess:false,
        newTicketId: null
    },
    tags: [],
    selectedTicketLocation: {},
    followingStates: [],
    fetchingFollowingStates: false,
    totalItems: 0,
    offset: 0,
    limit: 10,
    fetchingMassiveClose: false,
    massiveCloseSuccess: false,
    dataMassiveClose: null,
    ticketByPhone: {},
    getTicketByPhone: false,
    postSuccess: false,
    reportUrl: "",
    dateFromReport: "",
    dateToReport: "",
    getReportSucess: false,
    fetchingReport: false,
    parentTicket: null,
    typeOfView: VIEW_TYPE.TABLE
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKETS: {
            return {
                ...state,
                putTicketSuccess:false,
                fetching: true,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    submitSuccess: false
                },
                massiveCloseSuccess: false
            };
        }
        case REFRESH_TICKETS: {
            return {
                ...state,
                fetching: false,
            };
        }
        case GET_TICKETS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                tickets: action.payload.tickets,
                totalItems: action.payload.totalItems,
                fetchingPut: false,
            };
        }
        case SET_TOTAL_ITEMS: {
            return {
                ...state,
                totalItems: action.payload.totalItems,
            };
        }
        case GET_TICKETS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case GET_TICKET_FIRST_TIME: {
            return {
                ...state,
                fetching: true,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    fetching: true,
                    putAreaSuccess: false
                },
                getTicketByPhone: false,
                postSuccess: false
            };
        }
        case GET_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    fetching: false,
                    putAreaSuccess: false
                },
                getTicketByPhone: false,
                postSuccess: false
            };
        }
        case GET_TICKET_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    fetching: false,
                    putTicketFetching: false,
                    ticket: action.payload.ticket
                },
                selectedTicket: {
                    ...state.selectedTicket,
                    ticket: action.payload.ticket
                }
            };
        }
        case GET_TICKET_FAILURE: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    fetching: false
                }
            };
        }
        case SET_SELECTED_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    ticket: {
                        ...action.payload.ticket,
                    }
                },
                selectedTicket: {
                    ...state.selectedTicket,
                    ticket: action.payload.ticket
                }
            };
        }
        case SET_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    ticket: action.payload.ticket
                },
                selectedTicket: {
                    ...state.selectedTicket,
                    ticket: action.payload.ticket
                }
            };
        }
        case POST_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    postTicketFetching: true,
                    error: null
                },
                getTicketByPhone: false,
                postSuccess: false
            };
        }
        case POST_TICKET_FAILURE: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    postTicketFetching: false,
                    error: action.payload.error,
                    submitSuccess: false
                },
                postSuccess: false
            };
        }
        case POST_TICKET_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    postTicketFetching: false,
                    submitSuccess: true,
                    newTicketId: action.payload.id
                },
                postSuccess: true
            };
        }
        case CLEAN_NEW_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    newTicketId: null
                },
            };
        }
        case PUT_TICKET: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putTicketFetching: true,
                    error: null,
                    putTicketSuccess:false,
                }
            };
        }
        case PUT_TICKET_FAILURE: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putTicketFetching: false,
                    submitSuccess: false,
                    putTicketSuccess:false,
                }
            };
        }
        case PUT_TICKET_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putTicketFetching: false,
                    submitSuccess: true,
                    putTicketSuccess:true,
                    ticket: {
                        ...action.payload.ticket,
                        status: action.payload.status
                    }
                }
            };
        }
        case PUT_TICKET_FETCHING_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putTicketFetching: false,
                    submitSuccess: true,
                    putTicketSuccess:true,
                }
            };
        }
        case SET_TICKETS: {
            return {
                ...state,
                tickets: action.payload.tickets
            };
        }
        case GET_TICKETS_SCORE_SUCCESS: {
            return {
                ...state,
                selectedTicket: {
                    ...state.selectedTicket,
                    score: action.payload.score
                }
            };
        }
        case GET_TICKETS_SCORE_FAILURE: {
            return {
                ...state,
                fetching : false
            };
        }
        case PUT_TICKETS_SCORE_SUCCESS: {
            return {
                ...state,
                selectedTicket: {
                    ...state.selectedTicket,
                    score: action.payload.score   
                }
            };
        }
        case PUT_TICKETS_SCORE_FAILURE: {
            return {
                ...state,
                fetching : false            
            }
        };
        case PUT_TICKET_ASSIGNED_REQUEST: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAssignedTicketFetching: true,
                    error: null
                }
            };
        }
        case PUT_TICKET_ASSIGNED_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAssignedTicketFetching: false,
                    ticket: {
                        ...action.payload.ticket,
                        status: action.payload.status
                    }
                }
            }
        }
        case PUT_TICKET_ASSIGNED_FAILURE: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAssignedTicketFetching : false
                },
            }
        }
        case PUT_TICKET_AREA_REQUEST: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAreaTicketFetching: true,
                    putAreaSuccess: false
                }
            };
        }
        case PUT_TICKET_AREA_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAreaTicketFetching: false,
                    putAreaSuccess: true,
                    ticket: {
                        ...state.aggentAssistance.ticket,
                        status: action.payload.idTicket.status
                    }
                }
            }
        }
        case PUT_TICKET_AREA_FAILURE: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    putAreaTicketFetching : false,
                    putAreaSuccess: false
                }   
            }
        }
        case GET_TAGS_REQUEST: {
            return {
                ...state,
            };
        }
        case GET_TAGS_SUCCESS: {
            return {
                ...state,
                tags: action.payload.tags
            };
        }
        case GET_TAGS_FAILURE: {
            return {
                ...state,
            }
        }
        case POST_TAGS_REQUEST: {
            return {
                ...state,
            };
        }
        case POST_TAGS_SUCCESS: {
            return {
                ...state,
            };
        }
        case POST_TAGS_FAILURE: {
            return {
                ...state,
            }
        }
        case SET_CASE_LOCATION: {
            return {
                ...state,
                selectedTicketLocation: action.payload.location
            }
        }
        case GET_FOLLOWING_STATES_REQUEST:
            return {
                ...state,
                fetchingFollowingStates: true
            }
        case GET_FOLLOWING_STATES_SUCCESS:
            return {
                ...state,
                followingStates: action.payload.states,
                fetchingFollowingStates: false
            }
        case GET_FOLLOWING_STATES_FAILURE:
            return {
                ...state,
                fetchingFollowingStates: false
            }
        case GET_TICKET_HISTORY_REQUEST:
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    ticketHistory: action.payload.history,
                    fetchingTicketHistory: true
                }
            }
        case GET_TICKET_HISTORY_SUCCESS:
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    ticketHistory: action.payload.history,
                    fetchingTicketHistory: false
                }
            }
        case GET_TICKET_HISTORY_FAILURE:
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    fetchingTicketHistory: false
                }
            }
        case SET_TICKETS_PAGE:
            return {
                ...state,
                offset: action.payload.offset,
            }
        
        case SET_TICKETS_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }
        case CLEAN_TICKET: {
            return {
                ...state,
                selectedTicket: initialState.selectedTicket,
                aggentAssistance: initialState.aggentAssistance,
                tags : initialState.tags
            }
        }
        case PUT_MASSIVE_CLOSE_TICKETS_REQUEST: {
            return {
                ...state,
                fetchingMassiveClose: true,
                massiveCloseSuccess: false
            };
        }
        case PUT_MASSIVE_CLOSE_TICKETS_SUCCESS: {
            return {
                ...state,
                fetchingMassiveClose: false,
                massiveCloseSuccess: true,
                dataMassiveClose: action.payload.data
            };
        }
        case PUT_MASSIVE_CLOSE_TICKETS_FAILURE: {
            return {
                ...state,
                fetchingMassiveClose: false,
                massiveCloseSuccess: false,
                dataMassiveClose: action.payload.error
            };
        }
        case CLEAN_MASSIVE_CLOSE_DATA: {
            return {
                ...state,
                fetchingMassiveClose: false,
                massiveCloseSuccess: false,
                dataMassiveClose: null
            }
        }
        case GET_TICKET_BY_PHONE_REQUEST: {
            return {
                ...state,
                getTicketByPhone: false,
                fetching: true
            }
        }
        case GET_TICKET_BY_PHONE_SUCCESS: {
            return {
                ...state,
                getTicketByPhone: true,
                ticketByPhone: action.payload.ticket,
                fetching: false,
            }
        }
        case GET_TICKET_BY_PHONE_FAILURE: {
            return {
                ...state,
                getTicketByPhone: false,
                fetching: false
            }
        }
        case CLEAN_TICKET_BY_PHONE: {
            return {
                ...state,
                getTicketByPhone: {},
                fetching: false,
                postSuccess: false
            }
        }
        case GET_REPORT_REQUEST: {
            return {
                ...state,
                dateFromReport: action.payload.dateFromReport,
                dateToReport: action.payload.dateToReport,
                getReportSucess: false,
                fetchingReport: true
            }
        }
        case GET_REPORT_SUCCESS: {
            return {
                ...state,
                reportUrl: action.payload.reportUrl,
                getReportSucess: true,
                fetchingReport: false
            }
        }
        case GET_REPORT_FAILURE: {
            return {
                ...state,
                getReportSucess: false,
                fetchingReport: false
            }
        }
        case SET_PARENT_TICKET: {
            return {
                ...state,
                parentTicket: action.payload.parentTicket
            }
        }
        case CLEAR_PARENT_TICKET: {
            return {
                ...state,
                parentTicket: null
            }
        }
        case SET_TYPE_VIEW: {
            return {
                ...state,
                typeOfView: action.payload.type
            }
        }
        case LINK_CONTACT_TO_TICKET_SUCCESS: {
            return {
                ...state,
                aggentAssistance: {
                    ...state.aggentAssistance,
                    ticket: {
                        ...state.aggentAssistance.ticket,
                        contact: {
                            ...state.aggentAssistance.ticket.contact,
                            contactId: action.payload.contactId
                        }
                    }
                },
                selectedTicket: {
                    ...state.selectedTicket,
                    ticket: {
                        ...state.selectedTicket.ticket,
                        contact: {
                            ...state.selectedTicket.ticket.contact,
                            contactId: action.payload.contactId
                        }
                    }
                }
            };
        }
        default:
            return state;
    };
};
