export const GET_AREAS_REQUEST = 'GET_AREAS_REQUEST';
export const GET_AREAS_FAILURE = 'GET_AREAS_FAILURE';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';

export const SET_AREAS = 'SER_AREAS';
export const SET_AREA = 'SET_AREA';

export const SUBMIT_AREA_REQUEST = 'SUBMIT_AREA_REQUEST';
export const SUBMIT_AREA_FAILURE = 'SUBMIT_AREA_FAILURE';
export const SUBMIT_AREA_SUCCESS = 'SUBMIT_AREA_SUCCESS';

export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE';

export const SET_AREAS_LIMIT = 'SET_AREAS_LIMIT';


export const getAreasRequest = (filters = {}) => ({
    type: GET_AREAS_REQUEST,
    payload: {
        filters
    }
});
export const getAreasSuccess = (areas) => ({
    type: GET_AREAS_SUCCESS,
    payload: {
        areas
    }
});

export const getAreasFailure = (err) => ({
    type: GET_AREAS_FAILURE,
    error: err
});

export const setArea = (area) => ({
    type: SET_AREA,
    payload: {
        area
    }
});

export const setAreas = (areas = []) => ({
    type: SET_AREAS,
    payload: {
        areas
    }
});

export const setAreasLimit = (limit) => ({
    type: SET_AREAS_LIMIT,
    payload: { limit }
})

export const submitAreaRequest = (area,id) => ({
    type: SUBMIT_AREA_REQUEST,
    payload: {
        area,
        id
    }
});

export const submitAreaSuccess = () => ({
    type: SUBMIT_AREA_SUCCESS
});

export const submitAreaFailure = (err) => ({
    type: SUBMIT_AREA_FAILURE,
    error: err
});

export const deleteAreaRequest = (id) => ({
    type: DELETE_AREA_REQUEST,
    payload: { id }
});

export const deleteAreaSuccess = () => ({
    type: DELETE_AREA_SUCCESS,
    payload: { }
});

export const deleteAreaFailure = (err) => ({
    type: DELETE_AREA_FAILURE,
    payload: { err }
});