import { combineReducers } from "redux";
import filters from "./filters";
import integrations from "./integrations";
import pagination from "./pagination";

export default combineReducers({
  filters,
  pagination,
  integrations,
});
