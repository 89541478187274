// * The Drive module is under construction, so this line of code is disabled.
import { defaultPath as drivePath } from '../../drive/routes';

import { defaultPath as settingsPath } from "../../settings/routes";
import { defaultPath as ticketsPath } from "../../tickets/routes";
import { defaultPath as contactsPath } from "../../contacts/routes";
import { defaultPath as dashboardPath } from "../../dashboard/routes";
import { defaultPath as socialPath } from "../../social/routes";
import { defaultPath as statisticsPath } from "../../statistics/routes";
import { defaultPath as automationPath } from "../../automation/routes";
import { defaultPath as integrationsPath } from "../../integrations/routes";
import { defaultPath as businessInteligence } from "../../businessInteligence/routes";

// NOTE: accesslevel
const administrator = "administrator";

const defaultPermissionEntities = {
  [integrationsPath]: true,
  [drivePath]: true,
  [settingsPath]: true,
  [ticketsPath]: false,
  [contactsPath]: true,
  [dashboardPath]: true,
  [socialPath]: true,
  [statisticsPath]: true,
  [automationPath]: true,
  [businessInteligence]: true,
};

const permissionEntities = {
  [administrator]: {
    ...defaultPermissionEntities,
    [ticketsPath]: true,
  },
};

const defaultPermissionRole = {};

const permissionRole = {};

const defaultInitialRoute = {
  administrator: "/dashboard",
};

export const usersAuthorizedToEnterPlatform = [administrator];

export const getAuthProfile = (code = "") => ({
  authRole: permissionRole[code] || defaultPermissionRole,
  authEntities: permissionEntities[code] || defaultPermissionEntities,
  defaultInitialRoute: defaultInitialRoute[code] || settingsPath,
});
