import {
    takeLatest,
    put,
    call,
    select,
} from 'redux-saga/effects';
import {
    GET_COMMENTS,
    REFRESH_COMMENTS,
    getCommentsSuccess,
    getCommentsFailure,
    POST_COMMENT,
    postCommentFailure,
    postCommentSuccess
} from '../actions/comments';
import { showGlobalError, showGlobalErrorV2 } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';
import {
    getCommentsService,
    postCommentService,
    postCommentFileService
} from '../services/comments';
import { prepareFormData } from '../helpers/uploadFiles';
import { getTicket } from '../actions/tickets';

export const getCommentsWorker = function* ({ payload: { ticketId }}) {
    try {
        const token = yield select(accessTokenSelector);

        const { data } = yield call(getCommentsService, token, ticketId);
        yield put(getCommentsSuccess([...data]));
    }
    catch (err) {
        yield put(getCommentsFailure(err));
        yield put(showGlobalError(true, err.response?.data?.error?.code, err.response?.data?.error?.errorKey));
    };
};

export const postCommentWorker = function* ({ payload: { comment, ticketId, attachment, type }}) {
    try {
        const token = yield select(accessTokenSelector);
        if(attachment?.length > 0) {
            const { data: { files } } = yield call(postCommentFileService, token, ticketId, prepareFormData(attachment));

            comment.attachments = files;
        }
        const { data } = yield call(postCommentService, token, ticketId, comment);

        yield put(postCommentSuccess(data));
    }
    catch (err) {
        yield put(postCommentFailure({ type }));
        //Instagram has the new error handler
        //TODO: remove these ifs and use only the V2 error when all
        //social media uses the new error handler
        if(err.response?.data?.error?.code==='ig'){
            yield put(showGlobalErrorV2(err.response?.data?.error))
        }else{
            yield put(showGlobalError(true, 400, 'error_ocurred'));
        }
    };
};

export default [
    takeLatest(GET_COMMENTS, getCommentsWorker),
    takeLatest(REFRESH_COMMENTS, getCommentsWorker),
    takeLatest(POST_COMMENT, postCommentWorker),
];
