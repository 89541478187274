import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { showGlobalError } from "../../app/actions/app";
import { deleteTransitionFailure, deleteTransitionSuccess, DELETE_TRANSITION_REQUEST, getTransitionByIdFailure, getTransitionByIdSuccess, getTransitionsFailure, getTransitionsSuccess, GET_TRANSITIONS_REQUEST, GET_TRANSITION_BY_ID_REQUEST, postTransitionFailure, postTransitionSuccess, POST_TRANSITION_REQUEST, putTransitionFailure, putTransitionSuccess, PUT_TRANSITION_REQUEST } from "../actions/transitions";
import { deleteTransitionServices, getTransitionByIdServices, getTransitionsServices, postTransitionServices, putTransitionServices } from "../services/transitions";

export const getTransitionsWorker = function* ({ payload: { agentId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { transitions },
    } = yield call(getTransitionsServices, token, agentId);
    yield put(getTransitionsSuccess(transitions));
  } catch (err) {
    console.log(err);
    yield put(getTransitionsFailure(err));
  }
};

export const getTransitionByIdWorker = function* ({ payload: { agentId, transitionId } }) {
    try {
      const token = yield select(accessTokenSelector);
      const {
        data: { transition },
      } = yield call(getTransitionByIdServices, token, agentId, transitionId);
      yield put(getTransitionByIdSuccess(transition));
    } catch (err) {
      console.log(err);
      yield put(getTransitionByIdFailure(err));
    }
  };

export const postTransitionWorker = function* ({
  payload: { agentId, transition: transitionData },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const { data: { transition }}  = yield call(postTransitionServices, token, agentId, transitionData);

    yield put(postTransitionSuccess(transition));

  } catch (err) {
    console.log(err);
    yield put(postTransitionFailure(err));
    yield put(showGlobalError(true,err));
  }
};

export const putTransitionWorker = function* ({
  payload: { agentId, transitionId, transitionData },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { transition },
    } = yield call(putTransitionServices, token, agentId, transitionId, transitionData);
    yield put(putTransitionSuccess(transition));
  } catch (err) {
    console.log(err);
    yield put(putTransitionFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export const deleteTransitionWorker = function* ({ payload: { agentId, transitionId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { transition },
    } = yield call(deleteTransitionServices, token, agentId, transitionId);
    yield put(deleteTransitionSuccess(transition._id));
  } catch (err) {
    console.log(err);

    yield put(deleteTransitionFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export default [
  takeLatest(GET_TRANSITIONS_REQUEST, getTransitionsWorker),
  takeLatest(GET_TRANSITION_BY_ID_REQUEST, getTransitionByIdWorker),
  takeLatest(POST_TRANSITION_REQUEST, postTransitionWorker),
  takeLatest(PUT_TRANSITION_REQUEST, putTransitionWorker),
  takeLatest(DELETE_TRANSITION_REQUEST, deleteTransitionWorker),
];
