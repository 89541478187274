import Repository from "../../repository/Repository";

export default class FolderService {
  #endpoint;
  #repository;

  constructor() {
    this.#endpoint = "/knowledge-base/folders";
    this.#repository = new Repository();
  }

  async getFolder(getFolderParams) {
    try {
      const { folderId, token, signal, params } = getFolderParams;

      const endpoint = `${this.#endpoint}${folderId ? `/${folderId}` : ""}`;

      const data = await this.#repository.get({
        endpoint,
        token,
        signal,
        params,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async postFolder(postFolderParams) {
    try {
      const { information, token, signal } = postFolderParams;

      const endpoint = `${this.#endpoint}`;

      const data = await this.#repository.post({
        endpoint,
        information,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
