import {
    POST_TELEGRAM_BOT_REQUEST,
    POST_TELEGRAM_BOT_SUCCESS,
    POST_TELEGRAM_BOT_FAILURE,
    GET_TELEGRAM_BOT_REQUEST,
    GET_TELEGRAM_BOT_SUCCESS,
    GET_TELEGRAM_BOT_FAILURE,
    PUT_TELEGRAM_BOT_REQUEST,
    PUT_TELEGRAM_BOT_SUCCESS,
    PUT_TELEGRAM_BOT_FAILURE,
    DELETE_TELEGRAM_BOT_REQUEST,
    DELETE_TELEGRAM_BOT_SUCCESS,
    DELETE_TELEGRAM_BOT_FAILURE,
} from '../actions/telegramChannel';

const initialState = {
    fetching: true,
    telegramBot: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_TELEGRAM_BOT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_TELEGRAM_BOT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                telegramBot: action.payload.telegramBot
            };
        }
        case POST_TELEGRAM_BOT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case PUT_TELEGRAM_BOT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case PUT_TELEGRAM_BOT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                telegramBot: action.payload.telegramBot
            };
        }
        case PUT_TELEGRAM_BOT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case GET_TELEGRAM_BOT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case GET_TELEGRAM_BOT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                telegramBot: action.payload.telegramBot
            };
        }
        case GET_TELEGRAM_BOT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_TELEGRAM_BOT_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_TELEGRAM_BOT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                telegramBotDeleted: action.payload.telegramBotDeleted,
                telegramBot: {}
            };
        }
        case DELETE_TELEGRAM_BOT_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
}