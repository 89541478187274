export const CHANNEL_TYPE = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
};

export const STATUS_TYPE = {
  PUBLISHED: "published",
  PROGRAMMED: "programmed",
  DELETED: "deleted",
};
export const PUBLICATION_TYPE = {
  FEED: "feed", 
  CAMPAING: "campaing",
};

export const CHANNEL_NAME = {
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  linkedin: "Linkedin",
};

export const FEATURES_GROUP = {
  DETAILS: "Detalles",
};
