import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

import { 
    GET_TICKETS_REASONS_REQUEST, 
    getTicketsReasonsSuccess, 
    getTicketsReasonsFailure,

} from '../actions/ticketsReasons';

import { getTicketsReasonsService } from '../services/ticketsReasons';

export const getTicketReasonsWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { tasksCasesReasons } } = yield call(getTicketsReasonsService, token);

        yield put(getTicketsReasonsSuccess(tasksCasesReasons));
        
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(getTicketsReasonsFailure(err));
        yield put(showGlobalError(true, errorData?.statusCode, errorData?.message));
    }
}

export default [
    takeLatest(GET_TICKETS_REASONS_REQUEST, getTicketReasonsWorker),
]