import { lazy } from 'react';
import { workflowSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Social - EntryPoint" */ './components/EntryPoint'));
const WorkflowDiagram = lazy(() => import(/* webpackChunkName: "Workflow - WorkflowDiagram" */ './components/WorkflowDiagram'));

export const defaultPath = `/automation/workflows`;

export const innerRoutes = [{
    path: `${defaultPath}/`,
    Component: WorkflowDiagram,
    exact: true,
    icon: workflowSVG,
    title: {
        key: '',
        plural: false
    },
    subTitle: {
        key: 'workflow_diagram',
        plural: false
    }
}];

const Workflow = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: workflowSVG,
    title: {
        key: 'workflow',
        plural: false
    },
    subTitle: {
        key: 'workflow_configuration',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Workflow;
