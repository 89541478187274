import axios from 'axios';
import { api, headerFormData, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getTemplatesServices = (token,filters) => {
    return axios({
        method: 'get',
        url: `${api}/v1/templates?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })
}

export const postTemplateService = (token, template) => {
    return axios({
        method: 'post',
        url: `${api}/v1/templates`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: template
    })
}

export const putTemplateService = (token, template) => {
    return axios({
        method: 'put',
        url: `${api}/v1/templates/${template.id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: template
    })
}

export const deleteTemplateService = (token, id) => {
    return axios({
        method: 'delete',
        url: `${api}/v1/templates/${id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
    })
}

export const postImage = (token,data) => {
    return axios({
        method: 'POST',
        url: `${api}/v1/uploads`,
        headers: {
            ...headerFormData,
            'X-Access-Token': token
        },
        data
    })
}