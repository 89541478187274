import { lazy } from "react";
import { socialSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Social - EntryPoint" */ './components/EntryPoint'));
const SocialLoader = lazy(() => import(/* webpackChunkName: "Social - Loader" */ './components/ListLoader'));

export const defaultPath = `/social`;

export const innerRoutes = [{
    path: `${defaultPath}/`,
    Component: SocialLoader,
    exact: true,
    icon: socialSVG,
    title: {
        key: "",
        plural: false
    },
    subTitle: {
        key: 'social_detail',
        plural: false
    }
}];

const Social = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: socialSVG,
    title: {
        key: 'social',
        plural: false
    },
    subTitle: {
        key: 'social_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Social;
