import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getFiltersServices = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/filters/${data.view}/${data.entity}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });