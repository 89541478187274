import { combineReducers } from 'redux';
import profilesSel from './profilesSel';
import departments from './departments';
import channels from './channels';
import status from './status';

export default combineReducers({
    profilesSel,
    departments,
    channels,
    status
});
