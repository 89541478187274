import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postLinkedInAccountsServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/linkedin`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const putLinkedInAccountsServices = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/linkedin/${data.pageId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

// export const getLinkedInAccountsServices = (token, filters) => 
//     axios({
//         method: 'get',
//         url: `${api}/v1/linkedin`,
//         headers: {
//             ...headers,
//             'X-Access-Token': token,
//         },
//     });

export const getLinkedInAccountsServices = (token, filters) => { 
    return {
        data : {
            linkedInAccount: {

            }
        }
    };
};

export const deleteLinkedInAccountsServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/linkedin/${data.pageId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
