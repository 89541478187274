import { lazy } from "react";
import { contactsSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Contacts - EntryPoint" */ './components/EntryPoint'));
const ContactLoader = lazy(() => import(/* webpackChunkName: "Contacts - Loader" */ './components/ListLoader'));
const FormUserLoader = lazy(() => import(/* webpackChunkName: "FormUserLoader" */ './components/FormUserLoader'));


export const defaultPath = `/contacts`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: ContactLoader,
        exact: true,
        icon: contactsSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'contact_list',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: FormUserLoader,
        exact: true,
        icon: contactsSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'modification_client',
            plural: false
        }
    }
];

const Contacts = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: contactsSVG,
    title: {
        key: 'contacts',
        plural: false
    },
    subTitle: {
        key: 'contacts_list_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Contacts;
