import axios from "axios";
import { apiGateway } from "../../app/conf";

const axiosGatewayInstance = axios.create({
  baseURL: `${apiGateway}/api`,
});

axiosGatewayInstance.interceptors.request.use(
  (configuration) => {
    return configuration;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosGatewayInstance;
