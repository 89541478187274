export const GET_TICKETS = 'GET_TICKETS';
export const getTickets = (filters = {}) => ({
    type: GET_TICKETS,
    payload: { filters }
});

export const REFRESH_TICKETS = 'REFRESH_TICKETS';
export const refreshTickets = (filters = {}) => ({
    type: REFRESH_TICKETS,
    payload: { filters }
});

export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const getTicketsSuccess = (tickets = [], totalItems = 0) => ({
    type: GET_TICKETS_SUCCESS,
    payload: { 
        tickets,
        totalItems,
    }
});

export const GET_TICKETS_FAILURE = 'GET_TICKETS_FAILURE';
export const getTicketsFailure = (error = {}) => ({
    type: GET_TICKETS_FAILURE,
    payload: { error }
});

export const GET_TICKET = 'GET_TICKET';
export const getTicket = ticketId => ({
    type: GET_TICKET,
    payload: { ticketId }
});

export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const getTicketSuccess = ticket => ({
    type: GET_TICKET_SUCCESS,
    payload: { ticket }
});

//Separate action to show the loader only when it brings the case data the first time.
export const GET_TICKET_FIRST_TIME = 'GET_TICKET_FIRST_TIME';
export const getTicketFirstTime = ticketId => ({
    type: GET_TICKET_FIRST_TIME,
    payload: { ticketId }
});

export const GET_TICKET_FAILURE = 'GET_TICKET_FAILURE';
export const getTicketFailure = (error = {}) => ({
    type: GET_TICKET_FAILURE,
    payload: { error }
});

export const SET_SELECTED_TICKET = 'SET_SELECTED_TICKET';
export const setSelectedTicket = (ticket = {}) => ({
    type: SET_SELECTED_TICKET,
    payload: { ticket }
});

export const POST_TICKET = 'POST_TICKET';
export const postTicket = (ticket, tags) => ({
    type: POST_TICKET,
    payload: { ticket, tags }
});

export const POST_TICKET_SUCCESS = 'POST_TICKET_SUCCESS';
export const postTicketSuccess = (id) => ({
    type: POST_TICKET_SUCCESS,
    payload: { id }
});

export const CLEAN_NEW_TICKET = 'CLEAN_NEW_TICKET';
export const cleanNewTicket = () => ({
    type: CLEAN_NEW_TICKET
});

export const POST_TICKET_FAILURE = 'POST_TICKET_FAILURE';
export const postTicketFailure = (error = {}) => ({
    type: POST_TICKET_FAILURE,
    payload: { error }
});
export const PUT_TICKET = 'PUT_TICKET';
export const putTicket = (ticket, type, status) => ({
    type: PUT_TICKET,
    payload: { ticket, type, status }
});

export const PUT_TICKET_SUCCESS = 'PUT_TICKET_SUCCESS';
export const putTicketSuccess = (ticket = {}, status = "") => ({
    type: PUT_TICKET_SUCCESS,
    payload: { ticket, status }
});

export const PUT_TICKET_FETCHING_SUCCESS = 'PUT_TICKET_FETCHING_SUCCESS';
export const putTicketFetchingSuccess = () => ({
    type: PUT_TICKET_FETCHING_SUCCESS,
});

export const PUT_TICKET_FAILURE = 'PUT_TICKET_FAILURE';
export const putTicketFailure = (error = {}) => ({
    type: PUT_TICKET_FAILURE,
    payload: { error }
});

export const SET_TICKETS = 'SET_TICKETS';
export const setTickets = (tickets = []) => ({
    type: SET_TICKETS,
    payload: {
        tickets
    }
});

export const GET_TICKETS_SCORE_FAILURE = 'GET_TICKETS_SCORE_FAILURE';
export const getTicketsScoreFailure = (error={}) => ({
    type: GET_TICKETS_SCORE_FAILURE,
    payload: { error }
});

export const GET_TICKETS_SCORE_REQUEST = 'GET_TICKETS_SCORE_REQUEST';
export const getTicketsScoreRequest = ticketId => ({
    type: GET_TICKETS_SCORE_REQUEST,
    payload: { ticketId }
});

export const GET_TICKETS_SCORE_SUCCESS = 'GET_TICKETS_SCORE_SUCCESS';
export const getTicketsScoreSuccess = ticket => ({
    type: GET_TICKETS_SCORE_SUCCESS,
    payload: { ticket }
});

export const PUT_TICKETS_SCORE_FAILURE = 'PUT_TICKETS_SCORE_FAILURE';
export const putTicketsScoreFailure = (error={}) => ({
    type: PUT_TICKETS_SCORE_FAILURE,
    payload: { error }
});

export const PUT_TICKETS_SCORE_REQUEST = 'PUT_TICKETS_SCORE_REQUEST';
export const putTicketsScoreRequest = ({ticketId,score}) => ({
    type: PUT_TICKETS_SCORE_REQUEST,
    payload: { ticketId, score }
});

export const PUT_TICKETS_SCORE_SUCCESS = 'PUT_TICKETS_SCORE_SUCCESS';
export const putTicketsScoreSuccess = ticket => ({
    type: PUT_TICKETS_SCORE_SUCCESS,
    payload: { ticket }
});

export const PUT_TICKET_ASSIGNED_REQUEST = 'PUT_TICKET_ASSIGNED_REQUEST';
export const putTicketAssignedRequest = (ticket ) => ({
    type: PUT_TICKET_ASSIGNED_REQUEST,
    payload: { ticket }
});

export const PUT_TICKET_ASSIGNED_SUCCESS = 'PUT_TICKET_ASSIGNED_SUCCESS';
export const putTicketAssignedSuccess = (ticket = {}, status = "") => ({
    type: PUT_TICKET_ASSIGNED_SUCCESS,
    payload: { ticket, status }
});

export const PUT_TICKET_ASSIGNED_FAILURE = 'PUT_TICKET_ASSIGNED_FAILURE';
export const putTicketAssignedFailure = (error = {}) => ({
    type: PUT_TICKET_ASSIGNED_FAILURE,
    payload: { error }
});

export const PUT_TICKET_AREA_REQUEST= 'PUT_TICKET_AREA_REQUEST';
export const putTicketAreaRequest = (idTicket, project ) => ({
    type: PUT_TICKET_AREA_REQUEST,
    payload: { idTicket, project }
});

export const PUT_TICKET_AREA_SUCCESS = 'PUT_TICKET_AREA_SUCCESS';
export const putTicketAreaSuccess = (idTicket = {}, status = "") => ({
    type: PUT_TICKET_AREA_SUCCESS,
    payload: { idTicket, status }
});

export const PUT_TICKET_AREA_FAILURE = 'PUT_TICKET_AREA_FAILURE';
export const putTicketAreaFailure = (error = {}) => ({
    type: PUT_TICKET_AREA_FAILURE,
    payload: { error }
});

export const POST_TAGS_REQUEST = 'POST_TAGS_REQUEST';
export const postTagsRequest = ({ ticketId, tags }) => ({
    type: POST_TAGS_REQUEST,
    payload: { ticketId, tags }
});

export const POST_TAGS_SUCCESS = 'POST_TAGS_SUCCESS ';
export const postTagsSuccess = () => ({
    type: POST_TAGS_SUCCESS,
});

export const POST_TAGS_FAILURE = 'POST_TAGS_FAILURE';
export const postTagsFailure = (error) => ({
    type: POST_TICKET_FAILURE,
    payload: { error }
});

export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const getTagsRequest = (ticketId) => ({
    type: GET_TAGS_REQUEST,
    payload: { ticketId }
});

export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const getTagsSuccess = (tags = []) => ({
    type: GET_TAGS_SUCCESS,
    payload: { tags }
});

export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
export const getTagsFailure = (error) => ({
    type: GET_TAGS_FAILURE,
    payload: { error }
});

export const SET_CASE_LOCATION = 'SET_CASE_LOCATION';
export const setCaseLocation = (location) => ({
    type: SET_CASE_LOCATION,
    payload: { location }
})

export const GET_FOLLOWING_STATES_REQUEST = 'GET_FOLLOWING_STATES_REQUEST';
export const getFollowingStatesRequest = (state) => ({
    type:GET_FOLLOWING_STATES_REQUEST,
    payload: { state }
})

export const GET_FOLLOWING_STATES_SUCCESS = 'GET_FOLLOWING_STATES_SUCCESS';
export const getFollowingStatesSuccess = (states) => ({
    type:GET_FOLLOWING_STATES_SUCCESS,
    payload: { states }
})

export const GET_FOLLOWING_STATES_FAILURE = 'GET_FOLLOWING_STATES_FAILURE';
export const getFollowingStatesFailure = (err) => ({
    type:GET_FOLLOWING_STATES_FAILURE,
    payload: { err }
})


export const GET_TICKET_HISTORY_REQUEST = 'GET_TICKET_HISTORY_REQUEST';
export const getTicketHistoryRequest = (idTicket) => ({
    type: GET_TICKET_HISTORY_REQUEST,
    payload: { idTicket }
})

export const GET_TICKET_HISTORY_SUCCESS = 'GET_TICKET_HISTORY_SUCCESS';
export const getTicketHistorySuccess = (history) => ({
    type: GET_TICKET_HISTORY_SUCCESS,
    payload: { history }
})

export const GET_TICKET_HISTORY_FAILURE = 'GET_TICKET_HISTORY_FAILURE';
export const getTicketHistoryFailure = (err) => ({
    type: GET_TICKET_HISTORY_FAILURE,
    payload: { err }
})

export const SET_TICKETS_PAGE = 'SET_TICKETS_PAGE';
export const setTicketsPage = (offset) => ({
    type: SET_TICKETS_PAGE,
    payload: { offset }
})

export const SET_TICKETS_LIMIT = 'SET_TICKETS_LIMIT';
export const setTicketsLimit = (limit) => ({
    type: SET_TICKETS_LIMIT,
    payload: { limit }
})

export const CLEAN_TICKET = 'CLEAN_TICKET';
export const cleanTicket = () => ({
    type: CLEAN_TICKET,
});

export const PUT_MASSIVE_CLOSE_TICKETS_REQUEST = 'PUT_MASSIVE_CLOSE_TICKETS_REQUEST';
export const putMassiveCloseTicketsRequest = (ticketIds, tags, comment, commentType, destinationStatus) => ({
    type: PUT_MASSIVE_CLOSE_TICKETS_REQUEST,
    payload: { ticketIds, tags, comment, commentType, destinationStatus }
})

export const PUT_MASSIVE_CLOSE_TICKETS_SUCCESS = 'PUT_MASSIVE_CLOSE_TICKETS_SUCCESS';
export const putMassiveCloseTicketsSuccess = (data) => ({
    type: PUT_MASSIVE_CLOSE_TICKETS_SUCCESS,
    payload: { data }
})

export const PUT_MASSIVE_CLOSE_TICKETS_FAILURE = 'PUT_MASSIVE_CLOSE_TICKETS_FAILURE';
export const putMassiveCloseTicketsFailure = (error) => ({
    type: PUT_MASSIVE_CLOSE_TICKETS_FAILURE,
    payload: { error }
});

export const CLEAN_MASSIVE_CLOSE_DATA = 'CLEAN_MASSIVE_CLOSE_DATA';
export const cleanMassiveCloseTicketsData = () => ({
    type: CLEAN_MASSIVE_CLOSE_DATA
})


export const GET_TICKET_BY_PHONE_REQUEST = 'GET_TICKET_BY_PHONE_REQUEST';
export const getTicketByPhoneRequest = (phone) => ({
    type: GET_TICKET_BY_PHONE_REQUEST,
    payload: {
        phone
    }
})

export const GET_TICKET_BY_PHONE_SUCCESS= 'GET_TICKET_BY_PHONE_SUCCESS';
export const getTicketByPhoneSuccess = (ticket) => ({
    type: GET_TICKET_BY_PHONE_SUCCESS,
    payload: {
        ticket
    }
})

export const GET_TICKET_BY_PHONE_FAILURE = 'GET_TICKET_BY_PHONE_FAILURE';
export const getTicketByPhoneFailure = (err) => ({
    type: GET_TICKET_BY_PHONE_FAILURE,
})

export const CLEAN_TICKET_BY_PHONE = 'CLEAN_TICKET_BY_PHONE';
export const cleanTicketByPhone = () => ({
    type: CLEAN_TICKET_BY_PHONE,
})

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const getReportRequest = (dateFromReport, dateToReport) => ({
    type: GET_REPORT_REQUEST,
    payload: {
        dateFromReport,
        dateToReport
    }
})

export const GET_REPORT_SUCCESS= 'GET_REPORT_SUCCESS';
export const getReportSuccess = (reportUrl) => ({
    type: GET_REPORT_SUCCESS,
    payload: {
        reportUrl
    }
})

export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const getReportFailure = (err) => ({
    type: GET_REPORT_FAILURE,
})

export const SET_PARENT_TICKET= 'SET_PARENT_TICKET';
export const setParentTicket = (parentTicket) => ({
    type: SET_PARENT_TICKET,
    payload: {
        parentTicket
    }
})

export const CLEAR_PARENT_TICKET = 'CLEAR_PARENT_TICKET';
export const clearParentTicket = () => ({
    type: CLEAR_PARENT_TICKET,
})

export const SET_TYPE_VIEW = 'SET_TYPE_VIEW';
export const setTypeView = (type) => ({
    type: SET_TYPE_VIEW,
    payload: {
        type
    }
})

export const SET_TICKET = 'SET_TICKET';
export const setTicket= (ticket) => ({
    type: SET_TICKET,
    payload: {
        ticket
    }
})

export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS';
export const setTotalItems= (totalItems) => ({
    type: SET_TOTAL_ITEMS,
    payload: {
        totalItems
    }
})

export const LINK_CONTACT_TO_TICKET_SUCCESS = 'LINK_CONTACT_TO_TICKET_SUCCESS';
export const linkContactToTicketSuccess= (contactId) => ({
    type: LINK_CONTACT_TO_TICKET_SUCCESS,
    payload: {
        contactId
    }
})