// back error
const USER_ERRORS =  {
    EXISTING_AGENT_CODE: 'existing_agent_code',
};

// custom error
const ERRORS = {
    [USER_ERRORS.EXISTING_AGENT_CODE]: 'existing_agent_code',
};

export const getUserErrorCode = (error) => {
    return ERRORS[error];
};
