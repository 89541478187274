import {
    takeLatest,
    put,
    call,
    select,
} from 'redux-saga/effects';
import {
    GET_NOTIFICATION_CHANNELS_REQUEST,
    SUBMIT_NOTIFICATION_CHANNELS_REQUEST,
    getNotificationChannelsSuccess,
    getNotificationChannelsFailure,
    submitNotificationChannelsSuccess,
    submitNotificationChannelsFailure,
    getNotificationChannelsRequest
} from '../actions/notificationChannels';
import {
    getNotificationChannelsService,
    putNotificationChannelsService
} from '../services/notificationChannels'
import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

export const getNotificationChannelsWorker = function*() {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(getNotificationChannelsService, token);        
        yield put (getNotificationChannelsSuccess(data));
    }
    catch (err) {
        console.log(err);
        yield put (getNotificationChannelsFailure(err));
    }
};

export const submitNotificationChannelsWorker = function* ({ payload: { notificationChannels } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(putNotificationChannelsService, token, notificationChannels);
        yield put(submitNotificationChannelsSuccess(data));
        yield put(showGlobalError(true, 200, 'notification_channels_updated'));
        getNotificationChannelsRequest();
    }
    catch (err) {
        yield put(submitNotificationChannelsFailure(err));
        yield put(showGlobalError(true, err));
    };
};


export default [
    takeLatest(GET_NOTIFICATION_CHANNELS_REQUEST, getNotificationChannelsWorker),
    takeLatest(SUBMIT_NOTIFICATION_CHANNELS_REQUEST, submitNotificationChannelsWorker),

];