export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const UPDATE_LOGGED_USER = 'UPDATE_LOGGED_USER';
export const GET_AGENT_STATUS_REQUEST = 'GET_AGENT_STATUS_REQUEST';
export const GET_AGENT_STATUS_SUCCESS = 'GET_AGENT_STATUS_SUCCESS';
export const GET_AGENT_STATUS_FAILURE = 'GET_AGENT_STATUS_FAILURE';
export const GET_CALL_LOG_REQUEST = 'GET_CALL_LOG_REQUEST';
export const GET_CALL_LOG_SUCCESS = 'GET_CALL_LOG_SUCCESS';
export const GET_CALL_LOG_FAILURE = 'GET_CALL_LOG_FAILURE';
export const CLEAN_CALL_LOG = 'CLEAN_CALL_LOG';

export const CHANGE_EXPIRED_PASSWORD_REQUEST = 'CHANGE_EXPIRED_PASSWORD_REQUEST';
export const CHANGE_EXPIRED_PASSWORD_SUCCESS = 'CHANGE_EXPIRED_PASSWORD_SUCCESS';
export const CHANGE_EXPIRED_PASSWORD_FAILURE = 'CHANGE_EXPIRED_PASSWORD_FAILURE';

export const SET_AGENT_STATUS_SUCCESS = 'SET_AGENT_STATUS_SUCCESS';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS'

export const POLLING_STATUS_SUCCESS = 'POLLING_STATUS_SUCCESS';

export const setUserLogin = ({ user, permission, permisionEntities, profiles } = {}) => ({
    type: SET_USER_LOGIN,
    payload: {
        user,
        permission,
        permisionEntities,
        profiles
    }
});

export const updateLoggedUser = ( user ) => ({
    type: UPDATE_LOGGED_USER,
    payload: {
        user
    }
})

export const updateUserPermissions = ({permission, permisionEntities}) => ({
    type: UPDATE_USER_PERMISSIONS,
    payload: {
        permission,
        permisionEntities,
    }
})

export const getAgentStatusRequest = ( user , polling ) => ({
    type: GET_AGENT_STATUS_REQUEST,
    payload: {
        user,
        polling
    }
});

export const getAgentStatusSuccess = user => ({
    type: GET_AGENT_STATUS_SUCCESS,
    payload: { user }
});

export const setAgentStatusSuccess = user => ({
    type: SET_AGENT_STATUS_SUCCESS,
    payload: { user }
});

export const getAgentStatusFailure = (error={}) => ({
    type: GET_AGENT_STATUS_FAILURE,
    payload: { error }
});

export const getCallLogRequest = (id) => ({
    type: GET_CALL_LOG_REQUEST,
    payload: {id}
})

export const getCallLogSuccess = (log) => ({
    type: GET_CALL_LOG_SUCCESS,
    payload: {
        log
    }
})

export const getCallLogFailure = (error) => ({
    type: GET_CALL_LOG_FAILURE,
    payload: error
})

export const cleanCallLog = () => ({
    type: CLEAN_CALL_LOG,
})

export const changeExpiredPasswordRequest = ({ _id, oldPassword, password }) => ({
    type: CHANGE_EXPIRED_PASSWORD_REQUEST,
    payload: {
        _id,
        oldPassword,
        password
    }
})

export const changeExpiredPasswordSuccess = (user) => ({
    type: CHANGE_EXPIRED_PASSWORD_SUCCESS,
    payload: {
        user
    }
})

export const changeExpiredPasswordFailure = (err) => ({
    type: CHANGE_EXPIRED_PASSWORD_FAILURE,
    payload: {
        err
    }
})