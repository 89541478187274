import { memo, useCallback, useEffect, useState } from "react";
import { getCompanyConfigurationService } from "../../../../../shared/services/uploadFiles";
import { toast } from "../../../../../shared/helpers/toast";

const Company = ({ company, setCompany }) => {
  const [urlCompany, setUrlCompany] = useState("");

  useEffect(() => {
    setUrlCompany(window.location.href);
  }, []);

  const fetchGetCompany = useCallback(async () => {
    try {
      const response = await getCompanyConfigurationService(urlCompany);

      if (!response?.data?.picture) return;

      if (response.data && response.data.picture) {
        setCompany(response.data);
      }
    } catch (error) {
      toast("Error al obtener los datos de la compañia", "error");
    }
  }, [urlCompany]);

  useEffect(() => {
    if (urlCompany && urlCompany.length > 0) {
      fetchGetCompany();
    }
  }, [urlCompany]);

  return null;
};
export default memo(Company);
