import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getTicketsDetailsService = (token) =>
    axios({
        method: 'get',
        url: `${api}/v1/tasksCasesDetails`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })