import axiosInstance from "../configuration/axios";
import axiosGatewayInstance from "../configuration/axiosGateway";

export default class Repository {
  static #instance = null;

  constructor() {
    if (Repository.#instance) {
      return Repository.#instance;
    }

    this.axiosInstance = axiosInstance;
    this.axiosGatewayInstance = axiosGatewayInstance;
    Repository.#instance = this;
  }

  async get(getParams) {
    const { endpoint, token, signal, params, authorization, apiToken, useGateway } = getParams;

    const configuration = {
      headers: {
        "X-Access-Token": token,
        authorization
      },
      signal,
      params,
    };
    
    if (apiToken){
      configuration.headers.token = apiToken
    }

    const instance = useGateway ? this.axiosGatewayInstance: this.axiosInstance;

    try {
      const response = await instance.get(endpoint, configuration);

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async publicGet(publicGetParams) {
    const { endpoint, signal, params } = publicGetParams;

    const configuration = {
      signal,
      params,
    };

    try {
      const response = await this.axiosInstance.get(endpoint, configuration);

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async delete(deleteParams) { 
    const { endpoint, token, signal, params } = deleteParams;

    const configuration = {
      headers: {
        "X-Access-Token": token,
      },
      signal,
      params
    };
    try {
      const response = await this.axiosInstance.delete(endpoint, configuration);

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async post(postParams) {
    const { endpoint, information, token, signal, isFormData, authorization, useGateway } = postParams;

    const configuration = {
      headers: {
        "X-Access-Token": token,
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
      signal,
    };
    if(authorization){
      configuration.headers.authorization = authorization
    }

    const instance = useGateway? this.axiosGatewayInstance: this.axiosInstance;

    try {
      const response = await instance.post(
        endpoint,
        information,
        configuration
      );

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(putParams) {
    const { endpoint, information, token, signal, isFormData } = putParams;

    const configuration = {
      headers: {
        "X-Access-Token": token,
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
      signal,
    };

    try {
      const response = await this.axiosInstance.put(
        endpoint,
        information,
        configuration
      );

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async patch(putParams) {
    const { endpoint, information, token, signal, isFormData, useGateway } = putParams;

    const configuration = {
      headers: {
        "X-Access-Token": token,
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
      signal,
    };

    const instance = useGateway? this.axiosGatewayInstance: this.axiosInstance;

    try {
      const response = await instance.patch(
        endpoint,
        information,
        configuration
      );

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
