/**
 * Possible states of a file in the system.
 * @enum {string}
 */
export const FILE_STATE = {
  /**
   * The file has been rejected.
   */
  REJECTED: "REJECTED",
  /**
   * The file has been approved.
   */
  APPROVED: "APPROVED",
  /**
   * The file has not been reviewed yet.
   */
  NOT_REVIEWED: "NOT_REVIEWED",
  /**
   * The file has been reviewed.
   */
  REVIEWED: "REVIEWED",
};

/**
 * Visibility options for a file in the system.
 * @enum {boolean}
 */
export const FILE_VISIBLE = {
  /**
   * The file is public and visible outside.
   */
  PUBLIC: true,
  /**
   * The file is private and not visible outside.
   */
  PRIVATE: false,
};

/**
 * Represents user roles in the system related to files.
 * @enum {string}
 */
export const FILE_ROL = {
  /**
   * Represents an administrator role for files.
   */
  ADMINISTRATOR: "administrator",
  /**
   * Represents an internal agent role for files.
   */
  INTERNAL_AGENT: "internal_agent",
  /**
   * Represents an external user role for files.
   */
  EXTERNAL_USER: "external_user",
};

