export const SET_SOCKET_EVENT = "SET_SOCKET_EVENT";
export const SET_AGENT_STATUS_SOCKET_EVENT = "SET_AGENT_STATUS_SOCKET_EVENT";
export const SET_PROFILE_UPDATED_SOCKET_EVENT =
  "SET_PROFILE_UPDATED_SOCKET_EVENT";
export const SET_COMMENTS_SOCKET_EVENT = "SET_COMMENTS_SOCKET_EVENT";
export const SET_TASKS_SOCKET_EVENT = "SET_TASKS_SOCKET_EVENT";
export const SET_TASK_SOCKET_EVENT = "SET_TASK_SOCKET_EVENT";
export const SET_SOCIAL_OUTGOING_EVENT = "SET_SOCIAL_OUTGOING_EVENT";
export const SET_SOCIAL_INCOMING_EVENT = "SET_SOCIAL_INCOMING_EVENT"; 
export const SET_UPDATE_COMMENT_EVENT = "SET_UPDATE_COMMENT_EVENT"; 
export const SET_NEW_COMMENT_EVENT = "SET_NEW_COMMENT_EVENT"; 

export const SET_TASK_CREATED_EVENT = "SET_TASK_CREATED_EVENT"; 
export const SET_TASK_UPDATED_EVENT = "SET_TASK_UPDATED_EVENT"; 
export const SET_TASKS_CLOSED_EVENT = "SET_TASKS_CLOSED_EVENT"; 
export const ADD_TASKS_LIST_EVENT = "ADD_TASKS_LIST_EVENT"; 
export const SET_TASKS_LIST_EVENT = "SET_TASKS_LIST_EVENT"; 

export const setNotificationSocketEvent = (socketEvent) => {
  return {
    type: SET_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setAgentStatusEvent = (socketEvent) => {
  return {
    type: SET_AGENT_STATUS_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setProfileUpdatedEvent = (socketEvent) => {
  return {
    type: SET_PROFILE_UPDATED_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setTasksEvent = (socketEvent) => {
  return {
    type: SET_TASKS_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setCommentsSocketEvent = (socketEvent) => {
  return {
    type: SET_COMMENTS_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setTaskEvent = (socketEvent) => {
  return {
    type: SET_TASK_SOCKET_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setSocialIncomingEvent = (socketEvent) => {
  return {
    type: SET_SOCIAL_INCOMING_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setSocialOutgoingEvent = (socketEvent) => {
  return {
    type: SET_SOCIAL_OUTGOING_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setUpdateCommentEvent = (socketEvent) => {
  return {
    type: SET_UPDATE_COMMENT_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setNewCommentEvent = (socketEvent) => {
  return {
    type: SET_NEW_COMMENT_EVENT,
    payload: {
      socketEvent,
    },
  };
};
export const setTaskCreatedEvent = (socketEvent) => {
  return {
    type: SET_TASK_CREATED_EVENT,
    payload: {
      socketEvent,
    },
  };
};
export const setTaskUpdatedEvent = (socketEvent) => {
  return {
    type: SET_TASK_UPDATED_EVENT,
    payload: {
      socketEvent,
    },
  };
};
export const setTasksClosedEvent = (socketEvent) => {
  return {
    type: SET_TASKS_CLOSED_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const addTasksListEvent = (socketEvent) => {
  return {
    type: ADD_TASKS_LIST_EVENT,
    payload: {
      socketEvent,
    },
  };
};

export const setTasksListEvent = (events) => {
  return {
    type: SET_TASKS_LIST_EVENT,
    payload: {
      events,
    },
  };
};
