export const GET_TASKS_RESOLVED_REQUEST = 'GET_TASKS_RESOLVED_REQUEST';
export const GET_TASKS_RESOLVED_SUCCESS = 'GET_TASKS_RESOLVED_SUCCESS';
export const GET_TASKS_RESOLVED_FAILURE = 'GET_TASKS_RESOLVED_FAILURE';


export const getTasksResolvedRequest = (filters = {}) => ({
    type: GET_TASKS_RESOLVED_REQUEST,
    payload: {
        filters
    }
});

export const getTasksResolvedSuccess = (statistics) => ({
    type: GET_TASKS_RESOLVED_SUCCESS,
    payload: {
        statistics
    }
});

export const getTasksResolvedFailure = (err = {}) => ({
    type: GET_TASKS_RESOLVED_FAILURE,
    payload: {
        err
    }
});