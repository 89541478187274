import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getAgentStatusService = (token) => 
    axios({
        method: 'get',
        url: `${api}/v1/users/agentstatus`,
        headers: {
            ...headers,
            'access-token': token
        }
    });

export const getLogAgentStatusService = (token, id) => 
    axios({
        method: 'get',
        url: `${api}/v1/users/agentstatus/${id}`,
        headers: {
            ...headers,
            'access-token': token
        }
    });