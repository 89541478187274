import { SET_FILTERS_CONTACTS, CLEAN_FILTERS } from '../actions/filters';

export const initialState = {
    text: '',
    identifier: '',
    channel: '',
    phone: '',
    email: '',
    lastName: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_CONTACTS:
            return{
                text: action.payload.text,
                identifier: action.payload.identifier,
                channel: action.payload.channels,
                phone: action.payload.phone,
                email: action.payload.email,
                lastName: action.payload.lastName
            }
        case CLEAN_FILTERS:{
            return{
                ...initialState,
            }
        }    
        default:
            return state;
    }
}