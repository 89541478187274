import {
    POST_INSTAGRAM_USER_REQUEST,
    POST_INSTAGRAM_USER_SUCCESS,
    POST_INSTAGRAM_USER_FAILURE,
    GET_INSTAGRAM_USER_REQUEST,
    GET_INSTAGRAM_USER_SUCCESS,
    GET_INSTAGRAM_USER_FAILURE,
    PUT_INSTAGRAM_USER_REQUEST,
    PUT_INSTAGRAM_USER_SUCCESS,
    PUT_INSTAGRAM_USER_FAILURE,
    DELETE_INSTAGRAM_USER_REQUEST,
    DELETE_INSTAGRAM_USER_SUCCESS,
    DELETE_INSTAGRAM_USER_FAILURE,
} from '../actions/instagramChannel';

const initialState = {
    fetching: true,
    instagramUser: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_INSTAGRAM_USER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_INSTAGRAM_USER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                instagramUser: action.payload.instagramUser
            };
        }
        case POST_INSTAGRAM_USER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case PUT_INSTAGRAM_USER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case PUT_INSTAGRAM_USER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                instagramUser: action.payload.instagramUser
            };
        }
        case PUT_INSTAGRAM_USER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case GET_INSTAGRAM_USER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case GET_INSTAGRAM_USER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                instagramUser: action.payload.instagramUser
            };
        }
        case GET_INSTAGRAM_USER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_INSTAGRAM_USER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_INSTAGRAM_USER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                instagramUserDeleted: action.payload.instagramUserDeleted,
                instagramUser: {}
            };
        }
        case DELETE_INSTAGRAM_USER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
}