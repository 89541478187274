import { memo, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { cdnLogin } from '../../../../conf';

const Version = withRouter(({ history, currentVersion, setVersion }) => {

    const cleanStorage = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    const versionControl = useCallback(() => {
        console.info("Version: ", currentVersion);

        if (currentVersion && process.env.REACT_APP_VERSION !== currentVersion) {
            cleanStorage();
            setVersion(process.env.REACT_APP_VERSION);
            // window.location.href = cdnLogin;
            history.push(cdnLogin);
        }

        if (!currentVersion) {
            setVersion(process.env.REACT_APP_VERSION);
            console.info("Version: ", currentVersion);
        }

    }, [currentVersion, setVersion, cleanStorage, history]);

    useEffect(() => { versionControl(); }, [versionControl]);

    return (null);
});

export default memo(Version);