import { memo, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { cdnLogin } from "../../../../conf";
import { setTokens } from "../../../../../auth/actions/token";
import { setUserLogin } from "../../../../../home/actions/loggedUser";
import Cookies from "js-cookie";
import { getDomain } from "../../../../../auth/helpers";

const HOME = "/";
const PAGE_SIGNIN = "/signin";
const PAGE_TERMS = "/terms";
const PAGE_REDIRECT_LOGIN = "/redirect_login";
const PAGE_SIGNIN_RECOVER = "/signin/recover";

const LoggedUser = withRouter(({ history, loggedUser, validTokens }) => {
  const cleanStorage = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("session", { domain: getDomain() });
  }, []);

  useEffect(() => {
    const cookies = Cookies.get("session", { domain: getDomain() });

    // Valid tokens es true si
    // 1) Access token es distinto de null
    // 1) Refresh token es distinto de null

    // No tiene cookies
    // Tenes cookies pero validTokens es false
    // Tenes cookies y validTokens es true

    const isLoggedIn = cookies && validTokens;

    if (isLoggedIn && history.location.pathname.startsWith(PAGE_SIGNIN)) {
      history.push(HOME);
      return;
    }

    if (!isLoggedIn) {
      // Limpiar tokens de redux
      setTokens();

      //Limpiar el usuarios junto con el perfil,permisos de redux
      setUserLogin();

      //Valida si es una ruta privada
      const isPrivateRoute =
        history.location.pathname !== PAGE_TERMS &&
        !history.location.pathname.includes(PAGE_REDIRECT_LOGIN) &&
        !history.location.pathname.includes(PAGE_SIGNIN_RECOVER);

      if (!isPrivateRoute) return;

      history.push(cdnLogin);
    }
  }, [history.location, validTokens, cleanStorage, loggedUser]);

  return null;
});

export default memo(LoggedUser);
