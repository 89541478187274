import {
  removeFromArrayBy_id,
  removeObjectsFromArray,
  replaceFromArrayBy_id,
} from "../../shared/helpers/commons";
import { buildProfiles, buildUsers } from "../../users/helpers/users";
import {
  DELETE_ACTION_FAILURE,
  DELETE_ACTION_REQUEST,
  DELETE_ACTION_SUCCESS,
  GET_ACTIONS_FAILURE,
  GET_ACTIONS_REQUEST,
  GET_ACTIONS_SUCCESS,
  POST_ACTIONS_FAILURE,
  POST_ACTIONS_REQUEST,
  POST_ACTIONS_SUCCESS,
  POST_ACTION_FAILURE,
  POST_ACTION_REQUEST,
  POST_ACTION_SUCCESS,
} from "../actions/actions";
import {
  GET_BOTS_REQUEST,
  GET_BOTS_SUCCESS,
  GET_BOTS_FAILURE,
  SET_BOTS,
  SET_BOT,
  SUBMIT_BOTS_SUCCESS,
  SUBMIT_BOTS_REQUEST,
  SUBMIT_BOTS_FAILURE,
  DELETE_BOT_REQUEST,
  DELETE_BOT_FAILURE,
  DELETE_BOT_SUCCESS,
  SET_BOTS_LIMIT,
  UPDATE_BOT_SUCCESS,
} from "../actions/bots";
import {
  CLEAR_TEST_CONVERSATION,
  POST_TEST_CONVERSATION_MESSAGE_FAILURE,
  POST_TEST_CONVERSATION_MESSAGE_REQUEST,
  POST_TEST_CONVERSATION_MESSAGE_SUCCESS,
} from "../actions/conversation";
import {
  DELETE_INTENT_FAILURE,
  DELETE_INTENT_REQUEST,
  DELETE_INTENT_SUCCESS,
  GET_INTENTS_FAILURE,
  GET_INTENTS_REQUEST,
  GET_INTENTS_SUCCESS,
  POST_INTENT_FAILURE,
  POST_INTENT_REQUEST,
  POST_INTENT_SUCCESS,
  PUT_INTENT_REQUEST,
  PUT_INTENT_SUCCESS,
  PUT_INTENT_FAILURE,
  DELETE_INTENT_RESET,
} from "../actions/intents";
import {
  DELETE_RESPONSE_FAILURE,
  DELETE_RESPONSE_REQUEST,
  DELETE_RESPONSE_SUCCESS,
  GET_RESPONSES_FAILURE,
  GET_RESPONSES_REQUEST,
  GET_RESPONSES_SUCCESS,
  POST_RESPONSE_FAILURE,
  POST_RESPONSE_REQUEST,
  POST_RESPONSE_SUCCESS,
  PUT_RESPONSE_FAILURE,
  PUT_RESPONSE_REQUEST,
  PUT_RESPONSE_SUCCESS,
} from "../actions/responses";
import initialState from "./initialState";
import {
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  GET_STATE_BY_ID_REQUEST,
  GET_STATE_BY_ID_SUCCESS,
  GET_STATE_BY_ID_FAILURE,
  POST_STATE_REQUEST,
  POST_STATE_SUCCESS,
  POST_STATE_FAILURE,
  PUT_STATE_REQUEST,
  PUT_STATE_SUCCESS,
  PUT_STATE_FAILURE,
  DELETE_STATE_REQUEST,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAILURE,
  SET_STATE_OBJECT,
  CLEAN_STATE_WITHOUT_CONNECTION_DELETED,
} from "../actions/states";
import {
  GET_TRANSITIONS_REQUEST,
  GET_TRANSITIONS_SUCCESS,
  GET_TRANSITIONS_FAILURE,
  GET_TRANSITION_BY_ID_REQUEST,
  GET_TRANSITION_BY_ID_SUCCESS,
  GET_TRANSITION_BY_ID_FAILURE,
  POST_TRANSITION_REQUEST,
  POST_TRANSITION_SUCCESS,
  POST_TRANSITION_FAILURE,
  PUT_TRANSITION_REQUEST,
  PUT_TRANSITION_SUCCESS,
  PUT_TRANSITION_FAILURE,
  DELETE_TRANSITION_REQUEST,
  DELETE_TRANSITION_SUCCESS,
  DELETE_TRANSITION_FAILURE,
} from "../actions/transitions";
import {
  GET_VARIABLES_FAILURE,
  GET_VARIABLES_REQUEST,
  GET_VARIABLES_SUCCESS,
} from "../actions/variables";

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOTS_REQUEST: {
      return {
        ...state,
        fetching: true,
        submitSuccess: false,
      };
    }
    case GET_BOTS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        bots: buildUsers(action.payload.bots, action.payload.profiles),
        total:
          !action.payload.total || action.payload.total === 0
            ? state.total
            : action.payload.total,
        profiles: buildProfiles(action.payload.bots),
        bot: {
          ...state.bot,
          bot: [],
        },
      };
    }
    case GET_BOTS_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }
    case SET_BOTS: {
      return {
        ...state,
        bots: action.payload.bots,
        total: action.payload.total,
      };
    }
    case SET_BOT: {
      return {
        ...state,
        bot: {
          ...state.bot,
          bot: action.payload.bot,
        },
      };
    }
    case SET_BOTS_LIMIT:
      return {
        ...state,
        limit: action.payload.limit,
      };
    case SUBMIT_BOTS_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitFetching: true,
          submitSuccess: false,
        },
      };
    }
    case SUBMIT_BOTS_SUCCESS: {
      const updatedBots = [action.payload.bot, ...state.bots];

      return {
        ...state,
        bots: updatedBots,
        bot: {
          ...state.bot,
          bot: action.payload.bot,
          submitFetching: false,
          submitSuccess: true,
        },
      };
    }
    case UPDATE_BOT_SUCCESS: {
      const updatedBotsList = replaceFromArrayBy_id(
        action.payload.bot,
        state.bots
      );

      return {
        ...state,
        bots: updatedBotsList,
        bot: {
          ...state.bot,
          bot: action.payload.bot,
          submitFetching: false,
          submitSuccess: true,
        },
      };
    }
    case SUBMIT_BOTS_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitFetching: false,
          submitSuccess: false,
        },
      };
    }
    case DELETE_BOT_REQUEST: {
      return {
        ...state,
        deleteFetching: true,
        deleteSuccess: false,
      };
    }
    case DELETE_BOT_SUCCESS: {
      const deleteId = action.payload.id;

      const botsFiltered = state.bots.filter((bot) => bot._id !== deleteId);

      return {
        ...state,
        bots: botsFiltered,
        deleteFetching: false,
        deleteSuccess: true,
      };
    }
    case DELETE_BOT_FAILURE: {
      return {
        ...state,
        deleteFetching: false,
        deleteSuccess: false,
      };
    }

    case GET_ACTIONS_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
        },
      };
    }
    case GET_ACTIONS_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          actions: action.payload.actions,
          fetching: false,
        },
      };
    }
    case GET_ACTIONS_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
        },
      };
    }
    case GET_RESPONSES_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
        },
      };
    }
    case GET_RESPONSES_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          responses: action.payload.responses,
          fetching: false,
        },
      };
    }
    case GET_RESPONSES_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
        },
      };
    }
    case GET_INTENTS_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intentsFetching: true,
        },
      };
    }
    case GET_INTENTS_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intents: action.payload.intents,
          intentsFetching: false,
        },
      };
    }
    case GET_INTENTS_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intentsFetching: false,
        },
      };
    }

    case POST_ACTION_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          submitSuccess: false,
        },
      };
    }
    case POST_ACTION_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          actions: [...state.bot.actions, action.payload.action],
          fetching: false,
          submitSuccess: true,
        },
      };
    }
    case POST_ACTION_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          submitSuccess: false,
        },
      };
    }
    case POST_RESPONSE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          submitSuccess: false,
        },
      };
    }
    case POST_RESPONSE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          responses: [...state.bot.responses, action.payload.response],
          fetching: false,
          submitSuccess: true,
        },
      };
    }
    case POST_RESPONSE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          submitSuccess: false,
        },
      };
    }
    case PUT_RESPONSE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          submitSuccess: false,
        },
      };
    }
    case PUT_RESPONSE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          responses: replaceFromArrayBy_id(
            action.payload.response,
            state.bot.responses
          ),
          fetching: false,
          submitSuccess: true,
        },
      };
    }
    case PUT_RESPONSE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          submitSuccess: false,
        },
      };
    }
    case POST_INTENT_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitIntentFetching: true,
          submitIntentSuccess: false,
        },
      };
    }
    case POST_INTENT_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intents: [...state.bot.intents, action.payload.intent],
          submitIntentFetching: false,
          submitIntentSuccess: true,
        },
      };
    }
    case POST_INTENT_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitIntentFetching: false,
          submitIntentSuccess: false,
        },
      };
    }
    case PUT_INTENT_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          editIntentFetching: true,
          editIntentSuccess: false,
        },
      };
    }
    case PUT_INTENT_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intents: replaceFromArrayBy_id(
            action.payload.intent,
            state.bot.intents
          ),
          editIntentFetching: false,
          editIntentSuccess: true,
        },
      };
    }
    case PUT_INTENT_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          editIntentFetching: false,
          editIntentSuccess: false,
        },
      };
    }
    case DELETE_ACTION_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          submitSuccess: false,
        },
      };
    }
    case DELETE_ACTION_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          actions: removeFromArrayBy_id(action.payload.id, state.bot.actions),
          fetching: false,
          submitSuccess: true,
        },
      };
    }
    case DELETE_ACTION_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          submitSuccess: false,
        },
      };
    }
    case DELETE_INTENT_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          deleteSuccess: false,
        },
      };
    }
    case DELETE_INTENT_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          intents: removeFromArrayBy_id(action.payload.id, state.bot.intents),
          fetching: false,
          deleteSuccess: true,
        },
      };
    }
    case DELETE_INTENT_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          deleteSuccess: false,
        },
      };
    }
    case DELETE_INTENT_RESET: {
      return {
        ...state,
        bot: {
          ...state.bot,
          deleteSuccess: false,
        },
      };
    }
    case DELETE_RESPONSE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: true,
          submitSuccess: false,
        },
      };
    }
    case DELETE_RESPONSE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          responses: removeFromArrayBy_id(
            action.payload.id,
            state.bot.responses
          ),
          fetching: false,
          submitSuccess: true,
        },
      };
    }
    case DELETE_RESPONSE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          fetching: false,
          submitSuccess: false,
        },
      };
    }
    case POST_TEST_CONVERSATION_MESSAGE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          testConversation: {
            ...state.bot.testConversation,
            fetchingReply: true,
            submitReplySuccess: false,
            chat: [
              ...state.bot.testConversation.chat,
              {
                isClient: true,
                text: action.payload.message,
              },
            ],
          },
        },
      };
    }
    case POST_TEST_CONVERSATION_MESSAGE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          testConversation: {
            fetchingReply: false,
            submitReplySuccess: true,
            chat: [
              ...state.bot.testConversation.chat,
              {
                isClient: false,
                text: action.payload.previewResponse,
              },
            ],
            currentIntent: action.payload.nextPreview,
          },
        },
      };
    }
    case POST_TEST_CONVERSATION_MESSAGE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          testConversation: {
            ...state.bot.testConversation,
            fetchingReply: false,
            submitReplySuccess: false,
          },
        },
      };
    }
    case CLEAR_TEST_CONVERSATION: {
      return {
        ...state,
        bot: {
          ...state.bot,
          testConversation: {
            fetchingMessage: false,
            chat: [],
            currentIntent: null,
          },
        },
      };
    }
    case GET_STATES_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: true,
        },
      };
    }
    case GET_STATES_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          states: action.payload.states,
          statesFetching: false,
        },
      };
    }
    case GET_STATES_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: false,
        },
      };
    }
    case GET_STATE_BY_ID_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
        },
      };
    }
    case GET_STATE_BY_ID_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          stateObject: action.payload.stateObject,
        },
      };
    }
    case GET_STATE_BY_ID_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
        },
      };
    }
    case POST_STATE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitStateSuccess: false,
          statesFetching: true,
        },
      };
    }
    case POST_STATE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          states: [...state.bot.states, action.payload.stateObject],
          submitStateSuccess: true,
          statesFetching: false,
        },
      };
    }
    case POST_STATE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          submitStateSuccess: false,
          statesFetching: false,
        },
      };
    }
    case PUT_STATE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: true,
        },
      };
    }
    case PUT_STATE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          states: replaceFromArrayBy_id(
            action.payload.stateObject,
            state.bot.states
          ),
          statesFetching: false,
        },
      };
    }
    case PUT_STATE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: false,
        },
      };
    }
    case DELETE_STATE_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: true,
          stateWithoutConnectionDeleted: false,
        },
      };
    }
    case DELETE_STATE_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          states: removeFromArrayBy_id(
            action.payload.stateId,
            state.bot.states
          ),
          statesFetching: false,
          stateWithoutConnectionDeleted: true,
          transitions: removeObjectsFromArray(
            action.payload.transitions,
            state.bot.transitions
          ),
        },
      };
    }
    case DELETE_STATE_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          statesFetching: false,
          stateWithoutConnectionDeleted: false,
        },
      };
    }
    case CLEAN_STATE_WITHOUT_CONNECTION_DELETED: {
      return {
        ...state,
        bot: {
          ...state.bot,
          stateWithoutConnectionDeleted: false,
        },
      };
    }
    case GET_TRANSITIONS_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: true,
        },
      };
    }
    case GET_TRANSITIONS_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitions: action.payload.transitions,
          transitionsFetching: false,
        },
      };
    }
    case GET_TRANSITIONS_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
        },
      };
    }
    case GET_TRANSITION_BY_ID_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: true,
        },
      };
    }
    case GET_TRANSITION_BY_ID_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
          transition: action.payload.transition,
        },
      };
    }
    case GET_TRANSITION_BY_ID_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
        },
      };
    }
    case POST_TRANSITION_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: true,
          submitTransitionSuccess: false,
        },
      };
    }
    case POST_TRANSITION_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitions: [...state.bot.transitions, action.payload.transition],
          transitionsFetching: false,
          submitTransitionSuccess: true,
        },
      };
    }
    case POST_TRANSITION_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
          submitTransitionSuccess: false,
        },
      };
    }
    case PUT_TRANSITION_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: true,
        },
      };
    }
    case PUT_TRANSITION_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitions: replaceFromArrayBy_id(
            action.payload.transition,
            state.bot.transitions
          ),
        },
      };
    }
    case PUT_TRANSITION_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
        },
      };
    }
    case DELETE_TRANSITION_REQUEST: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: true,
          deleteTransitionSuccess: false,
        },
      };
    }
    case DELETE_TRANSITION_SUCCESS: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitions: removeFromArrayBy_id(
            action.payload.transitionId,
            state.bot.transitions
          ),
          transitionsFetching: false,
          deleteTransitionSuccess: true,
        },
      };
    }
    case DELETE_TRANSITION_FAILURE: {
      return {
        ...state,
        bot: {
          ...state.bot,
          transitionsFetching: false,
          deleteTransitionSuccess: false,
        },
      };
    }
    case SET_STATE_OBJECT: {
      return {
        ...state,
        bot: {
          ...state.bot,
          stateObject: action.payload.stateObject,
        },
      };
    }
    case GET_VARIABLES_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }
    case GET_VARIABLES_SUCCESS: {
      return {
        ...state,
        fetching: false,
        bot: {
          ...state.bot,
          variables: action.payload.botVariables,
        },
      };
    }
    case GET_VARIABLES_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};
