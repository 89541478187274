export const buildProfilesSel = profiles =>
    profiles.map((profile) => buildProfileSel(profile));

const buildProfileSel = (profile) => ({
    ...profile,
})

export const profilesOptions = [
    { label: 'Administrador', value: 'administrator' },
    { label: 'Operador', value: 'operator' }
];
