import { takeLatest, put, call, select } from 'redux-saga/effects';
import { GET_TEMPLATES_REQUEST,
    getTemplatesFailure, 
    getTemplatesSuccess, 
    SUBMIT_TEMPLATE_REQUEST, 
    submitTemplateFailure, 
    submitTemplateSuccess,
    DELETE_TEMPLATE_REQUEST,
    deleteTemplateFailure,
    deleteTemplateSuccess, 
    POST_TEMPLATE_REQUEST
} from '../actions/templates';
import { getTemplatesServices ,
        postTemplateService,
        putTemplateService,
        deleteTemplateService
    } from '../services/templates';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';

export const getTemplatesWorker = function* ({payload: {filters}}){
    try {

        const token = yield select(accessTokenSelector);

        const {data: { templates, total } } = yield call(getTemplatesServices, token, filters);

        yield put(getTemplatesSuccess(templates,total));
        
    } catch (error) {
        console.log(error);
        yield put(getTemplatesFailure(error));
        yield put(showGlobalError(true,error));
    }
}

export const editTemplateWorker = function* ({payload: {template, id}}){
    try {
        const token = yield select(accessTokenSelector);

        if(id){
            yield call(putTemplateService,token,template,id);
            yield put(submitTemplateSuccess());
            yield put(showGlobalError(true,200,'updated'));
        }
        else{
            yield call(postTemplateService,token,template);
            yield put(submitTemplateSuccess());
            yield put(showGlobalError(true,200,'created'));
        }

        
    } catch (error) {
        yield put(submitTemplateFailure(error));
        yield put(showGlobalError(true,error));
    }
}

const deleteTemplateWorker = function* ({ payload: { id } }) {
    try {
        const token = yield select(accessTokenSelector);
        yield call(deleteTemplateService, token, id);
        yield put(deleteTemplateSuccess());
        yield put(showGlobalError(true, 200, 'template_deleted'));
    } catch(err) {
        console.log(err)
        yield put(deleteTemplateFailure(err));
        yield put(showGlobalError(true, err));

    }
}

export default [
    takeLatest(GET_TEMPLATES_REQUEST, getTemplatesWorker),
    takeLatest(SUBMIT_TEMPLATE_REQUEST, editTemplateWorker),
    takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplateWorker)
]