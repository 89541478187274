import axios from 'axios';
import { api, headers } from '../../app/conf';

export const getStatesServices = (token, agentId) =>
    axios({
        method: 'get',
        url: `${api}/v2/chatbot/bots/${agentId}/states`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const getStateByIdServices = (token, agentId, stateId) =>
    axios({
        method: 'get',
        url: `${api}/v2/chatbot/bots/${agentId}/states/${stateId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
});

export const postStateServices = (token, agentId, state) =>
    axios({
        method: 'post',
        url: `${api}/v2/chatbot/bots/${agentId}/states`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:state
});

export const putStateServices = (token, agentId, stateId, state) =>
    axios({
        method: 'put',
        url: `${api}/v2/chatbot/bots/${agentId}/states/${stateId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data:state
});

export const deleteStateServices = (token, agentId, stateId) =>
    axios({
        method: 'delete',
        url: `${api}/v2/chatbot/bots/${agentId}/states/${stateId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    });