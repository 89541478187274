import {
  SET_CUSTOM_ATTRIBUTES_LIST,
  SET_CUSTOM_ATTRIBUTE_SELECTED,
} from "../actions/customAttributes";

const INITIAL_STATE = {
  data: null,
  selected: null,
};

const CustomAttributeState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CUSTOM_ATTRIBUTES_LIST: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case SET_CUSTOM_ATTRIBUTE_SELECTED: {
      return {
        ...state,
        selected: action.payload.data,
      };
    }

    default:
      return state;
  }
};

export default CustomAttributeState;
