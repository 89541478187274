import { call, takeLatest, put, select } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    GET_RESOLUTION_TIME_REQUEST,
    getResolutionTimeSuccess,
    getResolutionTimeFailure
} from '../actions/resolutionTime';
import {
    getResolutionTimeService
} from '../services/resolutionTime';


export const getResolutionTimeWorker = function*({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);
        
        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
          
        const { data } = yield call(getResolutionTimeService, token, filters);        
        yield put (getResolutionTimeSuccess(data));
    }
    catch (err) {
        console.log(err);
        yield put (getResolutionTimeFailure(err));
    }
};


export default [
    takeLatest(GET_RESOLUTION_TIME_REQUEST, getResolutionTimeWorker)
];