import { useState } from "react";
import styles from "./Text.module.scss";
import { FONT_COLOR, FONT_SIZE, FONT_WEIGHT, LINE_CLAMP } from "./types";
import { useTranslation } from "react-i18next";

/**
 * Text Component
 *
 * This component displays text with customizable styles.
 *
 * @param {string} value - The text to be displayed.
 * @param {string} fontSize - The desired font size. (Optional)
 * @param {string} fontWeight - The desired font weight. (Optional)
 * @param {string} fontColor - The desired font color. (Optional)
 * @param {string} lineClamp - The desired number of lines of text. (Optional)
 * @param {boolean} uppercase - The desired if uppercase. (Optional)
 * @param {boolean} capitalize - The desired if capitalize. (Optional)
*
* @returns {JSX.Element} The Text component with applied text and styles.
*/
export function Text(textProps) {
  const {
    value,
    fontSize,
    fontWeight,
    isLoading,
    fontColor,
    withSeeMore,
    lineClamp,
    uppercase,
    capitalize,
  } = textProps;

  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };
  const { t: translations } = useTranslation();

  const lineClampClass = lineClamp
    ? styles[`${lineClamp}`]
    : styles.withoutLineClamp;

  const fontSizeClass = fontSize
    ? styles[`${fontSize}`]
    : styles[FONT_SIZE.BASE];

  const fontWeightClass = fontWeight
    ? styles[`${fontWeight}`]
    : styles[FONT_WEIGHT.NORMAL];

  const fontColorClass = fontColor
    ? styles[`${fontColor}`]
    : styles[FONT_COLOR.DEFAULT];

  const TextWrapperStyles = `${styles.seeShowAll} ${
    showFullText ? styles.expanded : styles.notExpanded
  }`;

  const ButtonWrapperStyles = `${styles.button} ${styles[FONT_SIZE.XS]} ${
    styles[FONT_WEIGHT.MEDIUM]
  }`;

  const getText = () => {
    if (uppercase) return value.toUpperCase();

    if (capitalize) return value.charAt(0).toUpperCase() + value.slice(1);

    return value;
  };

  return withSeeMore ? (
    <>
      <span
        className={`${TextWrapperStyles} ${fontSizeClass} ${fontWeightClass} ${fontColorClass} ${lineClampClass}`}
      >
        {value}
      </span>

      {!showFullText && (
        <div onClick={toggleShowFullText} className={ButtonWrapperStyles}>
          {translations("tickets:see_more")}
        </div>
      )}
      {showFullText && (
        <div onClick={toggleShowFullText} className={ButtonWrapperStyles}>
          {translations("tickets:see_less")}
        </div>
      )}
    </>
  ) : (
    <span
      className={`${styles.container} ${fontSizeClass} ${fontWeightClass} ${fontColorClass} ${lineClampClass}
      `}
    >
      {!isLoading && getText()}
      {isLoading && <div className={styles.skeleton}></div>}
    </span>
  );
}

export default Text
