import { lazy } from "react";

const TermsLoader = lazy(() => import(/* webpackChunkName: "TermsLoader" */ './components/TermsLoader'));

const Terms = {
    path: "/terms",
    Component: TermsLoader,
    exact: false,
    innerRoutes: []
};

export default Terms;
