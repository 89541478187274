import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { showGlobalError } from "../../app/actions/app";
import { deleteResponseFailure, deleteResponseSuccess, DELETE_RESPONSE_REQUEST, getResponsesFailure, getResponsesSuccess, GET_RESPONSES_REQUEST, postResponseFailure, postResponsesSuccess, postResponseSuccess, POST_RESPONSE_REQUEST, putResponseFailure, putResponseSuccess, PUT_RESPONSE_REQUEST } from "../actions/responses";
import { deleteResponseServices, getResponsesServices, postResponseServices, putResponseServices } from "../services/responses";


export const getResponsesWorker = function* ({ payload: { botId, intentId } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { responses }} = yield call(getResponsesServices, token, botId, intentId);
        
        yield put(getResponsesSuccess(responses));
    } catch (err) {
        console.log(err);

        yield put(getResponsesFailure(err));
    }
};

export const putResponseWorker = function* ({ payload: { botId, respId, response: newResponse } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { response }} = yield call(putResponseServices, token, botId, respId, newResponse);
        
        yield put(putResponseSuccess(response));
    } catch (err) {
        console.log(err);

        yield put(putResponseFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const postResponseWorker = function* ({ payload: { botId, response: newResponse } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { response }} = yield call(postResponseServices, token, botId, newResponse);
        
        yield put(postResponseSuccess(response));
    } catch (err) {
        console.log(err);

        yield put(postResponseFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteResponseWorker = function* ({ payload: { botId, id } }) {
    try {
        const token = yield select(accessTokenSelector);
        const { data: { response } } = yield call(deleteResponseServices, token, botId,  id);
        
        yield put(deleteResponseSuccess(response._id));
    } catch (err) {
        console.log(err);

        yield put(deleteResponseFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(GET_RESPONSES_REQUEST, getResponsesWorker),
    takeLatest(POST_RESPONSE_REQUEST, postResponseWorker),
    takeLatest(PUT_RESPONSE_REQUEST, putResponseWorker),
    takeLatest(DELETE_RESPONSE_REQUEST, deleteResponseWorker)
]