import { SET_FILTERS } from "../actions/filters";
import { CHANNEL_TYPE, STATUS_TYPE } from "../helpers/types";

const INITIAL_STATE = {
  channel: CHANNEL_TYPE.CHILE_AUTOS,
  type: STATUS_TYPE.PUBLISHED,
};

const FiltersState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        ...action.payload.filters,
      };
    }

    default:
      return state;
  }
};

export default FiltersState;
