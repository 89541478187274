import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_TWITTER_CONNECTION_REQUEST,
    postTwitterConnectionSuccess,
    postTwitterConnectionFailure,
    PUT_TWITTER_CONNECTION_REQUEST,
    putTwitterConnectionSuccess,
    putTwitterConnectionFailure,
    GET_TWITTER_CONNECTION_REQUEST,
    getTwitterConnectionSuccess,
    getTwitterConnectionFailure,
    DELETE_TWITTER_CONNECTION_REQUEST,
    deleteTwitterConnectionSuccess,
    deleteTwitterConnectionFailure,
} from '../actions/twitterChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postTwitterConnectionsServices,
    getTwitterConnectionsServices,
    putTwitterConnectionsServices,
    deleteTwitterConnectionsServices,
} from '../services/twitterChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postTwitterConnectionWorker = function* ({ payload: { twitterConnection } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { twitterConnection: tC } } = yield call(postTwitterConnectionsServices, token, twitterConnection);

        yield put(showGlobalError(true, 200, 'twitter_created'));

        yield put(postTwitterConnectionSuccess(tC));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'twitter_connection_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postTwitterConnectionFailure(err));
    }
};

const putTwitterConnectionWorker = function* ({ payload: { twitterConnection } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { twitterConnection: tC } } = yield call(putTwitterConnectionsServices, token, twitterConnection);

        yield put(showGlobalError(true, 200, 'twitter_updated'));

        yield put(putTwitterConnectionSuccess(tC));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'twitter_connection_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(putTwitterConnectionFailure(err));
    }
};

export const getTwitterConnectionWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { twitterConnection } } = yield call(getTwitterConnectionsServices, token, filters);
        yield put(getTwitterConnectionSuccess(twitterConnection));
    } catch (err) {
        console.log(err);

        yield put(getTwitterConnectionFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteTwitterConnectionWorker = function* ({ payload: { twitterConnectionDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const { data: { tCD } } = yield call(deleteTwitterConnectionsServices, token, twitterConnectionDeleted);

        yield put(showGlobalError(true, 200, 'twitter_deleted'));

        yield put(deleteTwitterConnectionSuccess(tCD));
    } catch (err) {
        console.log(err);

        yield put(deleteTwitterConnectionFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_TWITTER_CONNECTION_REQUEST, postTwitterConnectionWorker),
    takeLatest(GET_TWITTER_CONNECTION_REQUEST, getTwitterConnectionWorker),
    takeLatest(PUT_TWITTER_CONNECTION_REQUEST, putTwitterConnectionWorker),
    takeLatest(DELETE_TWITTER_CONNECTION_REQUEST, deleteTwitterConnectionWorker),
];