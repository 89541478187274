import { lazy } from "react";
import { ICONS } from "./helpers/Icons/icons";

const EntryPoint = lazy(() => import("./components/EntryPoint/EntryPoint"));

const CustomAttributeFeature = lazy(() =>
  import("./components/CustomAttributes/CustomAttributes")
);

export const defaultPath = `/settings/custom-attributes`;

export const innerRoutes = [
  {
    path: `${defaultPath}/`,
    Component: CustomAttributeFeature,
    exact: true,
    icon: ICONS.LOGO,
    title: {
      key: "",
      plural: false,
    },
    subTitle: {
      key: "Gestioná tus archivos y/o documentos",
      plural: false,
    },
  },
];

const CustomAttributeBase = {
  path: defaultPath,
  Component: EntryPoint,
  exact: false,
  icon: ICONS.LOGO,
  title: {
    key: "Atributos",
    plural: false,
  },
  subTitle: {
    key: "Gestioná tus atributos personalizados",
    plural: false,
  },
  innerRoutes: innerRoutes,
  pathChilds: innerRoutes.map((e) => e.path),
};

export default CustomAttributeBase;
