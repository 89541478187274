export const POST_TEST_CONVERSATION_MESSAGE_REQUEST = 'POST_TEST_CONVERSATION_MESSAGE_REQUEST';
export const POST_TEST_CONVERSATION_MESSAGE_SUCCESS = 'POST_TEST_CONVERSATION_MESSAGE_SUCCESS';
export const POST_TEST_CONVERSATION_MESSAGE_FAILURE = 'POST_TEST_CONVERSATION_MESSAGE_FAILURE';

export const CLEAR_TEST_CONVERSATION = 'CLEAR_TEST_CONVERSATION';


export const postTestConversationMessageRequest = (botId, intentId, message) => ({
    type: POST_TEST_CONVERSATION_MESSAGE_REQUEST,
    payload: {
        botId,
        intentId,
        message
    }
});

export const postTestConversationMessagSuccess = (previewResponse, nextPreview) => ({
    type: POST_TEST_CONVERSATION_MESSAGE_SUCCESS,
    payload: {
        previewResponse,
        nextPreview
    }
});

export const postTestConversationMessagFailure = err => ({
    type: POST_TEST_CONVERSATION_MESSAGE_FAILURE,
    error: err
});

export const clearTestConversation =()=> ({
    type: CLEAR_TEST_CONVERSATION
});