export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const getCompanyRequest = (companyId) => ({
    type: GET_COMPANY_REQUEST,
    payload: {
        companyId
    }
});

export const getCompanySuccess = (company) => ({
    type: GET_COMPANY_SUCCESS,
    payload: {
        company,
    }
});

export const getCompanyFailure = err => ({
    type: GET_COMPANY_FAILURE,
    error: err
});