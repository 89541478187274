import {
    SET_AREA,
    SET_AREAS,
    SUBMIT_AREA_REQUEST,
    SUBMIT_AREA_SUCCESS,
    SUBMIT_AREA_FAILURE,
    GET_AREAS_REQUEST,
    GET_AREAS_FAILURE,
    GET_AREAS_SUCCESS,
    DELETE_AREA_REQUEST,
    DELETE_AREA_SUCCESS,
    DELETE_AREA_FAILURE,
    SET_AREAS_LIMIT
} from '../actions/areas';

const initialState = {
    fetching: false,
    area: {},
    areas: [],
    submmitFetching: false,
    submitSuccess: false,
    deleteFetching: false,
    deleteSuccess: false,
    page: 0,
    limit: 10
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_AREA_REQUEST:{
            return{
                ...state,
                submmitFetching: true,
                submitSuccess: false
            }
        }
        case SUBMIT_AREA_SUCCESS:{
            return{
                ...state,
                submmitFetching: false,
                submitSuccess: true
            }
        }
        case SUBMIT_AREA_FAILURE:{
            return{
                ...state,
                submmitFetching: false,
                submitSuccess: false
            }
        }
        case GET_AREAS_REQUEST:{
            return{
                ...state,
                fetching: true,
                submitSuccess: false,
                deleteSuccess:false
            }
        }
        case GET_AREAS_SUCCESS:{
            return{
                ...state,
                fetching: false,
                areas: action.payload.areas
            }
        }
        case GET_AREAS_FAILURE:{
            return{
                ...state,
                fetching: false
            }
        }
        case SET_AREA:{
            return{
                ...state,
                area: action.payload.area
            }
        }
        case SET_AREAS:{
            return{
                ...state,
                areas: action.payload.areas
            }
        }
        case SET_AREAS_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }
        case DELETE_AREA_REQUEST: {
            return {
                ...state,
                deleteFetching: true,
                deleteSuccess: false

            }
        }
        case DELETE_AREA_SUCCESS: {
            return {
                ...state,
                deleteFetching: false,
                deleteSuccess: true
            }
        }
        case DELETE_AREA_FAILURE: {
            return {
                ...state,
                deleteFetching: false
            }
        }
        default:
            return state;
    }
}