import axios from "axios";
import { api, headers } from "../../app/conf";

export const putTicketContactService = (token, ticketId, contactId) => 
    axios({
        method: 'put',
        url: `${api}/v1/tasks/${ticketId}/contact/${contactId}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

    export const postContactConnectionsService = (token, contactId, channels) => 
    axios({
        method: 'post',
        url: `${api}/v2/contacts/${contactId}/connections`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: channels
    })