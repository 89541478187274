import { REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from '../actions/refresh';

const initialState = {
    request: {
        fetching: false,
        error: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REFRESH_TOKEN_REQUEST: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: true
                }
            };
        }
        case REFRESH_TOKEN_SUCCESS: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                    error: false
                }
            };
        }
        case REFRESH_TOKEN_FAILURE: {
            return {
                ...state,
                request: {
                    ...state.request,
                    fetching: false,
                    error: action.error
                }
            };
        }
        default: return state;
    }
};
