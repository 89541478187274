import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getSocialsService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/social?${ makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSocialService = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/${data.socialId}?${makeQuery({ ...data.filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSocialsIncomingService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/incoming?${ makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSocialIncomingService = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/incoming/${data.id}?${makeQuery({ ...data.filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSocialsOutgoingService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/outgoing?${ makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getSocialOutgoingService = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/outgoing/${data.id}?${makeQuery({ ...data.filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getGeoLocationsService = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/geoLocation/${data.connectionId}?geoLocation=${data.geoLocation}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const postSocialService = (token, social) =>
    axios({
        method: 'post',
        url: `${api}/v1/social`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: {
            ...social
        }
    })

export const putSocialService = (token, social) => {
    axios({
        method: 'put',
        url: `${api}/v1/social/${social.socialId}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: social
    })
}

export const putSocialLikeService = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/social/${data.socialId}/reaction`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });

export const putSocialShareService = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/social/${data.socialId}/reaction`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });

export const deletePublicationService = (token, id) => 
    axios({
        method: 'delete',
        url: `${api}/v1/social/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
    })