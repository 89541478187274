export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS= 'SIGN_OUT_SUCCESS'; 

export const signOut = history => ({
    type: SIGN_OUT,
    payload: { history }
});

export const signOutSuccess = history => ({
    type: SIGN_OUT_SUCCESS,
    payload: { history }
});

 