import { FileStatusAdapter } from "../../adapter/files/state.adapter";
import Repository from "../../repository/Repository";

export default class FileService {
  #endpoint;
  #repository;

  constructor() {
    this.#endpoint = "/knowledge-base"; 
    this.#repository = new Repository();
  }

  /**
   * Uploads a file to a specified folder.
   *
   * @param {Object} postFileParams - Parameters for uploading a file.
   * @param {string} postFileParams.information.name - The name of the file without the extension.
   * @param {ArrayBuffer} postFileParams.information.file - The content of the file as an ArrayBuffer.
   * @param {string} postFileParams.information.extension - The file extension.
   * @param {string} postFileParams.information.folderId - The ID of the folder where the file should be uploaded.
   * @param {string} postFileParams.information.status - The status of the file (e.g., FILE_STATUS.LOADING).
   * @param {string} postFileParams.token - Authentication token (if required).
   * @param {AbortSignal} postFileParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the file is successfully uploaded or rejects with an error.
   */
  async postFile(postFileParams) {
    try {
      const { information, token, signal } = postFileParams;

      const endpoint = `${this.#endpoint}/files`;

      const data = await this.#repository.post({
        endpoint,
        information,
        token,
        signal,
        isFormData: true,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Deletes a file from the trash or permanently.
   *
   * @param {Object} deleteFileParams - Parameters for deleting a file.
   * @param {string} deleteFileParams.fileId - The ID of the file to be deleted.
   * @param {string} deleteFileParams.token - Authentication token (if required).
   * @param {AbortSignal} deleteFileParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the file is successfully deleted or rejects with an error.
   */
  async deleteFile(deleteFileParams) {
    try {
      const { fileId, token, signal } = deleteFileParams;

      const endpoint = `${this.#endpoint}/trashes/files/${fileId}`;

      const data = await this.#repository.delete({
        endpoint,
        fileId,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Creates a comment related to a file.
   *
   * @param {Object} postFolderParams - Parameters for creating a comment.
   * @param {Object} postFolderParams.information - Comment information.
   * @param {string} postFolderParams.information.message - The comment message.
   * @param {string} postFolderParams.information.fileId - The ID of the file the comment refers to.
   * @param {string} postFolderParams.information.userId - The ID of the user making the comment.
   * @param {string} postFolderParams.token - Authentication token (if required).
   * @param {AbortSignal} postFolderParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves with the created comment data or rejects with an error.
   *
   * @resolve Comment The created comment data.
   *   - success: boolean
   *   - comment:
   *   - _id: string - The unique ID of the created comment.
   *   - comment: string - The comment message.
   *   - fileId: string - The ID of the file to which the comment is related.
   *   - profileUsername: {string - The username of the user who made the comment.
   *   - profilePicture: {string - The user's profile picture (may be null).
   *   - createdAt: number - Timestamp of when the comment was created.
   *   - updatedAt: number - Timestamp of when the comment was last updated.
   *
   * @reject Error If an error occurs during the request.
   */
  async postComment(postFolderParams) {
    try {
      const { information, token, signal } = postFolderParams;

      const endpoint = `${this.#endpoint}/files/${information.fileId}/comments`;

      const data = await this.#repository.post({
        endpoint,
        information,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Fetches comments related to a specific file.
   *
   * @param {Object} getCommentsParams - Parameters for fetching comments.
   * @param {string} getCommentsParams.information.fileId - The ID of the file for which comments are to be fetched.
   * @param {string} getCommentsParams.token - Authentication token (if required).
   * @param {AbortSignal} getCommentsParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves with the comments data or rejects with an error.
   *   - success: boolean
   *   - comments:[]
   *   - _id: string - The unique ID of the created comment.
   *   - comment: string - The comment message.
   *   - fileId: string - The ID of the file to which the comment is related.
   *   - profileUsername: {string - The username of the user who made the comment.
   *   - profilePicture: {string - The user's profile picture (may be null).
   *   - createdAt: number - Timestamp of when the comment was created.
   *   - updatedAt: number - Timestamp of when the comment was last updated.
   *
   * @reject Error If an error occurs during the request.
   */
  async getComments(getCommentsParams) {
    try {
      const { information, token, signal } = getCommentsParams;

      const endpoint = `${this.#endpoint}/files/${information.fileId}/comments`;

      const data = await this.#repository.get({
        endpoint,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Updates a specific comment related to a file.
   *
   * @param {Object} updateCommentsParams - Parameters for updating a comment.
   * @param {Object} updateCommentsParams.information - Information about the comment update.
   * @param {string} updateCommentsParams.information.fileId - The ID of the file to which the comment belongs.
   * @param {string} updateCommentsParams.information.message - The updated message for the comment.
   * @param {string} updateCommentsParams.information.userId - The ID of the user who made the comment.
   * @param {string} updateCommentsParams.information.commentId - The ID of the comment to be updated.
   * @param {string} updateCommentsParams.token - Authentication token (if required).
   * @param {AbortSignal} updateCommentsParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the comment is successfully updated or rejects with an error.
   *   - success: boolean
   *   - comment:
   *   - _id: string - The unique ID of the created comment.
   *   - comment: string - The comment message.
   *   - fileId: string - The ID of the file to which the comment is related.
   *   - profileUsername: {string - The username of the user who made the comment.
   *   - profilePicture: {string - The user's profile picture (may be null).
   *   - createdAt: number - Timestamp of when the comment was created.
   *   - updatedAt: number - Timestamp of when the comment was last updated.
   *
   */
  async updateComments(getCommentsParams) {
    try {
      const { information, token, signal } = getCommentsParams;

      const endpoint = `${this.#endpoint}/files/${
        information.fileId
      }/comments/${information.commentId}`;

      const body = { comment: information.comment, userId: information.userId };

      const data = await this.#repository.put({
        endpoint,
        information: body,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Delete a specific comment related to a file.
   *
   * @param {Object} deleteCommentsParams - Parameters for updating a comment.
   * @param {Object} deleteCommentsParams.information - Information about the comment update.
   * @param {string} deleteCommentsParams.information.commentId - The ID of the comment to be delete.
   * @param {string} deleteCommentsParams.token - Authentication token (if required).
   * @param {AbortSignal} deleteCommentsParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the comment is successfully delete or rejects with an error.
   *   - success: boolean
   *   - comment:
   *   - _id: string - The unique ID of the created comment.
   *   - comment: string - The comment message.
   *   - fileId: string - The ID of the file to which the comment is related.
   *   - profileUsername: {string - The username of the user who made the comment.
   *   - profilePicture: {string - The user's profile picture (may be null).
   *   - createdAt: number - Timestamp of when the comment was created.
   *   - updatedAt: number - Timestamp of when the comment was last delete.
   *
   */
  async deleteComment(deleteCommentParams) {
    try {
      const { information, token, signal } = deleteCommentParams;

      const endpoint = `${this.#endpoint}/files/${
        information.fileId
      }/comments/${information.commentId}`;

      const data = await this.#repository.delete({
        endpoint,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Updates the state of a specific file.
   *
   * @param {Object} updateFileParams - Parameters for updating the state of a file.
   * @param {Object} updateFileParams.information - Information about the file state update.
   * @param {string} updateFileParams.information.fileId - The ID of the file for which the state will be updated.
   * @param {string} updateFileParams.information.state - The new state to assign to the file. Must be one of the following:
   * @param {string} updateFileParams.information.role - The new role to assign to the file. Must be one of the following:
   *   - "REJECTED"
   *   - "APPROVED"
   *   - "NOT_REVIEWED"
   *   - "REVIEWED"
   * @param {string} updateFileParams.information.userId - The ID of the user making the state update.
   * @param {string} updateFileParams.token - Authentication token (if required).
   * @param {AbortSignal} updateFileParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the file state is successfully updated or rejects with an error.
   */
  async updateFile(updateFileParams) {
    try {
      const { information, token, signal } = updateFileParams;

      const endpoint = `${this.#endpoint}/files/${information.fileId}`;

      const data = await this.#repository.put({
        endpoint,
        information: {
          role: information?.role,
          status: information?.state,
          visible: information?.visible,
        },
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Gets the possible states
   *
   * @param {Object} getStatesParams - Parameters for getting the possible file states.
   * @param {string} getStatesParams.token - Authentication token (if required).
   * @param {AbortSignal} getStatesParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise<{ value: string, label: string }[]>} A promise that resolves with an array of state objects when the possible file states are successfully retrieved or rejects with an error.
   */
  async getUpdateState(updateStateParams) {
    try {
      const { token, signal } = updateStateParams;

      const endpoint = `${this.#endpoint}/status`;

      const data = await this.#repository.get({
        endpoint,
        token,
        signal,
      });

      const adapter = FileStatusAdapter(data.status);

      return Promise.resolve(adapter);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Gets versions of file
   *
   * @param {Object} getStatesParams - Parameters for getting the versions of the file.
   * @param {string} getStatesParams.token - Authentication token (if required).
   * @param {AbortSignal} getStatesParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves with a field files that is an array of all versions of a file when they are successfully retrieved or rejects with an error.
   */
  async getVersionsFile(updateStateParams) {
    try {
      const { token, signal, information } = updateStateParams;

      const versionId = information.versionId;

      const endpoint = `${this.#endpoint}/versions/${versionId}`;

      const data = await this.#repository.get({
        endpoint,
        token,
        signal,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Uploads a file to a specified folder.
   *
   * @param {Object} postFileParams - Parameters for uploading a file.
   * @param {string} postFileParams.information.name - The name of the file without the extension.
   * @param {ArrayBuffer} postFileParams.information.file - The content of the file as an ArrayBuffer.
   * @param {string} postFileParams.information.extension - The file extension.
   * @param {string} postFileParams.information.folderId - The ID of the folder where the file should be uploaded.
   * @param {string} postFileParams.information.status - The status of the file (e.g., FILE_STATUS.LOADING).
   * @param {string} postFileParams.token - Authentication token (if required).
   * @param {AbortSignal} postFileParams.signal - A signal for canceling the request (optional).
   *
   * @returns {Promise} A promise that resolves when the file is successfully uploaded or rejects with an error.
   */
  async postVersionFile(postFileParams) {
    try {
      const { information, token, signal } = postFileParams;

      const versionId = information.versionId ?? null;

      const endpoint = `${this.#endpoint}/versions/${versionId}`;

      const data = await this.#repository.post({
        endpoint,
        information,
        token,
        signal,
        isFormData: true,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
