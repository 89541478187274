import { 
    GET_RESPONSE_TIME_FAILURE, 
    GET_RESPONSE_TIME_REQUEST,
    GET_RESPONSE_TIME_SUCCESS 
} from '../actions/responseTime'


const initialState = {
    statistics: {},
    fetching: false,
    getResponseTimeSuccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RESPONSE_TIME_REQUEST: {
            return {
                ...state,
                fetching: true,
                getResponseTimeSuccess: false
            };
        }
        case GET_RESPONSE_TIME_SUCCESS: {
            return {
                ...state,
                statistics: action.payload.statistics,
                fetching: false,
                getResponseTimeSuccess: true
            };
        }
        case GET_RESPONSE_TIME_FAILURE: {
            return {
                ...state,
                fetching: false,
                getResponseTimeSuccess: false
            };
        }
        default:
            return state;
    }
}