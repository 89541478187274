import { FILE_STATE } from "./types";

export const FileStatusAdapter = (fileStatus) => {
  const adapted = fileStatus.map((status) => {
    return {
      value: status.name,
      label: getLabelOption(status.name),
    };
  });

  return adapted;
};

export const getLabelOption = (status) => {
  switch (status) {
    case FILE_STATE.APPROVED:
      return "Aprobado";
    case FILE_STATE.NOT_REVIEWED:
      return "Sin revisar";
    case FILE_STATE.REJECTED:
      return "Rechazado";
    case FILE_STATE.REVIEWED:
      return "Revisado";
  }
};
