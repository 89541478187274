import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getProfilesService = (token,filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/roles?${makeQuery(filters)}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

export const getProfileService = (token,id) => 
    axios({
        method: 'get',
        url: `${api}/v1/roles/${id}`,
        headers: {
            ...headers,
            'access-token': token
        }
    })

export const editProfileService = (token,profile,id) => 
    axios({
        method: 'put',
        url: `${api}/v1/roles/${id}`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: profile
    })

export const submitProfileService = (token,profile) => 
    axios({
        method: 'post',
        url: `${api}/v1/roles`,
        headers: {
            ...headers,
            'access-token': token
        },
        data: profile
    })
