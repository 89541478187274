import {
    GET_MENTIONS_REQUEST,
    GET_MENTIONS_SUCCESS,
    GET_MENTIONS_FAILURE
} from '../actions/mentions';
import {buildMentions} from '../helpers/mentions';

const initialState = {
    fetching: false,
    total: 0,
    mentions: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MENTIONS_REQUEST: {
            return {
                ...state,
                fetching: true
            }
        }
        case GET_MENTIONS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                mentions: [...state.mentions, ...action.payload.mentions],
                total: (!action.payload.total || action.payload.total === 0) ? state.total : action.payload.total
            }
        }
        case GET_MENTIONS_FAILURE: {
            return {
                ...state,
                fetching: false
            }
        }
        default:
            return state;
    }
}
