import {
    GET_PREFERENCES_REQUEST,
    GET_PREFERENCES_SUCCESS,
    GET_PREFERENCES_FAILURE,
    SUBMIT_PREFERENCES_REQUEST,
    SUBMIT_PREFERENCES_SUCCESS,
    SUBMIT_PREFERENCES_FAILURE
} from '../actions/preferences';
const initialState = {
    fetching: true,
    company: {},
    fetchingPost:false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PREFERENCES_REQUEST: {
            return {
                ...state,
                fetching: true
            };
        }
        case GET_PREFERENCES_SUCCESS: {
            return {
                ...state,
                fetching: false,
                company: action.payload
            };
        }
        case GET_PREFERENCES_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case SUBMIT_PREFERENCES_REQUEST: {
            return {
                ...state,
                fetching: true,
                fetchingPost:true
            };
        }
        case SUBMIT_PREFERENCES_SUCCESS: {
            return {
                ...state,
                fetching: false,
                company: action.payload,
                fetchingPost:false
            };
        }
        case SUBMIT_PREFERENCES_FAILURE: {
            return {
                ...state,
                fetching: false,
                fetchingPost:false
            };
        }
        default:
            return state
    }
}