export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

export const GET_CHANNEL_STATISTICS_REQUEST = 'GET_CHANNEL_STATISTICS_REQUEST';
export const GET_CHANNEL_STATISTICS_SUCCESS = 'GET_CHANNEL_STATISTICS_SUCCESS';
export const GET_CHANNEL_STATISTICS_FAILURE = 'GET_CHANNEL_STATISTICS_FAILURE';

export const GET_ALL_CASES_REQUEST = 'GET_ALL_CASES_REQUEST';
export const GET_ALL_CASES_SUCCESS = 'GET_ALL_CASES_SUCCESS';
export const GET_ALL_CASES_FAILURE = 'GET_ALL_CASES_FAILURE';

export const GET_SCORES_AVERAGE_REQUEST = 'GET_SCORES_AVERAGE_REQUEST';
export const GET_SCORES_AVERAGE_SUCCESS = 'GET_SCORES_AVERAGE_SUCCESS';
export const GET_SCORES_AVERAGE_FAILURE = 'GET_SCORES_AVERAGE_FAILURE';

export const GET_TASK_TYPING_REQUEST = 'GET_TASK_TYPING_REQUEST';
export const GET_TASK_TYPING_SUCCESS = 'GET_TASK_TYPING_SUCCESS';
export const GET_TASK_TYPING_FAILURE = 'GET_TASK_TYPING_FAILURE';

export const GET_ABANDONED_CASES_REQUEST = 'GET_ABANDONED_CASES_REQUEST';
export const GET_ABANDONED_CASES_SUCCESS = 'GET_ABANDONED_CASES_SUCCESS';
export const GET_ABANDONED_CASES_FAILURE = 'GET_ABANDONED_CASES_FAILURE';

export const GET_DERIVED_CASES_REQUEST = 'GET_DERIVED_CASES_REQUEST';
export const GET_DERIVED_CASES_SUCCESS = 'GET_DERIVED_CASES_SUCCESS';
export const GET_DERIVED_CASES_FAILURE = 'GET_DERIVED_CASES_FAILURE';
export const GET_DOWNLOAD_STATISTICS_REQUEST = 'GET_DOWNLOAD_STATISTICS_REQUEST';
export const GET_DOWNLOAD_STATISTICS_SUCCESS = 'GET_DOWNLOAD_STATISTICS_SUCCESS';
export const GET_DOWNLOAD_STATISTICS_FAILURE = 'GET_DOWNLOAD_STATISTICS_FAILURE';

export const GET_CASES_BY_DATE_REQUEST = 'GET_CASES_BY_DATE_REQUEST';
export const GET_CASES_BY_DATE_SUCCESS = 'GET_CASES_BY_DATE_SUCCESS';
export const GET_CASES_BY_DATE_FAILURE = 'GET_CASES_BY_DATE_FAILURE';

export const GET_CASES_BY_AGENT_REQUEST = 'GET_CASES_BY_AGENT_REQUEST';
export const GET_CASES_BY_AGENT_SUCCESS = 'GET_CASES_BY_AGENT_SUCCESS';
export const GET_CASES_BY_AGENT_FAILURE = 'GET_CASES_BY_AGENT_FAILURE';

export const GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST = 'GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST';
export const GET_DOWNLOAD_SYSTEM_STATISTICS_SUCCESS = 'GET_DOWNLOAD_SYSTEM_STATISTICS_SUCCESS';
export const GET_DOWNLOAD_SYSTEM_STATISTICS_FAILURE = 'GET_DOWNLOAD_SYSTEM_STATISTICS_FAILURE';

export const GET_SESSIONS_STATISTICS_REQUEST = 'GET_SESSIONS_STATISTICS_REQUEST';
export const GET_SESSIONS_STATISTICS_SUCCESS = 'GET_SESSIONS_STATISTICS_SUCCESS';
export const GET_SESSIONS_STATISTICS_FAILURE = 'GET_SESSIONS_STATISTICS_FAILURE';

export const GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST = 'GET_TIME_ASSOCIATED_TASK_REQUEST';
export const GET_TIME_ASSOCIATED_AGENT_TASK_SUCCESS = 'GET_TIME_ASSOCIATED_TASK_SUCCESS';
export const GET_TIME_ASSOCIATED_AGENT_TASK_FAILURE = 'GET_TIME_ASSOCIATED_TASK_FAILURE';

export const GET_INACTIVITY_TIME_BY_AGENT_REQUEST = 'GET_INACTIVITY_TIME_TASK_REQUEST';
export const GET_INACTIVITY_TIME_BY_AGENT_SUCCESS = 'GET_INACTIVITY_TIME_TASK_SUCCESS';
export const GET_INACTIVITY_TIME_BY_AGENT_FAILURE = 'GET_INACTIVITY_TIME_TASK_FAILURE';


export const GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST = 'GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST';
export const GET_CUSTOM_ATTRIBUTES_STATISTICS_SUCCESS = 'GET_CUSTOM_ATTRIBUTES_STATISTICS_SUCCESS';
export const GET_CUSTOM_ATTRIBUTES_STATISTICS_FAILURE = 'GET_CUSTOM_ATTRIBUTES_STATISTICS_FAILURE';

export const getTaskTypingRequest = ( filters = {}) => ({
    type: GET_TASK_TYPING_REQUEST,
    payload: {
        filters
    }
});

export const getTaskTypingSuccess = (statisticsTasks) => ({
    type: GET_TASK_TYPING_SUCCESS,
    payload: {
        statisticsTasks
    }
});

export const getTaskTypingFailure = (err) => ({
    type: GET_TASK_TYPING_FAILURE,
    error: err
});

export const getAverageScoresRequest = ( filters = {}) => ({
    type: GET_SCORES_AVERAGE_REQUEST,
    payload: {
        filters
    }
});

export const getAverageScoresSuccess = (scores) => ({
    type: GET_SCORES_AVERAGE_SUCCESS,
    payload: {
        scores
    }
});

export const getAverageScoresFailure = (err) => ({
    type: GET_SCORES_AVERAGE_FAILURE,
    error: err
});

export const getAllCasesRequest = ( filters = {}) => ({
    type: GET_ALL_CASES_REQUEST,
    payload: {
        filters
    }
});

export const getAllCasesSuccess = (cases) => ({
    type: GET_ALL_CASES_SUCCESS,
    payload: {
        cases
    }
});

export const getAllCasesFailure = (err) => ({
    type: GET_ALL_CASES_FAILURE,
    error: err
});

export const getChannelsStatisticsRequest = (filters = {}) => ({
    type: GET_CHANNEL_STATISTICS_REQUEST,
    payload: {
        filters
    }
});

export const getChannelsStatisticsSuccess = (statistics) => ({
    type: GET_CHANNEL_STATISTICS_SUCCESS,
    payload: {
        statistics
    }
});

export const getChannelsStatisticsFailure = (err) => ({
    type: GET_CHANNEL_STATISTICS_FAILURE,
    error: err
});

export const getReportsRequest = (filters = {}) => ({
    type:GET_REPORTS_REQUEST,
    payload: {
        filters,
    }
});

export const getReportsSuccess = (reports) => ({
    type: GET_REPORTS_SUCCESS,
    payload: {
        reports
    }
});

export const getReportsFailure = (err) => ({
    type: GET_REPORTS_FAILURE,
    error: err
});

export const getAbandonedCasesRequest = (filters = {}) => ({
    type: GET_ABANDONED_CASES_REQUEST,
    payload:{
        filters
    }
});

export const getAbandonedCasesSuccess = (abandonedCases) => ({
    type: GET_ABANDONED_CASES_SUCCESS,
    payload:{
        abandonedCases
    }
});

export const getAbandonedCasesFailure = (err) => ({
    type: GET_ABANDONED_CASES_FAILURE,
    error: err
});

export const getDerivedasesRequest = (filters = {}) => ({
    type: GET_DERIVED_CASES_REQUEST,
    payload:{
        filters
    }
});

export const getDerivedCasesSuccess = (derivedCases) => ({
    type: GET_DERIVED_CASES_SUCCESS,
    payload:{
        derivedCases
    }
});

export const getDerivedCasesFailure = (err) => ({
    type: GET_DERIVED_CASES_FAILURE,
    error: err
});

export const getDownloadStatisticsRequest = (connectionId, channel, filters) => ({
    type: GET_DOWNLOAD_STATISTICS_REQUEST,
    payload:{
        connectionId, channel, filters
    }
});

export const getDownloadStatisticsSuccess = (urlReport) => ({
    type: GET_DOWNLOAD_STATISTICS_SUCCESS,
    payload:{
        urlReport
    }
});

export const getDownloadStatisticsFailure = (err) => ({
    type: GET_DOWNLOAD_STATISTICS_FAILURE,
    error: err
});

export const getCasesByDateRequest = (filters = {}) => ({
    type: GET_CASES_BY_DATE_REQUEST,
    payload:{
        filters
    }
});

export const getCasesByDateSuccess = (casesByDate) => ({
    type: GET_CASES_BY_DATE_SUCCESS,
    payload:{
        casesByDate
    }
});

export const getCasesByDateFailure = (err) => ({
    type: GET_CASES_BY_DATE_FAILURE,
    error: err
});

export const getCasesByAgentRequest = (filters = {}) => ({
    type: GET_CASES_BY_AGENT_REQUEST,
    payload:{
        filters
    }
});

export const getCasesByAgentSuccess = (casesByAgent) => ({
    type: GET_CASES_BY_AGENT_SUCCESS,
    payload:{
        casesByAgent
    }
});

export const getCasesByAgentFailure = (err) => ({
    type: GET_CASES_BY_AGENT_FAILURE,
    error: err
});

export const getDownloadSystemStatisticsRequest = (channel, filters) => ({
    type: GET_DOWNLOAD_SYSTEM_STATISTICS_REQUEST,
    payload:{
        channel, filters
    }
});

export const getDownloadSystemStatisticsSuccess = (urlReport) => ({
    type: GET_DOWNLOAD_SYSTEM_STATISTICS_SUCCESS,
    payload:{
        urlReport
    }
});

export const getDownloadSystemStatisticsFailure = (err) => ({
    type: GET_DOWNLOAD_SYSTEM_STATISTICS_FAILURE,
    error: err
});

export const getSessionsStatisticsRequest = (filters = {}) => ({
    type: GET_SESSIONS_STATISTICS_REQUEST,
    payload:{
        filters
    }
});

export const getSessionsStatisticsSuccess = (statistics) => ({
    type: GET_SESSIONS_STATISTICS_SUCCESS,
    payload:{
        statistics
    }
});

export const getSessionsStatisticsFailure = (err) => ({
    type: GET_SESSIONS_STATISTICS_FAILURE,
    error: err
});

export const getTimeAssociatedAgentTaskRequest = (filters = {}) => ({
    type: GET_TIME_ASSOCIATED_AGENT_TASK_REQUEST,
    payload:{
        filters
    }
});

export const getTimeAssociatedAgentTaskSuccess = (statistics) => ({
    type: GET_TIME_ASSOCIATED_AGENT_TASK_SUCCESS,
    payload:{
        statistics
    }
});

export const getTimeAssociatedAgentTaskFailure = (err) => ({
    type: GET_TIME_ASSOCIATED_AGENT_TASK_FAILURE,
    error: err
});


export const getInactivityTimeByAgentRequest = (filters = {}) => ({
    type: GET_INACTIVITY_TIME_BY_AGENT_REQUEST,
    payload:{
        filters
    }
});

export const getInactivityTimeByAgentSuccess = (statistics) => ({
    type: GET_INACTIVITY_TIME_BY_AGENT_SUCCESS,
    payload:{
        statistics
    }
});

export const getInactivityTimeByAgentFailure = (err) => ({
    type: GET_INACTIVITY_TIME_BY_AGENT_FAILURE,
    error: err
});

export const getCustomAttributesStatisticsRequest = ( filters = {}) => ({
    type: GET_CUSTOM_ATTRIBUTES_STATISTICS_REQUEST,
    payload: {
        filters
    }
});

export const getCustomAttributesStatisticsSuccess = (customAttributesStatistics) => ({
    type: GET_CUSTOM_ATTRIBUTES_STATISTICS_SUCCESS,
    payload: {
        customAttributesStatistics
    }
});

export const getCustomAttributesStatisticsFailure = (err) => ({
    type: GET_CUSTOM_ATTRIBUTES_STATISTICS_FAILURE,
    error: err
});