import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { getVariablesServices } from "../services/variables";
import { getVariablesFailure, getVariablesSuccess, GET_VARIABLES_REQUEST } from "../actions/variables";

export const getVariablesWorker = function* ({ payload: { agentId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const { data: { botVariables } } = yield call(getVariablesServices, token, agentId);
    yield put(getVariablesSuccess(botVariables));
  } catch (err) {
    console.log(err);
    yield put(getVariablesFailure(err));
  }
};

export default [
  takeLatest(GET_VARIABLES_REQUEST, getVariablesWorker),
];