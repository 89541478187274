export const GET_DEPARTMENTS_REQUEST = 'GET_DEPARTMENTS_REQUEST';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAILURE = 'GET_DEPARTMENTS_FAILURE';

export const getDepartmentsRequest = (filters = {}) => ({
    type: GET_DEPARTMENTS_REQUEST,
    payload: {
        filters
    }
});

export const getDepartmentsSuccess = (departments) => ({
    type: GET_DEPARTMENTS_SUCCESS,
    payload: {
        departments
    }
});

export const getDepartmentsFailure = (err) => ({
    type: GET_DEPARTMENTS_FAILURE,
    error: err
});