import {
    POST_WHATSAPP_NUMBER_REQUEST,
    POST_WHATSAPP_NUMBER_SUCCESS,
    POST_WHATSAPP_NUMBER_FAILURE,
    GET_WHATSAPP_NUMBER_REQUEST,
    GET_WHATSAPP_NUMBER_SUCCESS,
    GET_WHATSAPP_NUMBER_FAILURE,
    PUT_WHATSAPP_NUMBER_REQUEST,
    PUT_WHATSAPP_NUMBER_SUCCESS,
    PUT_WHATSAPP_NUMBER_FAILURE,
    DELETE_WHATSAPP_NUMBER_REQUEST,
    DELETE_WHATSAPP_NUMBER_SUCCESS,
    DELETE_WHATSAPP_NUMBER_FAILURE,
} from '../actions/whatsAppChannel';

const initialState = {
    fetching: true,
    whatsAppNumber: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case POST_WHATSAPP_NUMBER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case POST_WHATSAPP_NUMBER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                whatsAppNumber: action.payload.whatsAppNumber
            };
        }
        case POST_WHATSAPP_NUMBER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case PUT_WHATSAPP_NUMBER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case PUT_WHATSAPP_NUMBER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                whatsAppNumber: action.payload.whatsAppNumber
            };
        }
        case PUT_WHATSAPP_NUMBER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case GET_WHATSAPP_NUMBER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case GET_WHATSAPP_NUMBER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                whatsAppNumber: action.payload.whatsAppNumber
            };
        }
        case GET_WHATSAPP_NUMBER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        case DELETE_WHATSAPP_NUMBER_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false
            };
        }
        case DELETE_WHATSAPP_NUMBER_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                whatsAppNumberDeleted: action.payload.whatsAppNumberDeleted,
                whatsAppNumber: {}
            };
        }
        case DELETE_WHATSAPP_NUMBER_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false
            };
        }

        default:
            return state;
    }
}