export const DELETE_STATUS_REQUEST = 'DELETE_STATUS_REQUEST';
export const DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS';
export const DELETE_STATUS_FAILURE = 'DELETE_STATUS_FAILURE';

export const POST_STATUS_REQUEST = 'POST_STATUS_REQUEST';
export const POST_STATUS_SUCCESS = 'POST_STATUS_SUCCESS';
export const POST_STATUS_FAILURE = 'POST_STATUS_FAILURE';

export const PUT_STATUS_REQUEST = 'PUT_STATUS_REQUEST';
export const PUT_STATUS_SUCCESS = 'PUT_STATUS_SUCCESS';
export const PUT_STATUS_FAILURE = 'PUT_STATUS_FAILURE';

export const CLEAN_STATUS = 'CLEAN_STATUS';

export const RESET_EXIST_TASKS_WITH_THIS_STATUS = 'RESET_EXIST_TASKS_WITH_THIS_STATUS';

export const deleteStatusRequest = (statusId, filters= {}) => ({
    type: DELETE_STATUS_REQUEST,
    payload: { 
        statusId,
        filters,
    }
});

export const deleteStatusSuccess = (statusDeleted) => ({
    type: DELETE_STATUS_SUCCESS,
    payload: {
        statusDeleted,
    }
});

export const deleteStatusFailure = (err, existTasksWithThisStatus) => ({
    type: DELETE_STATUS_FAILURE,
    payload: {
        existTasksWithThisStatus,
    },
    error: err
});

export const postStatusRequest = (status) => ({
    type: POST_STATUS_REQUEST,
    payload: { 
        status, 
    }
});

export const postStatusSuccess = (status) => ({
    type: POST_STATUS_SUCCESS,
    payload: {
        status,
    }
});

export const postStatusFailure = err => ({
    type: POST_STATUS_FAILURE,
    error: err
});

export const putStatusRequest = (status, id) => ({
    type: PUT_STATUS_REQUEST,
    payload: { 
        status, 
        id
    }
});

export const putStatusSuccess = (status) => ({
    type: PUT_STATUS_SUCCESS,
    payload: {
        status,
    }
});

export const putStatusFailure = err => ({
    type: PUT_STATUS_FAILURE,
    error: err
});

export const cleanStatus = () => ({
    type: CLEAN_STATUS,
});

export const resetExistTasksWithThisStatus = () => ({
    type: RESET_EXIST_TASKS_WITH_THIS_STATUS,
});