import axios from 'axios';
import { api, headers } from '../../app/conf';

export const postChannelServices = (token, data) => 
    axios({
        method: 'post',
        url: `${api}/v1/channels/connection`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const putChannelServices = (token, data) => 
    axios({
        method: 'put',
        url: `${api}/v1/channels/connection/${data.id}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            ...data
        }
    });

export const getChannelsServices = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/channels/connection`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const getChannelServices = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/channels`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
 

export const deleteChannelServices = (token, data) => 
    axios({
        method: 'delete',
        url: `${api}/v1/channels/connection/${data.id}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });
