import { lazy } from "react";
import { settingSVG, preferencesSVG } from '../shared/helpers/icons';
import usersRoutes from "../users/routes";
import apikeysRoutes from "../apikeys/routes";
import channelsRoutes from "../channels/routes";
import areasRoutes from "../areas/routes";
import profilesRoutes from "../profiles/routes";
import templateRoutes from '../templates/routes';
import notificationRoutes from '../notificationChannels/routes';
import CustomAttributeBase from "../customAttributes/routes";

const EntryPoint = lazy(() => import( /* webpackChunkName: "settings" */ './components/EntryPoint'));
const WebConfiguretionLoader = lazy(() => import( /* webpackChunkName: "webConfiguretionLoader" */ './components/WebConfiguretionLoader'));

export const defaultPath = '/settings';

export const innerRoutes = [
    apikeysRoutes,
    usersRoutes,
    CustomAttributeBase,
    channelsRoutes,
    areasRoutes,
    profilesRoutes,
    templateRoutes,
    notificationRoutes,
    {
        path: `${defaultPath}/webConfiguration`,
        Component: WebConfiguretionLoader,
        exact: true,
        icon: preferencesSVG,
        title: {
            key: 'web',
            plural: false
        },
        subTitle: {
            key: 'manage_settings',
            plural: false
        }
    },

];

const Settings = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: settingSVG,
    title: {
        key: 'settings',
        plural: false
    },
    subTitle: {
        key: 'manage_settings',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path),
    availableSubMenu: true
};

export default Settings;
