export const GET_CONTACTS_TICKET_REQUEST = 'GET_CONTACTS_TICKET_REQUEST';
export const GET_CONTACTS_TICKET_SUCCESS = 'GET_CONTACTS_TICKET_SUCCESS';
export const GET_CONTACTS_TICKET_FAILURE = 'GET_CONTACTS_TICKET_FAILURE';

export const GET_CONTACT_TICKET_REQUEST = 'GET_CONTACT_TICKET_REQUEST';
export const GET_CONTACT_TICKET_SUCCESS = 'GET_CONTACT_TICKET_SUCCESS';
export const GET_CONTACT_TICKET_FAILURE = 'GET_CONTACT_TICKET_FAILURE';

export const GET_CONTACT_SELECTED_REQUEST = 'GET_CONTACT_SELECTED_REQUEST';
export const GET_CONTACT_SELECTED_SUCCESS = 'GET_CONTACT_SELECTED_SUCCESS';
export const GET_CONTACT_SELECTED_FAILURE = 'GET_CONTACT_SELECTED_FAILURE';

export const POST_CONTACT_TICKET_REQUEST = 'POST_CONTACT_TICKET_REQUEST';
export const POST_CONTACT_TICKET_SUCCESS = 'POST_CONTACT_TICKET_SUCCESS';
export const POST_CONTACT_TICKET_FAILURE = 'POST_CONTACT_TICKET_FAILURE';

export const PUT_TICKET_CONTACT_REQUEST = 'PUT_TICKET_CONTACT_REQUEST';
export const PUT_TICKET_CONTACT_SUCCESS = 'PUT_TICKET_CONTACT_SUCCESS';
export const PUT_TICKET_CONTACT_FAILURE = 'PUT_TICKET_CONTACT_FAILURE';

export const EDIT_CONTACT_TICKET_REQUEST = 'EDIT_CONTACT_TICKET_REQUEST';
export const EDIT_CONTACT_TICKET_SUCCESS = 'EDIT_CONTACT_TICKET_SUCCESS';
export const EDIT_CONTACT_TICKET_FAILURE = 'EDIT_CONTACT_TICKET_FAILURE';

export const SET_CONTACTS_TICKET = 'SET_CONTACTS_TICKET';
export const SET_CONTACT_TICKET = 'SET_CONTACT_TICKET';

export const SET_CONTACT_SELECTED = 'SET_CONTACT_SELECTED';

export const POST_CONTACT_CONNECTIONS_REQUEST = 'POST_CONTACT_CONNECTIONS_REQUEST';
export const POST_CONTACT_CONNECTIONS_SUCCESS = 'POST_CONTACT_CONNECTIONS_SUCCESS';
export const POST_CONTACT_CONNECTIONS_FAILURE = 'POST_CONTACT_CONNECTIONS_FAILURE';

export const CLEAN_CONTACT_TICKET = 'CLEAN_CONTACT_TICKET';

export const getContactsTicketRequest = (filters = {}, suggestedContactsFilters = {}) => ({
    type: GET_CONTACTS_TICKET_REQUEST,
    payload: {
        filters,
        suggestedContactsFilters
    }
});

export const getContactsTicketSuccess = (contacts, total, areContactsSuggested) => ({
    type: GET_CONTACTS_TICKET_SUCCESS,
    payload: {
        contacts,
        total,
        areContactsSuggested
    }
});

export const getContactsTicketFailure = err => ({
    type: GET_CONTACTS_TICKET_FAILURE,
    error: err
});


export const getContactTicketRequest = (idContact) => ({
    type: GET_CONTACT_TICKET_REQUEST,
    payload: {
        idContact
    }
});

export const getContactTicketSuccess = (contact) => ({
    type: GET_CONTACT_TICKET_SUCCESS,
    payload: {
        contact
    }
});

export const getContactTicketFailure = err => ({
    type: GET_CONTACT_TICKET_FAILURE,
    error: err
});

export const getContactSelectedRequest = (idContact) => ({
    type: GET_CONTACT_SELECTED_REQUEST,
    payload: {
        idContact
    }
});

export const getContactSelectedSuccess = (contact) => ({
    type: GET_CONTACT_SELECTED_SUCCESS,
    payload: {
        contact
    }
});

export const getContactSelectedFailure = err => ({
    type: GET_CONTACT_SELECTED_FAILURE,
    error: err
});


export const setContactsTicket = (contacts = [], total = 0, areContactsSuggested = false) => ({
    type: SET_CONTACTS_TICKET,
    payload: {
        contacts,
        total,
        areContactsSuggested
    }
});

export const setContactTicket = (contact = {}) => ({
    type: SET_CONTACT_TICKET,
    payload: {
        contact
    }
});

export const setContactSelected = (contact = {}) => ({
    type: SET_CONTACT_SELECTED,
    payload: {
        contact
    }
});

export const postContactTicketRequest = (contact) => ({
    type: POST_CONTACT_TICKET_REQUEST,
    payload: {
        contact
    }
});

export const postContactTicketSuccess = (contact) => ({
    type: POST_CONTACT_TICKET_SUCCESS,
    payload:{
        contact
    }
});

export const postContactTicketFailure = err => ({
    type: POST_CONTACT_TICKET_FAILURE,
    error: err
});

export const putTicketContactRequest = (ticketId, contactId) => ({
    type: PUT_TICKET_CONTACT_REQUEST,
    payload: {
        ticketId,
        contactId
    }
});

export const putTicketContactSuccess = (contact) => ({
    type: PUT_TICKET_CONTACT_SUCCESS,
    payload: {
        contact
    }
});

export const putTicketContactFailure = err => ({
    type: PUT_TICKET_CONTACT_FAILURE,
    error: err
});


export const editContactTicketRequest = (contact,id) => ({
    type: EDIT_CONTACT_TICKET_REQUEST,
    payload: {
        contact,
        id
    }
});

export const editContactTicketSuccess = (contact) => ({
    type: EDIT_CONTACT_TICKET_SUCCESS,
    payload: {
        contact
    }
});

export const editContactTicketFailure = err => ({
    type: EDIT_CONTACT_TICKET_FAILURE,
    error: err
});

export const postContactConnectionsRequest = (contactId, channels) => ({
    type: POST_CONTACT_CONNECTIONS_REQUEST,
    payload: {
        contactId,
        channels
    }
});

export const postContactConnectionsSuccess = (contact) => ({
    type: POST_CONTACT_CONNECTIONS_SUCCESS,
    payload: {
        contact
    }
});

export const postContactConnectionsFailure = err => ({
    type: POST_CONTACT_CONNECTIONS_FAILURE,
    error: err
});

export const cleanContactTicket = () => ({
    type: CLEAN_CONTACT_TICKET,
});