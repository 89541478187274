import {
  SET_AGENT_STATUS_SOCKET_EVENT,
  SET_SOCKET_EVENT,
  SET_PROFILE_UPDATED_SOCKET_EVENT,
  SET_COMMENTS_SOCKET_EVENT,
  SET_TASKS_SOCKET_EVENT,
  SET_TASK_SOCKET_EVENT,
  SET_SOCIAL_OUTGOING_EVENT,
  SET_SOCIAL_INCOMING_EVENT,
  SET_UPDATE_COMMENT_EVENT,
  SET_NEW_COMMENT_EVENT,
  SET_TASK_CREATED_EVENT,
  SET_TASK_UPDATED_EVENT,
  SET_TASKS_CLOSED_EVENT,
  ADD_TASKS_LIST_EVENT,
  SET_TASKS_LIST_EVENT,
} from "../actions/socketEvents";
const initialState = {
  notificationSocketEvent: {},
  agentStatusSocketEvent: {},
  profileUpdatedSocketEvent: {},
  commentsSocketEvent: {},
  tasksSocketEvent: null,
  taskSocketEvent: {},
  socialOutgoingSocketEvent: {},
  updateCommentEvent: null,
  newCommentEvent: null,
  taskCreatedEvent: null,
  taskUpdatedEvent: null,
  tasksClosedEvent: null,
  tasksListEvents: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKET_EVENT: {
      return {
        ...state,
        notificationSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_AGENT_STATUS_SOCKET_EVENT: {
      return {
        ...state,
        agentStatusSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_PROFILE_UPDATED_SOCKET_EVENT: {
      return {
        ...state,
        profileUpdatedSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_COMMENTS_SOCKET_EVENT: {
      return {
        ...state,
        commentsSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_TASKS_SOCKET_EVENT: {
      return {
        ...state,
        tasksSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_TASK_SOCKET_EVENT: {
      return {
        ...state,
        taskSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_SOCIAL_OUTGOING_EVENT: {
      return {
        ...state,
        socialOutgoingSocketEvent: action.payload.socketEvent,
      };
    }
    case SET_SOCIAL_INCOMING_EVENT: {
      return {
        ...state,
        socialIncomingSocketEvent: action.payload.socketEvent,
      };
    }

    case SET_UPDATE_COMMENT_EVENT: {
      return {
        ...state,
        updateCommentEvent: action.payload.socketEvent,
      };
    }

    case SET_NEW_COMMENT_EVENT: {
      return {
        ...state,
        newCommentEvent: action.payload.socketEvent,
      };
    }

    case SET_TASK_CREATED_EVENT: {
      return {
        ...state,
        taskCreatedEvent: action.payload.socketEvent,
      };
    }
    case SET_TASK_UPDATED_EVENT: {
      return {
        ...state,
        taskUpdatedEvent: action.payload.socketEvent,
      };
    }
    case SET_TASKS_CLOSED_EVENT: {
      return {
        ...state,
        tasksClosedEvent: action.payload.socketEvent,
      };
    }
    case ADD_TASKS_LIST_EVENT: {
      const updatedList = [...state.tasksListEvents];
      updatedList.push(action.payload.socketEvent)

      return {
        ...state,
        tasksListEvents: updatedList
      };
    }

    case SET_TASKS_LIST_EVENT: {
      return {
        ...state,
        tasksListEvents: action.payload.events
      };
    }
    default:
      return state;
  }
};
