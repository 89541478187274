import { put, takeLatest, call } from 'redux-saga/effects';
import { showGlobalError } from '../../app/actions/app';
import { postCompanyFailure, postCompanySuccess, POST_COMPANY_REQUEST } from '../actions/register';
import { postCompanyService } from '../services/signIn';
import { getCompanyErrorCode } from '../errors';

const registerCompanyWorker = function* ({ payload: { company, adminUser } }) {
    try {
        yield call(postCompanyService, company, adminUser)
        yield put(postCompanySuccess());
        yield put(showGlobalError(true, 200, 'post_company_success'));
    } catch (err) {
      console.log(err)
      if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
        const errorCode = getCompanyErrorCode(err.response?.data?.message);
        if (errorCode) {
            yield put(showGlobalError(true, 409, errorCode));
        } else {
            yield put(showGlobalError(true, 409, 'company_exists'));
        }
      } else {
          yield put(showGlobalError(true, err));
      }
      yield put(postCompanyFailure(err))
    }
};


export default [takeLatest(POST_COMPANY_REQUEST, registerCompanyWorker)];