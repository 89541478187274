import {
    SET_RULE,
    SET_RULES,
    SUBMIT_RULE_REQUEST,
    SUBMIT_RULE_SUCCESS,
    SUBMIT_RULE_FAILURE,
    GET_RULES_REQUEST,
    GET_RULES_FAILURE,
    GET_RULES_SUCCESS,
    DELETE_RULE_REQUEST,
    DELETE_RULE_SUCCESS,
    DELETE_RULE_FAILURE,
    PUT_RULE_REQUEST,
    PUT_RULE_SUCCESS,
    PUT_RULE_FAILURE,
    SET_RULES_LIMIT,
    POST_AFFECTED_TASKS_REQUEST,
    POST_AFFECTED_TASKS_SUCCESS,
    POST_AFFECTED_TASKS_FAILURE
} from '../actions/rules';

const initialState = {
    fetching: false,
    rule: {},
    rules: [],
    tasks:[],
    submitFetching: false,
    submitSuccess: false,
    deleteFetching: false,
    deleteSuccess: false,
    putFetching:false,
    putSuccess:false,
    postAffectedTasksSuccess:false,
    page:0,
    limit:10
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_RULE_REQUEST:{
            return{
                ...state,
                submitFetching: true,
                submitSuccess: false
            }
        }
        case SUBMIT_RULE_SUCCESS:{
            return{
                ...state,
                submitFetching: false,
                submitSuccess: true
            }
        }
        case SUBMIT_RULE_FAILURE:{
            return{
                ...state,
                submitFetching: false,
                submitSuccess: false
            }
        }
        case PUT_RULE_REQUEST:{
            return{
                ...state,
                putFetching: true,
                putSuccess: false
            }
        }
        case PUT_RULE_SUCCESS:{
            return{
                ...state,
                putFetching: false,
                putSuccess: true
            }
        }
        case PUT_RULE_FAILURE:{
            return{
                ...state,
                putFetching: false,
                putSuccess: false
            }
        }
        case GET_RULES_REQUEST:{
            return{
                ...state,
                fetching: true,
                submitSuccess: false
            }
        }
        case GET_RULES_SUCCESS:{
            return{
                ...state,
                fetching: false,
                rules: action.payload.rules
            }
        }
        case GET_RULES_FAILURE:{
            return{
                ...state,
                fetching: false
            }
        }
        case SET_RULE:{
            return{
                ...state,
                rule: action.payload.rule,
                submitSuccess:false,
                putSuccess:false
            }
        }
        case SET_RULES:{
            return{
                ...state,
                rules: action.payload.rules
            }
        }
        case SET_RULES_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }
        case DELETE_RULE_REQUEST: {
            return {
                ...state,
                deleteFetching: true,
                deleteSuccess: false

            }
        }
        case DELETE_RULE_SUCCESS: {
            return {
                ...state,
                deleteFetching: false,
                deleteSuccess: true
            }
        }
        case DELETE_RULE_FAILURE: {
            return {
                ...state,
                deleteFetching: false
            }
        }
        case POST_AFFECTED_TASKS_REQUEST: {
            return {
                ...state,
                postAffectedTasksSuccess: false

            }
        }
        case POST_AFFECTED_TASKS_SUCCESS: {
            return {
                ...state,
                postAffectedTasksSuccess: true,
                tasks:action.payload.tasks.tasks
            }
        }
        case POST_AFFECTED_TASKS_FAILURE: {
            return {
                ...state,
                postAffectedTasksSuccess: false
            }
        }
        default:
            return state;
    }
}