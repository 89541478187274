export const CHANNEL_TYPE = {
  MERCADO_LIBRE: "meli",
  CHILE_AUTOS: "chileAutos",
};

export const STATUS_TYPE = { PUBLISHED: "published", DELETED: "deleted" };

export const CHANNEL_NAME = {
  meli: "Mercado libre",
  chileAutos: "Chile Autos",
};

export const FEATURES_GROUP = {
  DETAILS: "Detalles",
};
