import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

import { 
    GET_TICKET_USERS_REQUEST, 
    getTicketUsersSuccess, 
    getTicketUsersFailure,

} from '../actions/ticketUsers';

import { getTicketUsersService } from '../services/ticketUsers';

export const getTicketUsersWorker = function* ( { payload: { ticketId, filters } } ){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { users: ticketUsers } } = yield call(getTicketUsersService, token, ticketId, filters);

        yield put(getTicketUsersSuccess(ticketUsers));
        
    } catch (err) {
        console.log(err);

        yield put(getTicketUsersFailure(err));
        yield put(showGlobalError(true, err));
    }
}

export default [
    takeLatest(GET_TICKET_USERS_REQUEST, getTicketUsersWorker),
]