import { call, takeLatest, put, select } from "redux-saga/effects";
import { accessTokenSelector } from "../../auth/selectors/token";
import {
    GET_LOCATIONS_REQUEST,
    getLocationsSuccess,
    getLocationsFailure
} from '../actions/locations';
import {
    getLocationsService
} from '../services/Locations';

export const getLocationsWorker = function*({ payload: { filters }}) {
    try {
        const token = yield select(accessTokenSelector);

        filters = (filters.milliseconds) && { 
            from: new Date(filters.milliseconds.from).toISOString(), 
            to: new Date(filters.milliseconds.to).toISOString()
        }
        
        const { data } = yield call(getLocationsService, token, filters);        
        yield put (getLocationsSuccess(data));
    }
    catch (err) {
        console.log(err);
        yield put (getLocationsFailure(err));
    }
};


export default [
    takeLatest(GET_LOCATIONS_REQUEST, getLocationsWorker)
];