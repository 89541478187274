import { SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE, RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE, SET_RECOVER_PASSWORD, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, CLEAN_FLAGS } from '../actions/signin';

const initialState = {
    fetching: false,
    fetchingRecoverPsw: false,
    mailSent: false,
    passwordRecover: false,
    changingPsw: false,
    passwordChanged: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_REQUEST: {
            return {
                ...state,
                fetching: true
            };
        }
        case SIGN_IN_SUCCESS: {
            return {
                ...state,
                fetching: false,
            };
        }
        case SIGN_IN_FAILURE: {
            return {
                ...state,
                fetching: false,
            };
        }
        case SET_RECOVER_PASSWORD: {
            return {
                ...state,
                passwordRecover: action.payload.boolean,
            }
        }
        case RECOVER_PASSWORD_REQUEST: {
            return {
                ...state,
                fetchingRecoverPsw: true,
                mailSent: false,
                passwordRecover: false
            }
        }
        case RECOVER_PASSWORD_SUCCESS: {
            return {
                ...state,
                fetchingRecoverPsw: false,
                mailSent: true
            }
        }
        case RECOVER_PASSWORD_FAILURE: {
            return  {
                ...state,
                fetchingRecoverPsw: false,
                mailSent: false
            }
        }
        case CHANGE_PASSWORD_REQUEST: {
            return  {
                ...state,
                changingPsw: true,
                passwordChanged: false
            }
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return  {
                ...state,
                changingPsw: false,
                passwordChanged: true
            }
        }
        case CHANGE_PASSWORD_FAILURE: {
            return  {
                ...state,
                changingPsw: false,
                passwordChanged: false
            }
        }
        case CLEAN_FLAGS: {
            return {
                state: initialState,
            }
        }
        default: return state;
    }
};