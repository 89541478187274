import { takeLatest, put, call, select } from 'redux-saga/effects';
import {showGlobalError} from '../../app/actions/app';
import {accessTokenSelector} from '../../auth/selectors/token';
import { getProfilesSuccess, getProfilesFailure, GET_PROFILES_REQUEST, submitProfileSuccess,submitProfileFailure, SUBMIT_PROFILE_REQUEST } from '../actions/profiles';
import { getProfilesService, submitProfileService, editProfileService } from '../services/profiles';

export const getProfilesWorker = function* ({ payload: { filters } }){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { roles } }  = yield call(getProfilesService, token, filters);

        yield put(getProfilesSuccess(roles));
        
    } catch (error) {
        console.log(error);
        yield put(getProfilesFailure(error));
        yield put(showGlobalError(true,error));
    }
}


export const submitProfileWorker = function* ({ payload: { profile, id } }){
    try {

        const token = yield select(accessTokenSelector);

        if(id){
            const data = { description: profile.description, permissions: profile. permissions };
            
            yield call(editProfileService, token , data , profile.name);
            yield put(showGlobalError(true, 200, 'updated'));
        }
        else{
            yield call(submitProfileService, token , profile);
            yield put(showGlobalError(true, 200, 'created'));

        }

        yield put(submitProfileSuccess());
        
    } catch (error) {
        console.log(error);
        yield put(submitProfileFailure(error));
        yield put(showGlobalError(true,error));
    }
}


export default [
    takeLatest(GET_PROFILES_REQUEST, getProfilesWorker),
    takeLatest(SUBMIT_PROFILE_REQUEST, submitProfileWorker)
]