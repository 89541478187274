import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

import { 
    GET_TICKETS_TYPES_REQUEST, 
    getTicketsTypesSuccess, 
    getTicketsTypesFailure,

} from '../actions/ticketsTypes';

import { getTicketsTypesService } from '../services/ticketsTypes';

export const getTicketTypesWorker = function* (){
    try {

        const token = yield select(accessTokenSelector);
        const { data: { tasksCasesTypes } } = yield call(getTicketsTypesService, token);

        yield put(getTicketsTypesSuccess(tasksCasesTypes));
        
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(getTicketsTypesFailure(err));
        yield put(showGlobalError(true, errorData?.statusCode, errorData?.message));
    }
}

export default [
    takeLatest(GET_TICKETS_TYPES_REQUEST, getTicketTypesWorker),
]