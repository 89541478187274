import axios from "axios";
import { api } from "../../app/conf";

export const signInService = (username, password, captchaToken) =>
  axios({
    method: "post",
    url: `${api}/access`,
    data: {
      username,
      password,
      captchaToken,
    },
  });

export const signInProviderService = (tokenId) =>
  axios({
    method: "post",
    url: `${api}/access`,
    data: {
      tokenId,
    },
  });

export const postCompanyService = (company, adminUser) =>
  axios({
    method: "post",
    url: `${api}/v1/companies`,
    data: {
      company,
      adminUser,
    },
  });

export const recoverPasswordService = (email, url) =>
  axios({
    method: "post",
    url: `${api}/access/passreset`,
    data: {
      userEmail: email,
    },
  });

export const changePasswordService = (email, code, password) =>
  axios({
    method: "post",
    url: `${api}/access/passreset/${email}`,
    data: {
      code,
      password,
    },
  });
