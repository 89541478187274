import axios from 'axios';
import { api } from '../../app/conf';

export const uploadFilesService = (token, companyID, file) => axios({
    method: 'post',
    url: `${api}/${companyID}/media`,
    headers: {
        "X-Access-Token": token,
    },
    data: file
});

/**
 * Retrieves the company configuration if it exists.
 *
 * @param {string} origin - The origin from which the request is made
 * @returns {Promise} A promise that resolves with the company configuration if it exists
 */
export const getCompanyConfigurationService = (origin) => axios({
    method: 'get',
    url: `${api}/v1/configuration`, 
    params: {
        origin,
      },
});