import { 
    GET_TICKETS_DETAILS_REQUEST,
    GET_TICKETS_DETAILS_SUCCESS,
    GET_TICKETS_DETAILS_FAILURE,
} from '../actions/ticketsDetails';

const initialState = {
    ticketsDetails:[],
    fetching: false,
    success: false,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_TICKETS_DETAILS_REQUEST: {
            return {
                ...state,
                fetching: true,
                success: false,
            };
        }

        case GET_TICKETS_DETAILS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                success: true,
                ticketsDetails: action.payload.ticketsDetails,
            };
        }

        case GET_TICKETS_DETAILS_FAILURE: {
            return {
                ...state,
                fetching: false,
                success: false,
            };
        }

        default:
            return state;
    }
}