import axios from "axios";
import { api, headers } from "../../app/conf";
import { makeQuery } from '../../shared/helpers/commons';

export const getTaskByChannelService = (token, filters ) =>
    axios({
        method: "get",
        url: `${api}/v1/statistics/tasksbychannel?${makeQuery({...filters})}`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });
