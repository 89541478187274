import { SET_PERMISSIONS } from '../actions/permissions';
import { REFRESH_TOKEN_FAILURE } from '../actions/refresh';

const initialState = {
    permissions: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PERMISSIONS: {
            return {
                ...state,
                permissions: action.payload.permissions,
            };
        }
        case REFRESH_TOKEN_FAILURE: return initialState;
        default: return state;
    }
};
