export const GET_WEBCHAT_REQUEST = 'GET_WEBCHAT_REQUEST';
export const GET_WEBCHAT_SUCCESS = 'GET_WEBCHAT_SUCCESS';
export const GET_WEBCHAT_FAILURE = 'GET_WEBCHAT_FAILURE';

export const SET_WEBCHAT_REQUEST = 'SET_WEBCHAT_REQUEST';
export const SET_WEBCHAT_SUCCESS = 'SET_WEBCHAT_SUCCESS';
export const SET_WEBCHAT_FAILURE = 'SET_WEBCHAT_FAILURE';

export const getWebchatRequest = (connectionId) => ({
    type: GET_WEBCHAT_REQUEST,
    payload:{
        connectionId
    }
});

export const getWebchatSuccess = (webchat) => ({
    type: GET_WEBCHAT_SUCCESS,
    payload:{
        webchat
    }
});

export const getWebchatFailure = (err) => ({
    type: GET_WEBCHAT_FAILURE,
    error: err
});

export const setWebchatRequest = (webchat,connectionId) => ({
    type: SET_WEBCHAT_REQUEST,
    payload:{
        webchat,
        connectionId
    }
});

export const setWebchatSuccess = (webchat) => ({
    type: SET_WEBCHAT_SUCCESS,
    payload:{
        webchat
    }
});

export const setWebchatFailure = (webchat) => ({
    type: SET_WEBCHAT_FAILURE,
    payload:{
        webchat
    }
});