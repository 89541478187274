import { lazy } from "react";
import {  botSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Channels - EntryPoint" */ './components/EntryPoint'));
const BotsListLoader = lazy(() => import(/* webpackChunkName: "Channels - ListLoader" */ './components/BotsListLoader'));
const FormBotsLoader = lazy(() => import(/* webpackChunkName: "Channels - ListLoader" */ './components/FormBotsLoader'));

export const defaultPath = `/automation/bots`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: BotsListLoader,
        exact: true,
        icon: botSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Bots',
            plural: false
        }
    },    
    {
        path: `${defaultPath}/:id`,
        Component: FormBotsLoader,
        exact: true,
        icon: botSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'Edita bot',
            plural: false
        }
    },
];

const Bots = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: botSVG,
    title: {
        key: 'Bots',
        plural: false
    },
    subTitle: {
        key: 'Lista de Bots',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Bots;
