import React, { useEffect, useState } from "react";
import PreferencesDataContext from "./PreferencesDataContext";
import useGetPreferences from "../../../shared/hooks/Preferences/useGetPreferences";

const PreferencesContext = ({ children }) => {
  const [preferences, setPreferences] = useState(null);

  const companyPreferences = useGetPreferences();

  useEffect(() => {
    companyPreferences.get();
  }, []);

  useEffect(() => {
    if (!companyPreferences.data) return;
    setPreferences(companyPreferences.data);
  }, [companyPreferences.data]);  

  return (
    <PreferencesDataContext.Provider value={{
      preferences,

      setPreferences
    }}>
      {children}
    </PreferencesDataContext.Provider>
  );
};

export default PreferencesContext;
