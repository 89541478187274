import axios from "axios";
import { api, headers } from "../../app/conf";

export const getCustomAttributesService = (token) =>
    axios({
        method: "get",
        url: `${api}/v1/customAttributes`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });