import { lazy } from "react";
import { userSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "usersEntryPoint" */ './components/EntryPoint'));
const UsersListLoader = lazy(() => import(/* webpackChunkName: "UsersListLoader" */ './components/UsersListLoader'));
const FormUserLoader = lazy(() => import(/* webpackChunkName: "FormUserLoader" */ './components/FormUserLoader'));

export const defaultPath = `/settings/users`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: UsersListLoader,
        exact: true,
        icon: userSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'users_list_detail',
            plural: false
        }
    },
    {
        path: `${defaultPath}/new`,
        Component: FormUserLoader,
        exact: true,
        icon: userSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'create_new_client',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: FormUserLoader,
        exact: true,
        icon: userSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'modification_client',
            plural: false
        }
    }
];

const Users = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: userSVG,
    title: {
        key: 'users',
        plural: false
    },
    subTitle: {
        key: 'users_list_detail',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Users;
