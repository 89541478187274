import axios from "axios";
import { api, headers } from "../../app/conf";

export const getNotificationChannelsService = (token) => 
    axios({
        method: 'get',
        url: `${api}/v1/notificationChannels?`,
        headers: {
            ...headers,
            "access-token": token,
        },
    });

export const putNotificationChannelsService = (token, data) =>
    axios({
        method: 'put',
        url: `${api}/v1/notificationChannels`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: data
    });