import { lazy } from "react";

const RedirectLoginLoader = lazy(() => import('./components/RedirectLogin'));

const RedirectLogin = {
    path: "/redirect_login/:loginId",
    Component: RedirectLoginLoader,
    exact: true,
    innerRoutes: []
};

export default RedirectLogin;
