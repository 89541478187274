import { SHOW_GLOBAL_ERROR, SHOW_GLOBAL_ERROR_V2 } from '../actions/app';

const initialState = {
    showError: false,
    status: null,
    key: "",
    error:null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_GLOBAL_ERROR:
            return {
                ...state,
                showError: action.payload.showError,
                status: action.payload.status,
                key: action.payload.key
            };
        case SHOW_GLOBAL_ERROR_V2:
            return {
                ...state,
                error:action.payload.error
            };
        default: return state;
    }
};