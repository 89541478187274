export const GET_CHANNELS_LOGINS_REQUEST = 'GET_CHANNELS_LOGINS_REQUEST';
export const GET_CHANNELS_LOGINS_SUCCESS = 'GET_CHANNELS_LOGINS_SUCCESS';
export const GET_CHANNELS_LOGINS_FAILURE = 'GET_CHANNELS_LOGINS_FAILURE';

export const POST_CHANNELS_LOGINS_REQUEST = 'POST_CHANNELS_LOGINS_REQUEST';
export const POST_CHANNELS_LOGINS_SUCCESS = 'POST_CHANNELS_LOGINS_SUCCESS';
export const POST_CHANNELS_LOGINS_FAILURE = 'POST_CHANNELS_LOGINS_FAILURE';

export const GET_CHANNELS_LOGINS_STATUS_REQUEST = 'GET_CHANNELS_LOGINS_STATUS_REQUEST';
export const GET_CHANNELS_LOGINS_STATUS_SUCCESS = 'GET_CHANNELS_LOGINS_STATUS_SUCCESS';
export const GET_CHANNELS_LOGINS_STATUS_FAILURE = 'GET_CHANNELS_LOGINS_STATUS_FAILURE';

export const GET_CHANNELS_LOGINS_STATUS_REQUEST_V2 = 'GET_CHANNELS_LOGINS_STATUS_REQUEST_V2';
export const GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2 = 'GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2';
export const GET_CHANNELS_LOGINS_STATUS_FAILURE_V2 = 'GET_CHANNELS_LOGINS_STATUS_FAILURE_V2';

export const DELETE_CHANNEL_LOGIN_STATUS_REQUEST_V2 = 'DELETE_CHANNEL_LOGIN_STATUS_REQUEST_V2';
export const DELETE_CHANNEL_LOGIN_STATUS_FAILURE_V2 = 'DELETE_CHANNEL_LOGIN_STATUS_FAILURE_V2';

export const CLEAN_LOGIN_DEPENDENCES = 'CLEAN_LOGIN_DEPENDENCES';

export const getChannelsLoginsRequest = (channelName, filters = {}) => ({
    type: GET_CHANNELS_LOGINS_REQUEST,
    payload: {
        channelName,
        filters
    }
});

export const getChannelsLoginsSuccess = (channelLogin) => ({
    type: GET_CHANNELS_LOGINS_SUCCESS,
    payload: {
        channelLogin,
    }
});

export const getChannelsLoginsFailure = err => ({
    type: GET_CHANNELS_LOGINS_FAILURE,
    error: err
});

export const postChannelsLoginsRequest = (channelName, loginId, redirectUrl, filters = {}) => ({
    type: POST_CHANNELS_LOGINS_REQUEST,
    payload: {
        channelName,
        loginId,
        redirectUrl,
        filters
    }
});

export const postChannelsLoginsSuccess = (channelLogin) => ({
    type: POST_CHANNELS_LOGINS_SUCCESS,
    payload: {
        channelLogin,
    }
});

export const postChannelsLoginsFailure = err => ({
    type: POST_CHANNELS_LOGINS_FAILURE,
    error: err
});

export const getChannelsLoginsStatusRequest = (channelName, requestToken) => ({
    type: GET_CHANNELS_LOGINS_STATUS_REQUEST,
    payload: {
        channelName,
        requestToken
    }
});

export const getChannelsLoginsStatusSuccess = (loginStatus) => ({
    type: GET_CHANNELS_LOGINS_STATUS_SUCCESS,
    payload: {
        loginStatus,
    }
});

export const getChannelsLoginsStatusFailure = err => ({
    type: GET_CHANNELS_LOGINS_STATUS_FAILURE,
    error: err
});

export const cleanLoginDependences = () => ({
    type: CLEAN_LOGIN_DEPENDENCES,
});

export const getChannelsLoginsStatusRequestV2 = (channelName, loginId) => ({
    type: GET_CHANNELS_LOGINS_STATUS_REQUEST_V2,
    payload: {
        channelName,
        loginId
    }
});

export const getChannelsLoginsStatusSuccessV2 = (login) => ({
    type: GET_CHANNELS_LOGINS_STATUS_SUCCESS_V2,
    payload: {
        login,
    }
});

export const getChannelsLoginsStatusFailureV2 = err => ({
    type: GET_CHANNELS_LOGINS_STATUS_FAILURE_V2,
    error: err
});

export const deleteChannelLoginStatusFailureV2 = (err) => ({
    type: DELETE_CHANNEL_LOGIN_STATUS_FAILURE_V2,
    error: err
});