import { takeLatest, put, call, select, race, delay, take } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import { REFRESH_TOKEN_REQUEST, refreshTokenSuccess, refreshTokenFailure } from '../actions/refresh';
import { setTokens } from '../actions/token';
import { getAccessToken, getRefreshToken } from '../selectors/token';
import { refreshTokenService } from '../services/refresh';
import { SIGN_OUT } from '../actions/signout';

const refreshTokenWorker = function* () {
    try {
        while (true) {

            const accessToken = yield select(getAccessToken);
            const refreshToken = yield select(getRefreshToken);

            if (refreshToken) {
                const { exp } = jwt(accessToken);

                const fiveMinutesBeforeTokenExpirationDate = new Date(1000 * (exp - 60 * 10));
                const waitingTimeForTokenExpiration = fiveMinutesBeforeTokenExpirationDate.getTime() - new Date().getTime();

                const { expiration } = yield race({
                    expiration: delay(waitingTimeForTokenExpiration),
                    logout: take(SIGN_OUT)
                });

                if (expiration) {
                    const {
                        data: {
                            access_token: newAccessToken,
                            refresh_token: newRefreshToken,
                        },
                    } = yield call(refreshTokenService, refreshToken, accessToken);

                    yield put(refreshTokenSuccess());

                    yield put(setTokens(newAccessToken, newRefreshToken));
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        }
    } catch (err) {
        console.log(err);
        yield put(refreshTokenFailure());
    }
};

export default [takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenWorker)];
