import { parseToDni } from "../../shared/helpers/commons";
import { useTranslation } from 'react-i18next';

export const buildUsers = (users, profiles) => users.filter(user => user.profiles[0] !== 'sic').map(user => buildUser(user, profiles));

export const buildUser = (user, profiles) => ({
    ...user,
    company: Array.isArray(user.company) ? user.company[0] : user.company,
    profile: profiles
        ? buildUserProfile(user.roles, profiles)
        : Array.isArray(user.profile)
            ? user.profile[0]
            : user.roles,
    displayName: `${user.name} ${user.lastName}`,
    displayNameWithUsername: `${user.name} ${user.lastName} (${user.username})`,
    identifier: parseToDni(user.legalId),
});

const buildUserProfile = (user_profile, profiles) => {
    if (user_profile[0]) {

        if (typeof user_profile[0] === "string") {
            return profiles.find(p => p._id === user_profile[0])
        } else {
            return user_profile[0]
        }

    } else {
        return {}
    }
};

export const buildProfiles = (users) => {
    let profiles = [];
    users.filter(user => !(['apikey','sic'].includes(user.profiles[0]))).forEach(user => {
        if(!(profiles.includes(user.profiles) && user.profiles !== 'apikey')){
            profiles.push(user.profiles);
        }
    });
    return profiles;
}

export const useBuildOptions = (options) => {
    const { t } = useTranslation(['home']);
    if(options){
        let parsedOptions = [];
        options.forEach((option) => {
            parsedOptions.push({
                value: option.name,
                label: t(`home:${option.name}`),
            })
        });
        return parsedOptions;
    }

}

export const buildAreasOptions = (areas) => {
    if(areas){
        let parsedOptions = [];
        areas.forEach((area) => {
            parsedOptions.push({
                value: area.name,
                label: area.description,
            })
        });
        return parsedOptions;
    }

}