export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAILURE = 'GET_CONTACTS_FAILURE';

export const GET_CONTACT_BY_ID_REQUEST = 'GET_CONTACT_BY_ID_REQUEST';
export const GET_CONTACT_BY_ID_SUCCESS = 'GET_CONTACT_BY_ID_SUCCESS';
export const GET_CONTACT_BY_ID_FAILURE = 'GET_CONTACT_BY_ID_FAILURE';

export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACT = 'SET_CONTACT';

export const SET_CONTACTS_LIMIT = 'SET_CONTACTS_LIMIT';
export const SET_CONTACTS_PAGE = 'SET_CONTACTS_PAGE';

export const EDIT_CONTACT_REQUEST = 'EDIT_CONTACT_REQUEST';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILURE = 'EDIT_CONTACT_FAILURE';

export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_FAILURE = 'POST_CONTACT_FAILURE';

export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';
export const CLEAN_HISTORY_REQUEST = 'CLEAN_HISTORY_REQUEST';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';

export const CLEAN_CONTACT = 'CLEAN_CONTACT';

export const SET_HISTORY = 'SET_HISTORY';


export const getContacts = (filters = {}) => ({
    type: GET_CONTACTS,
    payload: { filters }
});


export const getContactsSuccess = (contacts, total) => ({
    type: GET_CONTACTS_SUCCESS,
    payload: {
        contacts,
        total
    }
});

export const getContactsFailure = err => ({
    type: GET_CONTACTS_FAILURE,
    error: err
});

export const getContactByIdRequest = (idContact) => ({
    type: GET_CONTACT_BY_ID_REQUEST,
    payload: { idContact }
});


export const getContactByIdSuccess = (contact) => ({
    type: GET_CONTACT_BY_ID_SUCCESS,
    payload: {
        contact
    }
});

export const getContactByIdFailure = err => ({
    type: GET_CONTACT_BY_ID_FAILURE,
    error: err
});

export const editContactRequest = (contact,id) => ({
    type: EDIT_CONTACT_REQUEST,
    payload: {
        contact,
        id
    }
});

export const editContactSuccess = (contact) => ({
    type: EDIT_CONTACT_SUCCESS,
    payload: {
        contact
    }
});

export const editContactFailure = err => ({
    type: EDIT_CONTACT_FAILURE,
    error: err
});

export const setContacts = (contacts = [], total = 0) => ({
    type: SET_CONTACTS,
    payload: {
        contacts,
        total
    }
});

export const setContact = (contact = {}) => ({
    type: SET_CONTACT,
    payload: {
        contact
    }
});

export const setContactsLimit = (limit) => ({
    type: SET_CONTACTS_LIMIT,
    payload: { limit }
})

export const setContactsPage = (page) => ({
    type: SET_CONTACTS_PAGE,
    payload: { page }
})

export const postContactRequest = (contact) => ({
    type: POST_CONTACT_REQUEST,
    payload: {
        contact
    }
});

export const postContactSuccess = (contact) => ({
    type: POST_CONTACT_SUCCESS,
    payload:{
        contact
    }
});

export const postContactFailure = err => ({
    type: POST_CONTACT_FAILURE,
    error: err
});

export const getHistoryRequest = (contactId) => ({
    type: GET_HISTORY_REQUEST,
    payload: {
        contactId
    }
});

export const getHistorySuccess = (history) => ({
    type: GET_HISTORY_SUCCESS,
    payload: {
        history
    }
});

export const getHistoryFailure = err => ({
    type: GET_HISTORY_FAILURE,
    error: err
});

export const cleanHistoryRequest = () => ({
    type: CLEAN_HISTORY_REQUEST,
    payload: { }
});

export const setHistory = (history = []) => ({
    type: SET_HISTORY,
    payload: {
        history
    }
});

export const deleteContactRequest = (id) => ({
    type: DELETE_CONTACT_REQUEST,
    payload: { id }
});

export const deleteContactSuccess = () => ({
    type: DELETE_CONTACT_SUCCESS,
    payload: { }
});

export const deleteContactFailure = (err) => ({
    type: DELETE_CONTACT_FAILURE,
    payload: { err }
});
export const cleanContact = () => ({
    type: CLEAN_CONTACT,
});