export const POST_WEBCHAT_REQUEST = 'POST_WEBCHAT_REQUEST';
export const POST_WEBCHAT_SUCCESS = 'POST_WEBCHAT_SUCCESS';
export const POST_WEBCHAT_FAILURE = 'POST_WEBCHAT_FAILURE';

export const DELETE_WEBCHAT_REQUEST = 'DELETE_WEBCHAT_REQUEST';
export const DELETE_WEBCHAT_SUCCESS = 'DELETE_WEBCHAT_SUCCESS';
export const DELETE_WEBCHAT_FAILURE = 'DELETE_WEBCHAT_FAILURE';

export const postWebchatRequest = webchat => ({
    type: POST_WEBCHAT_REQUEST,
    payload: {
        webchat
    }  
});

export const postWebchatSuccess = webchat => ({
    type: POST_WEBCHAT_SUCCESS,
    payload: {
        webchat
    }  
});

export const postWebchatFailure = err => ({
    type: POST_WEBCHAT_FAILURE,
    error: err
});

export const deleteWebchatRequest = webchatDeleted => ({
    type: DELETE_WEBCHAT_REQUEST,
    payload: {
        webchatDeleted
    }  
});

export const deleteWebchatSuccess = webchatDeleted => ({
    type: DELETE_WEBCHAT_SUCCESS,
    payload: {
        webchatDeleted,
        webchat: {}
    }  
});

export const deleteWebchatFailure = err => ({
    type: DELETE_WEBCHAT_FAILURE,
    error: err
});