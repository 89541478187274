import {
    GET_COMMENTS,
    REFRESH_COMMENTS,
    GET_COMMENTS_SUCCESS,
    GET_COMMENTS_FAILURE,
    POST_COMMENT,
    POST_COMMENT_FAILURE,
    POST_COMMENT_SUCCESS,
    SET_COMMENTS,
    CLEAN_COMMENTS
} from '../actions/comments';

const initialState = {
    comments: [],
    fetching: false,
    submitSuccess: false,
    fetchingSubmit: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMENTS: {
            return {
                ...state,
                fetching: true,
                submitSuccess: false
            };
        }
        case REFRESH_COMMENTS: {
            return {
                ...state,
                fetching: false
            };
        }
        case GET_COMMENTS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                comments: action.payload.comments
            };
        }
        case GET_COMMENTS_FAILURE: {
            return {
                ...state,
                fetching: false
            };
        }
        case POST_COMMENT: {
            return {
                ...state,
                fetchingSubmit: true,
                submitSuccess: false
            };
        }
        case POST_COMMENT_FAILURE: {
            return {
                ...state,
                submitSuccess: false,
                fetchingSubmit: false
            };
        }
        case POST_COMMENT_SUCCESS: {
            return {
                ...state,
                submitSuccess: true,
                fetchingSubmit: false,
            };
        }
        case SET_COMMENTS: {
            return {
                ...state,
                comments: action.payload.comments
            };
        }
        case CLEAN_COMMENTS: {
            return {
                ...state,
                comments: initialState.comments
            }
        }
        default:
            return state;
    };
};
