import { subMonths } from 'date-fns';
import { SET_FILTERS_STATISTICS,CLEAN_FILTERS } from '../actions/filters';

export const initialState = {
    channel: 'general',
    dateFrom: subMonths(new Date(), 1),
    dateTo: new Date(),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_STATISTICS:{
            return{
                ...state,
                channel: action.payload.channel,
                dateFrom: action.payload.from,
                dateTo: action.payload.to,
            }
        }
        case CLEAN_FILTERS:{
            return{
                ...initialState,
                channel: initialState.channel,
                dateFrom: initialState.from,
                dateTo: initialState.to,
            }
        }
        default:
            return state;
    }
}