import { combineReducers } from "redux";
import tickets from "./tickets";
import ticketUsers from "./ticketUsers";
import ticketsTypes from "./ticketsTypes";
import ticketsReasons from "./ticketsReasons";
import ticketsDetails from "./ticketsDetails";
import comments from "./comments";
import filters from "./filters";
import contacts from "./contacts";
import tags from "./tags";
import customAttributes from "./customAttributes";
import viewEmailModal from "./viewEmailModal";

export default combineReducers({
  tickets,
  comments,
  filters,
  contacts,
  ticketUsers,
  ticketsTypes,
  ticketsReasons,
  ticketsDetails,
  tags,
  customAttributes,
  viewEmailModal,
});
