import { takeLatest, put, call, select } from 'redux-saga/effects';
import { 
    POST_TELEGRAM_BOT_REQUEST,
    postTelegramBotSuccess,
    postTelegramBotFailure,
    PUT_TELEGRAM_BOT_REQUEST,
    putTelegramBotSuccess,
    putTelegramBotFailure,
    GET_TELEGRAM_BOT_REQUEST,
    getTelegramBotSuccess,
    getTelegramBotFailure,
    DELETE_TELEGRAM_BOT_REQUEST,
    deleteTelegramBotSuccess,
    deleteTelegramBotFailure,
} from '../actions/telegramChannel';
import { showGlobalError } from '../../app/actions/app';
import { 
    postTelegramBotsServices,
    putTelegramBotsServices,
    getTelegramBotsServices,
    deleteTelegramBotsServices,
} from '../services/telegramChannel';
import { accessTokenSelector } from '../../auth/selectors/token';

const postTelegramBotWorker = function* ({ payload: { telegramBot } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { telegramBot: tB } } = yield call(postTelegramBotsServices, token, telegramBot);

        yield put(showGlobalError(true, 200, 'telegram_created'));

        yield put(postTelegramBotSuccess(tB));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'telegram_bot_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(postTelegramBotFailure(err));
    }
};

const putTelegramBotWorker = function* ({ payload: { telegramBot } }) {
    try {
        const token = yield select(accessTokenSelector);

        const {data: { telegramBot: tB } } = yield call(putTelegramBotsServices, token, telegramBot);

        yield put(showGlobalError(true, 200, 'telegram_updated'));

        yield put(putTelegramBotSuccess(tB));

    } catch (err) {
        if (err && err.response && err.response.status && (err.response.status === 409 || err.response.status === 412)) {
            yield put(showGlobalError(true, 409, 'telegram_bot_already_exist'));
        } else {
            yield put(showGlobalError(true, err));
        }
        yield put(putTelegramBotFailure(err));
    }
};

export const getTelegramBotWorker = function* ({ payload: { filters = {} } = {} } = {}) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { telegramBot } } = yield call(getTelegramBotsServices, token, filters);
        yield put(getTelegramBotSuccess(telegramBot));
    } catch (err) {
        console.log(err);

        yield put(getTelegramBotFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export const deleteTelegramBotWorker = function* ({ payload: { telegramBotDeleted } }) {
    try {

        const token = yield select(accessTokenSelector);

        const {data: { tBD } } = yield call(deleteTelegramBotsServices, token, telegramBotDeleted);

        yield put(showGlobalError(true, 200, 'telegram_deleted'));

        yield put(deleteTelegramBotSuccess(tBD));
    } catch (err) {
        console.log(err);

        yield put(deleteTelegramBotFailure(err));
        yield put(showGlobalError(true, err));
    }
};

export default [
    takeLatest(POST_TELEGRAM_BOT_REQUEST, postTelegramBotWorker),
    takeLatest(PUT_TELEGRAM_BOT_REQUEST, putTelegramBotWorker),
    takeLatest(GET_TELEGRAM_BOT_REQUEST, getTelegramBotWorker),
    takeLatest(DELETE_TELEGRAM_BOT_REQUEST, deleteTelegramBotWorker),
];