export const GET_FOLDER_REQUEST = "GET_FOLDER_REQUEST";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const GET_FOLDER_FAILURE = "GET_FOLDER_FAILURE";
export const ADD_FILE_TO_FOLDER = "ADD_FILE_TO_FOLDER";
export const SET_CURRENT_FOLDER_ID = "SET_CURRENT_ID_FOLDER";
export const SET_CURRENT_FOLDER_NAME = "SET_CURRENT_NAME_FOLDER";
export const REMOVE_FILE_TO_FOLDER = "REMOVE_FILE_TO_FOLDER";
export const POST_FOLDER_REQUEST = "POST_FOLDER_REQUEST";
export const POST_FOLDER_SUCCESS = "POST_FOLDER_SUCCESS";
export const POST_FOLDER_FAILURE = "POST_FOLDER_FAILURE";
export const UPDATE_FILE_IN_DATA = "UPDATE_FILE_IN_DATA";

export const getFolderRequest = (
  signal,
  folderId,
  folderName,
  withoutTask
) => ({
  type: GET_FOLDER_REQUEST,
  payload: {
    signal,
    folderId,
    folderName,
    withoutTask,
  },
});

export const getFolderSuccess = (response) => ({
  type: GET_FOLDER_SUCCESS,
  payload: {
    response,
  },
});

export const addFileToFolder = (file) => ({
  type: ADD_FILE_TO_FOLDER,
  payload: {
    file,
  },
});

export const removeFileToFolder = (fileId) => ({
  type: REMOVE_FILE_TO_FOLDER,
  payload: {
    fileId,
  },
});

export const getFolderFailure = (error) => ({
  type: GET_FOLDER_FAILURE,
  payload: {
    error,
  },
});

export const setCurrentFolderName = (folderName) => ({
  type: SET_CURRENT_FOLDER_NAME,
  payload: {
    folderName,
  },
});

export const setCurrentFolderId = (folderId) => ({
  type: SET_CURRENT_FOLDER_ID,
  payload: {
    folderId,
  },
});

export const postFolderRequest = (signal, information) => ({
  type: POST_FOLDER_REQUEST,
  payload: {
    signal,
    information,
  },
});

export const postFolderSuccess = (response) => ({
  type: POST_FOLDER_SUCCESS,
  payload: {
    response,
  },
});

export const postFolderFailure = () => ({
  type: POST_FOLDER_FAILURE,
});

export const replaceFileInFolder = (file) => ({
  type: UPDATE_FILE_IN_DATA,
  payload: {
    file,
  },
});
