import { takeLatest, put, call, select } from 'redux-saga/effects';

import { showGlobalError } from '../../app/actions/app';
import { accessTokenSelector } from '../../auth/selectors/token';

import {
    GET_COMPANY_REQUEST, 
    getCompanySuccess, 
    getCompanyFailure,
} from '../actions/companies';

import { getCompanyService } from '../services/companies';

export const getCompanyWorker = function* ({ payload: { companyId }}){
    try {

        const token = yield select(accessTokenSelector);

        const { data: { company } } = yield call(getCompanyService, token, companyId);

        yield put(getCompanySuccess(company));
        
    } catch (err) {
        const { data: errorData } = err.response;

        yield put(getCompanyFailure(err));
        yield put(showGlobalError(true, errorData.statusCode, errorData.message));
    }
}

export default [
    takeLatest(GET_COMPANY_REQUEST, getCompanyWorker),
]
