import generalStats from './generalStats';
import creatorsRanking from './creatorsRanking';
import resolutionTime from './resolutionTime';
import responseTime from './responseTime';
import locations from './locations';
import taskByArea from './taskByArea';
import tasksResolved from './tasksResolved';
import taskByChannel from './taskByChannel';
import topTagsByChannel from './topTagsByChannel';


export default function* () {
    yield* [
        ...generalStats,
        ...creatorsRanking,
        ...responseTime,
        ...resolutionTime,
        ...locations,
        ...taskByArea,
        ...tasksResolved,
        ...taskByChannel,
        ...topTagsByChannel
    ];
}
