import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { postTestConversationMessagFailure, postTestConversationMessagSuccess, POST_TEST_CONVERSATION_MESSAGE_REQUEST } from "../actions/conversation";
import { postTestConversationMessageServices } from "../services/conversation";


export const postTestConversationMessageWorker = function* ({ payload: { botId, intentId, message } }) {
    try {
        const token = yield select(accessTokenSelector);
        let data= {
            intentId,
            message
        };
        const { data: resp } = yield call(postTestConversationMessageServices, token, botId, data);
            
        yield put(postTestConversationMessagSuccess(resp.previewResponse, resp.nextPreview));
    } catch (err) {
        console.log(err);
        yield put(postTestConversationMessagFailure(err));
    }
};

export default [
    takeLatest(POST_TEST_CONVERSATION_MESSAGE_REQUEST, postTestConversationMessageWorker),
]