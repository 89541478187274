export const GET_VARIABLES_REQUEST = 'GET_VARIABLES_REQUEST';
export const GET_VARIABLES_SUCCESS = 'GET_VARIABLES_SUCCESS';
export const GET_VARIABLES_FAILURE = 'GET_VARIABLES_FAILURE';

export const getVariablesRequest = (agentId = '') => ({
    type: GET_VARIABLES_REQUEST,
    payload: {
        agentId
    }
});

export const getVariablesSuccess = (botVariables) => ({
    type: GET_VARIABLES_SUCCESS,
    payload: {
        botVariables
    }
});

export const getVariablesFailure = err => ({
    type: GET_VARIABLES_FAILURE,
    error: err
});