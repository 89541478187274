import {
    SUBMIT_TEMPLATE_REQUEST,
    SUBMIT_TEMPLATE_FAILURE,
    SUBMIT_TEMPLATE_SUCCESS,
    SET_TEMPLATES,
    SET_TEMPLATE,
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_FAILURE,
    GET_TEMPLATES_SUCCESS,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    SET_TEMPLATES_LIMIT
} from '../actions/templates';

const initialState = {
    fetching: false,
    templates: [],
    template: {},
    fetchingSubmit: false,
    submitSuccess: false,
    total: 0,
    deleteFetching: false,
    deleteSuccess: false,
    page:0,
    limit:10,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_TEMPLATE_REQUEST: {
            return{
                ...state,
                fetchingSubmit: true,
                submitSuccess: false
            }
        }
        case SUBMIT_TEMPLATE_SUCCESS: {
            return{
                ...state,
                fetchingSubmit: false,
                submitSuccess: true
            }
        }
        case SUBMIT_TEMPLATE_FAILURE: {
            return{
                ...state,
                fetchingSubmit: false,
                submitSuccess: false
            }
        }
        case GET_TEMPLATES_REQUEST: {
            return{
                ...state,
                fetching: true,
                templates: [],
                deleteSuccess:false
            }
        }
        case GET_TEMPLATES_SUCCESS: {
            return{
                ...state,
                fetching: false,
                templates: state.templates.concat(action.payload.templates),
                total: action.payload.total
            }
        }
        case GET_TEMPLATES_FAILURE: {
            return{
                ...state,
                fetchingSubmit: false
            }
        }
        case SET_TEMPLATE: {
            return{
                ...state,
                template: action.payload.template,
                submitSuccess: false
            }
        }
        case SET_TEMPLATES: {
            return{
                ...state,
                templates: action.payload.templates
            }
        }  
        case SET_TEMPLATES_LIMIT:
            return {
                ...state,
                limit: action.payload.limit,
            }
        case DELETE_TEMPLATE_REQUEST: {
            return {
                ...state,
                deleteFetching: true,
                deleteSuccess: false

            }
        }
        case DELETE_TEMPLATE_SUCCESS: {
            return {
                ...state,
                deleteFetching: false,
                deleteSuccess: true
            }
        }
        case DELETE_TEMPLATE_FAILURE: {
            return {
                ...state,
                deleteFetching: false
            }
        }
        default:
            return state;
    }
}