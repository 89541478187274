import { accessTokenSelector } from "../../auth/selectors/token";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { showGlobalError } from "../../app/actions/app";
import { deleteStateFailure, deleteStateSuccess, DELETE_STATE_REQUEST, getStateByIdFailure, getStateByIdSuccess, getStatesFailure, getStatesSuccess, GET_STATES_REQUEST, GET_STATE_BY_ID_REQUEST, postStateFailure, postStateSuccess, POST_STATE_REQUEST, putStateFailure, putStateSuccess, PUT_STATE_REQUEST } from "../actions/states";
import { deleteStateServices, getStateByIdServices, getStatesServices, postStateServices, putStateServices } from "../services/states";
import { getVariablesRequest } from "../actions/variables";

export const getStatesWorker = function* ({ payload: { agentId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { states },
    } = yield call(getStatesServices, token, agentId);
    yield put(getStatesSuccess(states));
  } catch (err) {
    console.log(err);
    yield put(getStatesFailure(err));
  }
};

export const getStateByIdWorker = function* ({ payload: { agentId, stateId } }) {
    try {
      const token = yield select(accessTokenSelector);
      const {
        data: { state },
      } = yield call(getStateByIdServices, token, agentId, stateId);
      yield put(getStateByIdSuccess(state));
    } catch (err) {
      console.log(err);
      yield put(getStateByIdFailure(err));
    }
  };

export const postStateWorker = function* ({
  payload: { agentId, state: stateObject },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const { data: { state }}  = yield call(postStateServices, token, agentId, stateObject);

    yield put(postStateSuccess(state));
    if(state?.type === 'input' || state?.type === 'integration'){
      yield put(getVariablesRequest(agentId));
    }
  } catch (err) {
    console.log(err);
    yield put(postStateFailure(err));
    const { data: errorData } = err.response;
    if(errorData?.error?.errorKey === 'variable_exists'){
      yield put(showGlobalError(true, 409, 'variable_already_exists'));
    }
    else{
      yield put(showGlobalError(true,err));
    }
  }
};

export const putStateWorker = function* ({
  payload: { agentId, stateId, state: stateObject },
}) {
  try {
    const token = yield select(accessTokenSelector);
    const {
      data: { state },
    } = yield call(putStateServices, token, agentId, stateId, stateObject);
    yield put(putStateSuccess(state));
    if(state?.type === 'input' || state?.type === 'integration'){
      yield put(getVariablesRequest(agentId));
    }
  } catch (err) {
    console.log(err);
    yield put(putStateFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export const deleteStateWorker = function* ({ payload: { agentId, stateId } }) {
  try {
    const token = yield select(accessTokenSelector);
    const { data: { state, transitions } } = yield call(deleteStateServices, token, agentId, stateId);

    yield put(deleteStateSuccess(state._id, transitions));
    if(state?.type === 'input' || state?.type === 'integration'){
      yield put(getVariablesRequest(agentId));
    }
  } catch (err) {
    console.log(err);

    yield put(deleteStateFailure(err));
    yield put(showGlobalError(true, err));
  }
};

export default [
  takeLatest(GET_STATES_REQUEST, getStatesWorker),
  takeLatest(GET_STATE_BY_ID_REQUEST, getStateByIdWorker),
  takeLatest(POST_STATE_REQUEST, postStateWorker),
  takeLatest(PUT_STATE_REQUEST, putStateWorker),
  takeLatest(DELETE_STATE_REQUEST, deleteStateWorker),
];
