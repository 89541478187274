import { lazy } from "react";
import { areasSVG } from '../shared/helpers/icons';

const EntryPoint = lazy(() => import(/* webpackChunkName: "Areas - EntryPoint" */ './components/EntryPoint'));
const AreasLoaderList = lazy(() => import(/* webpackChunkName: "Areas - Loader" */ './components/ListLoader'));
const FormAreaLoader = lazy(() => import(/* webpackChunkName: "Areas - Loader" */ './components/FormUserLoader'));

export const defaultPath = `/settings/areas`;

export const innerRoutes = [
    {
        path: `${defaultPath}/`,
        Component: AreasLoaderList,
        exact: true,
        icon: areasSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'list_areas',
            plural: false
        }
    },
    {
        path: `${defaultPath}/new`,
        Component: FormAreaLoader,
        exact: true,
        icon: areasSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'new_area',
            plural: false
        }
    },
    {
        path: `${defaultPath}/:id`,
        Component: FormAreaLoader,
        exact: true,
        icon: areasSVG,
        title: {
            key: "",
            plural: false
        },
        subTitle: {
            key: 'modify_area',
            plural: false
        }
    },
];

const Areas = {
    path: defaultPath,
    Component: EntryPoint,
    exact: false,
    icon: areasSVG,
    title: {
        key: 'Areas',
        plural: false
    },
    subTitle: {
        key: 'Listado de Areas',
        plural: false
    },
    innerRoutes: innerRoutes,
    pathChilds: innerRoutes.map(e => e.path)
};

export default Areas;
