export const SET_CREATE_CUSTOM_ATTRIBUTE_POPUP_STATUS =
  "SET_CREATE_CUSTOM_ATTRIBUTE_POPUP_STATUS";

export const SET_UPDATE_CUSTOM_ATTRIBUTE_POPUP_STATUS =
  "SET_UPDATE_CUSTOM_ATTRIBUTE_POPUP_STATUS";

export const setCreateCustomAttributePopupStatus = ({ isOpen }) => ({
  type: SET_CREATE_CUSTOM_ATTRIBUTE_POPUP_STATUS,
  payload: {
    isOpen,
  },
});
export const setUpdateCustomAttributePopupStatus = ({ isOpen }) => ({
  type: SET_UPDATE_CUSTOM_ATTRIBUTE_POPUP_STATUS,
  payload: {
    isOpen,
  },
});
