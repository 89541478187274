import refresh from './refresh';
import signIn from './signIn';
import singout from './singout';
import register from './register'
import login from './login';

export default function* () {
    yield* [
        ...refresh,
        ...signIn,
        ...singout,
        ...register,
        ...login
    ];
}
