import { useSelector } from "react-redux";
import { accessTokenSelector } from "../../../auth/selectors/token";
import { useState, useEffect } from "react";
import PreferencesService from "../../../domain/services/Preferences/Preferences.service";

const useGetPreferences = () => {
  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const controller = new AbortController();

  const Service = new PreferencesService();

  const token = useSelector(accessTokenSelector);

  const get = async () => {
    try {
      if (!token) {
        throw new Error("Missing token");
      }

      setError(null);
      setData(null);
      setIsLoading(true);

      const preferences = await Service.getPreferences({
        token,
        signal: controller.signal,
      });

      setData(preferences);

    } catch (error) {
      console.log('error', error);
      setError(error);
    }finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return { data, isLoading, error, get };
};

export default useGetPreferences;
