import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';

export const getChannelsLoginsServices = (token, channelName, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/channels/login/${channelName}?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const postChannelsLoginsServices = (token, channelName, loginId, redirectUrl, filters) => 
    axios({
        method: 'post',
        url: `${api}/v2/channels/login/${channelName}?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
        data: {
            loginId,
            redirectUrl
        }
    });

export const getChannelsLoginsStatusServices = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/channels/login/${data.channelName}/${data.requestToken}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const getChannelsLoginsStatusServicesV2 = (token, channelName, loginId) => 
    axios({
        method: 'get',
        url: `${api}/v2/channels/login/${channelName}/status/${loginId}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });

export const deleteChannelLoginStatusServicesV2 = (token, channelName,loginId) => 
    axios({
        method: 'delete',
        url: `${api}/v2/channels/login/${channelName}/${loginId}}`,
        headers: {
            ...headers,
            'X-Access-Token': token,
        },
    });