import { takeLatest, put, call, select } from 'redux-saga/effects';
import { accessTokenSelector } from '../../auth/selectors/token';
import { getSuggestedContactsTagsFailure, getSuggestedContactsTagsSuccess, GET_SUGGESTED_CONTACTS_TAGS_REQUEST, postSuggestedContactsTagsFailure, postSuggestedContactsTagsSuccess, POST_SUGGESTED_CONTACTS_TAGS_REQUEST } from '../actions/tags';
import { getSuggestedContactsTagsService, postSuggestedContactsTagsService } from '../services/tags';

export const getSuggestedContactsTagsWorker = function* ({ payload: { value }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data: { tags } } = yield call(getSuggestedContactsTagsService, token, value);
        yield put(getSuggestedContactsTagsSuccess(tags));
    } catch (err) {

        yield put(getSuggestedContactsTagsFailure());
    }
}

export const postSuggestedContactsTagsWorker = function* ({ payload: { value }}){
    try {
        const token = yield select(accessTokenSelector);
        const { data } = yield call(postSuggestedContactsTagsService, token, value);
        yield put(postSuggestedContactsTagsSuccess(data));
    } catch (err) {

        yield put(postSuggestedContactsTagsFailure());
    }
}

export default [
    takeLatest(GET_SUGGESTED_CONTACTS_TAGS_REQUEST, getSuggestedContactsTagsWorker),
    takeLatest(POST_SUGGESTED_CONTACTS_TAGS_REQUEST, postSuggestedContactsTagsWorker),
]