import axios from 'axios';
import { api, headers } from '../../app/conf';
import { makeQuery } from '../../shared/helpers/commons';
import _ from 'lodash';

export const getPublicationsService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/outgoing?${ makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getPublicationServices = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/outgoing/${data.postId}?${makeQuery({ ...data.filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getPublicationsIncomingService = (token, filters) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/incoming?${makeQuery({ ...filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const getPublicationIncomingService = (token, data) => 
    axios({
        method: 'get',
        url: `${api}/v1/social/incoming/${data.postId}?${makeQuery({ ...data.filters })}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        }
    })

export const postPublicationService = (token, publication) =>
    axios({
        method: 'post',
        url: `${api}/v1/social/outgoing`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: {
            ...publication
        }
    })

export const putPublicationService = (token, publication) => {
    axios({
        method: 'put',
        url: `${api}/v1/publications/${publication._id}`,
        headers: {
            ...headers,
            'X-Access-Token': token
        },
        data: publication
    })
}