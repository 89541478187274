export const GET_RULES_REQUEST = 'GET_RULES_REQUEST';
export const GET_RULES_FAILURE = 'GET_RULES_FAILURE';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';

export const SET_RULES = 'SER_RULES';
export const SET_RULE = 'SET_RULE';

export const SUBMIT_RULE_REQUEST = 'SUBMIT_RULE_REQUEST';
export const SUBMIT_RULE_FAILURE = 'SUBMIT_RULE_FAILURE';
export const SUBMIT_RULE_SUCCESS = 'SUBMIT_RULE_SUCCESS';

export const DELETE_RULE_REQUEST = 'DELETE_RULE_REQUEST';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAILURE = 'DELETE_RULE_FAILURE';

export const PUT_RULE_REQUEST = "PUT_RULE_REQUEST";
export const PUT_RULE_SUCCESS = "PUT_RULE_SUCCESS";
export const PUT_RULE_FAILURE = "PUT_RULE_FAILURE";

export const SET_RULES_LIMIT = 'SET_RULES_LIMIT';

export const POST_AFFECTED_TASKS_REQUEST = "POST_AFFECTED_TASKS_REQUEST";
export const POST_AFFECTED_TASKS_SUCCESS = "POST_AFFECTED_TASKS_SUCCESS";
export const POST_AFFECTED_TASKS_FAILURE = "POST_AFFECTED_TASKS_FAILURE";

export const getRulesRequest = (filters = {}) => ({
    type: GET_RULES_REQUEST,
    payload: {
        filters
    }
});
export const getRulesSuccess = (rules) => ({
    type: GET_RULES_SUCCESS,
    payload: {
        rules
    }
});

export const getRulesFailure = (err) => ({
    type: GET_RULES_FAILURE,
    error: err
});

export const setRule = (rule) => ({
    type: SET_RULE,
    payload: {
        rule
    }
});

export const setRules = (rules = []) => ({
    type: SET_RULES,
    payload: {
        rules
    }
});

export const setRulesLimit = (limit) => ({
    type: SET_RULES_LIMIT,
    payload: { limit }
})

export const submitRuleRequest = (rule) => ({
    type: SUBMIT_RULE_REQUEST,
    payload: {
        rule
    }
});

export const submitRuleSuccess = (rule) => ({
    type: SUBMIT_RULE_SUCCESS,
    payload: {
        rule
    }
});

export const submitRuleFailure = (err) => ({
    type: SUBMIT_RULE_FAILURE,
    error: err
});

export const deleteRuleRequest = (id) => ({
    type: DELETE_RULE_REQUEST,
    payload: { id }
});

export const deleteRuleSuccess = () => ({
    type: DELETE_RULE_SUCCESS,
    payload: { }
});

export const deleteRuleFailure = (err) => ({
    type: DELETE_RULE_FAILURE,
    payload: { err }
});

export const putRuleRequest = ( rule ) => ({
    type: PUT_RULE_REQUEST,
    payload: { rule }
});

export const putRuleSuccess = ( rule ) => ({
    type: PUT_RULE_SUCCESS,
    payload: { rule }
});

export const putRuleFailure = (error = {}) => ({
    type: PUT_RULE_FAILURE,
    payload: { error }
});

export const postAffectedTasksRequest = ( rule ) => ({
    type: POST_AFFECTED_TASKS_REQUEST,
    payload: { rule }
});

export const postAffectedTasksSuccess = (tasks) => ({
    type: POST_AFFECTED_TASKS_SUCCESS,
    payload: {tasks}
});

export const postAffectedTasksFailure = (error = {}) => ({
    type: POST_AFFECTED_TASKS_FAILURE,
    payload: { error }
});